import React from 'react';
import styled from '@emotion/styled';
import { Input, Props as InputProps } from './Input';
import { Currency } from 'utils/string';
import classNames from 'classnames';
import euroIcon from 'assets/icons/euro.svg';

interface Props extends Partial<InputProps> {
  name: string;
}

const CurrencyInputComponent = ({ className, ...rest }: Props) => {
  const toViewValue = (value: string) => formatNumber(value);
  const toModelValue = (value: string) => {
    return value.replace(/[^\d.]|,/g, '') || '0';
  };

  return (
    <Input
      className={classNames(className, `icon-${Currency.EUR}`)}
      type="text"
      toViewValue={toViewValue}
      toModelValue={toModelValue}
      {...rest}
    />
  );
};

export const CurrencyInput = styled(CurrencyInputComponent)`
  .input-container {
    display: flex;
    &::before {
      content: '';
      display: block;
      width: calc(1.75rem + 2px);
      border: solid 1px var(--primary-dark-100);
      border-right: 0;
      background-color: var(--primary-dark-blue-50);
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 0.125rem 0 0 0.125rem;
    }

    input {
      border-left: solid 1px var(--primary-dark-100);
      border-radius: 0 0.125rem 0.125rem 0;
    }
  }

  ${Object.values(Currency).map(
  curr => `
    &.icon-${curr} {
      .input-container::before {
        background-image: url(${euroIcon});
      }
    }
  `
)}
`;

const formatNumber = (value: string): string => {
  if (!isNaN(Number(value))) {
    const addDot = value.toString().endsWith('.');
    const valueArray = value.toString().split('.');
    let cost = Number(valueArray[0]).toLocaleString('en-GB');
    if (valueArray.length > 1 && valueArray[1] !== '') {
      if (valueArray[1].length > 2) {
        cost = Number(value).toLocaleString('en-GB', { maximumFractionDigits: 2 });
      } else {
        cost = cost + '.' + valueArray[1];
      }
    }
    return addDot ? cost + '.' : cost;
  }

  return formatNumber(
    String(value)
      /* remove non number characters */
      .replace(/[^\d.,]/, '')
      /* remove last dot from input like: '12.22.' */
      .replace(/(\..*)(\.)/, (_, p1) => p1)
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { getT } from 'utils/framework/intl';
import { PaymentsViewModel } from './PaymentsViewModel';
import { useConnect } from 'utils/framework';
import { Button } from 'modules/ui-components/Button';
import { Filters } from './components/Filters';
import { Table } from 'modules/ui-components/table/Table';
import { RequestPagination } from 'modules/ui-components/RequestPagination';
import { PAYMENTS_LIMIT } from 'utils/constants';
import { PaymentIntentStatus } from 'models/Payment';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  className?: string;
}

const PaymentsPageComponent = ({ className }: Props) => {
  const vm = useConnect(PaymentsViewModel);
  const { cardPayments } = useFlags();
  const _t = getT();

  if (!cardPayments) vm.redirectToDashboard();

  return (
    <div className={className}>
      <div className="header">
        <h1 className="page__header">{_t('payments.title')}</h1>
        <Filters isDisabled={vm.state.areFiltersDisabled} />
      </div>
      <Table
        tableHead={vm.state.tableHead}
        tableBody={vm.state.tableBody}
        isLoading={vm.state.isLoading}
        noContentNode={_t('payments.noPayments')}
        renderColumns={{
          4: row =>
            row.status === PaymentIntentStatus.PENDING ? (
              <Button
                onClick={() => vm.checkoutPaymentIntent(row.paymentId)}
                className="primary pay-btn"
              >
                {_t('payments.pay')}
              </Button>
            ) : (
              <div className="empty-action"></div>
            ),
        }}
      />
      <RequestPagination
        className="pagination"
        isLoading={vm.state.isLoading}
        hasNextPage={vm.state.paymentIntentListHasNextPage}
        hasPreviousPage={vm.state.paymentIntentListOffset >= PAYMENTS_LIMIT}
        previous={vm.loadPreviousPage}
        next={vm.loadNextPage}
      />
    </div>
  );
};

export const PaymentsPage = styled(PaymentsPageComponent)`
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .empty-action {
    min-height: 2.625rem;
  }

  .pay-btn {
    width: 5rem;
  }

  .pagination {
    margin-top: 1.25rem;
  }
`;

import { fork, put, takeEvery } from '@redux-saga/core/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notifyError } from 'modules/notification/store';
import { container } from 'tsyringe';
import { getT } from 'utils/framework/intl';
import { State } from 'utils/store';
import { CrmService } from 'services/CrmService';
import { Customer, Partner } from 'models/UserType';
import { ProfileData } from 'models/User';
import { captureException } from 'utils/reporting';

export interface ProfileState {
  profile: ProfileData | null;
  profileRetrievalFailed: boolean;
  isProfileLoading: boolean;
}

const initialState = {
  profile: null,
  profileRetrievalFailed: false,
  isProfileLoading: false,
} as ProfileState;

export const profile = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    FetchCustomerProfile: (
      state: ProfileState,
      action: PayloadAction<{ customerId: string }>
    ) => {},
    FetchPartnerProfile: (state: ProfileState, action: PayloadAction<{ partnerId: string }>) => {
      state.isProfileLoading = true;
    },
    FetchProfileComplete: (state: ProfileState, action: PayloadAction<ProfileData>) => {
      state.isProfileLoading = false;
      state.profile = action.payload;
    },
    FetchProfileFailed: (state: ProfileState) => {
      state.isProfileLoading = false;
      state.profileRetrievalFailed = true;
    },
  },
});

export const profileActions = {
  ...profile.actions,
};

function* fetchProfile() {
  const crmService = container.resolve(CrmService);
  const _t = getT();

  yield takeEvery(profileActions.FetchCustomerProfile, function* (action) {
    try {
      const customerId = action.payload.customerId;
      const data: Customer = yield crmService.fetchCustomer(customerId);
      yield put(profileActions.FetchProfileComplete(formatCustomerProfile(data)));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'profile data' })));
      yield put(profileActions.FetchProfileFailed());
    }
  });

  yield takeEvery(profileActions.FetchPartnerProfile, function* (action) {
    try {
      const partnerId = action.payload.partnerId;
      const data: Partner = yield crmService.fetchPartner(partnerId);
      yield put(profileActions.FetchProfileComplete(formatPartnerProfile(data)));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'profile data' })));
      yield put(profileActions.FetchProfileFailed());
    }
  });
}

export function* profileSaga() {
  yield fork(fetchProfile);
}

export const profileSelector = (state: State) => state[profile.name] as ProfileState;

const formatCustomerProfile = (customer: Customer) => {
  return {
    name: customer.fullName || '',
    email: customer.email || '',
    address1: customer.address_1 || '',
    address2: customer.address_2 || '',
    address3: customer.address_3 || '',
    county: customer.county || '',
    country: customer.country || '',
    phone: customer.phone || '',
  };
};

const formatPartnerProfile = (partner: Partner) => {
  return {
    name: partner.accountName || '',
    email: partner.companyEmail || '',
    address1: partner.billingStreet || '',
    address2: partner.address_2 || '',
    address3: partner.address_3 || '',
    county: partner.county || '',
    country: partner.country || '',
    phone: partner.phone || '',
  };
};

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { LeadStage, Lead as LeadType } from 'models/Lead';
import { getT } from 'utils/framework/intl';
import { Breakpoints, useBreakpoint } from 'utils/styling';
import { formatCurrency } from 'utils/string';
import { Button } from 'modules/ui-components/Button';
import { Arrow } from 'modules/ui-components/Arrow';
import { LeadDetails } from './LeadDetails';
import { LeadModal } from './LeadModal';
import { Tooltip } from 'modules/ui-components/Tooltip';
import { ZohoLoanProduct } from 'models/Application';

interface Props {
  className?: string;
  lead: LeadType;
  canModifyLead: boolean;
  editLead: (id: string) => void;
  cancelLead: (id: string) => void;
  uploadDocuments: (id: string) => void;
}

const LeadComponent = ({
  className,
  lead,
  canModifyLead,
  editLead,
  cancelLead,
  uploadDocuments,
}: Props) => {
  const _t = getT();
  const [openDetails, setOpenDetails] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLaptop] = useBreakpoint(Breakpoints.laptop, { useBody: true });

  const leadStage = lead.leadStage === LeadStage.Draft ? LeadStage.Draft : LeadStage.Submitted;
  const isPersonalMotorFinance = lead.product === ZohoLoanProduct.PersonalMotorFinance;
  const companyName = !isPersonalMotorFinance
    ? `${lead.contactFirstName} ${lead.contactLastName}`
    : lead.applicantName;

  const sections = [
    [
      {
        label: _t('leads.email'),
        value: (
          <Tooltip
            trigger="click"
            tooltipContent={() => <div style={{ wordBreak: 'break-word' }}>{lead.email}</div>}
            placement={'topLeft'}
          >
            <p className="tooltip">{lead.email}</p>
          </Tooltip>
        ),
      },
      { label: _t('leads.phone'), value: lead.phone },
    ],
    [
      { label: _t('leads.term'), value: lead.term },
      {
        label: _t('leads.loanPurpose'),
        value: !isPersonalMotorFinance ? lead.businessLine : _t('leads.personalMotorFinance'),
      },
    ],
    [
      { label: _t('leads.fundingAmount'), value: formatCurrency(lead.assetCost) },
      {
        label: !isPersonalMotorFinance ? _t('leads.companyName') : _t('leads.name'),
        value: (
          <Tooltip
            trigger="click"
            tooltipContent={() => <div style={{ wordBreak: 'break-word' }}>{companyName}</div>}
            placement={isLaptop ? 'topLeft' : 'topRight'}
          >
            <p className="tooltip">{companyName}</p>
          </Tooltip>
        ),
      },
    ],
  ];

  return (
    <div className={className}>
      <div className="top">
        {isLaptop && (
          <div className="openDetails">
            <div className="openDetails--arrow" onClick={() => setOpenDetails(!openDetails)}>
              <Arrow open={openDetails} isLaptop={isLaptop} />
            </div>
          </div>
        )}

        <div className="informations">
          <div className="header">
            <p className="header-title value">
              {lead.contactFirstName} {lead.contactLastName}
            </p>
            <div className="header-status">
              <p className="value">{leadStage || _t('loans.notAvailable')}</p>
              <p className="label">{_t('loans.status')}</p>
            </div>
          </div>
          {sections.map((section, i) => (
            <div key={i} className="section">
              {section.map(({ label, value }) => (
                <div className="labeled-value" key={label}>
                  <div className="value value--small">{value || _t('loans.notAvailable')}</div>
                  <p className="label">{label}</p>
                </div>
              ))}
            </div>
          ))}
        </div>

        {canModifyLead && (
          <div className="actions">
            <Button
              onClick={() => editLead(lead.id)}
              className="broker-primary broker-primary--simple"
            >
              {_t('leads.continue')}
            </Button>
            <Button
              onClick={() => uploadDocuments(lead.id)}
              className="broker-primary broker-primary--simple"
            >
              {_t('leads.uploadDocuments')}
            </Button>
            <Button onClick={() => setModalOpen(true)} className="primary">
              {_t('leads.cancel')}
            </Button>
          </div>
        )}
      </div>
      {openDetails && <LeadDetails lead={lead} />}

      {!isLaptop && (
        <div className="openDetails--mobile" onClick={() => setOpenDetails(!openDetails)}>
          <Arrow open={openDetails} isLaptop={isLaptop} />
        </div>
      )}

      <LeadModal
        isOpen={modalOpen}
        confirmAction={() => {
          cancelLead(lead.id);
          setModalOpen(false);
        }}
        cancelAction={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );
};
export const Lead = styled(LeadComponent)`
  margin-bottom: 1rem;
  border: var(--border-default);
  border-radius: var(--border-radius);

  .top {
    display: flex;
    gap: 1rem;
    padding: 1rem;

    @media (max-width: ${Breakpoints.laptop}px) {
      flex-direction: column;
    }

    .openDetails {
      min-width: 2rem;
      padding: 0.5rem;
      &--arrow {
        margin-top: 2.375rem;
        cursor: pointer;
      }
    }

    .informations {
      flex: 1;
      gap: 1rem;
      display: grid;
      grid-template-areas: 'header header header';
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

      @media (max-width: ${Breakpoints.laptop}px) {
        grid-template-areas: 'header';
        grid-template-columns: 1fr;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        grid-area: header;
        padding-bottom: 0.375rem;
        border-bottom: 1px solid var(--primary-dark-blue-50);

        &-title {
          color: var(--primary-dark-blue-500);
          flex: 2;
        }

        &-status {
          flex: 1;

          .label {
            margin: 0;
          }

          @media (max-width: ${Breakpoints.laptop}px) {
            flex: 2;
            text-align: right;
          }
        }
      }

      .section {
        border-right: 1px solid var(--primary-dark-blue-50);
        padding-right: 1rem;

        @media (max-width: ${Breakpoints.laptop}px) {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          padding-right: 0;
          border-right: none;

          .labeled-value:last-child {
            text-align: right;
          }
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding-left: 1rem;
      border-left: 1px solid var(--primary-dark-blue-50);

      @media (max-width: ${Breakpoints.laptop}px) {
        padding: 0;
        margin: 0;
        border-left: none;
      }

      button {
        height: 2rem;
        @media (max-width: ${Breakpoints.laptop}px) {
          height: auto;
        }
      }
    }
  }

  .openDetails--mobile {
    display: grid;
    place-items: center;
    padding: 1rem;
    border-top: 1px solid var(--primary-dark-blue-50);
    cursor: pointer;
  }

  .tooltip {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
    cursor: pointer;
  }

  .label {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
    margin: 0 0 0.5rem 0;
  }

  .value {
    font-family: var(--font-family-secondary);
    color: var(--primary-blue-500);
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 0;

    &--small {
      margin-top: 0.375rem;
      color: var(--primary-blue-500);
      font-size: 0.75rem;
      font-weight: 600;
      font-family: var(--font-family-primary);
      line-height: 1.67;
      letter-spacing: normal;
    }
  }
`;

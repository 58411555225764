import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { _t } from 'utils/string';
import { Breakpoints } from 'utils/styling';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';

export type RowDataType = string | JSX.Element | number | undefined;
export type TableBodyType = Array<RowDataType[]>;

export type RenderColumnsType = Record<number, (row: any) => JSX.Element> | undefined;

interface Props {
  tableHead: string[];
  tableBody: TableBodyType;
  renderColumns: RenderColumnsType;
  isLoading?: boolean;
  noContentNode?: ReactNode;
  className?: string;
}

export const maxTextLength = 25;

export const TableComponent = ({
  tableHead,
  tableBody,
  renderColumns,
  noContentNode,
  isLoading,
  className,
}: Props) => {
  const emptyTable = !tableBody.length && noContentNode;
  if (isLoading || emptyTable) {
    return (
      <table className={className}>
        <TableHeader tableHeaders={tableHead} />
        <tbody>
          <tr>
            {isLoading && <td className="tableCell">{_t('uiComponents.loading')}</td>}
            {emptyTable && !isLoading && <td className="tableCell">{noContentNode}</td>}
          </tr>
        </tbody>
      </table>
    );
  }

  return (
    <table className={className}>
      <TableHeader tableHeaders={tableHead} />
      <TableBody tableBody={tableBody} renderColumns={renderColumns} tableHeaders={tableHead} />
    </table>
  );
};

export const Table = styled(TableComponent)`
  border: 1px solid var(--primary-dark-100);
  border-radius: var(--border-radius);
  border-spacing: 0;
  font-size: 0.875rem;
  width: 100%;
  margin: 0;

  @media (max-width: ${Breakpoints.phone}px) {
    font-size: 0.75rem;
  }

  .tooltip {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &__child {
      cursor: pointer;
    }

    @media (max-width: ${Breakpoints.tablet}px) {
      white-space: normal;
      word-break: break-all;
      pointer-events: none;
    }
  }

  .tableCell {
    padding: 0.625rem;

    @media (max-width: ${Breakpoints.tablet}px) {
      text-align: center;
    }
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import { _t } from 'utils/string';

interface Props {
  className?: string;
}

const UploadFileLoadingComponent = ({ className }: Props) => {
  return (
    <div className={className}>
      <p>{_t('uiComponents.loading')}</p>
    </div>
  );
};

export const UploadFileLoading = styled(UploadFileLoadingComponent)`
  min-height: 30vh;
`;

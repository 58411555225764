import React from 'react';
import styled from '@emotion/styled';
import { Footer } from './components/Footer';
import { Link } from 'modules/ui-components/Link';
import { Route } from 'modules/routing';
import { ReactComponent as Logo } from 'assets/logo.svg';
import Notification from 'modules/notification/Notification';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const AnonymousLayoutComponent = ({ children, className }: Props) => {
  return (
    <div className={className}>
      <header>
        <Link to={Route.Dashboard} className="logo">
          <Logo />
        </Link>
      </header>
      <main>
        <Notification />
        <div className="layout__content">{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export const AnonymousLayout = styled(AnonymousLayoutComponent)`
  min-height: 100vh;
  display: grid;
  grid-template:
    'header' 3.75rem
    'main' 1fr
    'footer' min-content
    / 1fr;
  background-color: var(--primary-dark-blue-25);

  & > header {
    grid-area: header;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    background-color: var(--primary-dark-000);

    .logo {
      line-height: 0;
    }
  }

  & > main {
    position: relative;
    z-index: 1;

    --bg-image: linear-gradient(
      94deg,
      var(--primary-green-300),
      var(--primary-blue-500) 39%,
      var(--primary-dark-blue-600) 75%
    );
    background: var(--primary-dark-blue-25) var(--bg-image) no-repeat;
    background-size: 100% 15rem;
  }

  .layout__content {
    width: 100%;
    margin: auto;
    max-width: 42.75rem;
  }

  ${Footer} {
    grid-area: footer;
    z-index: 10;
  }
`;

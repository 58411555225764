import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';

interface Props {
  className?: string;
}

const HelpComponent = ({ className }: Props) => {
  return <div className={className} />;
};

export const Help = styled(HelpComponent)`
  border-left: 1px solid var(--primary-dark-blue-50);
  background-color: var(--primary-dark-blue-25);

  @media only screen and (min-width: ${Breakpoints.laptop}px) {
    border: 0;
  }
`;

import { createSelector } from '@reduxjs/toolkit';
import autobind from 'autobind-decorator';
import {
  CompanyTypes,
  Steps,
  UCGSToLoan,
  VisionNetCompany,
  VisionNetInputTypes,
  ZohoLoanProduct,
  loanToUCGS,
} from 'models/Application';
import {
  ClientDetailsFormValues,
  Lead,
  LeadStage,
  MotorFinanceClientDetailsValues,
  MotorFinanceLeadDetailsValues,
  LeadDetailsFormValues,
} from 'models/Lead';
import { User, UserType } from 'models/User';
import { auth0Selector, isBorrowerSelector, isBrokerSelector } from 'modules/auth/authStore';
import {
  CalculatorState,
  calculatorActions,
  calculatorSelector,
} from 'modules/leads/loan-calculator/store';
import { leadActions, leadSelector } from 'modules/leads/store';
import { routingSelector } from 'modules/routing/store';
import { BaseOption } from 'modules/ui-components/Select';
import { singleton } from 'tsyringe';
import { IRELAND_CONF, NEW_LEAD, SPECIAL_USE_EMAIL_DOMAIN_NAMES } from 'utils/constants';
import { ViewModel } from 'utils/framework';
import { debounce } from 'utils/function';

interface State {
  lead?: Partial<Lead>;
  user?: User;
  isBorrower: boolean;
  isPersonalMotorFinanceFlow: boolean;
  canSeeLeadDetails: boolean;
  canEditClient: boolean;
  canSeeFooterButtons: boolean;
  canSetLoanProduct: boolean;
  isLeadUpdating: boolean;
  isNewLead: boolean;
  isUcgsAvailable: boolean;
  isTimeWithEmployerLessThanOneYear: boolean;
  calculatorValues: CalculatorState;
  companiesLoading: boolean;
  companies: VisionNetCompany[];
  companyNames: BaseOption[];
  companyNumbers: BaseOption[];
}

interface SubmitLeadDetails<T> {
  values: T;
  saveForLater?: boolean;
  step?: Steps;
  errorKeys?: Array<keyof T>;
}

type ClientDetailsValuesOption = MotorFinanceClientDetailsValues | ClientDetailsFormValues;

export const hasSpecialUseDomainName = (email: string) => {
  const domain = email.split('.').at(-1);
  return !SPECIAL_USE_EMAIL_DOMAIN_NAMES.includes(domain!);
};

export const previousAddressFields = [
  'previousAddress_1',
  'previousAddress_2',
  'previousAddress_3',
  'previousCounty',
  'previousCountry',
  'previousEircode',
];

export const previousEmployerFields = [
  'previousEmployer',
  'timeWithPreviousEmployerYears',
  'timeWithPreviousEmployerMonths',
];

@singleton()
@autobind
export class ClientDetailsViewModel extends ViewModel {
  state!: State;

  saveClient = (values: ClientDetailsValuesOption) => {
    if (this.state.lead) {
      this.store.dispatch(leadActions.UpdateLead({ values: this.formatCreateLead(values) }));
    } else {
      const calculatorValues = calculatorSelector(this.store.getState()).calculatorValues;
      const createLeadValues = this.formatCreateLead(values);
      const userDetailsValues = this.getUserDetails();
      this.store.dispatch(
        leadActions.CreateLead({ ...calculatorValues, ...createLeadValues, ...userDetailsValues })
      );
    }
    this.state.canSeeLeadDetails = true;
  };

  getUserDetails = (): Partial<Lead> => {
    return {
      portalUserEmail: this.state.user?.email,
    };
  };

  formatCreateLead = (values: ClientDetailsValuesOption) => {
    if (this.state.isPersonalMotorFinanceFlow) {
      return this.formatMotorFinanceLead(values as MotorFinanceClientDetailsValues);
    }

    return this.formatLead(values as ClientDetailsFormValues);
  };

  searchEircode = (
    values: MotorFinanceLeadDetailsValues | LeadDetailsFormValues,
    eircode: string,
    isPrevious = false
  ) => {
    this.store.dispatch(leadActions.UpdateCurrentLead(values));
    this.store.dispatch(
      leadActions.SearchEircode({
        eircode: eircode.replaceAll(' ', '').toUpperCase(),
        country: values.country,
        isPrevious,
      })
    );
  };

  formatLead = (values: ClientDetailsFormValues) => {
    const initValues = {
      applicantType: values.companyType,
      contactFirstName: values.contact.firstName,
      contactLastName: values.contact.lastName,
      dateOfBirth: values.contact.dateOfBirth,
      ppsn: values.contact.ppsn,
      email: values.contact.email,
      product: values.loanProduct,
      leadSource: getLeadSource(this.state.user?.userType),
      leadStage: this.state.isNewLead ? LeadStage.Draft : undefined,
      introducer: !this.state.isBorrower
        ? {
            name: this.state.user?.nickname || '',
            id: this.state.user?.zohoId || '',
          }
        : undefined,
    };

    if (values.companyType === CompanyTypes.COMPANY) {
      return {
        ...initValues,
        applicantName: values.companyName,
        companyRegistrationNumber: values.companyNum,
      };
    }

    return {
      ...initValues,
      applicantName: `${values.contact.firstName} ${values.contact.lastName}`,
      companyRegistrationNumber: '',
    };
  };

  formatMotorFinanceLead = (values: MotorFinanceClientDetailsValues): Partial<Lead> => {
    return {
      ...values,
      email: values.email || this.state.user?.email,
      numberOfDependants: values.numberOfDependants !== '' ? values.numberOfDependants : undefined,
      maritalStatus: values.maritalStatus !== '' ? values.maritalStatus : undefined,
      applicantType: CompanyTypes.PERSONAL,
      applicantName: `${values.contactFirstName} ${values.contactLastName}`,
      leadSource: getLeadSource(this.state.user?.userType),
      leadStage: this.state.isNewLead ? LeadStage.Draft : undefined,
      introducer: !this.state.isBorrower
        ? {
            name: this.state.user?.nickname || '',
            id: this.state.user?.zohoId || '',
          }
        : undefined,
    };
  };

  updateCalculatorValues = (values: Partial<Lead>) => {
    this.store.dispatch(calculatorActions.UpdateCalculatorValues(values));
  };

  allowToEditClient = () => {
    this.store.dispatch(leadActions.SetCanEditClient(true));
  };

  setIsTimeWithEmployerLessThanOneYear = (isTimeWithEmployerLessThanOneYear: boolean) => {
    this.store.dispatch(
      leadActions.SetIsTimeWithEmployerLessThanOneYear(isTimeWithEmployerLessThanOneYear)
    );
  };

  submitMotorFinanceLeadDetails = ({
    values,
    saveForLater,
    step,
    errorKeys,
  }: SubmitLeadDetails<MotorFinanceLeadDetailsValues>) => {
    if (!this.state.lead) return;

    if (saveForLater) {
      errorKeys?.forEach(key => delete values[key]);
    }

    if (!values.livedAtThisAddressForLessThan_3Years || this.state.isBorrower) {
      previousAddressFields.forEach(
        key => delete values[key as keyof MotorFinanceLeadDetailsValues]
      );
    } else {
      values.previousCountry = IRELAND_CONF;
    }

    const formatedValues = {
      ...values,
      timeAtAddressYears: formatTime(values.timeAtAddressYears),
      timeAtAddressMonths: formatTime(values.timeAtAddressMonths),
      timeWithEmployerYears: formatTime(values.timeWithEmployerYears),
      timeWithEmployerMonths: formatTime(values.timeWithEmployerMonths),
      timeWithPreviousEmployerYears: formatTime(values.timeWithPreviousEmployerYears),
      timeWithPreviousEmployerMonths: formatTime(values.timeWithPreviousEmployerMonths),
    };

    this.store.dispatch(leadActions.UpdateLead({ values: formatedValues, step, saveForLater }));
  };

  submitLeadDetails = ({
    values,
    saveForLater,
    step,
    errorKeys,
  }: SubmitLeadDetails<LeadDetailsFormValues>) => {
    if (!this.state.lead) return;

    if (saveForLater) {
      errorKeys?.forEach(key => delete values[key]);
    }

    if (values.ucgsProduct && this.state.lead?.product !== ZohoLoanProduct.PersonalMotorFinance) {
      const detailsWithUCGS: Partial<Lead> = {
        ...values,
        ucgsProduct: true,
        product: this.state.lead.product ? loanToUCGS[this.state.lead.product] : undefined,
      };
      this.store.dispatch(leadActions.UpdateLead({ values: detailsWithUCGS, step, saveForLater }));
      return;
    }

    const detailsWithoutUCGS: Partial<Lead> = {
      ...values,
      product: this.state.lead.product ? UCGSToLoan[this.state.lead.product] : undefined,
      ucgsProduct: false,
      ucgsEligibilityCode: '',
    };
    this.store.dispatch(leadActions.UpdateLead({ values: detailsWithoutUCGS, step, saveForLater }));
  };

  findCompany = (params: { value: string; type: VisionNetInputTypes }) => {
    this.store.dispatch(leadActions.FindCompany(params));
  };

  onCompanyChange = (params: { value: string; type: VisionNetInputTypes }) => {
    const userTypingInterval = debounce(() => this.findCompany(params), 750);
    if (params.value.length >= 3) userTypingInterval();
  };

  protected stateMapper = createSelector(
    leadSelector,
    auth0Selector,
    calculatorSelector,
    isBrokerSelector,
    isBorrowerSelector,
    routingSelector,
    (leads, auth, calculatorValues, isBroker, isBorrower, route): State => {
      const companyNumbers = leads.companies.map(company => ({
        value: company.companyNum,
        label: company.companyName,
      }));
      const companyNames = leads.companies.map(company => ({
        value: company.companyName,
        label: company.companyName,
      }));
      return {
        lead: leads.lead,
        user: auth.user,
        isBorrower: isBorrower,
        isPersonalMotorFinanceFlow:
          leads.lead?.product === ZohoLoanProduct.PersonalMotorFinance ||
          calculatorValues.calculatorValues?.product === ZohoLoanProduct.PersonalMotorFinance,
        canSeeLeadDetails: !!leads.lead,
        canEditClient: leads.canEditClient,
        canSeeFooterButtons: !leads.canEditClient,
        canSetLoanProduct: isBroker && route.params.id === NEW_LEAD,
        isLeadUpdating: leads.isUpdating,
        isNewLead: route.params.id === NEW_LEAD,
        isUcgsAvailable: isBorrower || isBroker,
        isTimeWithEmployerLessThanOneYear: leads.isTimeWithEmployerLessThanOneYear,
        calculatorValues: calculatorValues,
        companiesLoading: leads.companiesLoading,
        companies: leads.companies,
        companyNames: leads.companies.length > 0 ? [defaultCompanyOption, ...companyNames] : [],
        companyNumbers:
          leads.companies.length > 0
            ? [
                defaultCompanyOption,
                ...companyNumbers.map(val => {
                  return { value: val.value.toString(), label: val.label };
                }),
              ]
            : [],
      };
    }
  );
}

export const defaultCompanyOption = { label: '', value: '' };

const getLeadSource = (userType?: UserType) => {
  switch (userType) {
    case UserType.Borrower:
      return 'Customer Portal';
    case UserType.Broker:
      return 'Broker Portal';
    case UserType.Dealer:
      return 'Dealer Portal';
  }
};

const formatTime = (time?: string) => {
  if (time === '') return null;
  return time;
};

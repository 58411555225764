import React, { ChangeEvent, useState } from 'react';
import styled from '@emotion/styled';
import { getT } from '../../utils/framework/intl';
import { Button } from './Button';
import { KEY_ENTER } from 'utils/constants';
import { useRef } from 'react';

interface Props {
  className?: string;
  isSearchingForAsset: boolean;
  setIsSearchingForAsset: (isSearchingForAsset: boolean) => void;
  search: (registrationNumber: string) => void;
}

export const RegistrationNumberInputComponent = ({
  className,
  search,
  isSearchingForAsset,
  setIsSearchingForAsset,
}: Props) => {
  const _t = getT();
  const [registrationNumber, setRegistrationNumber] = useState('');
  const registrationNumberInput = useRef<HTMLInputElement>(null);

  const submit = (registrationNumber: string) => {
    if (registrationNumberInput.current?.reportValidity()) {
      setIsSearchingForAsset(true);
      search(registrationNumber);
    }
  };

  return (
    <div className={className}>
      <div className="container">
        <div className="prefix">
          <div className="ireland">{_t('form.ireland')}</div>
          <div className="uk">{_t('form.uk')}</div>
        </div>
        <div className="input-wrapper">
          <input
            type="text"
            placeholder={_t('form.searchAssetByRegistrationNumber')}
            disabled={isSearchingForAsset}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setRegistrationNumber(event.target.value)
            }
            ref={registrationNumberInput}
            onKeyPress={e => {
              if (e.which === KEY_ENTER) {
                submit(registrationNumber);
                e.preventDefault();
              }
            }}
            required
          />
        </div>
      </div>
      <div className="submit">
        <Button
          type="button"
          className="broker-primary broker-primary--simple"
          onClick={() => {
            submit(registrationNumber);
          }}
          disabled={isSearchingForAsset}
        >
          {isSearchingForAsset ? <>{_t('form.searching')}...</> : <>{_t('form.search')}</>}
        </Button>
      </div>
    </div>
  );
};

export const RegistrationNumberInput = styled(RegistrationNumberInputComponent)`
  display: flex;
  .container {
    width: 100%;
    display: flex;
    border: 0.175rem var(--primary-dark-blue-700) solid;
    border-radius: 0.3rem;
    height: 3rem;

    .prefix {
      width: 15%;
      height: 100%;
      background-color: var(--primary-dark-blue-500);
      text-align: center;
      line-height: 1;
      border-top-left-radius: 0.13rem;
      border-bottom-left-radius: 0.13rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      div {
        width: 100%;
      }

      .ireland {
        margin-bottom: -0.3rem;
        color: var(--primary-blue-50);
      }

      .uk {
        margin-top: -0.3rem;
        color: var(--european-yellow);
      }
    }

    .input-wrapper {
      width: 85%;
      padding-left: 0.4rem;

      input {
        width: 100%;
        height: 100%;
        border: none;
      }

      input:focus {
        outline: none;
      }
    }
  }

  .submit {
    width: 25%;
    margin-left: 1rem;
    margin-bottom: 0.15rem;
  }
`;

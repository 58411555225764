import styled from '@emotion/styled';

export const Button = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: var(--border-default);
  border-radius: var(--border-radius);
  border-color: var(--primary-dark-blue-300);
  padding: 0.625rem 1.5rem;
  width: 100%;
  position: relative;

  font-size: 0.875rem;
  line-height: 1.4rem;
  text-align: center;
  color: var(--primary-dark-blue-900);

  cursor: pointer;

  &:disabled {
    background-image: unset;
    background-color: var(--primary-dark-blue-100);
    pointer-events: none;
    color: var(--primary-dark-300);
  }

  &.primary {
    border: 1px transparent solid;
    background-image: linear-gradient(
      111deg,
      var(--secondary-pink-500) 1%,
      var(--secondary-pink-600)
    );
    color: var(--primary-dark-000);

    &--simple {
      background-image: unset;
      background-color: var(--secondary-pink-500);
    }

    &:hover,
    &:focus {
      background-image: unset;
      background-color: var(--secondary-pink-600);
    }

    &:disabled {
      background-image: unset;
      background-color: var(--secondary-pink-200);
      pointer-events: none;
    }

    &:active {
      background-image: unset;
      background-color: var(--secondary-pink-700);
    }
  }

  &.broker-primary {
    border: 1px transparent solid;
    background-image: linear-gradient(111deg, var(--primary-green-400), var(--primary-blue-500));
    color: var(--primary-dark-000);

    &--simple {
      background-image: unset;
      background-color: var(--primary-dark-blue-500);
    }

    &:hover,
    &:focus {
      background-image: unset;
      background-color: var(--primary-dark-blue-600);
    }

    &:disabled {
      background-image: unset;
      background-color: var(--primary-dark-blue-200);
      pointer-events: none;
    }

    &:active {
      background-image: unset;
      background-color: var(--primary-dark-blue-700);
    }
  }

  &.broker-danger {
    border: 1px transparent solid;
    background-color: var(--additional-red-600);
    color: var(--primary-dark-000);

    &--simple {
      background-image: unset;
      background-color: var(--primary-dark-blue-500);
    }

    &:hover,
    &:focus {
      background-image: unset;
      background-color: var(--primary-dark-blue-600);
    }

    &:disabled {
      background-image: unset;
      background-color: var(--primary-dark-blue-200);
      pointer-events: none;
    }

    &:active {
      background-image: unset;
      background-color: var(--primary-dark-blue-700);
    }
  }

  &.text {
    border: none;

    &--simple {
      background-image: unset;
      background-color: var(--primary-dark-000);
    }

    &:hover,
    &:focus {
      background-image: unset;
      background-color: var(--primary-blue-50);
    }

    &:disabled {
      background-image: unset;
      background-color: var(--primary-dark-300);
      pointer-events: none;
    }

    &:active {
      background-image: unset;
      background-color: var(--primary-blue-100);
    }
  }
`;

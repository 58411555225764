import { ZohoLoanProduct } from 'models/Application';
import { Gender, MartialStatus } from 'models/Lead';
import { getT } from 'utils/framework/intl';

export const GENDERS = [
  {
    value: Gender.Male,
    label: Gender.Male,
  },
  {
    value: Gender.Female,
    label: Gender.Female,
  },
];

export const GET_LOAN_TYPES = () => {
  const _t = getT();

  return [
    {
      value: ZohoLoanProduct.Leasing,
      label: _t(`loans.leasing`),
    },
    {
      value: ZohoLoanProduct.HirePurchase,
      label: _t(`loans.hirePurchase`),
    },
    {
      value: ZohoLoanProduct.PersonalMotorFinance,
      label: _t(`loans.personalMotorFinance`),
    },
  ];
};

export const MARTIAL_STATUSES = [
  { value: MartialStatus.Married, label: MartialStatus.Married },
  { value: MartialStatus.Single, label: MartialStatus.Single },
  { value: MartialStatus.Separated, label: MartialStatus.Separated },
  { value: MartialStatus.Divorced, label: MartialStatus.Divorced },
  { value: MartialStatus.Widowed, label: MartialStatus.Widowed },
  { value: MartialStatus.Unknown, label: MartialStatus.Unknown },
];

export const HOME_STATUSES = [
  { value: 'Homeowner', label: 'Homeowner' },
  { value: 'Tenant', label: 'Tenant' },
  { value: 'Other', label: 'Other' },
];

export const EMPLOYMENT_STATUS = [
  { value: 'Full Time', label: 'Full Time' },
  { value: 'Part Time', label: 'Part Time' },
];

export const TIMELINES = [
  { value: 'Immediately', label: 'Immediately' },
  { value: 'Within 1 month', label: 'Within 1 month' },
  { value: 'Within 3 months', label: 'Within 3 months' },
  { value: 'Just looking', label: 'Just looking' },
];

export const MONTHS_NUMBERS = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
];

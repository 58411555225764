import React from 'react';
import styled from '@emotion/styled';

interface Props {
  label: string;
  value: string;
  className?: string;
  isCompanyNumber?: boolean;
}

export const Title = styled.div`
  margin: 1.5rem 0;
  padding: 0.625rem 0;
  border-bottom: 1px solid var(--primary-dark-blue-50);
  font-weight: bold;
`;

const CompanyOptionComponent = ({ className, label, value, isCompanyNumber }: Props) => {
  return (
    <div className={className}>
      <p className="value">{value}</p> {isCompanyNumber && <p className="label">{label}</p>}
    </div>
  );
};

export const CompanyOption = styled(CompanyOptionComponent)<{ isCompanyNumber?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 1.25rem;

  .value {
    font-weight: ${props => props.isCompanyNumber && 'bold'};
    line-height: 1.5;
    margin: 0;
  }

  .label {
    font-size: 8px;
    margin: 0;
  }
`;

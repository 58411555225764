import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';
import { getT } from 'utils/framework/intl';
import { Loan as LoanType } from 'models/Loan';
import { formatDate } from 'utils/date';

interface Props {
  loan: LoanType;
  className?: string;
}

const LoanComponent = ({ className, loan }: Props) => {
  const _t = getT();

  return (
    <div className={className}>
      <div className="section">
        <div className="labeled-value labeled-value--title">
          <p className="label">{loan.loanId}</p>
          <p className="value">{loan.loanName ? loan.loanName : _t('loans.notAvailable')}</p>
        </div>
      </div>

      <div className="section">
        <div className="labeled-value">
          <p className="value">{loan.loanAmount ? loan.loanAmount : _t('loans.notAvailable')}</p>
          <p className="label">{_t('loans.financeAmount')}</p>
        </div>
        <div className="labeled-value">
          <p className="value">
            {loan.nextPaymentAmount ? loan.nextPaymentAmount : _t('loans.notAvailable')}
          </p>
          <p className="label">{_t('loans.nextPaymentAmount')}</p>
        </div>
      </div>

      <div className="section">
        <div className="labeled-value">
          <p className="value value--small">
            {loan.dateOpened ? formatDate(loan.dateOpened) : _t('loans.notAvailable')}
          </p>
          <p className="label">{_t('loans.startDate')}</p>
        </div>
      </div>
      <div className="section">
        <div className="labeled-value">
          <p className="value">
            {loan.currentBalance ? loan.currentBalance : _t('loans.notAvailable')}
          </p>
          <p className="label">{_t('loans.currentBalance')}</p>
        </div>
      </div>
      <div className="button-container"></div>
    </div>
  );
};

export const Loan = styled(LoanComponent)`
  margin: 1rem 0;
  border-radius: var(--border-radius);
  border: var(--border-default);
  background-color: var(--primary-dark-000);
  padding: 0.5rem 1rem;
  display: grid;

  .section {
    padding: 1rem 0 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3) {
      border-bottom: 1px solid var(--primary-dark-blue-50);
    }
  }

  .labeled-value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:last-of-type:not(:first-of-type) {
      text-align: right;
    }

    .label {
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-300);
      margin: 0 0 0.5rem;
    }

    .value {
      font-family: var(--font-family-secondary);
      color: var(--primary-blue-500);
      font-size: 1.125rem;
      line-height: 1.56;
      letter-spacing: 0.2px;
      font-weight: bold;
      margin: 0;

      &--small {
        font-family: var(--font-family-primary);
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1.67;
        letter-spacing: normal;
        color: var(--primary-blue-500);
        margin-top: 0.375rem;
      }
    }

    &--title {
      .label {
        font-size: 1rem;
        line-height: 1.75;
        margin: 0 0 0.375rem;
      }

      .value {
        color: var(--primary-dark-blue-500);
        margin: 0 0 1rem;
      }
    }
  }

  @media only screen and (min-width: ${Breakpoints.laptop}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    padding: 1rem 1rem 0.5rem 4rem;

    .section {
      padding: 0 2rem;
      &:nth-of-type(1) {
        grid-column: span 3;
      }

      &:nth-of-type(1n) {
        /* Reset borders for all secitions */
        border: 0;
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-left: 0;
      }

      &:nth-of-type(1) {
        border-bottom: 1px solid var(--primary-dark-blue-50);
      }

      &:nth-of-type(3),
      &:nth-of-type(4) {
        border-left: 1px solid var(--primary-dark-blue-50);
      }

      flex-direction: column;
    }

    .labeled-value {
      &:last-of-type:not(:first-of-type) {
        text-align: left;
      }
    }
  }

  .button-container {
    grid-row: -1 / span 2;
    grid-column: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid var(--primary-dark-blue-50);
    padding-left: 1rem;
  }
`;

import styled from '@emotion/styled';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import classNames from 'classnames';

export interface Option {
  name: string;
  value: string;
}

interface Props {
  className?: string;
  placeholder: string;
  options: Array<Option>;
  submitEvent: (options: Array<Option>) => void;
}

const areArraysEqual = (arrayA: Array<any>, arrayB: Array<any>) => {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const differenceA = arrayA.filter(arrayAItem => !arrayB.includes(arrayAItem));
  return !differenceA.length;
};

const getSelectedFields = (selectedFields: Array<Option>, placeholder: string) => {
  if (selectedFields.length === 0) {
    return <p className="content">{placeholder}</p>;
  }

  if (selectedFields.length === 1) {
    return <p className="content">{selectedFields[0].name}</p>;
  }

  return (
    <>
      <p className="content">{selectedFields[0].name}</p> <span>+{selectedFields.length - 1}</span>
    </>
  );
};

const MultiSelectComponent = ({ className, options, placeholder, submitEvent }: Props) => {
  const [areOptionsOpen, setAreOptionsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Array<Option>>([]);
  const [oldSelectedOptions, setOldSelectedOptions] = useState<Array<Option>>([]);

  const openOptionsText = getSelectedFields(selectedOptions, placeholder);

  const toggleOptionSelection = (option: Option) => {
    const alreadyExist = selectedOptions.find(
      selectedOption => selectedOption.value === option.value
    );

    if (alreadyExist) {
      const newList = selectedOptions.filter(
        selectedOption => selectedOption.value !== option.value
      );

      setSelectedOptions(newList);
      return;
    }

    setSelectedOptions([...selectedOptions, option]);
  };

  const submitSelectedOptions = () => {
    const updateRequired = !areArraysEqual(oldSelectedOptions, selectedOptions);

    if (updateRequired) {
      setOldSelectedOptions(selectedOptions);
      submitEvent(selectedOptions);
    }
    setAreOptionsOpen(false);
  };

  const clearSelectedOptions = () => {
    setSelectedOptions([]);
  };

  return (
    <OutsideClickHandler onOutsideClick={submitSelectedOptions} disabled={!areOptionsOpen}>
      <div className={className}>
        <button className="openOptions-btn" onClick={() => setAreOptionsOpen(!areOptionsOpen)}>
          {openOptionsText}
          <ArrowDown className={classNames('icon', { 'icon-open': areOptionsOpen })} />
        </button>
        {areOptionsOpen && (
          <div className="options">
            <div className="list">
              {options.map((option, idx) => {
                const isSelected = !!selectedOptions.find(
                  selectedOption => selectedOption.value === option.value
                );
                return (
                  <label
                    key={`${idx}-${option.value}`}
                    className={classNames('option', { 'option--active': isSelected })}
                  >
                    <p>{option.name}</p>
                    <input
                      onChange={() => toggleOptionSelection(option)}
                      value={option.value}
                      type="checkbox"
                      checked={isSelected}
                    />
                  </label>
                );
              })}
            </div>
            <div className="footer">
              <button onClick={clearSelectedOptions} className="clear">
                Clear
              </button>
              <button onClick={submitSelectedOptions} className="apply">
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export const MultiSelect = styled(MultiSelectComponent)`
  position: relative;
  color: var(--primary-dark-blue-900);

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .openOptions-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding: 0 0.5rem;

    background-color: transparent;
    font-weight: bold;
    color: var(--primary-dark-blue-900);
    border: none;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;

      background-color: var(--primary-dark-50);
      padding: 0.25rem;
      border-radius: 0.25rem;
    }

    .content {
      padding: 0.25rem 0;
      max-width: 10rem;
      font-size: 0.875rem;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      margin-left: 0.25rem;
      transition: ease 200ms transform;

      &-open {
        transform: rotate(-180deg);
      }
    }
  }

  .options {
    position: absolute;
    right: 0;
    z-index: 2;

    min-width: 15.5rem;
    margin-top: 0.25rem;

    background-color: white;
    border: var(--border-default);
    border-radius: var(--border-radius);
    box-shadow: rgba(43, 34, 51, 0.12) 0px 4px 24px;

    .list {
      overflow-y: auto;
      max-height: 10rem;

      .option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;

        font-size: 0.75rem;
        font-weight: bold;
        border-bottom: var(--border-light);
        cursor: pointer;

        &:hover,
        &--active {
          background-color: var(--primary-dark-blue-25);
        }

        & input {
          accent-color: var(--primary-dark-blue-500);
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      padding: 0.5rem;
      border-top: var(--border-light);

      button {
        padding: 0.375rem 1rem;
        font-size: 0.75rem;
        font-weight: bold;

        border: none;
        border-radius: 0.25rem;
        cursor: pointer;
      }

      .clear {
        background: transparent;
        color: var(--primary-dark-blue-900);
        border: var(--border-default);

        &:hover,
        &:focus {
          background-color: var(--primary-dark-blue-25);
        }

        &:active {
          background-color: var(--primary-dark-blue-50);
        }
      }

      .apply {
        background: var(--primary-dark-blue-500);
        color: var(--primary-dark-000);

        &:hover,
        &:focus {
          background-color: var(--primary-dark-blue-600);
        }

        &:active {
          background-color: var(--primary-dark-blue-700);
        }
      }
    }
  }
`;

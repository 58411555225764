export const profile = {
  customerDetails: 'Customer details',
  customerName: 'Name',
  customerEmail: 'Email Address',
  customerAddress1: 'Address Line 1',
  customerAddress2: 'Address Line 2',
  customerAddress3: 'Address Line 3',
  customerCountry: 'Country',
  customerCounty: 'County',
  phoneNumber: 'Phone number',
};

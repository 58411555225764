import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import { useField } from 'formik';
import { ReactComponent as IrishFlag } from 'assets/flags/ireland.svg';
import { ReactComponent as UnitedKingdomFlag } from 'assets/flags/united-kingdom.svg';
import { usePrevious } from 'utils/function';
import { PhoneNumberPrefixes } from 'models/Loan';

enum PhoneNumberPlaceholders {
  Ireland = '87XXXXXX',
  England = '7XXXXXXXX',
}

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  className?: string;
  label?: React.ReactNode;
}

const CustomOption = ({ children, ...props }: any) => {
  return (
    <components.Option {...props} className="prefix-option">
      {GetCountryFlag(props.value)}
      {children}
    </components.Option>
  );
};

const separatePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.includes(PhoneNumberPrefixes.Ireland)) {
    const prefix = phoneNumber.slice(0, 4);
    const number = phoneNumber.slice(4);
    return { prefix, number };
  } else {
    const prefix = phoneNumber.slice(0, 3);
    const number = phoneNumber.slice(3);
    return { prefix, number };
  }
};

const GetCountryFlag = (prefix: string) => {
  if (prefix === PhoneNumberPrefixes.Ireland) {
    return <IrishFlag className="country-flag" />;
  } else {
    return <UnitedKingdomFlag className="country-flag" />;
  }
};

const PhoneNumberComponent = ({ name, className, label, disabled }: Props) => {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [selectedNumber, setSelectedNumber] = useState({ prefix: '', number: '' });
  const [placeholder, setPlaceholder] = useState('');

  const invalid = meta.touched && meta.error;
  const prefixes = [
    {
      label: PhoneNumberPrefixes.Ireland,
      value: PhoneNumberPrefixes.Ireland,
    },
    {
      label: PhoneNumberPrefixes.England,
      value: PhoneNumberPrefixes.England,
    },
  ];
  const prefixValue = field.value && prefixes.find(prefix => field.value.includes(prefix.value));

  useEffect(() => {
    if (selectedNumber.prefix === PhoneNumberPrefixes.Ireland) {
      setPlaceholder(PhoneNumberPlaceholders.Ireland);
    } else if (selectedNumber.prefix === PhoneNumberPrefixes.England) {
      setPlaceholder(PhoneNumberPlaceholders.England);
    } else {
      setPlaceholder('');
    }
  }, [selectedNumber.prefix]);

  useEffect(() => {
    setValue(selectedNumber.prefix + selectedNumber.number);
  }, [selectedNumber]);

  const previousFormValue = usePrevious(field.value);
  useEffect(() => {
    if (field.value && field.value !== previousFormValue) {
      const { prefix, number } = separatePhoneNumber(field.value);
      setSelectedNumber({ prefix, number });
    }
  }, [field.value, selectedNumber]);

  return (
    <label className={classNames(className, { invalid })}>
      <div className="label">{label}</div>
      <div className="phone-number-container">
        <div className="prefix">
          <Select
            name="prefix"
            classNamePrefix={'select'}
            options={prefixes}
            components={{ Option: CustomOption }}
            onChange={(option: any) => {
              setSelectedNumber({
                ...selectedNumber,
                prefix: option.value,
              });
            }}
            defaultValue={prefixValue}
            value={prefixValue}
          />
        </div>
        <div className="phone-number">
          <input
            name={name}
            type="text"
            onChange={(e: any) => {
              setSelectedNumber({
                ...selectedNumber,
                number: e.target.value,
              });
            }}
            onBlur={() => setTouched(true)}
            placeholder={placeholder}
            value={selectedNumber.number}
            disabled={disabled}
          />
        </div>
      </div>
      {invalid ? <div className="error">{meta.error}</div> : null}
    </label>
  );
};

export const PhoneNumber = styled(PhoneNumberComponent)`
  .phone-number-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    gap: 0.65rem;
  }

  .prefix-option {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .country-flag {
    width: 25px;
  }

  .select__control--is-disabled {
    background-color: var(--primary-dark-blue-50) !important;
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__control {
    border-radius: 2px;
    box-shadow: none;
    border: var(--border-default);
  }

  .select__menu {
    margin: 0.125rem 0;
    border-radius: 2px;
    border: var(--border-default);
    box-shadow: 0 2px 6px -2px rgba(0, 38, 73, 0.16);
  }

  .select__menu-list {
    padding: 0.125rem;
  }


  .select__input-container {
    margin: 0;
    padding: 0;
  }

  .select__option {
    border-radius: 1px;
    font-size: 0.875rem;
    line-height: 1.71;
    color: var(--primary-dark-blue-900);

    &--is-focused {
      background-color: var(--primary-dark-blue-25);
    }

    &--is-selected {
      background-color: var(--primary-dark-blue-50);
    }

    &--is-disabled {
      background-color: var(--primary-dark-100);
    }
  }

  .select__input,
  .select__single-value,
  .select__placeholder {
    font-size: 0.875rem;
    line-height: 1.71;
  }

  .select__placeholder {
    color: var(--primary-dark-300);
  }

  .label {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-900);
    margin-bottom: 0.5rem;
  }

  .error {
    position: relative;
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--additional-red-500);
    margin-top: 0.125rem;
  }

  &.invalid {
    .select__control {
      border-color: var(--additional-red-500);
    }

    input {
      border-color: var(--additional-red-500);
    }
  }

  input {
    border-radius: 0.125rem;
    background-color: var(--primary-dark-000);
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.71;
    border: none;
    width: 100%;
    min-width: 0; /* Firefox fix */
    border: var(--border-default);

    &:focus {
      outline: none;
      border: solid 1px var(--primary-dark-blue-400);
    }

    &:disabled {
      background-color: var(--primary-dark-blue-50);
      cursor: default;

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      box-shadow: 0 0 0 1px rgba(0, 142, 214, 0.2);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 1.875rem white inset !important;
    }

    &::placeholder {
      color: var(--primary-dark-300);
    }
  }
`;

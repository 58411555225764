import styled from '@emotion/styled';

export const SmallHeading = styled('h3')`
  font-family: var(--font-family-secondary);
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: 0.2px;
`;

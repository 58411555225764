export function extendObject<T extends { [key: string]: any }>(a: T, b: T): T {
  return Object.entries(a)
    .map(([k, v]) => [k, b[k] ?? v])
    .reduce((acc, [k, v]) => {
      acc[k] = v;
      return acc;
    }, {} as any);
}

export const getFileObject = (uploadedFiles: string[]) => {
  return uploadedFiles
    ? uploadedFiles.map((file: string) => ({
        file: undefined,
        saved: true,
        name: file,
        completed: true,
      }))
    : [];
};

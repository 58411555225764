import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as Hamburger } from 'assets/icons/hamburger.svg';
import { Breakpoints, useBreakpoint } from 'utils/styling';
import { LoanCalculatorBar } from 'modules/ui-components/layouts/primary-layout/LoanCalculatorBar/LoanCalculatorBar';
import { useConnect } from 'utils/framework';
import { Link } from 'modules/ui-components/Link';
import { Route } from 'modules/routing';
import classNames from 'classnames';
import { LoanCalculator } from 'modules/leads/loan-calculator/LoanCalculator';
import { LogoutButton } from './LogoutButton';
import { LoanCalculatorViewModel } from './LoanCalculatorBar/LoanCalculatorViewModel';

interface Props {
  className?: string;
  logout: () => void;
}

const HeaderComponent = ({ className, logout }: Props) => {
  const [isLaptop] = useBreakpoint(Breakpoints.laptop, { useBody: true });
  const vm = useConnect(LoanCalculatorViewModel);

  return (
    <div className={className}>
      <div className="hamburger-container">
        <Hamburger className="hamburger" data-cy="hamburger-menu" onClick={vm.toggleDrawer} />
      </div>
      <div className="logo-container">
        <Link to={Route.Dashboard} className="logo">
          <Logo />
        </Link>
      </div>
      <GradientBar className={classNames({ broker: vm.state.isBroker })}>
        <div className="calculator-container">
          <LoanCalculatorBar />
        </div>
      </GradientBar>
      {isLaptop && (
        <div className="logout">
          <LogoutButton onClick={logout} disabled={vm.state.isLogoutDisabled} />
        </div>
      )}

      {vm.state.calculatorOpen && (
        <LoanCalculator open={vm.state.calculatorOpen} toggleOpen={vm.toggleCalculator} />
      )}
    </div>
  );
};

const GradientBar = styled('div')`
  background-image: linear-gradient(
    94deg,
    var(--primary-green-300),
    var(--primary-blue-500) 39%,
    var(--primary-dark-blue-600) 75%
  );

  &.broker {
    background-image: linear-gradient(93deg, var(--primary-blue-500), var(--primary-dark-blue-500));
  }
`;

export const Header = styled(HeaderComponent)`
  left: 0;
  top: 0;

  width: 100%;
  background-color: var(--primary-dark-000);
  z-index: 100;

  --button-container-width: 3.25rem;
  display: grid;
  grid-template:
    'buttons logo' var(--header-height)
    'gradient gradient' var(--header-height)
    'logout logout' min-content
    / var(--button-container-width) 1fr;

  .hamburger-container {
    position: fixed;
    grid-area: buttons;
    top: 1.25rem;
    left: 1rem;
    z-index: 101;
  }

  .hamburger {
    justify-self: center;
    align-self: center;
  }

  .logo-container {
    grid-area: logo;
    width: 100%;
    position: fixed;
    padding: 1.125rem 0 1rem;
    justify-self: center;
    text-align: center;
    background-color: var(--primary-dark-000);
    z-index: 100;
    line-height: 1;
  }

  .logo {
    justify-self: center;
    align-self: center;
    margin-right: var(---button-container-width);
  }

  .logout {
    grid-area: logout;
  }

  ${GradientBar} {
    grid-area: gradient;
    position: fixed;
    z-index: 100;
    top: var(--header-height);
    width: 100%;
    display: flex;
    justify-content: center;

    .calculator-container {
      flex: 1;
      max-width: var(--content-max-width);
      width: 100%; /* For ellipsis */
    }
  }

  @media only screen and (min-width: ${Breakpoints.laptop}px) {
    position: fixed;
    grid-template:
      '. logo logout .' var(--header-height)
      'gradient gradient gradient gradient' var(--header-height)
      / 1fr minmax(auto, calc(var(--content-max-width) / 2)) minmax(
        auto,
        calc(var(--content-max-width) / 2)
      )
      1fr;

    .hamburger-container {
      display: none;
    }

    .logo-container {
      justify-self: start;
      width: auto;
      position: initial;
      padding: 0 0 0 1.5rem;
      background: none;
      align-self: center;
    }

    .logout {
      align-self: center;
      justify-self: right;
      grid-area: logout;

      button {
        width: 10rem;
        margin-right: 1rem;
      }
    }
  }
`;

export const layout = {
  locations: 'Locations',
  email: 'Email',
  phone: 'Phone',
  privacyPolicy: 'Privacy Policy',
  tos: 'Terms & Conditions',
  copywrite: '© Capitalflow {year}',
  dashboard: 'Dashboard',
  myDocuments: 'My Documents',
  users: 'Users',
  loggedAs: 'Logged In as',
  askQuestion: 'Got a question?',
  contactExperts: 'Contact your expert now!',
  assetsFinanceSupport: 'Capitalflow Support',
  startChat: 'Start a chat',
  needSomeHelp: 'Need some help?',
  contact: 'Contact',
  aboutUs: 'About Us',
  knowledgeHub: 'Knowledge Hub',
  changeCompany: 'Change company',
  invitations: 'Invitations',
  payments: 'Payments',
  profile: 'Your Profile',
  maintenance: 'Maintenance',
  weWillBack: "We'll Be Right Back!",
  maintenanceInfo: 'Sorry, this site is undergoing maintenance and will be back soon!',
  unverifiedTitle: 'Unverified user',
  unverifiedMessage:
    'We sent you a verification email, you need to verify it. Haven`t received email?',
  unverifiedButton: 'Click here to re-send.',
};

import React from 'react';

import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';

interface Props {
  open: boolean;
  isLaptop: boolean;
  upPlaceholder?: string;
  downPlaceholder?: string;
}

export const Arrow = ({ open, isLaptop, upPlaceholder, downPlaceholder }: Props) => {
  if (isLaptop) {
    return open ? <ArrowUp /> : <ArrowDown />;
  } else {
    return open ? (
      <>
        <ArrowUp /> {upPlaceholder}
      </>
    ) : (
      <>
        <ArrowDown /> {downPlaceholder}
      </>
    );
  }
};

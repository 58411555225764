import React, { ComponentType } from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import classNames from 'classnames';

interface Option {
  label: string;
  value: any;
  isDisabled?: boolean;
}

interface Props {
  className?: string;
  name: string;
  label?: React.ReactNode;
  options: Option[];
  components?: {
    Label: ComponentType<{ children: any; option: Option }>;
  };
  asRadio?: boolean;
  onChange?: (value: any) => void;
}

const ToggleComponent = ({
  className,
  label,
  name,
  options,
  asRadio,
  components,
  onChange,
}: Props) => {
  const [field, , { setValue, setTouched }] = useField(name);

  return (
    <label className={classNames(className, { asRadio })}>
      {label && <div className="label">{label}</div>}
      <span className="values">
        {options.map((o, i) => (
          <RadioInput
            name={name}
            setValue={setValue}
            setTouched={setTouched}
            onChange={onChange}
            selected={field.value === o.value}
            components={components}
            option={o}
            asRadio={asRadio!}
            key={i}
          />
        ))}
      </span>
    </label>
  );
};

interface InputProps {
  setValue: (value: any) => void;
  setTouched: (value: any) => void;
  onChange?: (value: any) => void;
  selected: boolean;
  name: string;
  option: Option;
  asRadio: boolean;
  components?: {
    Label: ComponentType<{ children: any; option: Option }>;
  };
}

const RadioInput = ({
  name,
  setValue,
  setTouched,
  selected,
  option,
  asRadio,
  components,
  onChange,
}: InputProps) => {
  const LabelComponent = components?.Label || Label;

  return (
    <label
      className={classNames({
        'toggle-radio': asRadio,
        'toggle-button': !asRadio,
        selected,
        disabled: option.isDisabled,
      })}
    >
      <input
        type="radio"
        name={name}
        value={option.value}
        checked={selected}
        hidden={!asRadio}
        disabled={option.isDisabled}
        onChange={() => {
          if (onChange) {
            onChange(option.value);
          }
          setValue(option.value);
          setTouched(true);
        }}
      />
      <LabelComponent option={option}>{option.label}</LabelComponent>
    </label>
  );
};

const Label = ({ children }: any) => <span className="option-label">{children}</span>;

export const Toggle = styled(ToggleComponent)`
  display: flex;
  flex-direction: column;

  .label {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-900);
    margin-bottom: 0.75rem;
  }

  .values {
    display: flex;
  }

  .toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;

    flex: 1;
    padding: 0.5rem 0;
    text-align: center;
    border: solid 1px var(--primary-dark-blue-100);
    background-color: var(--primary-dark-blue-25);
    cursor: pointer;

    &.disabled {
      cursor: auto;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }

    .option-label {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.71;
      color: var(--primary-dark-300);
    }

    &.selected {
      background-color: var(--primary-blue-500);
      border: solid 1px var(--primary-blue-500);
      .option-label {
        color: var(--primary-dark-000);
      }
    }
  }

  .toggle-radio {
    font-size: 0.875rem;
    line-height: 1.71;
    color: var(--primary-dark-blue-900);
    padding-left: 1.75rem;
    margin-bottom: 0.5rem;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      left: 0.5rem;
      top: 0.25rem;
      border: solid 1px var(--primary-dark-100);
      background-color: var(--primary-dark-blue-25);
    }

    &.selected {
      &::before {
        border: solid 1px var(--primary-blue-500);
      }
      &::after {
        position: absolute;
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        left: 0.75rem;
        top: 0.5rem;
        background-color: var(--primary-blue-500);
      }
    }

    input {
      opacity: 0;
      position: absolute;
    }

    .option-label {
      margin-left: 0.5rem;
    }
  }
`;

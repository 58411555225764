export enum NotificationStatuses {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export interface Notification {
  type: NotificationStatuses;
  message: string;
}

import { createSlice } from '@reduxjs/toolkit';
import { changeChatbotVisibility } from 'utils/function';

export interface UIState {
  drawer: {
    open: boolean;
  };
  calculator: {
    open: boolean;
  };
}

export const ui = createSlice({
  name: 'ui',
  initialState: { drawer: {}, calculator: {} } as UIState,
  reducers: {
    ToggleDrawer(state: UIState) {
      state.drawer.open = !state.drawer.open;
    },
    ToggleCalculatorAction(state: UIState) {
      state.calculator.open = !state.calculator.open;
      changeChatbotVisibility(state.calculator.open);
    },
  },
});

export const uiActions = {
  ...ui.actions,
};

export const uiSelector = (state: any) => state[ui.name] as UIState;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentIntent, PaymentIntentStatus } from 'models/Payment';
import { notifyError } from 'modules/notification/store';
import { Route } from 'modules/routing/routes';
import { routingActions } from 'modules/routing/store';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { PaymentsService } from 'services/PaymentsService';
import { container } from 'tsyringe';
import { getT } from 'utils/framework/intl';
import { captureException } from 'utils/reporting';
import { State } from 'utils/store';
import { _t } from 'utils/string';

export interface PaymentCheckoutState {
  intent: PaymentIntent | null;
  isIntentLoading: boolean;
}

const initialState: PaymentCheckoutState = {
  intent: null,
  isIntentLoading: true,
};

export const paymentCheckout = createSlice({
  name: 'paymentCheckout',
  initialState: initialState,
  reducers: {
    RedirectToPaymentCheckout: (
      state: PaymentCheckoutState,
      action: PayloadAction<{ id: string }>
    ) => {},
    FetchPaymentIntent: (state: PaymentCheckoutState, action: PayloadAction<{ id: string }>) => {
      state.isIntentLoading = true;
    },
    FetchPaymentIntentCompleted: (
      state: PaymentCheckoutState,
      action: PayloadAction<{ intent: PaymentIntent }>
    ) => {
      state.isIntentLoading = false;
      state.intent = action.payload.intent;
    },
  },
});

export const paymentCheckoutActions = {
  ...paymentCheckout.actions,
};

export const paymentCheckoutSelector = (state: State) =>
  state[paymentCheckout.name] as PaymentCheckoutState;

function* handleFetchPaymentIntent() {
  const paymentService = container.resolve(PaymentsService);
  const _t = getT();

  yield takeEvery(paymentCheckoutActions.FetchPaymentIntent, function* ({ payload }) {
    try {
      const intent: PaymentIntent = yield paymentService.getPaymentIntent(payload.id);
      if (intent.status !== PaymentIntentStatus.PENDING) {
        yield put(
          routingActions.Navigate({
            route: Route.Dashboard,
            params: {},
          })
        );
        return;
      }
      yield put(paymentCheckoutActions.FetchPaymentIntentCompleted({ intent }));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'record' })));
    }
  });
}

function* handleRedirect() {
  yield takeEvery(paymentCheckoutActions.RedirectToPaymentCheckout, function* (action) {
    try {
      yield put(
        routingActions.Navigate({
          route: Route.PaymentCheckout,
          params: { id: action.payload.id },
        })
      );
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.redirectToPaymentCheckoutFailed')));
    }
  });
}

export function* paymentCheckoutSaga() {
  yield fork(handleRedirect);
  yield fork(handleFetchPaymentIntent);
}

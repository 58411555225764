import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { Lead } from 'models/Lead';
import { dashboardSelector, dashboardActions } from 'modules/dashboard/store';
import { leadActions } from 'modules/leads/store';
import { Module, uploadDocumentsActions } from 'modules/upload-documents/store';
import { hasPermission, Permission } from 'modules/auth/services/PermissionsService';
import { auth0Selector, isBrokerSelector } from 'modules/auth/authStore';
import { NEW_LEAD } from 'utils/constants';
import { uiActions } from 'modules/ui-components/store';
import { User } from 'models/User';

@singleton()
@autobind
export class LeadsViewModel extends ViewModel {
  unsubscribe!: () => void;
  state!: {
    isBroker: boolean;
    leads: Lead[];
    leadsHasNextPage: boolean;
    leadsHasPreviousPage: boolean;
    leadsAreLoading: boolean;
    currentLeadPage: number;
    user: User;
    isUnverified: boolean;
  };

  protected connected() {
    const { currentLeadPage } = this.state;
    this.store.dispatch(dashboardActions.FetchLeads({ page: currentLeadPage }));
  }

  editLead = (id: string) => {
    this.store.dispatch(leadActions.RedirectToLeadSteps(id));
  };

  cancelLead = (id: string) => {
    this.store.dispatch(dashboardActions.cancelLead({ id }));
  };

  uploadDocuments = (id: string) => {
    this.store.dispatch(
      uploadDocumentsActions.RedirectToUploadDocuments({ id: id, module: Module.LEAD })
    );
  };

  goToNextLeadPage = () => {
    const { currentLeadPage } = this.state;
    this.store.dispatch(dashboardActions.FetchLeads({ page: currentLeadPage + 1 }));
  };

  goToPreviousLeadPage = () => {
    const { currentLeadPage } = this.state;
    this.store.dispatch(dashboardActions.FetchLeads({ page: currentLeadPage - 1 }));
  };

  toggleCalculator() {
    if (this.state.isBroker) {
      this.store.dispatch(leadActions.RedirectToLeadSteps(NEW_LEAD));
      return;
    }

    this.store.dispatch(uiActions.ToggleCalculatorAction());
  }

  canModifyLead({ unverifiedUsersCanApply }: { unverifiedUsersCanApply: boolean }) {
    if (this.state.isUnverified && !unverifiedUsersCanApply) return false;

    return hasPermission(Permission.CanModifyLead, this.state.user?.permissions);
  }

  protected stateMapper = createSelector(
    dashboardSelector,
    auth0Selector,
    isBrokerSelector,
    (dashboard, auth, isBroker) => ({
      isBroker: isBroker,
      leads: dashboard.leads.results,
      leadsHasNextPage: dashboard.leads.moreRecords,
      leadsHasPreviousPage: dashboard.leads.page > 1,
      leadsAreLoading: dashboard.leadsAreLoading,
      currentLeadPage: dashboard.leads.page,
      user: auth.user,
      isUnverified: !auth.user?.isVerified,
    })
  );
}

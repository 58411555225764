import styled from '@emotion/styled';
import React from 'react';
import { Breakpoints } from 'utils/styling';

interface Props {
  tableHeaders: string[];
  className?: string;
}

const TableHeaderComponent = ({ tableHeaders, className }: Props) => {
  return (
    <thead className={className}>
      <tr className="tableHeader-row">
        {tableHeaders.map(tableHeader => {
          return (
            <td key={tableHeader}>
              <p>{tableHeader}</p>
            </td>
          );
        })}
      </tr>
    </thead>
  );
};

export const TableHeader = styled(TableHeaderComponent)`
  .tableHeader-row {
    display: grid;
    grid-template-columns: ${props => `repeat(${props.tableHeaders?.length}, 1fr)`};

    background: var(--primary-dark-blue-50);
    color: var(--primary-dark-300);
    border-radius: var(--border-radius) var(--border-radius) 0 0;

    @media (max-width: ${Breakpoints.tablet}px) {
      display: none;
    }

    & > td {
      padding: 0 0.625rem;
    }
  }
`;

import React from 'react';
import { useConnect } from 'utils/framework';
import classNames from 'classnames';
import { Route, RoutingViewModel } from 'modules/routing';

interface Props {
  to: Route;
  params?: any;
  component?: React.ComponentType<any>;

  [key: string]: any;
}

export const Link: React.FC<Props> = ({ to, params, component, ...props }) => {
  const vm = useConnect(RoutingViewModel);

  const onClick = (e: React.MouseEvent) => {
    if (e.button !== 0 || e.metaKey || e.altKey || e.ctrlKey || e.shiftKey || e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    vm.navigate(to, params);
  };

  const { children, className, ...rest } = props;
  const linkComponent = component || 'a';

  return React.createElement(linkComponent, {
    onClick,
    href: vm.hrefOf(to, params),
    className: classNames(className, { active: vm.isActive(to, params || {}) }) || undefined,
    children,
    ...rest,
  });
};

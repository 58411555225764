import { format, getYear, parse, setYear } from 'date-fns';

export const getDateWithCorrectYear = (value: string) => {
  const valueToDate = parse(value, 'yyyyyy-MM-dd', new Date());
  const year = getYear(valueToDate);

  if (year > 9999) {
    const correctYear = Number(year.toString().slice(0, 4));
    const correctDate = setYear(valueToDate, correctYear);
    return format(correctDate, 'yyyy-MM-dd');
  }

  return value;
};

export const formatDate = (date: string) => {
  return format(new Date(date), 'dd MMM yyyy');
};

export const payments = {
  title: 'Payments',
  applicationNumber: 'Application number',
  amount: 'Amount',
  status: 'Status',
  createdAt: 'Date',
  actions: 'Actions',
  noPayments: 'No payments',
  paymentStatus: 'Payment status',
  all: 'All payments',
  clearFilters: 'Clear',
  filterBy: 'Filter by',
  filters: 'Filters',
  cancel: 'Cancel',
  done: 'Done',
  pay: 'Pay',
  makePayment: 'Make Payment',
  paymentRef: 'Ref: ',
  payNow: 'Pay now',
  paymentSuccessful: 'Payment Successful',
  amountPaid: 'Amount Paid: ',
  backToDashboard: 'Back to Dashboard',
};

import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { Steps } from 'models/Application';
import { leadActions, leadSelector } from './store';
import { routingSelector } from 'modules/routing/store';
import { NEW_LEAD } from 'utils/constants';

@singleton()
@autobind
export class LeadViewModel extends ViewModel {
  state!: {
    step: Steps;
  };

  protected connected() {
    const id = routingSelector(this.store.getState()).params.id;
    if (id !== NEW_LEAD) {
      this.store.dispatch(leadActions.LoadLead({ id }));
    }
  }

  protected disconnected() {
    this.store.dispatch(leadActions.ResetLead());
  }

  protected stateMapper = createSelector(leadSelector, loans => ({
    step: loans.step,
  }));
}

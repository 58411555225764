import { RequestPagination } from 'modules/ui-components/RequestPagination';
import React from 'react';
import { useConnect } from 'utils/framework';
import { getT } from 'utils/framework/intl';
import { Lead } from './Lead';
import { LeadsViewModel } from './LeadsViewModel';
import { Button } from 'modules/ui-components/Button';
import styled from '@emotion/styled';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  className?: string;
}

const LeadsSectionComponent = ({ className }: Props) => {
  const vm = useConnect(LeadsViewModel);
  const { unverifiedUsersCanApply } = useFlags();
  const _t = getT();

  if (vm.state.leads.length > 0) {
    return (
      <div className={className}>
        <h1 className="page__header">{_t('loans.enquiries')}</h1>
        {vm.state.leads.map(lead => (
          <Lead
            key={lead.id}
            lead={lead}
            canModifyLead={vm.canModifyLead({ unverifiedUsersCanApply })}
            editLead={vm.editLead}
            cancelLead={vm.cancelLead}
            uploadDocuments={vm.uploadDocuments}
          />
        ))}
        <RequestPagination
          isLoading={vm.state.leadsAreLoading}
          hasNextPage={vm.state.leadsHasNextPage}
          hasPreviousPage={vm.state.leadsHasPreviousPage}
          previous={vm.goToPreviousLeadPage}
          next={vm.goToNextLeadPage}
        />
      </div>
    );
  }

  const isApplyButtonVisible =
    !vm.state.leadsAreLoading && vm.canModifyLead({ unverifiedUsersCanApply });

  return (
    <div className={className}>
      <h1 className="page__header">{_t('loans.enquiries')}</h1>

      {!vm.state.leads || (vm.state.leads.length === 0 && <p>{_t('loans.noEnquiries')}</p>)}
      {vm.state.leads.map(lead => (
        <Lead
          key={lead.id}
          lead={lead}
          canModifyLead={vm.canModifyLead({ unverifiedUsersCanApply })}
          editLead={vm.editLead}
          cancelLead={vm.cancelLead}
          uploadDocuments={vm.uploadDocuments}
        />
      ))}
      {vm.state.leads.length > 0 ? (
        <RequestPagination
          isLoading={vm.state.leadsAreLoading}
          hasNextPage={vm.state.leadsHasNextPage}
          hasPreviousPage={vm.state.leadsHasPreviousPage}
          previous={vm.goToPreviousLeadPage}
          next={vm.goToNextLeadPage}
        />
      ) : (
        ''
      )}
      {vm.state.leadsAreLoading && <p>{_t('uiComponents.loading')}</p>}
      {isApplyButtonVisible && (
        <Button
          disabled={vm.state.leadsAreLoading}
          className="primary apply-btn"
          onClick={vm.toggleCalculator}
        >
          {_t('loans.show')}
        </Button>
      )}
    </div>
  );
};

export const LeadsSection = styled(LeadsSectionComponent)`
  .apply-btn {
    padding: 0.5rem 1.5rem;
    max-width: 11rem;
    margin-bottom: 1rem;
  }
`;

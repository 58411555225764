import styled from '@emotion/styled';
import { Formik } from 'formik';
import { Button } from 'modules/ui-components/Button';
import { Tooltip } from 'modules/ui-components/Tooltip';
import React from 'react';
import { useConnect } from 'utils/framework';
import { getT } from 'utils/framework/intl';
import { MyDocumentsViewModel } from '../MyDocumentsViewModel';
import { FiltersForm } from './FiltersForm';

interface Props {
  className?: string;
  isDisabled: boolean;
}

const FiltersComponent = ({ className, isDisabled }: Props) => {
  const vm = useConnect(MyDocumentsViewModel);
  const _t = getT();

  return (
    <Formik initialValues={vm.initialFormValues} onSubmit={values => vm.setFilterValues(values)}>
      {({ submitForm, resetForm }) => (
        <div className={className}>
          <Tooltip
            trigger="click"
            placement="bottomRight"
            tooltipContent={setVisible => (
              <FiltersForm
                submitForm={submitForm}
                options={vm.state.options}
                setVisible={setVisible}
                resetForm={resetForm}
              />
            )}
          >
            <Button disabled={isDisabled} className="filter-button">
              {_t('myDocuments.filters')}
            </Button>
          </Tooltip>
        </div>
      )}
    </Formik>
  );
};

export const Filters = styled(FiltersComponent)`
  margin-bottom: 1.25rem;

  .filter-button {
    width: 5rem;
  }
`;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Lead } from 'models/Lead';
import { State } from 'utils/store';

export interface CalculatorState {
  calculatorValues?: Partial<Lead>;
}

const initialState: CalculatorState = {
  calculatorValues: undefined,
};

export const calculator = createSlice({
  name: 'calculator',
  initialState: initialState,
  reducers: {
    UpdateCalculatorValues: (state: CalculatorState, action: PayloadAction<Partial<Lead>>) => {
      state.calculatorValues = { ...state.calculatorValues, ...action.payload };
    },
  },
});

export const calculatorActions = {
  ...calculator.actions,
};

export const calculatorSelector = (state: State) => state[calculator.name];

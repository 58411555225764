import { ZohoLoanProduct } from './Application';
import { AssetType } from '../utils/constants';
import { OpenBankingStatus } from './openBanking';

export enum LeadStage {
  InProgress = 'In Progress',
  Draft = 'Draft',
  Submitted = 'Submitted',
  OnHold = 'On Hold',
  Converted = 'Converted',
  Rejected = 'Rejected',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum MartialStatus {
  Married = 'Married',
  Single = 'Single',
  Separated = 'Separated',
  Divorced = 'Divorced',
  Widowed = 'Widowed',
  Unknown = 'Unknown',
}

export type FetchLeadsParams =
  | { email?: string; page: number }
  | { partner_id?: string; page: number };

export interface LeadsResponse {
  data: Lead[];
  info: {
    page: number;
    perPage: number;
    count: number;
    moreRecords: boolean;
  };
}

export interface CreateLeadResponse {
  code: string;
  details: {
    modifiedTime: string;
    modifiedBy: {
      name: string;
      id: string;
    };
    createdTime: string;
    id: string;
    createdBy: {
      name: string;
      id: string;
    };
  };
  message: string;
  status: string;
}

export interface Lead {
  id: string;
  address_2: string;
  address_3: string;
  annualTurnover: number;
  applicantName: string;
  applicantType: string;
  openBankingState: OpenBankingStatus;
  approval: {
    approve: boolean;
    reject: boolean;
    resubmit: boolean;
    delegate: boolean;
  } | null;
  approvalState: string;
  approved: boolean;
  assetType: AssetType | '';
  assetCost: number;
  businessLine: string;
  businessReferenceNumber: string;
  canvasId: string;
  chassisNumber: string;
  commission: number;
  companyRegistrationNumber: string;
  condition: string;
  contactFirstName: string;
  contactLastName: string;
  converted: boolean;
  convertedDetail: {};
  corelationId: string;
  country: string;
  countryOfIncorporation: string;
  county: string;
  createdBy: {
    name: string;
    id: string;
    email: string;
  } | null;
  createdTime: string;
  currencySymbol: string;
  dateOfBirth: string;
  dealer: string;
  dealerOther: string;
  debtorsBook: number;
  deposit: number;
  editable: boolean;
  email: string;
  emailOptOut: boolean;
  fieldStates: string;
  financeAmount: string;
  fullName: string;
  inMerge: boolean;
  interestRate: number;
  introducer: {
    name: string;
    id: string;
  } | null;
  lastActivityTime: string;
  layout: {
    name: string;
    id: string;
  } | null;
  leadConverted: string;
  leadSource: string;
  leadStage: LeadStage;
  linkedApplication: {
    name: string;
    id: string;
  } | null;
  linkedCustomer: {
    name: string;
    id: string;
  } | null;
  make: string;
  marketingConsentEmail: boolean;
  marketingConsentPhone: boolean;
  marketingConsentSms: boolean;
  mileage: number | null;
  model: string;
  modifiedBy: {
    name: string;
    id: string;
    email: string;
  } | null;
  modifiedTime: string;
  monthlyRepayments: number;
  orchestration: boolean;
  owner: {
    name: string;
    id: string;
    email: string;
  } | null;
  phone: string;
  portalUserEmail: string;
  ppsn: string;
  processFlow: boolean;
  product: ZohoLoanProduct;
  propertyLocation: string;
  propertyValuation: number;
  quoteAmount: string;
  registrationNumber: string;
  reasonForPurchase: string;
  rejectionDetails: string;
  rejectionReason: string;
  rentalIncome: number;
  review: string;
  reviewProcess: {
    approve: boolean;
    reject: boolean;
    resubmit: boolean;
  } | null;
  salesPerson: {
    name: string;
    id: string;
  } | null;
  secondaryEmail: string;
  serialNumber: string;
  settlement: number;
  state: string;
  street: string;
  term: number;
  tag: string[];
  timeline: string;
  totalCost: number;
  tradeIn: number;
  ucgsEligibilityCode: string;
  ucgsProduct: boolean;
  unsubscribedMode: string;
  unsubscribedTime: string;
  website: string;
  vatExempt: boolean;
  yearOfManufacture: string;
  zipCode: string;
  maidenName: string;
  gender: string;
  countryOfNationality: string;
  numberOfDependants: number | string;
  maritalStatus: string;
  yearOfRegistration: string;
  timeAtAddressYears: string | null;
  timeAtAddressMonths: string | null;
  homeStatus: string;
  occupation: string;
  employmentStatus: string;
  employer: string;
  timeWithEmployerYears: string | null;
  timeWithEmployerMonths: string | null;
  previousEmployer: string;
  timeWithPreviousEmployerYears: string | null;
  timeWithPreviousEmployerMonths: string | null;
  incomeNetMonthly: number;
  otherIncomeNetMonthly: number;
  mortgageRentPerMonth: number;
  otherBorrowingsPerMonth: number;
  previousAddress_1: string | null;
  previousAddress_2: string | null;
  previousAddress_3: string | null;
  previousCountry: string | null;
  previousCounty: string | null;
  previousEircode: string | null;
  livedAtThisAddressForLessThan_3Years: boolean;
}

export interface ClientDetailsFormValues {
  companyType: string;
  companyName: string;
  companyNum: string;
  contact: Contact;
  loanProduct: ZohoLoanProduct;
}

export interface MotorFinanceClientDetailsValues {
  product: ZohoLoanProduct;
  contactLastName: string;
  contactFirstName: string;
  maidenName: string;
  dateOfBirth: string;
  ppsn: string;
  gender?: string;
  countryOfNationality: string;
  numberOfDependants: number | string;
  maritalStatus?: string;
  email: string;
}

export interface LeadDetailsFormValues {
  countryOfIncorporation: string;
  zipCode: string;
  street: string;
  address_2: string;
  address_3: string;
  country: string;
  county: string;
  phone: string;
  ucgsProduct: boolean;
}

export interface MotorFinanceLeadDetailsValues {
  phone: string;
  street: string;
  address_2: string;
  address_3: string;
  country: string;
  county: string;
  zipCode: string;
  timeAtAddressYears?: string;
  timeAtAddressMonths?: string;
  homeStatus: string;
  occupation: string;
  employmentStatus: string;
  employer: string;
  timeWithEmployerYears?: string;
  timeWithEmployerMonths?: string;
  previousEmployer: string;
  timeWithPreviousEmployerYears?: string;
  timeWithPreviousEmployerMonths?: string;
  incomeNetMonthly?: number;
  otherIncomeNetMonthly?: number;
  mortgageRentPerMonth?: number;
  otherBorrowingsPerMonth?: number;
  previousAddress_1?: string;
  previousAddress_2?: string;
  previousAddress_3?: string;
  previousCountry?: string;
  previousCounty?: string;
  previousEircode?: string;
  livedAtThisAddressForLessThan_3Years: boolean;
}

export interface MotorFinanceAssetDetailsValues {
  registrationNumber: string;
  make: string;
  model: string;
  yearOfRegistration: string;
  condition: string;
  dealer: string;
  mileage: number;
  assetType: AssetType | '';
}

export interface Contact {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  ppsn: string;
  email: string;
}

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Auth0 } from 'modules/auth/authStore';
import { container } from 'tsyringe';

interface TokenPair {
  access: string;
  refresh: string;
}

export class Http {
  private readonly fetcher: AxiosInstance;
  private readonly auth: Auth0;
  tokens?: TokenPair;

  constructor(apiUrl: string) {
    this.auth = container.resolve(Auth0);
    this.fetcher = axios.create({
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async request(url: string, config: AxiosRequestConfig = {}) {
    await this.setAuthHeader();
    return this.fetcher({ url, ...config });
  }

  private async setAuthHeader() {
    const data = await this.auth.getAccessToken();
    this.fetcher.defaults.headers.common.Authorization = `Bearer ${data}`;
  }
}

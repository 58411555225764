import React from 'react';
import { Steps } from 'models/Application';
import { useConnect } from 'utils/framework';
import { LeadViewModel } from './LeadViewModel';
import { ClientDetailsStep } from './lead-steps/client-details-step/ClientDetailsStep';
import { FinanceDetailsStep } from './lead-steps/finance-details-step/FinanceDetailsStep';
import { LeadOverviewStep } from './lead-steps/overview-step/LeadOverviewStep';
import { AssetDetailsStep } from './lead-steps/asset-details-step/AssetDetailsStep';

export const LeadPage = () => {
  const vm = useConnect(LeadViewModel);

  switch (vm.state.step) {
    case Steps.CUSTOMER:
      return <ClientDetailsStep />;

    case Steps.ASSETS:
      return <AssetDetailsStep />;

    case Steps.FINANCE:
      return <FinanceDetailsStep />;

    case Steps.OVERVIEW:
      return <LeadOverviewStep />;

    default:
      return <></>;
  }
};

import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as Logo } from 'assets/logo-narrow.svg';
import { Breakpoints } from 'utils/styling';
import { Link } from 'modules/ui-components/Link';
import { Route } from 'modules/routing';

interface Props {
  className?: string;
  step: string;
}

const HeaderComponent = ({ className, step }: Props) => {
  return (
    <div className={className}>
      <div className="logo-container">
        <Link to={Route.Dashboard}>
          <Logo />
        </Link>
      </div>
      <Link to={Route.Dashboard} className="arrow">
        <ArrowRight />
      </Link>

      <div className="heading">
        <div className="title">{step}</div>
      </div>

      <GradientBar />
    </div>
  );
};

const GradientBar = styled('div')`
  height: 0.125rem;
  background-image: linear-gradient(
    to right,
    var(--primary-blue-500),
    var(--primary-dark-blue-500)
  );
`;

export const Header = styled(HeaderComponent)`
  background-color: var(--primary-dark-000);
  display: grid;
  position: fixed;
  z-index: 100;
  width: 100%;
  line-height: 1;

  top: 0;
  grid-template:
    'arrow content ' 3.75rem
    'gradient gradient' 0.125rem
    / 3rem 1fr;

  ${GradientBar} {
    grid-area: gradient;
  }

  .logo-container {
    grid-area: logo;
    display: none;
  }

  .arrow {
    grid-area: arrow;
    justify-self: center;
    align-self: center;

    path.arrow {
      fill: var(--primary-dark-400);
    }
  }

  .heading {
    grid-area: content;
    align-self: center;
    .title {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.71;
      color: var(--primary-dark-blue-900);
    }

    .subtitle {
      font-size: 0.75rem;
      line-height: 1.67;
      letter-spacing: normal;
      color: var(--primary-dark-300);
    }
  }

  @media only screen and (min-width: ${Breakpoints.laptop}px) {
    grid-template:
      'logo arrow content ' 3.75rem
      'gradient gradient gradient' 0.125rem
      / 4.5rem 4rem 1fr;

    .logo-container {
      display: flex;
      justify-self: stretch;
      justify-content: center;
      align-self: center;
      border-right: 1px solid var(--primary-dark-blue-50);
    }

    .arrow {
      margin-left: 1rem;
    }
  }
`;

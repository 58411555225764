export enum ModulesKeys {
  Application = 'APP',
  Lead = 'LEAD',
}

export enum OpenBankingStatusList {
  Sent = 'Sent',
  Complete = 'Complete',
  Expired = 'Expired',
  Error = 'Error',
}

export type OpenBankingStatus = OpenBankingStatusList | null;

export enum OpenBankingResponseState {
  Success = 'success',
  Error = 'error',
}

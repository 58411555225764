export enum FileCategories {
  BALANCE_CERT = 'balanceCert',
  INTEREST_CERT = 'interestCert',
  LAD_LETTERS = 'ladLetters',
  STATEMENTS = 'statements',
  CLEARANCE_LETTER = 'clearanceLetter',
  VARIATION_LETTER = 'variationLetter',
  ANNUAL_CF_STATEMENTS = 'annualCfStatements',
  VAT_SCHEDULE = 'vatSchedule',
  UCGS_ELIGIBILITY_DOCUMENT = 'ucgsEligibility',
  PROPOSAL = 'proposal',
  BANK_STATEMENTS = 'bankStatements',
  TAX_CLEARANCE_CERTIFICATE = 'taxClearanceCertificate',
  STATEMENT_OF_AFFAIRS = 'statementOfAffairs',
  ACCOUNTS = 'accounts',
  INVOICE = 'invoice',
  PAYSLIPS = 'payslips',
  CURRENT_TENANCY_SCHEDULE = 'currentTenancySchedule',
  ACCOUNTANT_TAX_CLARIFICATION = 'accountantTaxClarification',
  INSURANCE_POLICY = 'insurancePolicy',
  WELCOME_PACK = 'welcomePack',
  CREDIT_REPORT = 'creditReport',
}

export enum UploadFileCategories {
  UCGS_ELIGIBILITY_DOCUMENT = FileCategories.UCGS_ELIGIBILITY_DOCUMENT,
  PROPOSAL = FileCategories.PROPOSAL,
  BANK_STATEMENTS = FileCategories.BANK_STATEMENTS,
  TAX_CLEARANCE_CERTIFICATE = FileCategories.TAX_CLEARANCE_CERTIFICATE,
  STATEMENT_OF_AFFAIRS = FileCategories.STATEMENT_OF_AFFAIRS,
  ACCOUNTS = FileCategories.ACCOUNTS,
  INVOICE = FileCategories.INVOICE,
  PAYSLIPS = FileCategories.PAYSLIPS,
}

export type Files = { [UploadFileCategory in UploadFileCategories]: FileCategory };

export interface TemporaryFile {
  fileName: string;
  file?: File;
  saved?: boolean;
}

export interface UploadedFile extends TemporaryFile {
  type: string;
  customer: string;
  application?: string;
  reviewName?: string;
  leadId?: string;
  loanId?: string;
  createdAt?: string;
  modifiedAt?: string;
  documentId?: string;
  applicationName?: string;
  url?: string;
}

export interface DocumentsResponse {
  results: UploadedFile[];
  total: number;
  limit: number;
  offset: number;
  hasMore: boolean;
}

export interface FileCategory {
  isUploadingFiles: boolean;
  temporary: TemporaryFile[];
  uploaded: UploadedFile[];
}

import autobind from 'autobind-decorator';
import { container, singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { getT } from 'utils/framework/intl';
import {
  CalculatorService,
  MAX_TERM,
  MAX_TERM_PERSONAL_MOTOR_FINANCE,
  MIN_TERM,
} from 'modules/leads/loan-calculator/CalculatorService';
import { uiActions } from 'modules/ui-components/store';
import * as Yup from 'yup';
import { leadActions } from '../store';
import { CalculatorValues, ZohoLoanProduct } from 'models/Application';
import { calculatorActions } from './store';
import { createSelector } from '@reduxjs/toolkit';

interface StateMapper {
  loanProducts: Array<{ value: string; label: string }>;
  formInitialValues: CalculatorValues;
}

@singleton()
@autobind
export class CalculatorViewModel extends ViewModel {
  state!: StateMapper;

  readonly calculatorService = container.resolve(CalculatorService);

  get validationSchema() {
    const _t = getT();
    return Yup.object({
      term: Yup.number()
        .min(MIN_TERM, _t('form.min', { min: MIN_TERM }))
        .when('loanProduct', {
          is: ZohoLoanProduct.PersonalMotorFinance,
          then: Yup.number().max(
            MAX_TERM_PERSONAL_MOTOR_FINANCE,
            _t('form.max', { max: MAX_TERM_PERSONAL_MOTOR_FINANCE })
          ),
          otherwise: Yup.number().max(MAX_TERM, _t('form.max', { max: MAX_TERM })),
        })
        .required(_t('form.required')),
      netCost: Yup.number().min(5000, _t('form.min', { min: 5000 })),
      assetDetails: Yup.object().when('loanProduct', {
        is: ZohoLoanProduct.PersonalMotorFinance,
        then: Yup.object({ assetType: Yup.string().required(_t('form.required')) }),
      }),
    });
  }

  submitCalculator(values: CalculatorValues) {
    this.store.dispatch(uiActions.ToggleCalculatorAction());
    this.store.dispatch(
      calculatorActions.UpdateCalculatorValues({
        product: values.loanProduct,
        assetType: !!values.assetDetails.assetType ? values.assetDetails.assetType : '',
        condition: !!values.assetDetails.condition ? values.assetDetails.condition : '',
        assetCost: values.financeAmount,
        totalCost: values.netCost,
        vatExempt: values.vatExempt,
        term: values.term,
        monthlyRepayments: values.monthlyPayment,
      })
    );
    this.store.dispatch(leadActions.RedirectToLeadSteps('new'));
  }

  protected stateMapper = createSelector(() => {
    const calculatorFormOptions = this.calculatorService.calculatorFormOptions;
    return {
      loanProducts: calculatorFormOptions.loanProducts,
      formInitialValues: this.calculatorService.formInitialValues,
    };
  });
}

import autobind from 'autobind-decorator';
import { OpenBankingResponseState } from 'models/openBanking';
import { openBankingActions } from 'modules/open-banking/store';
import { routingSelector } from 'modules/routing/store';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { cameliseKeys } from 'utils/string';

interface RouteParams {
  customerRef?: string;
  state?: OpenBankingResponseState;
}

@singleton()
@autobind
export class OpenBankingredirectViewModel extends ViewModel {
  protected async connected() {
    const { customerRef, state }: RouteParams = await cameliseKeys(
      routingSelector(this.store.getState()).params
    );

    this.store.dispatch(openBankingActions.RedirectBackToPortal({ customerRef, state }));
  }
}

import React from "react";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { Input, Props as InputProps } from "./Input";
import styled from "@emotion/styled";

interface Props extends Partial<InputProps> {
  className?: string;
  name: string;
  type: string;
  label: string;
  help: string;
}

const TooltipInputComponent = ({ className, name, type, label, help }: Props) => {
  return (
    <>
      <div className={className}>
        <span className="label">
          {label}
        </span>


        <div className="icon">
          <InfoIcon />
        </div>
        <span className="tooltip">{help}</span>

      </div>
      <Input name={name} type={type} />
    </>
  );
};


export const TooltipInput = styled(TooltipInputComponent)`
  position: relative;

  display: flex;
  align-items: center;

  .label {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-900);
  }

  .icon {
    cursor: pointer;
    margin-left: 0.125rem;
    height: 1.25rem;

    svg {
      height: 1.125rem;
    }

    &:hover + .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltip {
    position: absolute;
    bottom: 100%;

    font-size: 0.875rem;

    text-align: center;
    padding: 0.5rem;
    margin-bottom: 0.375rem;

    background-color: var(--primary-dark-000);
    color: var(--primary-dark-blue-900);

    border: var(--border-default);
    border-radius: var(--border-radius);
    border-color: var(--primary-dark-blue-300);

    visibility: hidden;
    opacity: 0;
    transition: visibility 0.1s linear, opacity 0.1s linear;
  
}`;

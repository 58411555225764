import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'modules/ui-components/Link';
import { Route } from 'modules/routing';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

interface Props {
  className?: string;
  route: Route;
  icon: React.ReactElement;
  message: string;
}

const MenuButtonComponent = ({ className, route, icon, message }: Props) => {
  return (
    <Link to={route} className={classnames(className)}>
      <div className="icon">{icon}</div>
      <FormattedMessage id={message} />
    </Link>
  );
};

export const MenuButton = styled(MenuButtonComponent)``;

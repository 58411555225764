import { getT } from 'utils/framework/intl';
import React from 'react';
import { formatCurrency, zohoLoanProductLabel } from 'utils/string';
import { Lead } from 'models/Lead';
import { ZohoLoanProduct } from 'models/Application';
import classNames from 'classnames';

interface Props {
  lead: Lead | Partial<Lead>;
  isBroker: boolean;
  isBorrower: boolean;
  header: string;
}

export const ProposalTable = ({ lead, isBroker, header, isBorrower }: Props) => {
  const _t = getT();
  const asset = [lead.make, lead.model, lead.yearOfManufacture].join(' ');
  const isPersonalMotorFinance = lead.product === ZohoLoanProduct.PersonalMotorFinance;

  return (
    <div className="proposal-table">
      <div className="proposal-table__header">{header}</div>

      <div className="proposal-table__names">
        <div className="section summary">
          <div className="title">{_t('loans.financeAmount')}</div>
          {lead.product !== ZohoLoanProduct.PersonalMotorFinance && (
            <div className="subtitle">{_t('loans.exclVat')}</div>
          )}
        </div>
        <div className="line">
          <div
            className={classNames(
              {
                'double-value': lead.product === ZohoLoanProduct.PersonalMotorFinance,
              },
              'title'
            )}
          >
            {_t('loans.loanType')}
          </div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.monthlyPayments')}</div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.totalNetCost')}</div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.upfrontDeposit')}</div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.estimatedTradeInValueShort')}</div>
        </div>
        {lead.settlement && (
          <div className="line">
            <div className="title">{_t('loans.settlement')}</div>
          </div>
        )}
        {lead.ucgsEligibilityCode && (
          <div className="line">
            <div className="title">{_t('loans.UCGSCode')}</div>
          </div>
        )}
        <div className="section asset">
          <div className="title">
            {lead.product !== ZohoLoanProduct.PersonalMotorFinance
              ? _t('loans.asset')
              : _t('loans.vehicle')}
          </div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.condition')}</div>
          {(isBorrower || isBroker) && <div className="title">{_t('loans.dealerOrSupplier')}</div>}
          {isBroker && !isPersonalMotorFinance && (
            <div className="title">{_t('loans.brokerFee')}</div>
          )}
        </div>
      </div>

      <div className="proposal-table__body">
        <div className="section summary">
          <div className="value">{formatCurrency(lead.financeAmount || 0)}</div>
          <div className="duration">{_t('loans.forMonths', { term: lead.term })}</div>
        </div>
        <div className="line">
          <div className="value">
            <div>{lead.product ? zohoLoanProductLabel(lead.product) : lead.product}</div>
            <div>
              {lead.product === ZohoLoanProduct.PersonalMotorFinance &&
                `(${_t('loans.hirePurchaseOption')})`}
            </div>
          </div>
        </div>
        <div className="line">
          <div className="value">{formatCurrency(lead.monthlyRepayments || 0)}</div>
        </div>
        <div className="line">
          <div className="value">{formatCurrency(lead.assetCost || 0)}</div>
        </div>
        <div className="line">
          <div className="value">{formatCurrency(lead.deposit || 0)}</div>
        </div>
        <div className="line">
          <div className="value">{formatCurrency(lead.tradeIn || 0)}</div>
        </div>
        {lead.settlement && (
          <div className="line">
            <div className="value">{formatCurrency(lead.settlement || 0)}</div>
          </div>
        )}
        {lead.ucgsEligibilityCode && (
          <div className="line">
            <div className="value">{lead.ucgsEligibilityCode}</div>
          </div>
        )}
        <div className="section asset">
          <div className="value">{asset.length > 32 ? asset.substring(0, 32) + '...' : asset}</div>
        </div>
        <div className="line">
          <div className="title">{lead.condition || '\u00A0'}</div>
          {(isBorrower || isBroker) && <div className="title">{lead.dealer || '\u00A0'}</div>}
          {isBroker && !isPersonalMotorFinance && (
            <div className="title">{lead.commission || '\u00A0'}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ProposalTableMobile = ({ lead, isBroker, header, isBorrower }: Props) => {
  const _t = getT();
  const asset = [lead.make, lead.model, lead.yearOfManufacture].join(' ');
  const isPersonalMotorFinance = lead.product === ZohoLoanProduct.PersonalMotorFinance;

  return (
    <div className="proposal-table">
      <div className="proposal-table__header">{header}</div>
      <div className="proposal-table__body">
        <div className="summary">
          <div className="title">
            {_t('loans.financeAmount')}
            {lead.product !== ZohoLoanProduct.PersonalMotorFinance && ` ${_t('loans.exclVat')}`}
          </div>
          <div className="value">{formatCurrency(lead.financeAmount || 0)}</div>
          <div className="duration">{_t('loans.forMonths', { term: lead.term })}</div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.loanType')}</div>
          <div className="value">
            {lead.product ? zohoLoanProductLabel(lead.product) : lead.product}
            {lead.product === ZohoLoanProduct.PersonalMotorFinance &&
              ` (${_t('loans.hirePurchaseOption')})`}
          </div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.monthlyPayments')}</div>
          <div className="value">{lead.monthlyRepayments}</div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.totalNetCost')}</div>
          <div className="value">{formatCurrency(lead.assetCost || 0)}</div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.upfrontDeposit')}</div>
          <div className="value">{formatCurrency(lead.deposit || 0)}</div>
        </div>
        <div className="line">
          <div className="title">{_t('loans.estimatedTradeInValueShort')}</div>
          <div className="value">{formatCurrency(lead.tradeIn || 0)}</div>
        </div>
        {lead.settlement && (
          <div className="line">
            <div className="title">{_t('loans.settlement')}</div>
            <div className="value">{formatCurrency(lead.settlement || 0)}</div>
          </div>
        )}
        {lead.ucgsEligibilityCode && (
          <div className="line">
            <div className="title">{_t('loans.UCGSCode')}</div>
            <div className="value">{lead.ucgsEligibilityCode}</div>
          </div>
        )}
        <div className="asset">
          <div className="title">{_t('loans.asset')}</div>
          <div className="value">{asset.length > 32 ? asset.substring(0, 32) + '...' : asset}</div>
        </div>

        {isBorrower && (
          <div className="line">
            <div className="title">{_t('loans.numberOfAssets')}</div>
            <div className="value">1</div>
          </div>
        )}
        <div className="line">
          <div className="title">{_t('loans.condition')}</div>
          <div className="value">{lead.condition || '\u00A0'}</div>
        </div>
        {(isBorrower || isBroker) && (
          <div className="line">
            <div className="title">{_t('loans.dealer')}</div>
            <div className="value">{lead.dealer || '\u00A0'}</div>
          </div>
        )}
        {isBroker && !isPersonalMotorFinance && (
          <div className="line">
            <div className="title">{_t('loans.brokerFee')}</div>
            <div className="value">{lead.commission || '\u00A0'}</div>
          </div>
        )}
      </div>
    </div>
  );
};

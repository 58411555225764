import React from 'react';
import { EventsType, IdleTimerProvider } from 'react-idle-timer';
import { useConnect } from 'utils/framework';
import { UserActivityViewModel } from './UserActivityViewModel';

const activityEvents: EventsType[] = ['click'];

interface Props {
  children: React.ReactNode;
}

export const UserActivityProvider = ({ children }: Props) => {
  const vm = useConnect(UserActivityViewModel);

  return (
    <IdleTimerProvider
      timeout={vm.state.sessionDuration}
      onIdle={vm.logout}
      startManually={!vm.state.active}
      crossTab={true}
      events={activityEvents}
    >
      {children}
    </IdleTimerProvider>
  );
};

import React from 'react';
import { Router } from 'modules/Router';
import { ErrorBoundary } from 'utils/ErrorBoundary';
import { UserActivityProvider } from 'modules/activity/provider';

function App() {
  return (
    <UserActivityProvider>
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </UserActivityProvider>
  );
}

export default App;

import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';
import { ReactComponent as SuccessIcon } from 'assets/icons/status-appproved.svg';
import { Button } from 'modules/ui-components/Button';
import { formatCurrency } from 'utils/string';
import { CheckoutCompleteViewModel } from '../CheckoutCompleteViewModel';
import { Link } from 'modules/ui-components/Link';
import { Route } from 'modules/routing';
import { getT } from 'utils/framework/intl';

interface Props {
  className?: string;
  amountPaid: string;
}

const CheckoutCompleteBoxComponent = ({ className, amountPaid }: Props) => {
  const _t = getT();
  return (
    <div className={className}>
      <div className="checkout-box__box">
        <div className="checkout-box__success-icon">
          <SuccessIcon />
        </div>
        <h1 className="checkout-box__title">{_t('payments.paymentSuccessful')}</h1>
        <h3 className="checkout-box__amount">
          {_t('payments.amountPaid')}
          {formatCurrency(amountPaid)}
        </h3>
        <div className="checkout-box__button">
          <Link to={Route.Dashboard}>
            <Button className="button-primary-pink">{_t('payments.backToDashboard')}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const CheckoutCompleteBox = styled(CheckoutCompleteBoxComponent)`
  .checkout-box__success-icon {
    margin: 2rem 0 1rem;
    display: flex;
    justify-content: center;

    svg {
      width: 80px;
      height: 80px;
    }
  }

  .checkout-box__title {
    text-align: center;
    margin-bottom: 0;
  }

  .checkout-box__amount {
    text-align: center;
  }

  .checkout-box__button {
    margin-top: 3.5rem;
  }

  .checkout-box__box {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-height: 30rem;
    margin: 2rem 0 2.5rem;
    border-radius: var(--border-radius);

    @media only screen and (min-width: ${Breakpoints.tablet}px) {
      padding: 1rem 7.5rem;
      margin: 5rem auto;
    }

    position: relative;
    background: var(--primary-dark-000);
    box-shadow: 0 32px 50px -20px rgba(0, 38, 73, 0.06);
  }
`;

import { User, UserType } from 'models/User';
import { singleton } from 'tsyringe';

export enum Permission {
  All = '__all__',
  CanCreateApplication = 'can_create_application',
  CanSeeProfilePage = 'can_see_profile_page',
  CanSeeApplications = 'can_see_application',
  CanSeeLoans = 'can_see_loans',
  CanSeeLeads = 'can_see_leads',
  CanModifyLead = 'can_modify_lead',
  CanSeeUserInfo = 'can_see_user_info',
  CanCreateDealerInAssetDetails = 'can_create_dealer_in_asset_details',
  CanSeeMyDocuments = 'can_see_documents',
  CanSeePayments = 'can_see_payments',
}

@singleton()
export class PermissionsService {
  addPermissions(user: User): Permission[] {
    const { userType, isVerified } = user;
    const isDealer = userType === UserType.Dealer;
    const isBroker = userType === UserType.Broker;
    const isBorrower = userType === UserType.Borrower;

    const unVerifiedPerms: Permission[] = [];
    const verifiedPerms = [Permission.CanSeeProfilePage];
    const applicationPerms = [Permission.CanSeeApplications];
    const dealerPerms = [...applicationPerms, Permission.CanSeeUserInfo, Permission.CanSeePayments];
    const brokerPerms = [
      ...applicationPerms,
      Permission.CanSeeUserInfo,
      Permission.CanCreateDealerInAssetDetails,
      Permission.CanSeePayments,
    ];
    const borrowerPerms = [
      ...applicationPerms,
      Permission.CanCreateDealerInAssetDetails,
      Permission.CanSeeLoans,
      Permission.CanSeeMyDocuments,
      Permission.CanSeePayments,
    ];

    let permissions: Permission[] = [
      Permission.CanSeeLeads,
      Permission.CanModifyLead,
      Permission.CanCreateApplication,
    ];

    if (!isVerified) {
      return [...unVerifiedPerms, ...permissions];
    }

    if (isDealer) {
      permissions = [...permissions, ...dealerPerms];
    }

    if (isBroker) {
      permissions = [...permissions, ...brokerPerms];
    }

    if (isBorrower) {
      permissions = [...permissions, ...borrowerPerms];
    }

    return [...verifiedPerms, ...permissions];
  }
}

export const hasPermission = (permission: Permission, permissions?: Permission[]): boolean => {
  if (!permissions) {
    return false;
  }

  return permissions.includes(Permission.All) || permissions.includes(permission);
};

import { Permission } from 'modules/auth/services/PermissionsService';

export enum UserType {
  Borrower = 'BORROWER',
  Broker = 'BROKER',
  Dealer = 'DEALER',
}

export enum UserPortalRoles {
  Owner = 'owner',
  Admin = 'admin',
  Standard = 'standard',
}

export type User = Partial<{
  sub: string;
  email: string;
  name: string;
  nickname: string;
  userType: UserType;
  zohoId: string;
  permissions: Permission[];
  isVerified: boolean;
}>;

interface Organisation {
  pk: number;
  name: string;
}

export enum InvitationStatuses {
  JOINED = 'joined',
  PENDING = 'pending',
}

export interface PortalUser {
  invitationStatus: InvitationStatuses;
  organisation: Organisation;
  organisationRole: UserPortalRoles;
  pk: number;
  portalUser: { pk: number; email: string };
}

export interface PortalInvitation {
  organisation: Organisation;
  pk: number;
  invitationStatus: InvitationStatuses;
}

export interface ProfileData {
  name: string;
  email: string;
  address1: string;
  address2: string;
  address3: string;
  country: string;
  county: string;
  phone: string;
}

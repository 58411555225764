import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { Lead } from 'models/Lead';
import { leadActions, leadSelector } from 'modules/leads/store';
import { CartellAssetResponse, Condition, Steps, ZohoLoanProduct } from 'models/Application';
import { AssetType, VEHICLE_ASSETS } from 'utils/constants';
import { isBorrowerSelector, isBrokerSelector } from 'modules/auth/authStore';

interface Props {
  isBroker: boolean;
  isPersonalMotorFinanceFlow: boolean;
  isBorrower: boolean;
  isLeadUpdating: boolean;
  cartell?: CartellAssetResponse;
  lead?: Partial<Lead>;
  assetType?: AssetType | '';
  searchInCartell?: boolean;
  isSearchingForAsset: boolean;
}

interface SubmitValues {
  saveForLater?: boolean;
  prev?: boolean;
  step?: Steps;
  values: Partial<Lead>;
  errors?: Array<keyof Partial<Lead>>;
}
@singleton()
@autobind
export class AssetDetailsViewModel extends ViewModel {
  state!: Props;

  resetCartell = () => {
    this.store.dispatch(leadActions.ResetCartell());
  };

  assetIsVehicle = (asset?: AssetType | '') => {
    if (!asset) return;
    return VEHICLE_ASSETS.includes(asset as AssetType);
  };

  setSearchInCartell = (searchInCartell: boolean) => {
    this.store.dispatch(leadActions.SetSearchAssetMode({ searchInCartell }));
  };

  setIsSearchingForAsset = (isSearchingForAsset: boolean) => {
    this.store.dispatch(leadActions.SetIsSearchingForAsset({ isSearchingForAsset }));
  };

  searchAssetDetails = (registrationNumber: string) => {
    this.store.dispatch(
      leadActions.SearchCartell({
        registrationNumber: registrationNumber.replace(/[^0-9a-z]/gi, ''),
      })
    );
  };

  formatMotorFinanceLead = (values: Partial<Lead>): Partial<Lead> => {
    const leadData = {
      assetType: values.assetType,
      make: values.make,
      model: values.model,
      yearOfRegistration: values.yearOfRegistration,
      condition: values.condition,
      dealer: values.dealer,
      registrationNumber: values.registrationNumber,
    };

    if (!this.state.isBorrower) {
      return { ...leadData, mileage: values.mileage };
    }

    return leadData;
  };

  changeAssetType = (newValue: { value?: AssetType }) => {
    if (this.state.assetType != newValue.value) {
      this.resetCartell();
      this.store.dispatch(
        leadActions.UpdateCurrentLead({
          make: '',
          model: '',
          chassisNumber: '',
          yearOfManufacture: '',
          registrationNumber: '',
          assetCost: 0,
          totalCost: 0,
          condition: Condition.new,
          dealer: '',
        })
      );

      if (newValue.value) {
        this.store.dispatch(leadActions.SetAssetType({ assetType: newValue.value }));
      }

      this.store.dispatch(
        leadActions.SetSearchAssetMode({ searchInCartell: !!this.assetIsVehicle(newValue?.value) })
      );
    }
  };

  submitMotorFinance = ({ values, step, saveForLater, prev, errors }: SubmitValues) => {
    this.submit({ values: this.formatMotorFinanceLead(values), step, saveForLater, prev, errors });
  };

  submit = ({ values, step, saveForLater, prev, errors }: SubmitValues) => {
    if (saveForLater || prev) {
      errors?.forEach(error => delete values[error]);
    }
    this.store.dispatch(leadActions.UpdateLead({ values, saveForLater, step }));
    this.resetCartell();
  };

  protected stateMapper = createSelector(
    leadSelector,
    isBorrowerSelector,
    isBrokerSelector,
    (leads, isBorrower, isBroker): Props => {
      return {
        isPersonalMotorFinanceFlow: leads.lead?.product === ZohoLoanProduct.PersonalMotorFinance,
        isLeadUpdating: leads.isUpdating,
        lead: leads.lead,
        cartell: leads.cartell,
        assetType: leads.lead?.assetType,
        searchInCartell: leads.searchInCartell,
        isSearchingForAsset: leads.isSearchingForAsset,
        isBorrower,
        isBroker,
      };
    }
  );
}

import React from 'react';
import styled from '@emotion/styled';
import { useConnect } from 'utils/framework';
import { Breakpoints } from 'utils/styling';
import { LayoutViewModel } from './LayoutViewModel';
import { Footer } from './components/Footer';
import { Header } from './primary-layout/Header';
import { UserBar } from './primary-layout/UserBar';
import { ContactUs } from './primary-layout/ContactUs';
import Notification from 'modules/notification/Notification';
import { FormattedMessage } from 'react-intl';
import { ErrorBox } from '../ErrorBox';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const PrimaryLayoutComponent = ({ children, className }: Props) => {
  const vm = useConnect(LayoutViewModel);
  return (
    <div className={className}>
      <Header logout={vm.logout} />
      <div className="layout__content">
        <UserBar open={vm.state.drawerOpen} logout={vm.logout} toggleOpen={vm.toggleDrawer} />
        <main>
          {!vm.state.isVerified && (
            <ErrorBox className="info">
              <FormattedMessage id="layout.unverifiedMessage" />
              <button className="resend" onClick={vm.resendVerificationEmail}>
                <FormattedMessage id="layout.unverifiedButton" />
              </button>
            </ErrorBox>
          )}
          <Notification />
          {children}
        </main>
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};

export const PrimaryLayout = styled(PrimaryLayoutComponent)`
  min-height: 100vh;
  display: grid;
  grid-template:
    'header' min-content
    'main' 1fr
    'footer' min-content
    / 1fr;
  background-color: var(--primary-dark-blue-25);
  --header-height: 3.75rem;

  ${Header} {
    grid-area: header;
  }

  ${Footer} {
    grid-area: footer;
  }

  .resend {
    background: none;
    border: none;
    padding: 0;
    margin-left: 0.2rem;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .layout__content {
    grid-area: main;
    justify-self: center;
    width: 100%;
    max-width: var(--content-max-width);
    display: grid;
    grid-template:
      'main'
      'right-sidebar';

    main {
      grid-area: main;
      padding: 1rem;
      background: var(--primary-dark-000);
    }

    ${UserBar} {
      grid-area: userbar;
    }

    @media only screen and (min-width: ${Breakpoints.laptop}px) {
      grid-template:
        'userbar main'
        'userbar right-sidebar' min-content
        / 16.25rem minmax(0, 100%);

      padding-top: 7.75rem;

      main {
        padding: 1rem 1.5rem;
      }
    }

    @media only screen and (min-width: ${Breakpoints.desktopS}px) {
      grid-template:
        'userbar main right-sidebar'
        / 16.25rem minmax(0, 100%) 16.25rem;
    }
  }

  ${ContactUs} {
    grid-area: right-sidebar;
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';

interface Props {
  className?: string;
  children: React.ReactNode;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  progress?: number;
}

const AuthBoxComponent = ({ className, children, title, subTitle }: Props) => {
  return (
    <div className={className}>
      <h1 className="auth-box__title">{title}</h1>
      <div className="auth-box__box">
        <div className="auth-box__progress-bar" />
        {subTitle && <h2 className="auth-box__sub-title">{subTitle}</h2>}
        {children}
      </div>
    </div>
  );
};

export const AuthBox = styled(AuthBoxComponent)`
  .auth-box__title {
    text-align: center;
    font-family: var(--font-family-secondary);
    line-height: 1.5;
    color: var(--primary-dark-000);
    letter-spacing: 0.4px;

    margin: 2.5rem 1rem 1.5rem;
  }

  .auth-box__sub-title {
    font-family: var(--font-family-secondary);
    line-height: 1.5;
    color: var(--primary-dark-blue-500);
    margin: 2rem 0 2.5rem;

    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      width: 2.5rem;
      height: 3px;
      background: var(--bg-image);
      left: 0;
      bottom: -0.25rem;
    }
  }

  .auth-box__need_help {
    margin: 1.5rem;
    text-align: center;
    a {
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-blue-900);
      text-decoration: underline;
    }
  }

  .auth-box__box {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-height: 28rem;
    margin: 0 1rem;
    border-radius: var(--border-radius);

    @media only screen and (min-width: ${Breakpoints.tablet}px) {
      padding: 1rem 7.5rem;
      margin: auto;
    }

    position: relative;
    background: var(--primary-dark-000);
    box-shadow: 0 32px 50px -20px rgba(0, 38, 73, 0.06);

    .auth-box__progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0.25rem;

      ::after {
        display: block;
        content: ' ';
        background: var(--secondary-pink-500);
        height: 0.25rem;
        width: ${({ progress }) => `${progress || 0}%`};
        border-radius: 0.125rem;
      }
    }
  }

  .auth-box__form {
    flex: 1;
    display: flex;
    flex-direction: column;

    .auth-box__cta {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-self: center;
      width: 15rem;
      margin-top: 2rem;
    }
  }

  .auth-box__description {
    font-size: 0.875rem;
    line-height: 1.71;
    color: var(--primary-dark-300);
    margin-bottom: 1.5rem;
  }

  .auth-box-link {
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    .auth-box-link-title {
      font-size: 0.875rem;
      line-height: 1.71;
      color: var(--primary-dark-300);
      margin-bottom: 1.5rem;
      margin-right: 0.3rem;
    }

    a {
      line-height: 1.71;
      font-size: 0.875rem;
      color: var(--primary-dark-blue-900);
      text-decoration: underline;
    }
  }

  .auth-box-policy {
    .auth-box-link-title {
      font-size: 0.7rem;
    }

    a {
      font-size: 0.7rem;
    }

    .and {
      margin-left: 0.3rem;
    }
  }

  .info {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
    margin-bottom: 0.25rem;
  }

  .highlight {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.71;
    color: var(--primary-dark-blue-900);
    margin-bottom: 1.25rem;
  }

  .get-a-quote {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
`;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useField } from 'formik';
import classNames from 'classnames';
import checkmark from 'assets/icons/checkmark.svg';
import checkmarkDisabled from 'assets/icons/checkmark-disabled.svg';
import { ReactComponent as ShowPassword } from 'assets/icons/password-show.svg';
import { ReactComponent as HidePassword } from 'assets/icons/password-hide.svg';
import { identity } from 'utils/function';
import { getDateWithCorrectYear } from 'utils/date';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  name: string;
  type: string;
  label?: React.ReactNode;
  help?: React.ReactNode;
  toViewValue?: (value: any) => string;
  toModelValue?: (value: string) => string;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  rtl?: boolean;
  hideErrorMessage?: boolean;
}

const InputComponent = ({
  className,
  label,
  type,
  help,
  required,
  disabled,
  onBlur,
  hideErrorMessage = false,
  ...rest
}: Props) => {
  const [field, meta, { setValue, setTouched }] = useField(rest);
  const invalid = meta.touched && meta.error;

  return (
    <label className={classNames(className, type, { invalid, required, disabled })}>
      <InputWrapper
        type={type}
        value={field.value}
        setValue={setValue}
        setTouched={setTouched}
        disabled={disabled}
        optionalOnBlur={onBlur}
        {...rest}
      />
      <div className="label">
        {label}
        {help && <span className="help">{help}</span>}
      </div>
      {invalid && !hideErrorMessage ? <div className="error">{meta.error}</div> : null}
    </label>
  );
};

const InputWrapper = ({
  type,
  value,
  toViewValue,
  toModelValue,
  setValue,
  setTouched,
  rtl,
  optionalOnBlur,
  ...rest
}: any) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (toModelValue || identity)(e.target.value);
    setValue(value);
  };
  const onBlur = (e: React.FocusEventHandler<HTMLInputElement>) => {
    if (optionalOnBlur) {
      optionalOnBlur(e);
    }
    setTouched(e);
  };
  const viewValue = (toViewValue || identity)(value);

  const [showPassword, setShowPassword] = useState(false);
  const _type = type === 'password' && showPassword ? 'text' : type;

  const props = {
    ...rest,
    type: _type,
    onChange,
    onBlur: onBlur,
    value: viewValue,
    autoComplete: 'on',
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(value);
    setValue(e.target.checked);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (toModelValue || getDateWithCorrectYear)(e.target.value);
    setValue(value);
  };

  switch (_type) {
    case 'checkbox':
      return (
        <input
          checked={!!value}
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
        />
      );
    case 'range':
      return <StyledSlider {...props} className={classNames({ rtl })} />;
    case 'date':
      return (
        <input
          {...props}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e)}
        />
      );
    default:
      return (
        <div className="input-container">
          <input {...props} />
          {type === 'password' && <PasswordIcon show={showPassword} onClick={setShowPassword} />}
        </div>
      );
  }
};

const PasswordIcon = ({ show, onClick }: { show: boolean; onClick: (show: boolean) => void }) =>
  show ? (
    <HidePassword className="show-icon" onClick={() => onClick(!show)} />
  ) : (
    <ShowPassword className="show-icon" onClick={() => onClick(!show)} />
  );

export const InputStyles = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;

  &.checkbox {
    display: inline-flex;
  }

  .input-container {
    order: 2;
  }

  input {
    order: 2;

    flex: 1;
    border-radius: 0.125rem;
    background-color: var(--primary-dark-000);
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.71;
    border: none;
    width: 100%;
    min-width: 0; /* Firefox fix */
    border: var(--border-default);

    &:focus {
      outline: none;
      border: solid 1px var(--primary-dark-blue-400);
    }

    &:hover {
      box-shadow: 0 0 0 1px rgba(0, 142, 214, 0.2);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &::placeholder {
      color: var(--primary-dark-300);
    }
  }

  &.invalid input {
    border-color: var(--additional-red-500);
  }

  &.disabled input {
    background-color: var(--primary-dark-blue-50);
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }

  &.required .label::after {
    content: ' *';
    color: var(--additional-red-500);
  }

  .error,
  .label {
    position: relative;

    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-900);

    .help {
      position: absolute;
      right: 0;
      color: var(--primary-dark-300);
    }

    margin-bottom: 0.5rem;
  }

  .error {
    order: 3;
    color: var(--additional-red-500);
    margin-top: 0.125rem;
  }

  input + .show-icon {
    position: absolute;
    top: 2rem;
    right: 0.25rem;
    padding: 0.5rem;
    height: unset;
    width: unset;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;
    z-index: 100;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &.password input {
    padding-right: 2.25rem;
    &[style*='background-image'] {
      /* Accomodate place for last-pass */
      padding-right: 3.5rem;
      & + .show-icon {
        right: 1.5rem;
      }
    }
  }

  input[type='date'] {
    padding: 0.438rem 0.75rem;
  }

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;

    + .label {
      display: inline;
      cursor: pointer;
      position: relative;
      padding-left: 1.75rem;
      line-height: 1.5rem;

      font-size: 0.875rem;
      color: var(--primary-dark-blue-900);

      a {
        color: inherit;
        text-decoration: underline;
      }

      &::before {
        position: absolute;
        content: ' ';
        left: 0.25rem;
        top: 0.25rem;
        height: 1rem;
        width: 1rem;
        border: solid 1px var(--primary-dark-200);
        background-color: var(--primary-dark-blue-25);
        border-radius: 2px;
      }
    }

    &:checked + .label::before {
      border-color: var(--primary-blue-500);
      background: var(--primary-blue-500);
      background-image: url(${checkmark});
      background-position: -1px -1px;
    }

    &:not(:disabled):active + .label::before,
    &:not(:disabled):focus + .label::before {
      box-shadow: 0 0 0 0.125rem rgba(0, 0, 0, 0.12);
    }

    &:not(:disabled):hover + .label::before {
      border-color: var(--primary-blue-500);
    }

    &:disabled + .label {
      cursor: unset;
    }

    &:disabled + .label::before {
      border: solid 1px var(--primary-dark-blue-100);
      background-color: var(--primary-dark-blue-100);
    }

    &:disabled:checked + .label::before {
      border: solid 1px var(--primary-dark-blue-100);
      background: var(--primary-dark-blue-100) url(${checkmarkDisabled}) -1px -1px;
    }
  }

  &.invalid input[type='checkbox'] {
    + .label {
      ::before {
        border-color: var(--additional-red-500);
      }
    }

    ~ .error {
      margin-top: -0.25rem;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Input = styled(InputComponent)`
  ${InputStyles}
`;

const StyledSlider = styled('input')`
  &[type='range'] {
    padding: 0;
    border: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    margin: 10px 0;

    &:focus {
      outline: none;
    }
  }

  &.rtl {
    direction: rtl;
  }

  &::-webkit-slider-runnable-track {
    height: 0.5rem;
    width: 100%;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    background: ${({ value, min, max, className }: any) =>
      value === 0
        ? `var(--primary-dark-blue-50)`
        : `linear-gradient(
      to right, 
      var(--primary-blue-500) 0%, 
      var(--primary-blue-500) ${progress(value, min, max, className.includes('rtl'))}%, 
      var(--primary-dark-blue-50) ${progress(value, min, max, className.includes('rtl'))}%, 
      var(--primary-dark-blue-50) 100%
    )`};
  }

  &::-moz-range-track {
    height: 0.5rem;
    width: 100%;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    background: var(--primary-dark-blue-50);
    background: ${({ value, min, max, className }: any) =>
      value === 0
        ? `var(--primary-dark-blue-50)`
        : `linear-gradient(
      to right, 
      var(--primary-blue-500) 0%, 
      var(--primary-blue-500) ${progress(value, min, max, className.includes('rtl'))}%, 
      var(--primary-dark-blue-50) ${progress(value, min, max, className.includes('rtl'))}%, 
      var(--primary-dark-blue-50) 100%
    )`};
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    cursor: pointer;
    border: solid 3px var(--primary-blue-500);
    background: var(--primary-dark-000);
  }

  &::-moz-range-thumb {
    border-radius: 50%;
    width: 0.7rem;
    height: 0.7rem;
    margin-top: -0.25rem;
    cursor: pointer;
    border: solid 3px var(--primary-blue-500);
    background: var(--primary-dark-000);
  }
`;

const progress = (value: number, min: number, max: number, isRtl: boolean) => {
  const progress = (((value ?? 50) - (min ?? 0)) * 100) / ((max ?? 100) - (min ?? 0));

  return isRtl ? 100 - progress : progress;
};

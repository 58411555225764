import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification, NotificationStatuses } from 'models/Notification';
import { State } from 'utils/store';

export interface NotificationState {
  notifications: Notification[];
}

const initialState = {
  notifications: [],
} as NotificationState;

export const notification = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    AddNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
    },
    ClearNotifications: state => {
      state.notifications = [];
    },
  },
});

export const notificationActions = {
  ...notification.actions,
};

export const notificationSelector = (state: State) => state[notification.name] as NotificationState;

export const notifyInfo = (message: string) =>
  notificationActions.AddNotification({ message, type: NotificationStatuses.Info });
export const notifySuccess = (message: string) =>
  notificationActions.AddNotification({ message, type: NotificationStatuses.Success });
export const notifyWarning = (message: string) =>
  notificationActions.AddNotification({ message, type: NotificationStatuses.Warning });
export const notifyError = (message: string) =>
  notificationActions.AddNotification({ message, type: NotificationStatuses.Error });

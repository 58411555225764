import React, { useEffect, useRef } from 'react';
import { useConnect } from 'utils/framework';
import { Form, Formik } from 'formik';
import { getT } from 'utils/framework/intl';
import { Toggle } from 'modules/ui-components/Toggle';
import { CurrencyInput } from 'modules/ui-components/CurrencyInput';
import { _t, formatCurrency, zohoLoanProductLabel } from 'utils/string';
import { Input } from 'modules/ui-components/Input';
import { Breakpoints, useBreakpoint } from 'utils/styling';
import {
  FinanceDetailsValues,
  Steps,
  UCGSToLoan,
  ZohoLoanProduct,
  loanToUCGS,
} from 'models/Application';
import { FooterButtons } from 'modules/ui-components/FooterButtons';
import { Button } from 'modules/ui-components/Button';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import {
  MAX_TERM,
  MAX_TERM_PERSONAL_MOTOR_FINANCE,
  MIN_TERM,
} from 'modules/leads/loan-calculator/CalculatorService';
import { TextArea } from 'modules/ui-components/TextArea';
import { TIMELINES } from '../leadConstants';
import { Select } from 'modules/ui-components/Select';
import styled from '@emotion/styled';
import { FinanceDetailsViewModel } from './FinanceDetailsViewModel';

interface Props {
  className?: string;
}

export const FinanceDetailsStepComponent = ({ className }: Props) => {
  const vm = useConnect(FinanceDetailsViewModel);

  return (
    <div className={className}>
      <h1 className="page__header">{_t('loans.financeDetails')}</h1>

      <Formik
        initialValues={vm.formInitialValues}
        onSubmit={async values => vm.submit(values, { step: Steps.OVERVIEW })}
        validationSchema={vm.validationSchema}
      >
        {({ values, setFieldValue }) => (
          <ApplicationForm vm={vm} values={values} setFieldValue={setFieldValue} />
        )}
      </Formik>
    </div>
  );
};

interface FormProps {
  vm: FinanceDetailsViewModel;
  values: FinanceDetailsValues;
  setFieldValue: (field: string, value: any) => void;
}

const ApplicationForm = ({ vm, values, setFieldValue }: FormProps) => {
  const [isTablet] = useBreakpoint(Breakpoints.tablet, { useBody: true });
  const _t = getT();
  const oldMonthlyPayment = useRef(values.monthlyPayment);
  const isPersonalMotorFinance = values.loanProduct === ZohoLoanProduct.PersonalMotorFinance;
  const isUCGSEligible = !isPersonalMotorFinance && (vm.state.isBorrower || vm.state.isBroker);

  const maxTerm = isPersonalMotorFinance ? MAX_TERM_PERSONAL_MOTOR_FINANCE : MAX_TERM;
  const { vatExempt, loanProduct, settlement } = values;

  useEffect(() => {
    if (vatExempt && loanProduct === ZohoLoanProduct.HirePurchase) {
      setFieldValue('vatExempt', false);
    }

    if (!settlement) {
      setFieldValue('settlementAmount', 0);
    }
  }, [setFieldValue, vatExempt, loanProduct, settlement]);

  const { netCost, settlementAmount, tradeIn, deposit } = values;
  useEffect(() => {
    const value = Number(netCost) - Number(tradeIn) - Number(deposit) + Number(settlementAmount);
    setFieldValue('financeAmount', value || 0);
  }, [setFieldValue, netCost, settlementAmount, tradeIn, deposit]);

  useEffect(() => {
    if (vm.state.isBroker) return;

    if (values.financeAmount > 0 && oldMonthlyPayment.current === values.monthlyPayment)
      setFieldValue(
        'monthlyPayment',
        vm.calculatorService.calculateMonthlyRepayment(
          values.financeAmount,
          Math.min(Math.max(values.term, MIN_TERM), maxTerm)
        )
      );
    oldMonthlyPayment.current = values.monthlyPayment;
  }, [
    setFieldValue,
    vm.calculatorService,
    values.term,
    values.financeAmount,
    values.monthlyPayment,
  ]);

  useEffect(() => {
    if (values.term > maxTerm) setFieldValue('term', maxTerm);
  }, [setFieldValue, values.term]);

  useEffect(() => {
    setFieldValue(
      'loanProduct',
      values.ucgsProduct ? loanToUCGS[values.loanProduct] : UCGSToLoan[values.loanProduct]
    );
    if (!values.ucgsProduct) {
      setFieldValue('ucgsEligibilityCode', '');
    }
  }, [setFieldValue, values.ucgsProduct, values.loanProduct]);

  const showVat =
    [ZohoLoanProduct.Leasing, ZohoLoanProduct.LeasingUCGS].includes(values.loanProduct) &&
    !vatExempt;
  const summaryLabel = _t(showVat ? 'loans.totalNetCost' : 'loans.totalCost');

  const onMonthlyPaymentBlur = () => {
    if (vm.state.isBroker) return;

    const term = vm.calculatorService.calculateTerm(values.financeAmount, values.monthlyPayment);
    values.term = Math.min(Math.max(MIN_TERM, term), maxTerm);
    values.monthlyPayment = vm.calculatorService.calculateMonthlyRepayment(
      values.financeAmount,
      values.term
    );
  };

  return (
    <Form className="form">
      <Input
        type="text"
        name="loanProduct"
        toViewValue={zohoLoanProductLabel}
        label={_t('loans.loanType')}
        disabled
      />
      <CurrencyInput name="netCost" label={_t('loans.totalCost')} />

      {[ZohoLoanProduct.Leasing, ZohoLoanProduct.LeasingUCGS].includes(values.loanProduct) &&
        !vm.state.isBroker && (
          <Input type="checkbox" name="vatExempt" label={_t('loans.vatExempt')} />
        )}

      <CurrencyInput name="deposit" label={_t('loans.upfrontDepositLong')} />
      <CurrencyInput name="tradeIn" label={_t('loans.estimatedTradeInValue')} />
      <Toggle
        name="settlement"
        asRadio
        label={_t('loans.settlePreviousLoanBackendRequired')}
        options={vm.formOptions.settlement}
      />

      {values.settlement && (
        <CurrencyInput name="settlementAmount" label={_t('loans.settlement')} />
      )}

      <CurrencyInput name="financeAmount" disabled label={_t('loans.financeAmount')} />

      {isPersonalMotorFinance && (
        <Select
          className="timeline"
          name="timeline"
          label={_t('loans.purchaseTimeline')}
          options={TIMELINES}
        />
      )}

      {!vm.state.isBroker ? (
        <>
          <div className="sliderRow">
            <div>
              <Input
                type="range"
                name="term"
                min={MIN_TERM}
                max={maxTerm}
                step={1}
                label={_t('loans.terms', {
                  help: (...chunks: any) => (
                    <span className="gray-text" key="none">
                      {chunks}
                    </span>
                  ),
                })}
              />
              <div className="help">
                <div>{MIN_TERM}</div>
                <div>{maxTerm}</div>
              </div>
            </div>
            <div className="slider-input">
              <Input name="term" type="number" min={MIN_TERM} max={maxTerm} />
            </div>
          </div>

          <div className="sliderRow">
            <div>
              <Input
                type="range"
                name="term"
                min={MIN_TERM}
                max={maxTerm}
                step={1}
                rtl
                label={_t('loans.monthlyPayments')}
                hideErrorMessage={true}
              />
              <div className="help">
                <div>
                  {formatCurrency(
                    vm.calculatorService.calculateMonthlyRepayment(values.financeAmount, maxTerm)
                  )}
                </div>
                <div>
                  {formatCurrency(
                    vm.calculatorService.calculateMonthlyRepayment(values.financeAmount, MIN_TERM)
                  )}
                </div>
              </div>
            </div>

            <div className="slider-input">
              <CurrencyInput name="monthlyPayment" step={0.01} onBlur={onMonthlyPaymentBlur} />
              {showVat && <div className="help vat-help">{_t('loans.additionalVatAmount')}</div>}
            </div>
          </div>
        </>
      ) : (
        <>
          <Input
            name="term"
            type="number"
            min="12"
            max="72"
            label={_t('loans.terms', {
              help: (...chunks: any) => (
                <span className="gray-text" key="none">
                  {chunks}
                </span>
              ),
            })}
          />

          <CurrencyInput
            name="monthlyPayment"
            step={0.01}
            onBlur={onMonthlyPaymentBlur}
            label={_t('loans.monthlyPayments')}
          />
        </>
      )}

      <div className="summary">
        <div className="net-total">
          {summaryLabel}
          <div className="total">{formatCurrency(values.financeAmount)}</div>
        </div>
        {showVat ||
          (true && (
            <div className="vat">
              <p>{_t('loans.additionalVatAmount')}</p>
            </div>
          ))}
      </div>

      {vm.state.isBroker && !isPersonalMotorFinance && (
        <div>
          <CurrencyInput label={_t('loans.commission')} name="commission" step={0.01} />
        </div>
      )}

      <div>
        <div className="purchase-reason-label">
          <span className="label">
            {vm.state.isBroker ? _t('loans.customerBackground') : _t('loans.reasonForPurchase')}
          </span>

          {!vm.state.isBroker && (
            <>
              <div className="icon">
                <InfoIcon />
              </div>
              <span className="tooltip">
                {isPersonalMotorFinance
                  ? _t('loans.reasonForPurchaseHelpPMF')
                  : _t('loans.reasonForPurchaseHelp')}
              </span>
            </>
          )}
        </div>
        <TextArea
          name="reasonForPurchase"
          placeholder={
            values.loanProduct !== ZohoLoanProduct.PersonalMotorFinance
              ? _t('loans.reasonForPurchaseExample')
              : _t('loans.reasonForPurchasePMFExample')
          }
        />
      </div>

      {isUCGSEligible && (
        <div className="UCGS-container">
          <Input
            className="UCGS-checkbox"
            type="checkbox"
            name="ucgsProduct"
            label={_t('loans.UCGSCheck')}
          />
          <Input
            type="text"
            name="ucgsEligibilityCode"
            disabled={!values.ucgsProduct}
            label={_t('loans.UCGSCode')}
          />
        </div>
      )}

      <div className="confirmation">
        <div>{_t('loans.confirmation')}</div>
        <p className="confirmation-description">{_t('loans.confirmationDescription')}</p>
        <Input name="consentConfirmed" type="checkbox" label={_t('loans.acceptLoanConfirmation')} />
      </div>

      <FooterButtons
        saveForLater={() => vm.submit(values, { saveForLater: true })}
        goPrev={() => vm.submit(values, { step: Steps.ASSETS, prev: true })}
        loading={vm.state.isLeadUpdating}
        submitElement={
          <Button
            className="broker-primary broker-primary--simple"
            type="submit"
            disabled={vm.state.isLeadUpdating}
          >
            {_t(isTablet ? 'loans.reviewEnquiry' : 'loans.submit')}
          </Button>
        }
      />
    </Form>
  );
};

export const FinanceDetailsStep = styled(FinanceDetailsStepComponent)`
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .gray-text {
    color: var(--primary-dark-300);
  }

  .purchase-reason-label {
    position: relative;

    display: flex;
    align-items: center;

    .label {
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-blue-900);
    }

    .icon {
      cursor: pointer;
      margin-left: 0.125rem;
      height: 1.25rem;

      svg {
        height: 1.125rem;
      }

      &:hover + .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }

    .tooltip {
      position: absolute;
      bottom: 100%;

      font-size: 0.875rem;

      text-align: center;
      padding: 0.5rem;
      margin-bottom: 0.375rem;

      background-color: var(--primary-dark-000);
      color: var(--primary-dark-blue-900);

      border: var(--border-default);
      border-radius: var(--border-radius);
      border-color: var(--primary-dark-blue-300);

      visibility: hidden;
      opacity: 0;
      transition: visibility 0.1s linear, opacity 0.1s linear;
    }
  }

  .summary {
    padding-top: 1rem;
    margin: 1rem 0;
    border-top: 1px solid var(--primary-dark-blue-50);

    .net-total {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.71;
      color: var(--primary-dark-blue-900);

      .total {
        font-family: var(--font-family-secondary);
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.4px;
        color: var(--primary-dark-blue-500);
      }
    }
    .vat {
      display: flex;
      justify-content: flex-end;
      font-size: 0.75rem;
      color: var(--primary-dark-300);
    }
  }

  .confirmation {
    font-size: 0.75rem;
    line-height: 1.67;

    .confirmation-description {
      color: var(--primary-dark-300);
      text-align: justify;
    }
  }

  .help {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
    margin-top: -1.25rem;
  }

  .vat-help {
    justify-content: flex-end;
    margin: 0;
  }

  .timeline {
    margin-bottom: 1rem;
  }

  .UCGS-container {
    @media only screen and (min-width: ${Breakpoints.desktopS}px) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .UCGS-checkbox {
        margin-bottom: 1.5rem;
      }
    }
  }

  .sliderRow {
    .slider-input {
      margin-top: 0.75rem;
    }

    @media only screen and (min-width: ${Breakpoints.desktopS}px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1.5rem;
    }
  }
`;

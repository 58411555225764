import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { FormattedMessage } from 'react-intl';

interface Props {
  className?: string;
  onClick: () => void;
  disabled: boolean
}

const LogoutButtonComponent = ({ className, ...rest }: Props) => {
  return (
    <button className={className} data-cy="logout-btn" {...rest}>
      <div>
        <Logout />
      </div>
      <FormattedMessage id="auth.logOut" />
    </button>
  );
};

export const LogoutButton = styled(LogoutButtonComponent)`
  height: 2rem;
  width: 100%;
  border: var(--border-light);
  border-radius: var(--border-radius);
  font-size: 0.875rem;
  background-color: var(--primary-dark-blue-25);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;

  svg {
    margin: 0.5rem 0.5rem 0.25rem 0;
  }
`;

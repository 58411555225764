import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';
import { Spinner } from 'modules/ui-components/Spinner';
import { ApplicationAsset } from 'models/Application';
import { _t } from 'utils/string';

interface Props {
  className?: string;
  assets?: ApplicationAsset[];
}

const getAssetFields = (asset: ApplicationAsset) => {
  return [
    {
      isValid: asset.model && asset.make && asset.yearOfManufacture,
      label: _t('loans.assetDetails'),
      value: `${asset.model} ${asset.make} ${asset.yearOfManufacture}`,
    },
    {
      isValid: asset.make,
      label: _t('loans.make'),
      value: asset.make,
    },
    {
      isValid: asset.make && asset.model,
      label: _t('loans.makeAndModel'),
      value: `${asset.make} ${asset.model}`,
    },
    {
      isValid: asset.yearOfManufacture,
      label: _t('loans.yearOfManufacture'),
      value: asset.yearOfManufacture,
    },
    {
      isValid: asset.supplier,
      label: _t('loans.supplier'),
      value: asset.supplier?.name,
    },
  ];
};

const ApplicationAssetsContainer = ({ className, assets }: Props) => {
  if (assets === undefined) {
    return (
      <div className={className}>
        <Spinner className="loader" />
      </div>
    );
  }

  if (!assets.length || !assets[0].assetType) {
    return (
      <div className={className}>
        <p className="noData">{_t('loans.noAssetDetails')} </p>
      </div>
    );
  }

  const assetFields = getAssetFields(assets[0]);
  return (
    <div className={className}>
      {assetFields.map(field => {
        return (
          <div key={field.value} className="desc">
            <p className="label">{field.label}</p>
            <p className="value">{field.isValid ? field.value : _t('loans.notAvailable')}</p>
          </div>
        );
      })}
    </div>
  );
};

export const ApplicationAssets = styled(ApplicationAssetsContainer)`
  display: grid;
  padding: 1rem;
  background-color: var(--primary-dark-blue-25);
  border-top: var(--border-default);
  gap: 1rem;

  .desc {
    .label {
      margin: 0;
      margin-bottom: 0.25rem;
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-300);
    }

    .value {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.71;
      color: var(--primary-dark-blue-900);
      word-break: break-all;
    }
  }

  .noData {
    margin: 0;
    color: var(--primary-dark-300);
    font-size: 0.875rem;
    text-align: center;

    @media only screen and (min-width: ${Breakpoints.laptop}px) {
      grid-area: noData;
      text-align: left;
    }
  }

  .loader {
    display: flex;
    justify-self: center;

    @media only screen and (min-width: ${Breakpoints.laptop}px) {
      grid-area: noData;
      text-align: left;
      justify-self: flex-start;
    }
  }

  @media only screen and (min-width: ${Breakpoints.laptop}px) {
    grid-template-areas: 'noData noData noData noData';
    grid-template-columns: repeat(4, 1fr);
    padding: 1.5rem 1.5rem 1.5rem 4rem;
  }
`;

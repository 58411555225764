import React, { FormEvent, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Layout } from '@stripe/stripe-js';
import styled from '@emotion/styled';
import { Button } from 'modules/ui-components/Button';
import { Spinner } from './Spinner';
import { toast } from 'react-toastify';
import { getT } from 'utils/framework/intl';
import { PaymentIntent } from 'models/Payment';

interface Props {
  className?: string;
  intent: PaymentIntent;
}

const CheckoutFormComponent = ({ className, intent }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const _t = getT();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_STRIPE_CHECKOUT_REDIRECT_URI}?paymentId=${intent.paymentId}&amountPaid=${intent.amount}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message as string);
      toast.error(`Payment failed: ${error.message}`);
    } else {
      setMessage('An unexpected error occurred.');
      toast.error('Payment failed: An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs' as Layout,
  };

  return (
    <form className={className} id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement className="payment-element" options={paymentElementOptions} />
      <Button className="pay-btn button-primary-pink" type="submit">
        <span id="button-text">
          {isLoading || !stripe || !elements ? <Spinner /> : _t('payments.payNow')}
        </span>
      </Button>
      {message && <div className="payment-message">{message}</div>}
    </form>
  );
};

export const CheckoutForm = styled(CheckoutFormComponent)`
  margin-bottom: 1rem;

  .pay-btn {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .payment-message {
    color: var(--additional-red-500);
    line-height: 1.4;
    text-align: center;
  }

  .payment-element {
    margin-bottom: 1.5rem;
  }
`;

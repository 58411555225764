export const notification = {
  fetchFailed: 'Something went wrong with fetching {data}, please try again',
  downloadFailed: 'Something went wrong with downloading {data}, please try again',
  updateCalculatorFailed: 'Updating calculator went wrong, please try again',
  checkVantageFailed: 'Something went wrong with Vantage Status, please try again',
  deleteLeadSuccess: 'Lead deleted successfully',
  deleteLeadFailed: 'Something went wrong with cancelling your Lead, please try again',
  submitLeadFailed: 'Something went wrong with submitting your enquiry, please try again',
  createApplicationFailed: 'Something went wrong with creating your application, please try again',
  uploadFileFailed: 'Something went wrong with uploading file, please try again',
  uploadFileSuccess: 'File uploaded successfully',
  savedFileSuccess: 'File saved successfully',
  noSupportedFile: 'File is either too large or an unsupported file type',
  saveFileFailed: 'Something went wrong with saving {fileName} file, please try again',
  inviteUserSuccess: 'Invitation sent successfully',
  inviteUserFailed: 'Something went wrong with inviting user, please try again',
  deleteUserFailed: 'Something went wrong with deleting user, please try again',
  deleteUserSuccess: 'The user was deleted successfully',
  invitationAccepted: 'Invitation accepted',
  InvitationAcceptFailed: 'Something went wrong with accepting invitation, please try again',
  changeCompanyFailed: 'Something went wrong with changing company, please try again',
  setupSetPasswordError: 'Something went wrong with setting password, please try again',
  setPhoneError: 'Something went wrong with setting password',
  setupConfirmCodeError: 'Something went wrong with setup confirmation code',
  userSignUpFailed: 'Something went wrong with sign up, please try again',
  userLoginFailed: 'Something went wrong with login, please try again',
  userResetPasswordFailed: 'Something went wrong with resetting password, please try again',
  verifyEmailSuccess: 'Your account has been activated.',
  assetDetailsSearch:
    "We can't seem to find that Asset, please try again or manually enter the Asset's details",
  eircodeSearchNotFound:
    "We can't seem to find that Eircode, please try again or manually enter the address details",
  eircodeSearchInvalid:
    'Provided Eircode is not valid, please remove any spaces and special characters',
  eircodeSearchFailed: 'Something went wrong with retrieving address details, please try again',
  visionNetSearchError:
    "We can't seem to find that company with this name or number, please try again",
  resendEmailSuccess: 'Verification email has been sent successfully',
  resendEmailFailure: 'Something went wrong with sending email, please try again!',
  setupVerifyTokenError: 'Something went wrong with setting up your Verify Token',
  redirectToOpenBankingFailed:
    'Something went wrong with redirecting to Open Banking, please try again',
  redirectToUploadDocumentsFailed:
    'Something went wrong with redirecting to Upload Documents page, please try again',
  redirectToPaymentCheckout:
    'Something went wrong with redirecting to Payment Checkout page, please try again',
  logoutFailed: 'Something went wrong while logging you out. Please try again later.',
  redirectToLeadStepsFailed:
    'Something went wrong with redirecting to Lead Application, please try again',
};

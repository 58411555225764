import { AssetType } from '../utils/constants';
import { OpenBankingStatus } from './openBanking';

export interface PaginatedResponse<T> {
  page: number;
  moreRecords: boolean;
  results: Array<T>;
}

export type oldApplication = any;

export interface Application {
  id: string;
  amlStatus: string;
  appdata: string;
  applicationNarrative: string;
  applicationNumber: string;
  approval: {
    approve: boolean;
    reject: boolean;
    resubmit: boolean;
    delegate: boolean;
  };
  approvalChanges: string;
  approvalChangesLog: string;
  approvalState: string;
  approved: string;
  branch: string;
  ccrEnquiryStatus: string;
  charges: number;
  chequeReferenceNumber: string;
  commission: number;
  completedDate: string;
  conditions: string;
  contactName: {
    name: string;
    id: string;
  };
  createdBy: {
    name: string;
    id: string;
    email: string;
  };
  createdTime: string;
  creditAgreementReceived: boolean;
  currencySymbol: string;
  customerVisionnetUpdated: boolean;
  dealName: string;
  dealerCommissionNotes: string;
  dealerLeaseCommission: number;
  dealerVatExempt: boolean;
  declineNotes: string;
  declineReason: string;
  deposit: number;
  depositVat: number;
  directDebitMandateReceived: true;
  documentationFee: number;
  editable: string;
  errorMessage: string;
  feesCommission: boolean;
  fieldStates: string;
  financeAmount: string;
  financeStartDate: string;
  firstRepaymentDate: string;
  frequency: string;
  guaranteed: boolean;
  hpiRoiCheck: boolean;
  hpiUkCheck: boolean;
  inMerge: string;
  instalment: number;
  openBankingState: OpenBankingStatus;
  insurancePolicyNumber: string;
  insuranceProviderBroker: string;
  interestRate: number;
  introducer: {
    name: string;
    id: string;
  };
  lastActivityTime: string;
  leadConversionTime: string;
  leadSource: string;
  loanProduct: ZohoLoanProduct;
  ltv: string;
  modifiedBy: {
    name: string;
    id: string;
  };
  modifiedTime: string;
  monthlyInstalments: number;
  moreInformationDate: string;
  netCost: number;
  numberOfRepayments: number;
  numberOfUpfrontRentals: number;
  orchestration: string;
  overallSalesDuration: string;
  owner: {
    name: string;
    id: string;
    email: string;
  };
  paymentMethod: string;
  personalGuaranteeReceived: boolean;
  policyException: boolean;
  policyExceptionCommentsNotes: string;
  policyExceptionDetails: string;
  portalStage: string;
  portalUserEmail: string;
  processFlow: string;
  purchaseFee: number;
  readyForProposal: boolean;
  reassessed: boolean;
  recalculateRepayments: boolean;
  relationshipManager: {
    name: string;
    id: string;
  };
  repaymentTerms: string;
  review: string;
  reviewProcess: {
    approve: boolean;
    reject: boolean;
    resubmit: boolean;
  };
  salesCycleDuration: string;
  salesPerson: {
    name: string;
    id: string;
  };
  sendToPayoutDate: string;
  settlement: number;
  settlementTo: string;
  settlementVat: number;
  stage: ApplicationStatus;
  state: string;
  supplier: {
    name: string;
    id: string;
  };
  supplierApproved: boolean;
  supplierIbanValidated: boolean;
  tag: string[];
  term: number;
  thirdPartyFee: number;
  totalLoanAmount: number;
  tradeIn: number;
  tradeInVat: number;
  type: string;
  underwriter: {
    name: string;
    id: string;
  };
  underwriterComments: string;
  underwritingCompletedDate: string;
  underwritingDate: string;
  upfrontAmount: number;
  upfrontAmountVat: number;
  upfrontRentals: number;
  vat: number;
  yield: number;
  assets?: ApplicationAsset[];
  ucgsEligibilityCode: string;
  ucgsProduct: boolean;
}

export interface ApplicationAsset {
  id: string;
  name: string;
  assetCategory: string;
  assetType: string;
  make: string;
  model: string;
  condition: string;
  chassisNumberVin: string;
  serialNumber: string;
  registrationNumber: string;
  imported: boolean;
  modeOfImport: string;
  yearOfManufacture: number;
  currencySymbol: string;
  netCost: number;
  vat: number;
  vrt: number;
  vatRate: string;
  vatRateValue: number;
  guidePrice: number;
  valuationMethod: string;
  supplier?: {
    name: string;
    id: string;
  };
  supplierApproved: boolean;
  owner?: {
    name: string;
    id: string;
    email: string;
  };
  countryOfOrigin: string;
  state: string;
  hpiRoiResponse: string;
  hpiRoiCheck: string;
  hpiRegDate: string;
  hpiUkCheck: string;
  hpiCeaseDate: string;
  lastActivityTime: string;
  createdTime: string;
  modifiedTime: string;
  unsubscribedTime: string;
  approved: boolean;
  approval: {
    approve: boolean;
    reject: boolean;
    resubmit: boolean;
    delegate: boolean;
  };
  approvalState: string;
  processFlow: boolean;
  fieldStates: string;
  reviewProcess: {
    approve: boolean;
    reject: boolean;
    resubmit: boolean;
  };
  review: string;
  linkedApplications: {
    name: string;
    id: string;
  };
  orchestration: boolean;
  forbearance: string;
  canvasId: string;
  interstatCode: string;
  editable: boolean;
  inMerge: boolean;
  unsubscribedMode: string;
}

export interface ApplicationsResponse {
  data: Application[];
  info: {
    page: number;
    perPage: number;
    count: number;
    moreRecords: boolean;
  };
}

export interface ApplicationAssetsResponse {
  data: ApplicationAsset[];
  info: {
    page: number;
    per_page: number;
    count: number;
    more_records: boolean;
  };
}

export enum ApplicationStatus {
  ALL = 'All',
  PROPOSAL = 'Proposal',
  UNDERWRITING = 'Underwriting',
  CLOSED = 'Closed',
  CANCELLED = 'Cancelled',
  PAY_OUT = 'Pay Out',
  ACTIVATED = 'Activated',
  NOT_TAKEN_UP = 'Not Taken Up',
  ACCEPTED = 'Accepted',
  NEED_MORE_INFORMATION = 'Need More Information',
  DRAFT = 'Draft',
  READY_FOR_PROPOSAL = 'Ready for Proposal',
  PAYOUT_FAILED = 'Payout Failed',
  DECLINED = 'Declined',
  DOCUMENTS_ISSUED = 'Documents Issued',
  DOCUMENTS_RECEIVED = 'Documents Received',
}

export const FinishedApplicationStatuses = [
  ApplicationStatus.PAY_OUT,
  ApplicationStatus.ACTIVATED,
  ApplicationStatus.DECLINED,
  ApplicationStatus.CANCELLED,
  ApplicationStatus.NOT_TAKEN_UP,
];

export enum ZohoLoanProduct {
  Leasing = 'Business Lease',
  HirePurchase = 'Business Hire Purchase',
  PersonalMotorFinance = 'Consumer Hire Purchase',

  LeasingUCGS = 'UCGS Business Lease',
  HirePurchaseUCGS = 'UCGS Business Hire Purchase',
}

export const loanToUCGS = {
  [ZohoLoanProduct.HirePurchase]: ZohoLoanProduct.HirePurchaseUCGS,
  [ZohoLoanProduct.Leasing]: ZohoLoanProduct.LeasingUCGS,

  [ZohoLoanProduct.PersonalMotorFinance]: ZohoLoanProduct.PersonalMotorFinance,
  [ZohoLoanProduct.HirePurchaseUCGS]: ZohoLoanProduct.HirePurchaseUCGS,
  [ZohoLoanProduct.LeasingUCGS]: ZohoLoanProduct.LeasingUCGS,
};

export const UCGSToLoan = {
  [ZohoLoanProduct.HirePurchaseUCGS]: ZohoLoanProduct.HirePurchase,
  [ZohoLoanProduct.LeasingUCGS]: ZohoLoanProduct.Leasing,

  [ZohoLoanProduct.PersonalMotorFinance]: ZohoLoanProduct.PersonalMotorFinance,
  [ZohoLoanProduct.HirePurchase]: ZohoLoanProduct.HirePurchase,
  [ZohoLoanProduct.Leasing]: ZohoLoanProduct.Leasing,
};

export enum Condition {
  new = 'New',
  used = 'Used',
}

export enum Steps {
  CUSTOMER = 'Customer Details',
  ASSETS = 'Asset Details',
  FINANCE = 'Finance Details',
  OVERVIEW = 'Lead Overview',
}

export enum CompanyTypes {
  SOLE_TRADER = 'Sole Trader',
  COMPANY = 'Company',
  PERSONAL = 'Personal',
}

export interface CalculatorValues {
  loanProduct: ZohoLoanProduct;
  netCost: number;
  vatExempt: boolean;
  monthlyPayment: number;
  term: number;
  financeAmount: number;
  assetDetails: Partial<AssetDetails>;
}

export interface FinanceDetailsValues extends CalculatorValues {
  deposit: number;
  tradeIn: number;
  settlement: boolean;
  settlementAmount: number;
  commission: number;
  consentConfirmed: boolean;
  reasonForPurchase: string;
  timeline: string;
  ucgsEligibilityCode: string;
  ucgsProduct: boolean;
}

export interface AssetDetails {
  assetType: AssetType | '';
  make: string;
  model: string;
  yearOfManufacture: string;
  assetCost: number;
  totalCost: number;
  condition: string;
  chassisNumber: string;
  registrationNumber: string;
  dealer: string;
}

export interface EircodeAddressResponse {
  address_1: string;
  address_2: string;
  address_3: string;
  address_4?: string;
  county: string;
  eircode: string;
}

export interface CartellAssetResponse {
  make: string;
  model: string;
  description: string;
  engineCapacity: string;
  fuelType: string;
  bodyType: string;
  transmission: string;
  chassisNumber: string;
  engineNumber: string;
  firstRegistrationDate: string;
  imported: string;
  firstRegisteredIreland: string;
  manufactureDate: string;
  colour: string;
  newCpgRetailPrice: string;
  registrationNumber?: string;
  assetType: AssetType;
}

export enum VisionNetInputTypes {
  Name = 'name',
  Number = 'number',
}

export interface VisionNetCompany {
  compTypeDesc: string;
  companyAddr1: string;
  companyAddr2: string;
  companyAddr3: string;
  companyAddr4: string;
  companyBusInd: string;
  companyEircode: string;
  companyName: string;
  companyNum: number;
  companyRegDate: string;
  companyStatusCode: number;
  companyStatusDate: string;
  companyStatusDesc: string;
  companyTypeCode: number;
  lastAccDate: string;
  lastArDate: string;
  nextArDate: string;
}

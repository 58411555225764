import { MutableRefObject, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

interface UseBreakpointOptions {
  useBody?: boolean;
  ref?: MutableRefObject<any>;
}
/**
 * Hook for hot-swapping components basing on their width (or the browser's width)
 * Example:
 * For body width:
 * ```
 *   const [isTablet] = useBreakpoint(Breakpoints.tablet, { useBody: true })
 *   console.log(isTablet);
 * ```
 * For element width:
 * ```
 *   const [isDesktop, ref] = useBreakpoint(1000);
 *   return <div ref={ref}>{isDesktop ? <div>My menu</div> : <Drawer>My menu</Drawer>}</div>;
 * ```
 */
export function useBreakpoint(
  breakPoint: number,
  { useBody, ref }: UseBreakpointOptions
): [boolean, (instance: HTMLElement | null) => void] {
  const bodyRef = useRef(window.document.documentElement);
  const [matches, setMatches] = useState(false);
  const matchesRef = useRef<boolean>();
  const setValue = (value: boolean) => {
    matchesRef.current = value;
    setMatches(value);
  };
  const _ref = ref || useBody ? bodyRef : undefined;
  const getWidthWithScroll = (width: number, el?: HTMLElement) => {
    return el && hasOverflow(el) ? width + getScrollbarWidth() : width;
  };

  if (matchesRef.current === undefined && _ref?.current) {
    let width = getWidthWithScroll(_ref.current.clientWidth, _ref.current);
    setValue(width >= breakPoint);
  }

  const { ref: elementRef } = useResizeObserver({
    ref: _ref!,
    onResize: ({ width }) => {
      const _width = getWidthWithScroll(width || 0, _ref!.current);

      if (_width! < breakPoint && matchesRef.current) {
        setValue(false);
      } else if (_width! >= breakPoint && !matchesRef.current) {
        setValue(true);
      }
    },
  });

  return [matches, elementRef];
}

const getScrollbarWidth = () => {
  return window.innerWidth - document.documentElement.clientWidth;
};

function hasOverflow(el: HTMLElement) {
  if (['auto', 'visible', 'scroll'].includes(getComputedStyle(el).overflowY)) {
    return el.scrollHeight > el.clientHeight;
  }
}

export const scrollToElement = (el: HTMLElement, padding = 64) => {
  const y = el.getBoundingClientRect().top + window.scrollY - padding;

  window.scrollTo(0, y);
};

export enum Breakpoints {
  min = 320,
  phone = 450,
  tablet = 768,
  laptop = 1024,
  desktopS = 1200,
  desktop = 1400,
  max = 1600,
}

import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';
import { Button } from 'modules/ui-components/Button';

import { FormattedMessage } from 'react-intl';
import { getT } from 'utils/framework/intl';

import { formatCurrency } from 'utils/string';
import { Spinner } from 'modules/ui-components/Spinner';

interface Props {
  className?: string;
  totalCost: number;
  label: string;
  hideVat: boolean;
}

const CalculatorSummaryComponent = ({ className, totalCost, label, hideVat }: Props) => {
  const _t = getT();

  return (
    <div className={className}>
      <div className="summary-section">
        <div className="total-cost">
          <p className="total-cost-label">{label}</p>
          <div className="help-wrapper">
            <p className="total-cost-value">{formatCurrency(totalCost)}</p>
            {hideVat && (
              <div className="help help--right">
                <p>{_t('loans.additionalVatAmount')}</p>
              </div>
            )}
          </div>
        </div>
        <div className="buttons-summary-container">
          <Button type="submit" className="primary">
            <FormattedMessage id={'loans.applyNow'} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const CalculatorSummary = styled(CalculatorSummaryComponent)`
  .summary-section {
    padding: 0 1rem;
  }

  p {
    margin: 0;
  }

  .buttons-summary-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
    position: relative;

    ${Spinner} {
      position: absolute;
      z-index: 1;
      top: 0.5rem;
      right: 0.75rem;
    }
  }

  .values {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: var(--primary-dark-300);
    margin-top: 0.25rem;
    &.vat {
      justify-content: flex-end;
      position: absolute;
      bottom: -2.25rem;
      right: 0;
    }
  }

  .total-cost {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px var(--primary-dark-blue-50);
    margin: 2.5rem 0 2.5rem 0;
    padding-top: 1rem;
    position: relative;
  }

  .total-cost-label {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 2.5;
    color: var(--primary-dark-blue-900);
  }

  .total-cost-value {
    font-family: var(--font-family-secondary);
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: var(--primary-dark-blue-500);
    text-align: right;
    margin-bottom: 1.25rem;
  }

  @media only screen and (min-width: ${Breakpoints.laptop}px) {
    .total-cost {
      margin: 0;
      padding: 0;
      border: none;
      display: flex;
      align-items: center;
    }

    .summary-section {
      padding: 1.375rem 0;
      display: flex;
      justify-content: space-between;
      border-top: solid 1px var(--primary-dark-blue-50);
      margin: 0 1.5rem;
    }

    .buttons-summary-container {
      flex-direction: row;
      align-items: center;
      padding: 0;
      button {
        width: 15rem;
        height: 2.75rem;
      }

      ${Spinner} {
        position: static;
        margin-right: 1rem;
      }
    }
    .total-cost-value {
      font-size: 2rem;
      margin-left: 1rem;
    }
    .help-wrapper {
      display: flex;
      gap: 0.5rem;
      align-items: flex-end;

      .total-cost-value {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (min-width: ${Breakpoints.desktop}px) {
    .summary-section {
      margin: 0 auto;
      padding: 1.375rem 1.5rem;
      max-width: ${Breakpoints.max}px;
    }
  }
`;

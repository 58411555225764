import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { ProfileData, UserType } from 'models/User';
import { profileActions, profileSelector } from './store';
import { _t } from 'utils/string';
import { auth0Selector } from 'modules/auth/authStore';

@singleton()
@autobind
export class ProfileViewModel extends ViewModel {
  state!: {
    profile: ProfileData;
    zohoId: string;
    userType: UserType;
  };

  protected stateMapper = createSelector(profileSelector, auth0Selector, (profile, auth) => ({
    profile: profile.profile,
    zohoId: auth.user?.zohoId,
    userType: auth.user?.userType,
  }));

  protected connected() {
    if (!(this.state.zohoId && this.state.userType)) return;

    if (this.state.userType === UserType.Borrower) {
      this.store.dispatch(profileActions.FetchCustomerProfile({ customerId: this.state.zohoId }));
      return;
    }

    this.store.dispatch(profileActions.FetchPartnerProfile({ partnerId: this.state.zohoId }));
  }
}

import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import {
  availableToUserDocumentTypes,
  documentsActions,
  documentsSelector,
  Filter,
  getDocumentTypesForUser,
} from './store';
import { userTypeSelector } from 'modules/auth/authStore';
import { formatDate } from 'utils/date';
import { TableBodyType } from 'modules/ui-components/table/Table';
import { translateLabels } from 'utils/string';
import { UploadedFile } from 'models/File';
import { capitalCase, constantCase } from 'change-case';
import { DOCUMENTS_LIMIT } from 'utils/constants';
import { UserType } from '../../models/User';

export interface BaseOption {
  label: string;
  value: string;
}

@singleton()
@autobind
export class MyDocumentsViewModel extends ViewModel {
  state!: {
    isLoading: boolean;
    documentListOffset: number;
    documentListHasNextPage: boolean;
    areFiltersDisabled: boolean;
    tableHead: string[];
    tableBody: TableBodyType;
    options: {
      documentType: BaseOption[];
      fileType: BaseOption[];
      applicationName: BaseOption[];
    };
  };

  protected tableHead = [
    'myDocuments.name',
    'myDocuments.type',
    'myDocuments.uploadDate',
    'myDocuments.actions',
  ];

  get initialFormValues() {
    return {
      fileType: '',
    };
  }

  protected connected() {
    this.store.dispatch(documentsActions.FetchDocuments({ offset: 0 }));
  }

  setFilterValues(filter: Filter) {
    this.store.dispatch(documentsActions.SetFilter(filter));
    this.store.dispatch(documentsActions.FetchDocuments({ offset: 0 }));
  }

  downloadDocument(documentId: string) {
    this.store.dispatch(documentsActions.DownloadDocument(documentId));
  }

  loadNextPage() {
    const { documentListOffset } = this.state;
    this.store.dispatch(
      documentsActions.FetchDocuments({ offset: documentListOffset + DOCUMENTS_LIMIT })
    );
  }

  loadPreviousPage() {
    const { documentListOffset } = this.state;
    this.store.dispatch(
      documentsActions.FetchDocuments({ offset: documentListOffset - DOCUMENTS_LIMIT })
    );
  }

  protected stateMapper = createSelector(
    documentsSelector,
    userTypeSelector,
    (documentsState, userType) => {
      const tableBody = formatDocumentsToTableBody(documentsState.documents.results);
      return {
        isLoading: documentsState.isLoading,
        tableHead: translateLabels(this.tableHead),
        tableBody: tableBody,
        documentListOffset: documentsState.documents.offset,
        documentListHasNextPage: documentsState.documents.hasMore,
        areFiltersDisabled: !tableBody.length && !documentsState.filter.fileType,
        options: { fileType: getDocumentTypeOptions(userType) },
      };
    }
  );
}

const formatDocumentsToTableBody = (documents: UploadedFile[]) => {
  return documents.map(document => {
    const { fileName, type, createdAt, documentId } = document;
    return [fileName, capitalCase(type), formatDate(createdAt || ''), { documentId }];
  });
};

const getDocumentTypeOptions = (userType?: UserType) => {
  const availableTypes = getDocumentTypesForUser(userType);
  const options = availableTypes.map(type => {
    return { label: capitalCase(type), value: constantCase(type) };
  });

  return [{ label: '', value: '' }, ...options];
};

import styled from '@emotion/styled';
import warningIcon from 'assets/icons/warning.svg';
import infoIcon from 'assets/icons/info.svg';
import SuccessIcon from 'assets/icons/status-appproved.svg';

export const ErrorBox = styled('div')`
  position: relative;
  border-radius: var(--border-radius);
  padding: 0.5rem 2.875rem;

  font-size: 0.875rem;
  line-height: 1.71;

  margin-bottom: 2rem;

  &::before {
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    left: 0.625rem;
    top: 0.625rem;
  }

  &.info {
    border: solid 1px var(--primary-blue-500);
    background-color: var(--primary-blue-50);
    &::before {
      background: url(${infoIcon});
    }

    color: var(--primary-dark-blue-900);
  }

  &.warning {
    border: solid 1px var(--secondary-orange-500);
    background-color: var(--secondary-orange-50);
    &::before {
      background: url(${warningIcon});
    }

    color: var(--primary-dark-blue-900);
  }

  &.success {
    border: solid 1px var(--primary-green-500);
    background-color: var(--primary-green-50);
    &::before {
      background: url(${SuccessIcon});
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    color: var(--primary-dark-blue-900);
  }
`;

import React from 'react';
import { useConnect } from 'utils/framework';
import { AssetDetailsViewModel } from './AssetDetailsViewModel';
import { MotorFinanceAssetDetails } from './personalMotorFinanceFlow/MotorFinanceAssetDetails';
import { AssetDetails } from './normalFlow/AssetDetails';
import styled from '@emotion/styled';
import { Select } from 'modules/ui-components/Select';
import { Toggle } from 'modules/ui-components/Toggle';
import { Breakpoints } from 'utils/styling';

interface Props {
  className?: string;
}

const AssetDetailsStepComponent = ({ className }: Props) => {
  const vm = useConnect(AssetDetailsViewModel);

  if (vm.state.isPersonalMotorFinanceFlow) {
    return (
      <div className={className}>
        <MotorFinanceAssetDetails />
      </div>
    );
  }

  return (
    <div className={className}>
      <AssetDetails />
    </div>
  );
};

export const AssetDetailsStep = styled(AssetDetailsStepComponent)`
  .cartell-link {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }

  .enter-manually {
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }

  .asset {
    padding: 1rem;
    border-radius: 4px;
    background-color: var(--primary-dark-blue-25);

    .asset-header {
      display: flex;
      gap: 1rem;
      padding: 0.125rem 0 0.5rem;
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.71;
      color: var(--primary-dark-blue-900);
      border-bottom: var(--border-light);
    }
  }

  ${Select}, ${Toggle} {
    margin-bottom: 1rem;
  }

  ${Toggle} .values {
    flex-direction: row;
    gap: 1rem;
  }

  @media only screen and (min-width: ${Breakpoints.tablet}px) {
    .row {
      display: flex;
      gap: 1.5rem;

      & > * {
        width: 100%;
      }
    }
  }
`;

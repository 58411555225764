import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'modules/ui-components/Button';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import open from 'assets/icons/open.svg';
import hide from 'assets/icons/hide.svg';
import { LoanCalculatorViewModel } from './LoanCalculatorViewModel';
import { useConnect } from 'utils/framework';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  className?: string;
}

export const LoanCalculatorBarComponent = ({ className }: Props) => {
  const vm = useConnect(LoanCalculatorViewModel);
  const { unverifiedUsersCanApply } = useFlags();
  const hide = vm.state.calculatorOpen;

  return (
    <div className={className}>
      <div className="header">{vm.getTitleText}</div>
      {vm.canCreateApplication({ unverifiedUsersCanApply }) && (
        <Button
          className={classNames('button-main-header', { hide })}
          onClick={vm.toggleCalculator}
        >
          <FormattedMessage id={vm.getCalcBtnText} />
        </Button>
      )}
    </div>
  );
};

export const LoanCalculatorBar = styled(LoanCalculatorBarComponent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 1rem;

  .header {
    font-family: var(--font-family-secondary);
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: var(--primary-dark-000);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1 auto;
    max-width: 100%;
  }

  .button-main-header {
    position: relative;
    flex-shrink: 0;

    width: 10rem;
    padding: 0.25rem 2rem 0.25rem 0.875rem;
    background: var(--secondary-pink-500);

    font-size: 14px;
    font-weight: 600;
    line-height: 1.4rem;
    white-space: nowrap;
    color: var(--primary-dark-000);

    border-radius: 15px;
    border: solid 1px var(--primary-dark-000);

    &:focus {
      outline: none;
      border: solid 1px var(--primary-dark-100);
    }

    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 0.626rem);
      right: 0.313rem;

      display: block;
      background: url(${open});
      width: 1.25rem;
      height: 1.25rem;
    }
    &.hide {
      &::after {
        background: url(${hide});
        width: 1.375rem;
        height: 1.25rem;
      }
    }
  }
`;

export enum PaymentIntentStatus {
  PAID = 'PAID',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  APPROVED = 'APPROVED',
}

export enum PaymentIntentType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export interface PaymentIntent {
  paymentId: string;
  amount: number;
  iban: string | null;
  bic: string | null;
  applicationId: string;
  zohoCustomerId: string;
  partnerName: string | null;
  remittanceInformation: string | null;
  status: PaymentIntentStatus;
  providerPaymentId: string | null;
  providerType: string | null;
  paymentType: PaymentIntentType;
  applicationNumber: string | null;
  clientSecret: string | null;
  createdAt: string;
  modifiedAt: string;
}

export interface PaymentIntentsResponse {
  results: PaymentIntent[];
  total: number;
  limit: number;
  offset: number;
  hasMore: boolean;
}

import React from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { SmallHeading } from 'modules/ui-components/typography/SmallHeading';
import { Button } from 'modules/ui-components/Button';
import { Breakpoints } from 'utils/styling';
import { useConnect } from 'utils/framework';
import { LayoutViewModel } from 'modules/ui-components/layouts/LayoutViewModel';

interface Props {
  className?: string;
}

const ContactUsComponent = ({ className }: Props) => {
  const vm = useConnect(LayoutViewModel);
  const [phone, email] = vm.state.contactInfo;
  return (
    <div className={className}>
      <div className="content-container">
        <SmallHeading>
          <FormattedMessage id={'layout.askQuestion'} />
        </SmallHeading>
        <p className="subtext">
          <FormattedMessage id={'layout.contactExperts'} />
        </p>
        <div className="contactInfo">
          <dl>
            <div className="entry">
              <dt>
                <FormattedMessage id={'layout.phone'} />
              </dt>
              <dd>
                <a href={`tel:${phone}`}>{phone}</a>
              </dd>
            </div>
            <div className="entry">
              <dt>
                <FormattedMessage id={'layout.email'} />
              </dt>
              <dd>
                <a href={`mailto:${email}`}>{email}</a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export const ContactUs = styled(ContactUsComponent)`
  background-color: var(--primary-dark-blue-25);
  padding: 1.5rem 1rem 0;

  ${SmallHeading} {
    color: var(--primary-dark-blue-500);
    margin: 0;
  }

  .subtext {
    font-size: 0.75rem;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--primary-dark-300);
    margin: 0;
  }

  .contactInfo {
    margin: 0;
    padding: 1rem;

    .entry {
      display: flex;
      flex-wrap: wrap;
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }

    dl {
      margin: 0;
    }

    dt,
    dd {
      flex: 1 0 100%;
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-300);
    }

    dt {
      color: var(--primary-dark-300);
      width: 3.75rem;
    }

    dd {
      margin: 0;
      color: var(--primary-dark-blue-900);
    }

    a {
      color: inherit;
    }
  }

  ${Button} {
    margin-bottom: 2rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (min-width: ${Breakpoints.tablet}px) {
    padding: 1rem 1.5rem;

    .contactInfo {
      padding: 1rem 0;

      dl {
        padding: 0 1rem;
      }
    }
  }

  @media only screen and (min-width: ${Breakpoints.desktopS}px) {
    .content-container {
      position: sticky;
      top: calc(var(--header-height) * 2 + 1.25rem);
    }
  }
`;

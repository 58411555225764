import React from 'react';
import styled from '@emotion/styled';
import { getT } from 'utils/framework/intl';
import { useConnect } from 'utils/framework';
import { ProfileViewModel } from './ProfileViewModel';
import classNames from 'classnames';
import { Breakpoints, useBreakpoint } from 'utils/styling';
import { InputStyles } from 'modules/ui-components/Input';

interface Props {
  className?: string;
}

const ProfilePageComponent = ({ className }: Props) => {
  const vm = useConnect(ProfileViewModel);
  const [isTablet] = useBreakpoint(Breakpoints.tablet, { useBody: true });
  const _t = getT();

  return (
    <div className={className}>
      <div>
        <h1 className="page__header">{_t('profile.customerDetails')}</h1>
      </div>
      {vm.state.profile && (
        <div className="profile">
          <div
            className={classNames('details', {
              isTablet: isTablet,
            })}
          >
            <DisabledInput label={_t('profile.customerName')} value={vm.state.profile.name} />
            <DisabledInput label={_t('profile.customerEmail')} value={vm.state.profile.email} />
            <DisabledInput
              label={_t('profile.customerAddress1')}
              value={vm.state.profile.address1}
            />
            <DisabledInput
              label={_t('profile.customerAddress2')}
              value={vm.state.profile.address2}
            />
            <DisabledInput
              label={_t('profile.customerAddress3')}
              value={vm.state.profile.address3}
            />
            <DisabledInput label={_t('profile.customerCountry')} value={vm.state.profile.country} />
            <DisabledInput label={_t('profile.customerCounty')} value={vm.state.profile.county} />
            <DisabledInput label={_t('profile.phoneNumber')} value={vm.state.profile.phone} />
          </div>
        </div>
      )}
    </div>
  );
};

interface DisabledInputProps {
  className?: string;
  label: string;
  value: string;
}

const DisabledInputComponent = ({ className, label, value }: DisabledInputProps) => {
  return (
    <label className={`${className} disabled`}>
      <input disabled value={value} />
      <div className="label">{label}</div>
    </label>
  );
};

const DisabledInput = styled(DisabledInputComponent)`
  ${InputStyles}
`;

export const ProfilePage = styled(ProfilePageComponent)`
  .profile {
    display: flex;
    flex-direction: column;

    .details {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
    }

    .isTablet {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { toast, ToastContainer } from 'react-toastify';

import { useConnect } from 'utils/framework';
import { NotificationViewModel } from './NotificationViewModel';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as ErrorIcon } from 'assets/icons/error-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/status-appproved.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

const customIcons = {
  error: <ErrorIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
};

const Notification = () => {
  const vm = useConnect(NotificationViewModel);

  useEffect(() => {
    vm.state.notifications.forEach(({ type, message }) =>
      toast[type](message, { icon: customIcons[type] })
    );
    if (vm.state.notifications.length > 0) {
      vm.clearNotifications();
    }
  }, [vm, vm.state.notifications]);

  return (
    <ToastStyled
      hideProgressBar={false}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={true}
      progressClassName="toast-progressbar"
    />
  );
};
const ToastStyled = styled(ToastContainer)`
  font-size: 14px;

  .toast-progressbar {
    background: linear-gradient(
      94deg,
      var(--primary-green-300),
      var(--primary-blue-500) 39%,
      var(--primary-dark-blue-600) 75%
    ) !important;
  }
`;

export default Notification;

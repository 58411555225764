import React from 'react';
import { createRoot } from 'react-dom/client';
import 'reflect-metadata';
import 'normalize.css';
import './index.scss';
import App from './App';
import * as Sentry from '@sentry/react';
import { INTL_DI_TOKEN, registerServices } from 'utils/registerServices';
import { RawIntlProvider } from 'react-intl';
import { container } from 'tsyringe';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracePropagationTargets: [
      "localhost", /^https:\/\/.*\.capitalflow\.(ie|dev)/
    ],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
      ? parseInt(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
      : 1.0,
  });
}

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
})(App);

registerServices();
const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <RawIntlProvider value={container.resolve(INTL_DI_TOKEN)}>
      <LDProvider />
    </RawIntlProvider>
  </React.StrictMode>
);

import styled from '@emotion/styled';
import spinner from 'assets/icons/spinner.svg';

export const Spinner = styled('div')`
  width: 24px;
  height: 24px;
  background: url(${spinner});
  animation: spin 1.4s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

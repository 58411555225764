import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'modules/ui-components/Button';
import { Select } from 'modules/ui-components/Select';
import { BaseOption } from '../MyDocumentsViewModel';
import { getT } from 'utils/framework/intl';
import { Form } from 'formik';

interface Props {
  submitForm: () => void;
  resetForm: () => void;
  options: {
    documentType: BaseOption[];
    fileType: BaseOption[];
    applicationName: BaseOption[];
  };
  setVisible: (visible: boolean) => void;
  className?: string;
}

const FiltersFormComponent = ({ submitForm, resetForm, options, setVisible, className }: Props) => {
  const _t = getT();

  const handleSubmit = () => {
    submitForm();
    setVisible(false);
  };

  return (
    <Form className={className}>
      <div className="heading">
        <div className="heading-text">{_t('myDocuments.filterBy')}</div>
        <Button type="button" onClick={resetForm}>
          {_t('myDocuments.clearFilters')}
        </Button>
      </div>
      <Select
        name="fileType"
        options={options.fileType}
        label={_t('myDocuments.documentType')}
        formatOptionLabel={option => {
          return <div className="option">{(option as BaseOption).label}</div>;
        }}
      />
      <div className="buttons">
        <Button type="button" onClick={() => setVisible(false)}>
          {_t('myDocuments.cancel')}
        </Button>
        <Button type="button" className="primary" onClick={handleSubmit}>
          {_t('myDocuments.done')}
        </Button>
      </div>
    </Form>
  );
};

export const FiltersForm = styled(FiltersFormComponent)`
  width: 20rem;

  ${Select} {
    margin-bottom: 1rem;
    min-width: 12rem;
  }

  .option {
    min-height: 1.25rem;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${Button} {
      width: 4rem;
      height: 2rem;
    }
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${Button} {
      width: 4rem;
      height: 2rem;
    }
  }

  .heading-text {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.75;
    color: var(--primary-dark-blue-900);
    margin-bottom: 0.5rem;
  }
`;

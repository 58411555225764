import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { Button } from 'modules/ui-components/Button';
import { Modal } from 'modules/ui-components/Modal';
import { _t } from 'utils/string';

interface Props {
  isOpen: boolean;
  confirmAction: () => void;
  cancelAction: () => void;
}

export const LeadModal = ({ isOpen, confirmAction, cancelAction }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="modal-heading">
        <div className="modal-title">
          <div className="modal-icon">
            <WarningIcon width="30" height="30" />
          </div>
          <h3>{_t('leads.cancelLead')}</h3>
        </div>
      </div>
      <div className="modal-body">
        <p>{_t('leads.cancelLeadConfirmation')}</p>
      </div>
      <div className="modal-footer">
        <div className="modal-buttons">
          <Button className="cta cancel-modal-button button-primary-white" onClick={cancelAction}>
            {_t('leads.noCancel')}
          </Button>
          <Button className="cta action-modal-button button-primary-pink" onClick={confirmAction}>
            {_t('leads.yesCancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { getYear } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as FbIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as WhiteLogo } from 'assets/logo-white.svg';
import background from 'assets/footer-bg.svg';
import { Links } from 'utils/string';
import { Breakpoints } from 'utils/styling';

interface Props {
  className?: string;
}

const FooterComponent = ({ className }: Props) => {
  const currentYear = getYear(new Date());

  return (
    <div className={className}>
      <WhiteLogo className="logo" />
      <div className="links">
        <p>
          <a href={Links.privacyPolicy} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id={'layout.privacyPolicy'} />
          </a>
        </p>
        <p>
          <a href={Links.tos} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id={'layout.tos'} />
          </a>
        </p>
        <p>
          <FormattedMessage id={'layout.copywrite'} values={{ year: currentYear }} />
        </p>
      </div>
      <div className="socials">
        <a href={Links.fb} target="_blank" rel="noopener noreferrer">
          <FbIcon />
        </a>
        <a href={Links.ln} target="_blank" rel="noopener noreferrer">
          <LinkedinIcon />
        </a>
        <a href={Links.twitter} target="_blank" rel="noopener noreferrer">
          <TwitterIcon />
        </a>
      </div>
    </div>
  );
};

export const Footer = styled(FooterComponent)`
  background: var(--primary-dark-blue-800) url(${background}) no-repeat right bottom 7.875rem;
  color: var(--primary-dark-000);
  padding: 0.75rem 1.5rem;

  display: grid;
  grid-template-areas: 'logo links socials';
  grid-template-columns: 1fr auto 1fr;
  align-items: center;

  @media only screen and (max-width: ${Breakpoints.tablet}px) {
    grid-gap: 0.75rem;
    grid-template-columns: auto;
    grid-template-areas:
      'socials'
      'links';
  }

  .logo {
    grid-area: logo;
    height: 1.125rem;

    @media only screen and (max-width: ${Breakpoints.tablet}px) {
      display: none;
    }
  }

  .links {
    grid-area: links;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media only screen and (max-width: ${Breakpoints.phone}px) {
      gap: 0.5rem;
    }

    p {
      margin: 0;
      font-size: 0.75rem;

      border-right: solid 1px var(--primary-dark-blue-100);
      padding-right: 1rem;

      & > a {
        color: var(--primary-dark-000);
      }

      @media only screen and (max-width: ${Breakpoints.phone}px) {
        text-align: center;
        padding-right: 0.5rem;
      }

      &:last-of-type {
        border-right: none;
        padding-right: 0;
      }
    }
  }

  .socials {
    grid-area: socials;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;

    @media only screen and (max-width: ${Breakpoints.tablet}px) {
      justify-content: center;
      gap: 1rem;
    }

    a {
      height: 1.25rem;
    }
  }
`;

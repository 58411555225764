import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import classNames from 'classnames';
import { identity } from 'utils/function';

export interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  name: string;
  label?: React.ReactNode;
  help?: React.ReactNode;
  toViewValue?: (value: string) => string;
  toModelValue?: (value: string) => string;
  rtl?: boolean;
}

const TextAreaComponent = ({ className, label, help, required, disabled, ...rest }: Props) => {
  const [field, meta, { setValue, setTouched }] = useField(rest);
  const invalid = meta.touched && meta.error;

  return (
    <label className={classNames(className, { invalid, required, disabled })}>
      <TextAreaWrapper
        value={field.value}
        setValue={setValue}
        setTouched={setTouched}
        disabled={disabled}
        {...rest}
      />
      <div className="label">
        {label}
        {help && <span className="help">{help}</span>}
      </div>
      {invalid ? <div className="error">{meta.error}</div> : null}
    </label>
  );
};

const TextAreaWrapper = ({
  value,
  toViewValue,
  toModelValue,
  setValue,
  setTouched,
  rtl,
  ...rest
}: any) => {
  const onChange = (e: any) => {
    const value = (toModelValue || identity)(e.target.value);
    setValue(value);
  };
  const viewValue = (toViewValue || identity)(value);

  const props = {
    ...rest,
    onChange,
    onBlur: setTouched,
    value: viewValue,
  };

  return (
    <div className="textarea-container">
      <textarea {...props} />
    </div>
  );
};

export const TextArea = styled(TextAreaComponent)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;

  .textarea-container {
    order: 2;
  }

  textarea {
    order: 2;

    flex: 1;
    border-radius: 0.125rem;
    background-color: var(--primary-dark-000);
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.71;
    border: none;
    width: 100%;
    min-width: 0; /* Firefox fix */
    border: var(--border-default);
    resize: vertical;

    &:focus {
      outline: none;
      border: solid 1px var(--primary-dark-blue-400);
    }

    &:hover {
      box-shadow: 0 0 0 1px rgba(0, 142, 214, 0.2);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &::placeholder {
      color: var(--primary-dark-300);
    }
  }

  &.invalid textarea {
    border-color: var(--additional-red-500);
  }

  &.disabled textarea {
    background-color: var(--primary-dark-blue-50);
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }

  &.required .label::after {
    content: ' *';
    color: var(--additional-red-500);
  }

  .error,
  .label {
    position: relative;

    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-900);

    .help {
      position: absolute;
      right: 0;
      color: var(--primary-dark-300);
    }

    margin-bottom: 0.5rem;
  }

  .error {
    order: 3;
    color: var(--additional-red-500);
    margin-top: 0.125rem;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { getT } from 'utils/framework/intl';
import { Spinner } from 'modules/ui-components/Spinner';
import { ReactComponent as DocumentIcon } from 'assets/icons/document.svg';
import { ReactComponent as DropFilesIcon } from 'assets/icons/drop-files-here.svg';
import { ReactComponent as FilesUploadIcon } from 'assets/icons/files-upload.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Button } from 'modules/ui-components/Button';
import { FileCategory, TemporaryFile, UploadFileCategories } from 'models/File';
import { OpenBanking } from 'modules/open-banking/OpenBanking';

interface Props {
  className?: string;
  name: UploadFileCategories;
  files: FileCategory;
  isSaveFilesEnabled: boolean;
  isOpenBankingOnTop: boolean;
  isOpenBankingVisible: boolean;
  importFiles: (files: File[], category: UploadFileCategories) => void;
  removeImportedFile: (file: TemporaryFile, category: UploadFileCategories) => void;
  uploadFiles: (category: UploadFileCategories) => void;
}

const UploadComponent = ({
  className,
  name,
  files,
  isSaveFilesEnabled,
  isOpenBankingOnTop,
  isOpenBankingVisible,
  importFiles,
  removeImportedFile,
  uploadFiles,
}: Props) => {
  const _t = getT();

  const inputRef = useRef<HTMLInputElement>(null);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [isDragOver, setDragOver] = useState(false);

  const onDrop = (e: React.DragEvent) => {
    e.preventDefault();
    let newFiles;
    if (e.dataTransfer.items) {
      newFiles = Array.from(e.dataTransfer.items)
        .filter(i => i.kind === 'file')
        .map(i => i.getAsFile())
        .filter(f => !!f);
    } else {
      newFiles = Array.from(e.dataTransfer.files);
    }
    const category = inputRef.current!.name as unknown as UploadFileCategories;
    importFiles(newFiles as File[], category);

    setDragOver(false);
  };

  const onChange = (e: React.ChangeEvent) => {
    const category = inputRef.current!.name as unknown as UploadFileCategories;
    importFiles(Array.from(inputRef.current!.files || []), category);
    inputRef.current!.value = null as any;
  };

  useEffect(() => {
    const onDragOver = (e: DragEvent) => {
      e.preventDefault();
      let parent = e.target;
      while (parent) {
        if (parent === dropZoneRef.current) break;
        parent = (parent as HTMLElement).parentElement;
      }

      setDragOver(!!parent);
    };

    document.body.addEventListener('dragover', onDragOver);
    return () => document.body.removeEventListener('dragover', onDragOver);
  }, []);

  return (
    <div className={className} onDrop={onDrop}>
      <div className={classNames('container', { isDragOver })} ref={dropZoneRef}>
        {name && (files.uploaded.length || files.temporary.length) ? (
          <div className="file-list">
            <p className="header">{_t('loans.uploadedFiles')}</p>
            <ul>
              {files.uploaded.map((f, i) => (
                <li key={i}>
                  <span>
                    <DocumentIcon /> {f.fileName.substring(f.fileName.lastIndexOf('/') + 1)}
                  </span>
                </li>
              ))}
              {files.temporary.map((f, i) => (
                <li key={i}>
                  <span>
                    <DocumentIcon /> {f.fileName.substring(f.fileName.lastIndexOf('/') + 1)}
                  </span>
                  <span>
                    {f.file && (
                      <CloseIcon
                        className="close-icon"
                        onClick={() => removeImportedFile(f, name)}
                      />
                    )}
                  </span>
                </li>
              ))}
            </ul>

            <div className="footer">
              <button className="file-button" onClick={() => inputRef.current!.click()}>
                {_t('loans.uploadMore')}
              </button>
              {files.isUploadingFiles && <Spinner />}
              <Button
                className="broker-primary broker-primary--simple"
                onClick={() => uploadFiles(name)}
                disabled={!isSaveFilesEnabled}
              >
                {_t('loans.save')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="icon-container">
            {isDragOver ? (
              <div className="drop-files">
                <DropFilesIcon />
                <p>{_t('loans.dropFilesHere')}</p>
              </div>
            ) : (
              <div className="drop-files">
                <FilesUploadIcon />
                <p>{_t('loans.dragFiles')}</p>
                <p className="help">{_t('loans.fileFormatsRestriction')}</p>
                <p className="help">{_t('loans.fileSizeRestriction')}</p>

                <button className="file-button" onClick={() => inputRef.current!.click()}>
                  {_t('loans.importFromComputer')}
                </button>
              </div>
            )}
          </div>
        )}
        {isOpenBankingVisible && (
          <div className={classNames({ 'openBanking-top': isOpenBankingOnTop })}>
            <OpenBanking />
          </div>
        )}
      </div>
      <input type="file" name={String(name)} hidden multiple ref={inputRef} onChange={onChange} />
    </div>
  );
};

export const Upload = styled(UploadComponent)`
  font-size: 0.875rem;
  line-height: 1.71;
  color: var(--primary-dark-blue-900);

  .container {
    display: grid;
    grid-template-areas: 'openBanking-top';
    gap: 1rem;
    border: 1px dashed var(--primary-dark-blue-100);
    padding: 1rem;
    border-radius: var(--border-radius);

    .openBanking-top {
      grid-area: openBanking-top;
    }

    &.isDragOver {
      border-color: var(--primary-blue-500);
    }
  }

  .icon-container {
    min-height: 12rem;
    display: flex;
    flex: 1;

    justify-content: center;
    align-items: center;
  }

  .drop-files {
    text-align: center;

    .help {
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-300);
    }

    p {
      margin: 0;
    }
  }

  .file-list {
    flex: 1;
    .header {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.71;
      color: var(--primary-dark-300);
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        font-size: 0.75rem;
        line-height: 1.67;
        color: var(--primary-dark-blue-900);
        background-color: var(--primary-dark-blue-25);
        padding: 0.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: flex;
          align-items: center;

          svg:not(.close-icon),
          ${Spinner} {
            margin-right: 0.5rem;
          }

          .close-icon {
            cursor: pointer;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .broker-primary {
        width: unset;
        flex-shrink: 1;
        margin-left: 1rem;
      }
    }
  }

  .file-button {
    margin: 1rem 0;
    margin-right: auto;
    background: none;
    border: 0;
    cursor: pointer;

    text-align: left;
    text-decoration: underline;
    font-size: 0.875rem;
    line-height: 1.71;
    color: var(--primary-dark-blue-900);
  }
`;

import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { errorPageActions, ErrorPageDetails, errorsSelector } from './store';
import { routingActions } from 'modules/routing/store';
import { Route } from 'modules/routing/routes';
import { _t } from 'utils/string';
import { getT } from 'utils/framework/intl';

const getDefaultErrorDetails = () => {
  const _t = getT();

  return {
    title: _t('notFoundPage.errorNumber500'),
    message: _t('notFoundPage.somethingWentWrong'),
    description: _t('notFoundPage.errorInfo'),
  } as ErrorPageDetails;
};

@singleton()
@autobind
export class ErrorPageViewModel extends ViewModel {
  state!: {
    error: Error;
    details: ErrorPageDetails;
  };

  protected connected() {
    if (!this.state.error)
      this.store.dispatch(routingActions.Navigate({ route: Route.Dashboard, params: {} }));
  }

  protected disconnected() {
    this.store.dispatch(errorPageActions.ClearError());
  }

  protected stateMapper = createSelector(errorsSelector, error => ({
    error: error.error,
    details: { ...getDefaultErrorDetails(), ...error.details },
  }));
}

import autobind from 'autobind-decorator';
import { container, singleton } from 'tsyringe';
import { RouteParams, routingActions, routingSelector } from './store';
import { RoutingService, ViewModel } from 'utils/framework';
import { Route } from './routes';
import { createSelector } from '@reduxjs/toolkit';
import { auth0Selector } from 'modules/auth/authStore';
import { User } from 'models/User';

interface State {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  params: RouteParams;
  user?: User;
  route?: Route;
}

@singleton()
@autobind
export class RoutingViewModel extends ViewModel {
  state!: State;
  private router = container.resolve(RoutingService);

  navigate(route: Route, params: RouteParams) {
    this.store.dispatch(routingActions.Navigate({ route, params }));
  }

  hrefOf(route: Route, params: RouteParams) {
    return this.router.compile(route, params);
  }

  isActive(route: Route, params: RouteParams) {
    return this.state.route === route && isEqual(this.state.params, params);
  }

  trigger403() {
    this.store.dispatch(routingActions.UnauthorizedAccess());
  }

  protected stateMapper = createSelector(
    routingSelector,
    auth0Selector,
    (routing, auth): State => ({
      route: routing.route,
      params: routing.params,
      isAuthenticated: !!auth.user,
      isAuthenticating: auth.isAuthenticationPending,
      user: auth.user,
    })
  );
}

const isEqual = (a: RouteParams, b: RouteParams) => {
  if (!a && !b) {
    return true;
  }

  if (a && b) {
    const aProperties = Object.getOwnPropertyNames(a);
    const bProperties = Object.getOwnPropertyNames(b);

    if (aProperties.length !== bProperties.length) {
      return false;
    }

    return aProperties.every(prop => a[prop] === b[prop]);
  }

  return false;
};

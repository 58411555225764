import styled from '@emotion/styled';
import { Button } from 'modules/ui-components/Button';
import React from 'react';
import { getT } from 'utils/framework/intl';
import { useConnect } from 'utils/framework';
import { OpenBankingViewModel } from './OpenBankingViewModel';
import { ReactComponent as SuccessIcon } from 'assets/icons/checkmark.svg';
import classNames from 'classnames';

interface Props {
  isCompleted: boolean;
}

export const OpenBanking = () => {
  const _t = getT();
  const vm = useConnect(OpenBankingViewModel);

  return (
    <StyledOpenBanking isCompleted={vm.state.isCompleted}>
      <p className="help">{vm.state.helpText}</p>
      <Button
        onClick={vm.triggerOpenBanking}
        disabled={vm.state.isDisabled}
        className={classNames({
          button: true,
          primary: true,
          isCompleted: vm.state.isCompleted,
        })}
      >
        {vm.state.isCompleted && <SuccessIcon width="24" height="24" className="checkMark" />}
        {vm.state.buttonText}
      </Button>
    </StyledOpenBanking>
  );
};

const StyledOpenBanking = styled.div<Props>`
  border: 1px dashed
    ${props => (props.isCompleted ? 'var(--primary-green-600)' : 'var(--primary-dark-blue-100)')};
  padding: 1rem;
  border-radius: var(--border-radius);
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin: 0;
  }

  .help {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
  }

  .button {
    margin: auto;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    max-width: max-content;

    border: none;
    line-height: 1.67;
    font-weight: bold;

    &.isCompleted {
      background: var(--primary-green-400);
      color: var(--primary-dark-000);

      .checkMark {
        margin-right: 0.125rem;
      }
    }
  }
`;

import styled from '@emotion/styled';
import React from 'react';
import { RenderColumnsType, TableBodyType } from './Table';
import { Breakpoints } from 'utils/styling';
import { TableCell } from './TableCell';

interface Props {
  tableBody: TableBodyType;
  tableHeaders: string[];
  renderColumns: RenderColumnsType;
  className?: string;
}

const TableBodyComponent = ({ tableBody, renderColumns, tableHeaders, className }: Props) => {
  return (
    <tbody className={className}>
      {tableBody.map((row, i) => (
        <tr key={i}>
          {row.map((item, cellIndex) => (
            <TableCell
              renderColumns={renderColumns}
              cellIndex={cellIndex}
              item={item}
              tableHeaders={tableHeaders}
              key={cellIndex}
            />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export const TableBody = styled(TableBodyComponent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  tr {
    display: grid;
    grid-template-columns: ${props => `repeat(${props.tableHeaders?.length}, 1fr)`};

    @media (max-width: ${Breakpoints.tablet}px) {
      grid-template-columns: unset;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--primary-dark-100);
    }
  }
`;

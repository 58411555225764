import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'modules/ui-components/Button';

interface Props {
  className?: string;
  message: React.ReactNode;
  description: React.ReactNode;
  icon: React.ReactNode;
  cta?: React.ReactNode;
}

const ConfirmationComponent = ({ className, message, description, icon, cta }: Props) => {
  return (
    <div className={className}>
      {icon}
      <div className="message">{message}</div>
      <div className="description">{description}</div>
      {cta}
    </div>
  );
};

export const Confirmation = styled(ConfirmationComponent)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  .message {
    margin: 1rem 0 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.75;
    text-align: center;
    color: var(--primary-dark-blue-900);
  }

  .description {
    font-size: 0.875rem;
    line-height: 1.71;
    color: var(--primary-dark-300);
    text-align: center;
    margin-bottom: 3rem;
    white-space: pre-line;
  }

  ${Button} {
    width: max-content;
  }
`;

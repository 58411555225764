import autobind from 'autobind-decorator';
import { routingSelector } from 'modules/routing/store';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { cameliseKeys } from 'utils/string';
import { paymentCheckoutActions, paymentCheckoutSelector } from './store';
import { createSelector } from '@reduxjs/toolkit';
import { PaymentIntent } from '@stripe/stripe-js';

interface RouteParams {
  paymentIntent?: string;
  paymentIntentClientSecret?: string;
  paymentIntentId: string;
  amountPaid: string;
  redirectStatus: string;
}

@singleton()
@autobind
export class CheckoutCompleteViewModel extends ViewModel {
  state!: {
    intent: PaymentIntent;
    isIntentLoading: boolean;
    amountPaid: string;
  };

  protected async connected() {
    const { amountPaid }: RouteParams = await cameliseKeys(
      routingSelector(this.store.getState()).params
    );

    this.state.amountPaid = amountPaid;
    this.triggerUpdate();
  }

  protected stateMapper = createSelector(paymentCheckoutSelector, paymentCheckout => {
    return {
      intent: paymentCheckout.intent,
      isIntentLoading: paymentCheckout.isIntentLoading,
    };
  });
}

import { User } from 'models/User';
import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';

export const setUserContext = (user: User) => {
  Sentry.setUser({
    id: user.sub,
    isVerified: user.isVerified,
    zohoId: user.zohoId,
    userType: user.userType,
  });
};

export const captureException = (exception: any, context?: CaptureContext) => {
  Sentry.captureException(exception, context);
};

export const captureMessage = (
  message: string,
  context?: CaptureContext | Sentry.SeverityLevel
) => {
  Sentry.captureMessage(message, context);
};

import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import {
  ALLOWED_FILE_MIME_TYPES,
  MAXIMUM_FILE_SIZE,
  MinimumNetCostWhenFileIsRequired,
} from 'utils/constants';
import { Files, TemporaryFile, UploadFileCategories } from 'models/File';
import { isBorrowerSelector, isBrokerSelector, isDealerSelector } from 'modules/auth/authStore';
import { Module, uploadDocumentsActions, uploadDocumentsSelector } from './store';
import { routingActions, routingSelector } from 'modules/routing/store';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { getT } from 'utils/framework/intl';
import { ZohoLoanProduct } from '../../models/Application';

interface State {
  isBorrower: boolean;
  isDealer: boolean;
  files: Files;
  filesFetchCompleted: boolean;
  isBankStatementDocumentRequired: boolean;
  isSaveFilesEnabled: {
    [UploadFileCategory in UploadFileCategories]: boolean;
  };
  isPersonalMotorFinanceType: boolean;
  uploadSections: Array<{
    name: UploadFileCategories;
    title: string;
    isVisible: boolean;
  }>;
}

@singleton()
@autobind
export class UploadDocumentsViewModel extends ViewModel {
  state!: State;

  validateFile = async (file: File) => {
    const _t = getT();
    const fileNameRegex = /^[\w -]{1,128}\.[a-zA-Z0-9]{1,10}$/;
    const isDuplicateName = (key: 'uploaded' | 'temporary', name = '') => {
      const fileNames = Object.values(this.state.files).flatMap(category =>
        category[key].flatMap(file => file.fileName)
      );
      return fileNames.includes(name);
    };

    const schema = Yup.object().shape({
      name: Yup.string()
        .matches(fileNameRegex, _t('form.fileWrongPattern', { fileName: file.name }))
        .test('importedName', _t('form.fileAlreadyImported', { fileName: file.name }), name => {
          return !isDuplicateName('temporary', name);
        })
        .test('uploadedName', _t('form.fileAlreadyUploaded', { fileName: file.name }), name => {
          return !isDuplicateName('uploaded', name);
        }),
      size: Yup.mixed().test('fileSize', _t('form.fileNotAllowed'), size => {
        return size <= MAXIMUM_FILE_SIZE;
      }),
      type: Yup.mixed().test('fileType', _t('form.fileNotAllowed'), type => {
        return ALLOWED_FILE_MIME_TYPES.indexOf(type) !== -1;
      }),
    });

    try {
      await schema.validate({ name: file.name, size: file.size, type: file.type });
      return { file };
    } catch (err: unknown) {
      const errors = (err as { errors: string[] }).errors;
      return { file, error: errors[0] };
    }
  };

  importFiles = async (files: File[], category: UploadFileCategories) => {
    const validFiles: File[] = [];
    for (const file of files) {
      const response = await this.validateFile(file);
      if (response.error) {
        toast.error(response.error);
        continue;
      }
      validFiles.push(response.file);
    }

    const formatedFiles = validFiles.map(file => ({ fileName: file.name, type: file.type, file }));
    this.store.dispatch(uploadDocumentsActions.ImportNewFiles({ files: formatedFiles, category }));
  };

  removeImportedFile = (file: TemporaryFile, category: UploadFileCategories) => {
    this.store.dispatch(uploadDocumentsActions.RemoveImportedFile({ file, category }));
  };

  uploadFiles = (category: UploadFileCategories) => {
    this.store.dispatch(uploadDocumentsActions.UploadFiles({ category }));
  };

  protected connected() {
    const { id, module } = routingSelector(this.store.getState()).params as {
      id: string;
      module: Module;
    };

    if (!Object.values(Module).includes(module)) {
      this.store.dispatch(routingActions.UnauthorizedAccess());
      return;
    }

    this.store.dispatch(uploadDocumentsActions.FetchRecord({ id, module }));
  }

  protected disconnected(): void {
    this.store.dispatch(
      uploadDocumentsActions.SetUploadedFilesComplete({ filesFetchCompleted: false })
    );
  }

  protected stateMapper = createSelector(
    uploadDocumentsSelector,
    isDealerSelector,
    isBorrowerSelector,
    isBrokerSelector,
    (uploadDocuments, isDealer, isBorrower, isBroker): State => {
      const isSaveFilesEnabled = (category: UploadFileCategories) => {
        return uploadDocuments.files[category].temporary.length > 0;
      };
      const isPersonalMotorFinance =
        uploadDocuments.record.loanProductType === ZohoLoanProduct.PersonalMotorFinance;
      return {
        isBorrower,
        isDealer,
        files: uploadDocuments.files,
        filesFetchCompleted: uploadDocuments.filesFetchCompleted,
        isSaveFilesEnabled: {
          [UploadFileCategories.BANK_STATEMENTS]: isSaveFilesEnabled(
            UploadFileCategories.BANK_STATEMENTS
          ),
          [UploadFileCategories.TAX_CLEARANCE_CERTIFICATE]: isSaveFilesEnabled(
            UploadFileCategories.TAX_CLEARANCE_CERTIFICATE
          ),
          [UploadFileCategories.STATEMENT_OF_AFFAIRS]: isSaveFilesEnabled(
            UploadFileCategories.STATEMENT_OF_AFFAIRS
          ),
          [UploadFileCategories.ACCOUNTS]: isSaveFilesEnabled(UploadFileCategories.ACCOUNTS),
          [UploadFileCategories.INVOICE]: isSaveFilesEnabled(UploadFileCategories.INVOICE),
          [UploadFileCategories.PROPOSAL]: isSaveFilesEnabled(UploadFileCategories.PROPOSAL),
          [UploadFileCategories.UCGS_ELIGIBILITY_DOCUMENT]: isSaveFilesEnabled(
            UploadFileCategories.UCGS_ELIGIBILITY_DOCUMENT
          ),
          [UploadFileCategories.PAYSLIPS]: isSaveFilesEnabled(UploadFileCategories.PAYSLIPS),
        },
        isBankStatementDocumentRequired: !!(
          uploadDocuments.record.netCost &&
          uploadDocuments.record.netCost > MinimumNetCostWhenFileIsRequired.BANK_STATEMENTS
        ),
        isPersonalMotorFinanceType: isPersonalMotorFinance,
        uploadSections: [
          {
            name: UploadFileCategories.UCGS_ELIGIBILITY_DOCUMENT,
            title: 'loans.ucgsEligibilityDocument',
            isVisible:
              (isBorrower || isBroker) &&
              uploadDocuments.record.ucgsProduct &&
              !isPersonalMotorFinance,
          },
          {
            name: UploadFileCategories.PAYSLIPS,
            title: !isPersonalMotorFinance ? 'loans.payslips' : 'loans.payslipPersonalMotorFinance',
            isVisible: isPersonalMotorFinance,
          },
          {
            name: UploadFileCategories.PROPOSAL,
            title: 'loans.proposalForms',
            isVisible: !isBorrower && !isPersonalMotorFinance,
          },
          {
            name: UploadFileCategories.BANK_STATEMENTS,
            title: !isPersonalMotorFinance
              ? 'loans.bankStatements'
              : 'loans.bankStatementsPersonalMotorFinance',
            isVisible: true,
          },
          {
            name: UploadFileCategories.TAX_CLEARANCE_CERTIFICATE,
            title: 'loans.taxClearance',
            isVisible: !isPersonalMotorFinance,
          },
          {
            name: UploadFileCategories.STATEMENT_OF_AFFAIRS,
            title: 'loans.statementOfAffairs',
            isVisible: !isPersonalMotorFinance,
          },
          {
            name: UploadFileCategories.ACCOUNTS,
            title: 'loans.accounts',
            isVisible: !isPersonalMotorFinance,
          },
          {
            name: UploadFileCategories.INVOICE,
            title: 'loans.invoice',
            isVisible: isDealer && !isPersonalMotorFinance,
          },
        ],
      };
    }
  );
}

import { useEffect, useRef } from 'react';
import { BaseOption } from '../modules/ui-components/Select';
import { AssetType } from './constants';
import { ZohoLoanProduct } from 'models/Application';

export const debounce = (fn: Function, ms: number) => {
  let timeout: any;

  return (...args: any) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = undefined;
      fn(...args);
    }, ms);
  };
};

export const identity = (x: any) => x;

export const getPastYearDate = (year: number): Date => {
  let now = new Date();
  now.setFullYear(now.getFullYear() - year);
  return now;
};

export const PSInit = (w: any, s: string) => {
  var e = document.createElement('script');
  e.type = 'text/javascript';
  e.async = true;
  e.src = process.env.REACT_APP_PAGESENSE_LINK || '';
  var x = document.getElementsByTagName('script')[0];
  x.parentNode!.insertBefore(e, x);
};

export const changeChatbotVisibility = (isOpen: boolean) => {
  if (isOpen) {
    document.body.classList.add('calculator-open');
  } else {
    document.body.classList.remove('calculator-open');
  }
};

export function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const toNumber = (number: number | null | undefined): number => {
  if (!number || isNaN(number)) return 0;
  return number;
};

export const assetTypes: BaseOption[] = Object.values(AssetType).map(val => {
  return { label: val, value: val };
});

export const getFiltredAssetTypes = (loanProduct: ZohoLoanProduct): BaseOption[] => {
  if (loanProduct === ZohoLoanProduct.PersonalMotorFinance) {
    return [
      { value: AssetType.car, label: AssetType.car },
      { value: AssetType.lightCommercial, label: AssetType.lightCommercial },
    ];
  }

  return assetTypes;
};

import { Form, Formik } from 'formik';
import React from 'react';
import { _t } from 'utils/string';
import { Input } from 'modules/ui-components/Input';
import { Select } from 'modules/ui-components/Select';
import { Toggle } from 'modules/ui-components/Toggle';
import { Button } from 'modules/ui-components/Button';
import { FormattedMessage } from 'react-intl';
import { GENDERS, GET_LOAN_TYPES, MARTIAL_STATUSES } from '../../leadConstants';
import { Lead } from 'models/Lead';
import { ZohoLoanProduct } from 'models/Application';
import { useConnect } from 'utils/framework';
import { ClientDetailsViewModel, hasSpecialUseDomainName } from '../ClientDetailsViewModel';
import { getT } from 'utils/framework/intl';
import * as Yup from 'yup';
import { getPastYearDate } from 'utils/function';
import { COUNTRIES } from 'utils/constants';
import { User } from 'models/User';

interface GetFormInitialValues {
  lead?: Partial<Lead>;
  calculatorValues?: Partial<Lead>;
  user?: User;
  isBorrower: boolean;
}

const clientDetailsFormSchema = () => {
  const _t = getT();
  const ppsnRegex = /^(\d{7})([A-Z]{1,2})$/;

  return Yup.object({
    product: Yup.string().required(_t('form.required')),
    contactFirstName: Yup.string()
      .max(255, _t('form.lengthMax', { len: 255 }))
      .required(_t('form.required')),
    contactLastName: Yup.string()
      .max(255, _t('form.lengthMax', { len: 255 }))
      .required(_t('form.required')),
    maidenName: Yup.string().max(255, _t('form.lengthMax', { len: 255 })),
    dateOfBirth: Yup.date()
      .required(_t('form.required'))
      .max(getPastYearDate(18), _t('form.dobMin'))
      .min(getPastYearDate(100), _t('form.dobMax')),
    ppsn: Yup.string().matches(ppsnRegex, _t('form.ppsn')).required(_t('form.required')),
    gender: Yup.string().required(_t('form.required')),
    countryOfNationality: Yup.string().required(_t('form.required')),
    numberOfDependants: Yup.number()
      .min(0, _t('form.min', { min: 0 }))
      .max(99, _t('form.lengthMax', { len: 99 })),
    maritalStatus: Yup.string(),
    email: Yup.string()
      .email(_t('form.email'))
      .required(_t('form.required'))
      .test({
        name: 'emailSpecialUseDomainNames',
        test: value => hasSpecialUseDomainName(value || ''),
        message: _t('form.email'),
      }),
  });
};

const getFormInitialValues = ({
  calculatorValues,
  lead,
  user,
  isBorrower,
}: GetFormInitialValues) => {
  const initEmail = isBorrower ? user?.email : lead?.email;

  return {
    product: lead?.product || calculatorValues?.product || ZohoLoanProduct.PersonalMotorFinance,
    contactFirstName: lead?.contactFirstName || '',
    contactLastName: lead?.contactLastName || '',
    maidenName: lead?.maidenName || '',
    dateOfBirth: lead?.dateOfBirth || '',
    ppsn: lead?.ppsn || '',
    gender: lead?.gender || '',
    countryOfNationality: lead?.countryOfNationality || '',
    numberOfDependants: lead?.numberOfDependants || '',
    maritalStatus: lead?.maritalStatus || '',
    email: initEmail || '',
  };
};

export const MotorFinanceClientDetails = () => {
  const vm = useConnect(ClientDetailsViewModel);

  return (
    <Formik
      initialValues={getFormInitialValues({
        calculatorValues: vm.state.calculatorValues.calculatorValues,
        lead: vm.state.lead,
        user: vm.state.user,
        isBorrower: vm.state.isBorrower,
      })}
      validationSchema={clientDetailsFormSchema}
      onSubmit={async values => vm.saveClient(values)}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <Form className="form">
          {vm.state.canSetLoanProduct && (
            <Toggle
              name={'product'}
              label={_t('loans.loanType')}
              options={GET_LOAN_TYPES()}
              onChange={(e: ZohoLoanProduct) => vm.updateCalculatorValues({ product: e })}
            />
          )}
          <div className="form-section">
            <div className="row">
              <Input
                name="contactFirstName"
                type="text"
                label={_t('loans.forename')}
                disabled={!vm.state.canEditClient}
              />
              <Input
                name="contactLastName"
                type="text"
                label={_t('loans.surname')}
                disabled={!vm.state.canEditClient}
              />
            </div>
            <Input
              name="maidenName"
              type="text"
              label={_t('loans.maidenName')}
              disabled={!vm.state.canEditClient}
            />
            <div className="row">
              <Input
                name="dateOfBirth"
                type="date"
                label={_t('loans.dateOfBirth')}
                disabled={!vm.state.canEditClient}
              />
              <Input
                name="ppsn"
                type="text"
                label={_t('loans.ppsn')}
                disabled={!vm.state.canEditClient}
              />
            </div>
            <div className="row">
              <Select
                name="gender"
                label={_t('loans.gender')}
                options={GENDERS}
                isDisabled={!vm.state.canEditClient}
              />
              <Select
                name="countryOfNationality"
                label={_t('loans.nationality')}
                options={COUNTRIES}
                isDisabled={!vm.state.canEditClient}
              />
            </div>
            <div className="row">
              <Input
                name="numberOfDependants"
                type="number"
                label={_t('loans.dependents')}
                disabled={!vm.state.canEditClient}
              />
              <Select
                name="maritalStatus"
                label={_t('loans.maritalStatus')}
                options={MARTIAL_STATUSES}
                isDisabled={!vm.state.canEditClient}
              />
            </div>
            {!vm.state.isBorrower && (
              <Input
                label={_t('layout.email')}
                name="email"
                type="email"
                disabled={!vm.state.canEditClient}
              />
            )}
          </div>

          {vm.state.canEditClient ? (
            <Button
              onClick={() => handleSubmit()}
              disabled={vm.state.isLeadUpdating}
              className="action-btn broker-primary broker-primary--simple"
              type="button"
            >
              <FormattedMessage id="loans.saveClient" />
            </Button>
          ) : (
            <Button onClick={vm.allowToEditClient} className="action-btn" type="button">
              <FormattedMessage id="loans.edit" />
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

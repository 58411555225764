import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { camelCase, constantCase, snakeCase } from 'change-case';
import { Application, FinishedApplicationStatuses, ZohoLoanProduct } from 'models/Application';
import {
  DocumentsResponse,
  Files,
  TemporaryFile,
  UploadedFile,
  UploadFileCategories,
} from 'models/File';
import { Lead } from 'models/Lead';
import { OpenBankingStatus } from 'models/openBanking';
import { notifyError, notifySuccess } from 'modules/notification/store';
import { openBankingActions } from 'modules/open-banking/store';
import { Route } from 'modules/routing/routes';
import { routingActions } from 'modules/routing/store';
import { fork, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { CrmService } from 'services/CrmService';
import { DocumentsService } from 'services/DocumentsService';
import { container } from 'tsyringe';
import { getT } from 'utils/framework/intl';
import { captureException } from 'utils/reporting';
import { State } from 'utils/store';
import { _t } from 'utils/string';

export enum Module {
  APPLICATION = 'application',
  LEAD = 'lead',
}

export interface Record {
  module: Module;
  id?: string;
  netCost?: number;
  customerId?: string;
  openBankingStatus: OpenBankingStatus;
  loanProductType: ZohoLoanProduct;
  ucgsProduct: boolean;
}

export interface UploadDocumentsState {
  files: Files;
  filesFetchCompleted: boolean;
  record: Record;
}

const getEmptyFileCategory = {
  isUploadingFiles: false,
  temporary: [],
  uploaded: [],
};

const initialState: UploadDocumentsState = {
  files: {
    [UploadFileCategories.BANK_STATEMENTS]: getEmptyFileCategory,
    [UploadFileCategories.TAX_CLEARANCE_CERTIFICATE]: getEmptyFileCategory,
    [UploadFileCategories.STATEMENT_OF_AFFAIRS]: getEmptyFileCategory,
    [UploadFileCategories.ACCOUNTS]: getEmptyFileCategory,
    [UploadFileCategories.INVOICE]: getEmptyFileCategory,
    [UploadFileCategories.PAYSLIPS]: getEmptyFileCategory,
    [UploadFileCategories.PROPOSAL]: getEmptyFileCategory,
    [UploadFileCategories.UCGS_ELIGIBILITY_DOCUMENT]: getEmptyFileCategory,
  },
  filesFetchCompleted: false,
  record: {
    module: Module.APPLICATION,
    openBankingStatus: null,
    loanProductType: ZohoLoanProduct.Leasing,
    ucgsProduct: false,
  },
};

export const uploadDocuments = createSlice({
  name: 'uploadDocuments',
  initialState: initialState,
  reducers: {
    FetchUploadedFiles(
      state: UploadDocumentsState,
      action: PayloadAction<{ id: string; module: Module }>
    ) {
      state.files = initialState.files;
      state.filesFetchCompleted = false;
    },
    FetchUploadedFileComplete(
      state: UploadDocumentsState,
      action: PayloadAction<{ file: UploadedFile; category: UploadFileCategories }>
    ) {
      state.files[action.payload.category].uploaded = [
        ...state.files[action.payload.category].uploaded,
        action.payload.file,
      ];
    },
    SetUploadedFilesComplete(
      state: UploadDocumentsState,
      action: PayloadAction<{ filesFetchCompleted: boolean }>
    ) {
      state.filesFetchCompleted = action.payload.filesFetchCompleted;
    },
    FetchRecord(
      state: UploadDocumentsState,
      action: PayloadAction<{ id: string; module: Module }>
    ) {},
    FetchRecordCompleted(state: UploadDocumentsState, action: PayloadAction<Record>) {
      state.record = action.payload;
    },
    RedirectToUploadDocuments: (
      state: UploadDocumentsState,
      action: PayloadAction<{ id: string; module: Module }>
    ) => {},
    ImportNewFiles(
      state: UploadDocumentsState,
      action: PayloadAction<{ files: TemporaryFile[]; category: UploadFileCategories }>
    ) {
      state.files[action.payload.category].temporary = [
        ...state.files[action.payload.category].temporary,
        ...action.payload.files,
      ];
    },
    RemoveImportedFile(
      state: UploadDocumentsState,
      action: PayloadAction<{ file: TemporaryFile; category: UploadFileCategories }>
    ) {
      state.files[action.payload.category].temporary = state.files[
        action.payload.category
      ].temporary.filter(file => file.file !== action.payload.file.file);
    },
    UploadFiles(
      state: UploadDocumentsState,
      action: PayloadAction<{ category: UploadFileCategories }>
    ) {
      state.files[action.payload.category].isUploadingFiles = true;
    },
    UpdateUploadedFile(
      state: UploadDocumentsState,
      action: PayloadAction<{ file: UploadedFile; category: UploadFileCategories }>
    ) {
      const category = action.payload.category;
      const newFile = action.payload.file;

      const newTemporaryFiles = state.files[category].temporary.filter(
        file => file.file !== newFile.file
      );

      state.files[category].temporary = newTemporaryFiles;
      state.files[category].uploaded = [...state.files[category].uploaded, newFile];
    },
    UploadFilesCompleted(
      state: UploadDocumentsState,
      action: PayloadAction<{ category: UploadFileCategories }>
    ) {
      state.files[action.payload.category].isUploadingFiles = false;
    },
  },
});

export const uploadDocumentsActions = {
  ...uploadDocuments.actions,
};

export const uploadDocumentsSelector = (state: State) =>
  state[uploadDocuments.name] as UploadDocumentsState;

function* fetchRecord() {
  const crmService = container.resolve(CrmService);
  const _t = getT();

  yield takeLatest(uploadDocumentsActions.FetchRecord, function* ({ payload }) {
    try {
      switch (payload.module) {
        case Module.LEAD: {
          const lead: Lead = yield crmService.fetchLead(payload.id);
          yield put(uploadDocumentsActions.FetchRecordCompleted(formatLeadRecord(lead)));
          break;
        }
        case Module.APPLICATION: {
          const app: Application = yield crmService.fetchApplication(payload.id);
          const isOnFinishedStage = FinishedApplicationStatuses.includes(app.stage);
          if (isOnFinishedStage) {
            yield put(routingActions.Navigate({ route: Route.Dashboard, params: {} }));
          } else {
            yield put(uploadDocumentsActions.FetchRecordCompleted(formatApplicationRecord(app)));
          }
          break;
        }
      }
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'record' })));
    }
    yield put(uploadDocumentsActions.FetchUploadedFiles(payload));
  });

  yield takeEvery(uploadDocumentsActions.FetchRecordCompleted, function* ({ payload }) {
    yield put(
      openBankingActions.UpdateOpenBankingState({
        status: payload.openBankingStatus,
        view: payload.module,
        customerRef: payload.id || '',
      })
    );
  });
}

function* fetchUploadedFiles() {
  const documentService = container.resolve(DocumentsService);
  const _t = getT();

  yield takeLeading(uploadDocumentsActions.FetchUploadedFiles, function* ({ payload }) {
    const paramName = {
      [Module.APPLICATION]: 'applications',
      [Module.LEAD]: 'leads',
    }[payload.module];

    try {
      const documentTypes = Object.keys(initialState.files).map(fileType => constantCase(fileType));
      const response: DocumentsResponse = yield documentService.getUserDocuments({
        [paramName]: [payload.id],
        types: documentTypes,
      });
      for (let file of response.results) {
        const fileCategory = camelCase(file.type) as unknown as UploadFileCategories;

        if (Object.values(UploadFileCategories).includes(fileCategory)) {
          yield put(
            uploadDocumentsActions.FetchUploadedFileComplete({
              file: file,
              category: fileCategory,
            })
          );
        }
      }
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'documents' })));
    }
    yield put(uploadDocumentsActions.SetUploadedFilesComplete({ filesFetchCompleted: true }));
  });
}

function* handleUploadingFiles() {
  const documentService = container.resolve(DocumentsService);
  const _t = getT();

  yield takeLeading(uploadDocumentsActions.UploadFiles, function* ({ payload }) {
    const { files, record }: UploadDocumentsState = yield select(uploadDocumentsSelector);
    const recordModule = {
      [Module.APPLICATION]: 'applicationId',
      [Module.LEAD]: 'leadId',
    }[record.module];

    for (let file of files[payload.category].temporary) {
      if (!file.file) continue;
      const body = {
        uploadFile: file.file,
        type: snakeCase(String(payload.category)).toUpperCase(),
        customerId: record.customerId,
        [recordModule]: record.id,
      };

      try {
        const uploadedFile: UploadedFile = yield documentService.uploadFile(body);
        yield put(
          uploadDocumentsActions.UpdateUploadedFile({
            file: { ...uploadedFile, file: file.file },
            category: payload.category,
          })
        );
        yield put(notifySuccess(_t('notification.savedFileSuccess')));
      } catch (err) {
        captureException(err);
        if (err instanceof AxiosError) {
          const error = err.response?.data.uploadFile || '';
          const msg = error[0] || _t('notification.saveFileFailed', { fileName: file.fileName });
          yield put(notifyError(msg));
        }
      }
    }
    yield put(uploadDocumentsActions.UploadFilesCompleted(payload));
  });
}

function* handleRedirect() {
  yield takeEvery(uploadDocumentsActions.RedirectToUploadDocuments, function* (action) {
    try {
      yield put(
        routingActions.Navigate({
          route: Route.UploadDocuments,
          params: { id: action.payload.id, module: action.payload.module },
        })
      );
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.redirectToUploadDocumentsFailed')));
    }
  });
}

export function* uploadDocumentsSaga() {
  yield fork(fetchUploadedFiles);
  yield fork(fetchRecord);
  yield fork(handleUploadingFiles);
  yield fork(handleRedirect);
}

const formatApplicationRecord = (application: Application) => {
  return {
    id: application.id,
    customerId: application.contactName.id,
    netCost: application.netCost,
    module: Module.APPLICATION,
    openBankingStatus: application.openBankingState,
    loanProductType: application.loanProduct,
    ucgsProduct: application.ucgsProduct,
  };
};

const formatLeadRecord = (lead: Lead) => {
  return {
    id: lead.id,
    netCost: lead.assetCost,
    module: Module.LEAD,
    openBankingStatus: lead.openBankingState,
    loanProductType: lead.product,
    ucgsProduct: lead.ucgsProduct,
  };
};

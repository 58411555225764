import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Route } from 'modules/routing/routes';
import { routingActions } from 'modules/routing/store';
import { fork, put, takeEvery } from 'redux-saga/effects';
import { State } from 'utils/store';

export interface ErrorPageDetails {
  title: string;
  message: string;
  description: string;
}

export interface ErrorPageState {
  error?: Error;
  details?: Partial<ErrorPageDetails>;
}

export const errors = createSlice({
  name: 'errors',
  initialState: {} as ErrorPageState,
  reducers: {
    ShowError: (
      state: ErrorPageState,
      action: PayloadAction<{ error: Error; details?: Partial<ErrorPageDetails> }>
    ) => {},
    SetError: (
      state: ErrorPageState,
      action: PayloadAction<{ error: Error; details?: Partial<ErrorPageDetails> }>
    ) => {
      state.error = action.payload.error;
      state.details = action.payload.details;
    },
    ClearError: () => undefined,
  },
});

export const errorPageActions = {
  ...errors.actions,
};

export const errorsSelector = (state: State) => state[errors.name] as ErrorPageState;

function* handleShowError() {
  yield takeEvery(errorPageActions.ShowError, function* (action) {
    yield put(errorPageActions.SetError(action.payload));
    yield put(
      routingActions.Navigate({
        route: Route.ErrorPage,
        params: {},
      })
    );
  });
}

export function* errorPageSaga() {
  yield fork(handleShowError);
}

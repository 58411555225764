import React, { useCallback, useEffect } from 'react';
import { CompanyTypes, VisionNetInputTypes, ZohoLoanProduct } from 'models/Application';
import { _t } from 'utils/string';
import { Button } from 'modules/ui-components/Button';
import { Input } from 'modules/ui-components/Input';
import { BaseOption, Select } from 'modules/ui-components/Select';
import { FormattedMessage } from 'react-intl';
import { CompanyOption, Title } from '../../utils';
import { Form, Formik, useFormikContext } from 'formik';
import { useConnect } from 'utils/framework';
import { ClientDetailsFormValues, Lead } from 'models/Lead';
import { User } from 'models/User';
import { Toggle } from 'modules/ui-components/Toggle';
import {
  ClientDetailsViewModel,
  defaultCompanyOption,
  hasSpecialUseDomainName,
} from '../ClientDetailsViewModel';
import { GET_LOAN_TYPES } from '../../leadConstants';
import { getT } from 'utils/framework/intl';
import * as Yup from 'yup';
import { getPastYearDate } from 'utils/function';
import { string } from 'yup';
import { AnyObject } from 'yup/lib/types';

const clientDetailsFormSchema = () => {
  const _t = getT();
  const ppsnRegex = /^(\d{7})([A-Z]{1,2})$/;
  const companyNameMaxLength = 80;

  const testCompanyNameMaxLength = (showMessage: boolean) => ({
    name: 'companyNameMaxLength',
    test: (value: string | undefined, context: Yup.TestContext<AnyObject>) => {
      const firstNameLength = context.parent.firstName ? context.parent.firstName.length : 0;
      const lastNameLength = context.parent.lastName ? context.parent.lastName.length : 0;
      return firstNameLength + lastNameLength < companyNameMaxLength;
    },
    message: showMessage ? _t('form.applicantNameMaxLength', { max: companyNameMaxLength }) : ' ',
  });

  return Yup.object({
    companyType: Yup.string().required(_t('form.required')),
    companyName: Yup.string().when('companyType', {
      is: (companyType: string) => companyType === CompanyTypes.COMPANY,
      then: string().required(_t('form.required')),
      otherwise: string(),
    }),
    companyNum: Yup.string(),
    contact: Yup.object().shape({
      firstName: Yup.string().required(_t('form.required')).test(testCompanyNameMaxLength(true)),
      lastName: Yup.string().required(_t('form.required')).test(testCompanyNameMaxLength(false)),
      dateOfBirth: Yup.date()
        .required(_t('form.required'))
        .max(getPastYearDate(18), _t('form.dobMin'))
        .min(getPastYearDate(100), _t('form.dobMax')),
      ppsn: Yup.string().matches(ppsnRegex, _t('form.ppsn')),
      email: Yup.string()
        .email(_t('form.email'))
        .required(_t('form.required'))
        .test({
          name: 'emailSpecialUseDomainNames',
          test: value => hasSpecialUseDomainName(value || ''),
          message: _t('form.email'),
        }),
    }),
  });
};

interface GetFormInitialValues {
  isBorrower: boolean;
  user?: User;
  calculatorValues?: Partial<Lead>;
  lead?: Partial<Lead>;
}

const getFormInitialValues = ({
  isBorrower,
  user,
  calculatorValues,
  lead,
}: GetFormInitialValues) => {
  const initEmail = isBorrower ? user?.email : lead?.email;
  return {
    loanProduct: lead?.product || calculatorValues?.product || ZohoLoanProduct.Leasing,
    companyType: lead?.applicantType || '',
    companyName: lead?.applicantName || '',
    companyNum: lead?.companyRegistrationNumber || '',
    contact: {
      firstName: lead?.contactFirstName || '',
      lastName: lead?.contactLastName || '',
      dateOfBirth: lead?.dateOfBirth || '',
      ppsn: lead?.ppsn || '',
      email: initEmail || '',
    },
  };
};

export const ClientDetails = () => {
  const vm = useConnect(ClientDetailsViewModel);

  const companyOptions = [
    { label: _t('form.privateCompanyLimited'), value: CompanyTypes.COMPANY },
    { label: _t('form.soleTrader'), value: CompanyTypes.SOLE_TRADER },
  ];

  return (
    <Formik
      initialValues={getFormInitialValues({
        user: vm.state.user,
        isBorrower: vm.state.isBorrower,
        calculatorValues: vm.state.calculatorValues.calculatorValues,
        lead: vm.state.lead,
      })}
      validationSchema={clientDetailsFormSchema}
      onSubmit={async values => vm.saveClient(values)}
      enableReinitialize={true}
    >
      {({ handleSubmit, values }) => (
        <Form className="form">
          <Title className="title-small">{_t('loans.company')}</Title>

          {vm.state.canSetLoanProduct && (
            <Toggle
              name={'loanProduct'}
              label={_t('loans.loanType')}
              options={GET_LOAN_TYPES()}
              onChange={(e: ZohoLoanProduct) => vm.updateCalculatorValues({ product: e })}
            />
          )}

          <Select
            label={_t('loans.companyType')}
            placeholder={_t('form.select')}
            name="companyType"
            options={companyOptions}
            isDisabled={!vm.state.canEditClient}
          />

          {values.companyType === CompanyTypes.COMPANY && <CompanyInputs vm={vm} />}

          <div className="form-section">
            <div className="row">
              <Input
                name="contact.firstName"
                type="text"
                label={_t('loans.firstName')}
                disabled={!vm.state.canEditClient}
              />
              <Input
                name="contact.lastName"
                type="text"
                label={_t('loans.lastName')}
                disabled={!vm.state.canEditClient}
              />
            </div>
            <div className="row">
              <Input
                name="contact.dateOfBirth"
                type="date"
                label={_t('loans.dateOfBirth')}
                disabled={!vm.state.canEditClient}
              />
              <Input
                name="contact.ppsn"
                type="text"
                label={_t('loans.ppsn')}
                disabled={!vm.state.canEditClient}
              />
            </div>
            {!vm.state.isBorrower && (
              <Input
                label={_t('layout.email')}
                name="contact.email"
                type="email"
                disabled={!vm.state.canEditClient}
              />
            )}
          </div>

          {vm.state.canEditClient ? (
            <Button
              onClick={() => handleSubmit()}
              disabled={vm.state.isLeadUpdating}
              className="action-btn broker-primary broker-primary--simple"
              type="button"
            >
              <FormattedMessage id="loans.saveClient" />
            </Button>
          ) : (
            <Button className="action-btn" type="button" onClick={vm.allowToEditClient}>
              <FormattedMessage id="loans.edit" />
            </Button>
          )}
        </Form>
      )}
    </Formik>
  );
};

const CompanyInputs = ({ vm }: { vm: ClientDetailsViewModel }) => {
  const { values, setFieldValue } = useFormikContext<ClientDetailsFormValues>();
  useCompanyFieldsSync(vm, values, setFieldValue);
  return (
    <>
      <Select
        placeholder={_t('loans.searchInDatabase')}
        label={_t('loans.companyName')}
        name="companyName"
        isLoading={vm.state.companiesLoading}
        options={vm.state.companyNames}
        onInputChange={value => vm.onCompanyChange({ type: VisionNetInputTypes.Name, value })}
        noOptionsMessage={() => _t('loans.noOptionsCompany')}
        isDisabled={!vm.state.canEditClient}
        formatOptionLabel={option => {
          return (
            <CompanyOption
              label={(option as BaseOption).label}
              value={(option as BaseOption).value}
            />
          );
        }}
      />
      <Select
        placeholder={_t('loans.searchInDatabase')}
        label={_t('loans.companyNumber')}
        name="companyNum"
        isLoading={vm.state.companiesLoading}
        options={vm.state.companyNumbers}
        onInputChange={value => vm.onCompanyChange({ type: VisionNetInputTypes.Number, value })}
        noOptionsMessage={() => _t('loans.noOptionsCompany')}
        isDisabled={!vm.state.canEditClient}
        formatOptionLabel={option => {
          return (
            <CompanyOption
              isCompanyNumber={true}
              label={(option as BaseOption).label}
              value={(option as BaseOption).value}
            />
          );
        }}
      />
    </>
  );
};

const useCompanyFieldsSync = (
  vm: ClientDetailsViewModel,
  values: ClientDetailsFormValues,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) => {
  const setNewValue = useCallback((fieldName: string, syncValue: string, newValue?: string) => {
    if (vm.state.companies.length > 0) {
      if (syncValue) {
        setFieldValue(fieldName, newValue);
      } else {
        setFieldValue(fieldName, defaultCompanyOption.value);
      }
    }
  }, []);

  useEffect(() => {
    const newValue = vm.state.companyNumbers.find(
      companyNumberOption => companyNumberOption.label === values.companyName
    )?.value;

    setNewValue('companyNum', values.companyName, newValue?.toString());
  }, [values.companyName]);

  useEffect(() => {
    const companyName = vm.state.companies.find(
      company => company.companyNum === Number(values.companyNum)
    )?.companyName;
    const newValue = vm.state.companyNames.find(
      companyNameOption => companyNameOption.value === companyName
    )?.value;

    setNewValue('companyName', values.companyNum, newValue);
  }, [values.companyNum]);
};

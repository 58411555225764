import autobind from 'autobind-decorator';
import { routingSelector } from 'modules/routing/store';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { paymentCheckoutActions, paymentCheckoutSelector } from './store';
import { createSelector } from '@reduxjs/toolkit';
import { PaymentIntent } from 'models/Payment';

@singleton()
@autobind
export class CheckoutPageViewModel extends ViewModel {
  state!: {
    intent: PaymentIntent;
    isIntentLoading: boolean;
  };

  protected connected() {
    const { id } = routingSelector(this.store.getState()).params as {
      id: string;
    };

    this.store.dispatch(paymentCheckoutActions.FetchPaymentIntent({ id }));
  }

  protected stateMapper = createSelector(paymentCheckoutSelector, paymentCheckout => {
    return {
      intent: paymentCheckout.intent,
      isIntentLoading: paymentCheckout.isIntentLoading,
    };
  });
}

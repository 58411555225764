import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'modules/ui-components/Button';
import { Confirmation } from 'modules/ui-components/Confirmation';
import { useConnect } from 'utils/framework';
import { Upload } from 'modules/upload-documents/components/Upload';
import { getT } from 'utils/framework/intl';
import { FooterButtons } from 'modules/ui-components/FooterButtons';
import { FileHeader } from 'modules/ui-components/FileHeader';
import { UploadDocumentsViewModel } from './UploadDocumentsViewModel';
import { UploadFileCategories } from 'models/File';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UploadFileLoading } from './components/UploadFilesLoading';

interface Props {
  className?: string;
}

const UploadDocumentsComponent = ({ className }: Props) => {
  const vm = useConnect(UploadDocumentsViewModel);
  const _t = getT();
  const { openBanking } = useFlags();

  const getUploadDocumentsMessage = () => {
    if (vm.state.isPersonalMotorFinanceType) {
      return _t('loans.uploadDocumentsMessagePersonalMotorFinance');
    }
    if (vm.state.isBankStatementDocumentRequired) {
      return _t('loans.uploadDocumentsMessageAboveNetCost');
    }
    return _t('loans.uploadDocumentsMessageBelowNetCost');
  };

  return (
    <section className={className}>
      <h1 className="page__header">{_t('loans.uploadDocuments')}</h1>
      {vm.state.filesFetchCompleted && (
        <p className="upload-message">{getUploadDocumentsMessage()}</p>
      )}

      {vm.state.filesFetchCompleted ? (
        vm.state.uploadSections.map((section, i) => {
          const isOpenBankingVisible =
            openBanking && section.name === UploadFileCategories.BANK_STATEMENTS;

          return (
            section.isVisible && (
              <div key={i}>
                <FileHeader title={_t(section.title)} />
                <Upload
                  name={section.name}
                  files={vm.state.files[section.name]}
                  importFiles={vm.importFiles}
                  removeImportedFile={vm.removeImportedFile}
                  uploadFiles={vm.uploadFiles}
                  isSaveFilesEnabled={vm.state.isSaveFilesEnabled[section.name]}
                  isOpenBankingOnTop={isOpenBankingVisible && vm.state.isPersonalMotorFinanceType}
                  isOpenBankingVisible={isOpenBankingVisible}
                />
              </div>
            )
          );
        })
      ) : (
        <UploadFileLoading />
      )}

      <FooterButtons backToDashboard />
    </section>
  );
};

export const UploadDocuments = styled(UploadDocumentsComponent)`
  .step-name {
    margin: 0 0 -0.5rem;
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
  }

  .upload-message {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
  }

  .processing-message {
    font-size: 1.2rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-500);
  }

  ${FileHeader} {
    margin: 1.5rem 0 1rem 0;
  }

  ${Confirmation} {
    border-radius: 4px;
    border: solid 1px var(--primary-dark-blue-200);
    padding: 2rem 4rem;

    .description {
      font-size: 0.875rem;
      line-height: 1.71;
      text-align: center;
      margin-bottom: 0;
      color: var(--primary-dark-blue-300);
    }
  }

  .application__form_buttons .button-panel ${Button} {
    max-width: unset;
    margin: 0;
  }

  .help {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-900);

    button {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
    }
  }
`;

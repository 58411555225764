import React, { useState } from 'react';
import RcTooltip from 'rc-tooltip';
import 'assets/rc-tooltip.scss';

interface TooltipProps {
  children: any;
  tooltipContent: (setVisible: (visible: boolean) => void) => React.ReactNode;
  className?: string;
  trigger: 'click' | 'hover';
  placement?: 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight';
  forceVisible?: boolean;
}
export const Tooltip = ({
  className,
  children,
  tooltipContent,
  trigger,
  placement = 'top',
  forceVisible,
}: TooltipProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <RcTooltip
      placement={placement}
      trigger={[trigger]}
      visible={forceVisible || visible}
      onVisibleChange={v => setVisible(v)}
      destroyTooltipOnHide={true}
      overlay={<div className={className}>{tooltipContent(setVisible)}</div>}
      overlayInnerStyle={{
        boxShadow: 'var(--border-shadow-elevation-3)',
        borderRadius: 'var(--border-radius)',
      }}
    >
      {children}
    </RcTooltip>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { Confirmation } from 'modules/ui-components/Confirmation';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { AuthBox } from 'modules/auth/components/AuthBox';
import { useConnect } from 'utils/framework';
import { ErrorPageViewModel } from './ErrorPageViewModel';
import { _t } from 'utils/string';

interface Props {
  className?: string;
}

const ErrorPageComponent = ({ className }: Props) => {
  const vm = useConnect(ErrorPageViewModel);
  const { title, message, description } = vm.state.details;

  return (
    <AuthBox className={className} title={title}>
      <Confirmation message={message} description={description} icon={<Error />} />
    </AuthBox>
  );
};

export const ErrorPage = styled(ErrorPageComponent)`
  .auth-box__box {
    min-height: 25rem;
  }
`;

import autobind from 'autobind-decorator';
import { createSelector } from '@reduxjs/toolkit';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { getT } from 'utils/framework/intl';
import { _t } from 'utils/string';
import { openBankingActions, openBankingSelector } from './store';
import { auth0Selector } from 'modules/auth/authStore';
import { UserType } from 'models/User';
import { OpenBankingStatus, OpenBankingStatusList } from 'models/openBanking';

@singleton()
@autobind
export class OpenBankingViewModel extends ViewModel {
  state!: {
    buttonText: string;
    isCompleted: boolean;
    isDisabled: boolean;
    userType?: UserType;
    helpText: string;
  };

  protected disconnected() {
    this.store.dispatch(openBankingActions.ClearOpenBanking());
  }

  triggerOpenBanking = () => {
    if (this.state.userType) {
      this.store.dispatch(openBankingActions.StartOpenBanking({ userType: this.state.userType }));
    }
  };

  protected stateMapper = createSelector(
    openBankingSelector,
    auth0Selector,
    (openBanking, auth) => {
      return {
        buttonText: getButtonName(openBanking.status, auth.user?.userType),
        isCompleted: openBanking.status === OpenBankingStatusList.Complete,
        isDisabled: openBanking.status === OpenBankingStatusList.Complete || openBanking.isLoading,
        userType: auth.user?.userType,
        helpText: getHelpText(auth.user?.userType),
      };
    }
  );
}

const getButtonName = (status: OpenBankingStatus, userType?: UserType) => {
  const _t = getT();
  if (userType === UserType.Borrower) {
    switch (status) {
      case OpenBankingStatusList.Sent:
        return _t('openBanking.btnSentCustomer');
      case OpenBankingStatusList.Complete:
        return _t('openBanking.btnCompleteCustomer');
      case OpenBankingStatusList.Expired:
        return _t('openBanking.btnExpiredCustomer');
      default:
        return _t('openBanking.btnConnectCustomer');
    }
  }

  if (userType === UserType.Broker || userType === UserType.Dealer) {
    switch (status) {
      case OpenBankingStatusList.Sent:
        return _t('openBanking.btnSentPartner');
      case OpenBankingStatusList.Complete:
        return _t('openBanking.btnCompletePartner');
      case OpenBankingStatusList.Expired:
        return _t('openBanking.btnExpiredPartner');
      default:
        return _t('openBanking.btnConnectPartner');
    }
  }

  return _t('openBanking.btnSentCustomer');
};


const getHelpText = (userType?: UserType) => {
  const _t = getT();

  if (userType === UserType.Borrower) {
    return _t('openBanking.openBankingHelp')
  }
  return _t('openBanking.openBankingHelpPartner');
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ModulesKeys,
  OpenBankingStatus,
  OpenBankingStatusList,
  OpenBankingResponseState,
} from 'models/openBanking';
import { UserType } from 'models/User';
import { notifyError } from 'modules/notification/store';
import { routingActions } from 'modules/routing/store';
import { Route } from 'modules/routing/routes';
import { Module, uploadDocumentsActions } from 'modules/upload-documents/store';
import { fork, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { CrmService } from 'services/CrmService';
import { container } from 'tsyringe';
import { RoutingService } from 'utils/framework';
import { captureException, captureMessage } from '@sentry/react';
import { State } from 'utils/store';
import { _t } from 'utils/string';

type ViewType = Module | null;

export interface OpenBankingState {
  status: OpenBankingStatus;
  view: ViewType;
  customerRef: string;
  isLoading: boolean;
}

const initialState: OpenBankingState = {
  status: null,
  view: null,
  customerRef: '',
  isLoading: true,
};

export const openBanking = createSlice({
  name: 'openBanking',
  initialState: initialState,
  reducers: {
    UpdateOpenBankingState: (
      state: OpenBankingState,
      action: PayloadAction<{ status: OpenBankingStatus; view: ViewType; customerRef: string }>
    ) => {
      state.status = action.payload.status;
      state.view = action.payload.view;
      state.customerRef = action.payload.customerRef;
      state.isLoading = false;
    },
    StartOpenBanking: (state: OpenBankingState, action: PayloadAction<{ userType: UserType }>) => {
      state.status = OpenBankingStatusList.Sent;
      state.isLoading = true;
    },
    StartLeadOpenBanking: (
      state: OpenBankingState,
      action: PayloadAction<{ userType: UserType }>
    ) => {},
    StartApplicationOpenBanking: (
      state: OpenBankingState,
      action: PayloadAction<{ userType: UserType }>
    ) => {},
    StartOpenBankingComplete: (state: OpenBankingState) => {
      state.isLoading = false;
    },
    RedirectBackToPortal: (
      state: OpenBankingState,
      payload: PayloadAction<{ customerRef?: string; state?: OpenBankingResponseState }>
    ) => {},
    ClearOpenBanking: (state: OpenBankingState) => (state = initialState),
  },
});

function* OpenBankingProcess() {
  const routingService = container.resolve(RoutingService);
  const crmService = container.resolve(CrmService);

  yield takeLeading(openBankingActions.StartOpenBanking, function* ({ payload }) {
    const { view }: OpenBankingState = yield select(openBankingSelector);

    if (view === Module.LEAD) {
      yield put(openBankingActions.StartLeadOpenBanking({ userType: payload.userType }));
    }

    if (view === Module.APPLICATION) {
      yield put(openBankingActions.StartApplicationOpenBanking({ userType: payload.userType }));
    }
  });

  yield takeLeading(openBankingActions.StartLeadOpenBanking, function* ({ payload }) {
    const { customerRef }: OpenBankingState = yield select(openBankingSelector);

    try {
      // Updating openBankingState also triggers sending Open Banking email to the Borrower #only for Broker/Dealer
      yield crmService.updateLead(customerRef, { openBankingState: OpenBankingStatusList.Sent });

      if (payload.userType === UserType.Borrower) {
        const urlParams = new URLSearchParams({
          client_id: `${process.env.REACT_APP_DIRECT_ID_CLIENT_ID}`,
          customer_ref: `${ModulesKeys.Lead}-${customerRef}`,
        });
        routingService.push(`https://connect.direct.id?${urlParams}`);
      }

      yield put(openBankingActions.StartOpenBankingComplete());
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('openBanking.openBankingStatusError')));
      yield put(openBankingActions.StartOpenBankingComplete());
    }
  });

  yield takeLeading(openBankingActions.StartApplicationOpenBanking, function* ({ payload }) {
    const { customerRef }: OpenBankingState = yield select(openBankingSelector);

    try {
      // Updating openBankingState also triggers sending Open Banking email to the Borrower #only for Broker/Dealer
      yield crmService.updateApplicationOpenBankingStatus(customerRef, {
        openBankingState: OpenBankingStatusList.Sent,
      });

      if (payload.userType === UserType.Borrower) {
        const urlParams = new URLSearchParams({
          client_id: `${process.env.REACT_APP_DIRECT_ID_CLIENT_ID}`,
          customer_ref: `${ModulesKeys.Application}-${customerRef}`,
        });
        routingService.push(`https://connect.direct.id?${urlParams}`);
      }

      yield put(openBankingActions.StartOpenBankingComplete());
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('openBanking.openBankingStatusError')));
      yield put(openBankingActions.StartOpenBankingComplete());
    }
  });
}

function* OpenBankingRedirect() {
  const module = {
    [ModulesKeys.Application]: Module.APPLICATION,
    [ModulesKeys.Lead]: Module.LEAD,
  };

  yield takeLatest(openBankingActions.RedirectBackToPortal, function* ({ payload }) {
    const parsedRef = payload.customerRef?.split('-');

    if (parsedRef?.length !== 2) {
      yield put(routingActions.Navigate({ route: Route.Dashboard, params: {} }));
      return;
    }

    const [key, id] = parsedRef as [ModulesKeys, string];
    const containsModuleKey = Object.values(ModulesKeys).includes(key);

    if (!containsModuleKey || !id.length) {
      yield put(routingActions.Navigate({ route: Route.Dashboard, params: {} }));
      return;
    }

    yield put(uploadDocumentsActions.RedirectToUploadDocuments({ id: id, module: module[key] }));

    if (payload.state === OpenBankingResponseState.Error) {
      captureMessage('DirectID journey resulted in an error', 'warning');
      yield put(notifyError(_t('openBanking.redirectStatusFailed')));
    }
  });
}

export function* openBankingSaga() {
  yield fork(OpenBankingProcess);
  yield fork(OpenBankingRedirect);
}

export const openBankingActions = {
  ...openBanking.actions,
};

export const openBankingSelector = (state: State) => state[openBanking.name] as OpenBankingState;

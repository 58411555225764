import { auth } from './auth';
import { form } from './form';
import { loans } from './loans';
import { profile } from './profile';
import { notFoundPage } from './notFoundPage';
import { mapKeys } from './utils';
import { layout } from './layout';
import { pageTitle } from 'translations/pageTitle';
import { notification } from './notification';
import { leads } from './leads';
import { myDocuments } from './myDocuments';
import { openBanking } from './openBanking';
import { uiComponents } from './uiComponents';
import { payments } from './payments';

export const messages = {
  ...mapKeys('auth', auth),
  ...mapKeys('form', form),
  ...mapKeys('layout', layout),
  ...mapKeys('uiComponents', uiComponents),
  ...mapKeys('pageTitle', pageTitle),
  ...mapKeys('loans', loans),
  ...mapKeys('notFoundPage', notFoundPage),
  ...mapKeys('profile', profile),
  ...mapKeys('notification', notification),
  ...mapKeys('leads', leads),
  ...mapKeys('myDocuments', myDocuments),
  ...mapKeys('payments', payments),
  ...mapKeys('openBanking', openBanking),
};

import styled from '@emotion/styled';
import { Button } from 'modules/ui-components/Button';
import React from 'react';
import { useConnect } from 'utils/framework';
import { Breakpoints, useBreakpoint } from 'utils/styling';
import { ProposalTable, ProposalTableMobile } from './ProposalTable';
import { getT } from 'utils/framework/intl';
import { FooterButtons } from 'modules/ui-components/FooterButtons';
import { LeadOverviewViewModel } from './LeadOverviewViewModel';
import { ZohoLoanProduct } from 'models/Application';
import { ReactComponent as Warning } from 'assets/icons/yellow-warning.svg';

interface Props {
  className?: string;
}

const LeadOverviewStepComponent = ({ className }: Props) => {
  const vm = useConnect(LeadOverviewViewModel);
  const [isTablet] = useBreakpoint(Breakpoints.tablet, { useBody: true });
  const _t = getT();
  const isPersonalMotorFinance =
    vm.state.currentLead.product === ZohoLoanProduct.PersonalMotorFinance;

  return (
    <div className={className}>
      <h1 className="page__header">
        {!isPersonalMotorFinance ? vm.state.step : _t('loans.applicationOverview')}
      </h1>
      <p className="processing-message">{_t('loans.waitingForUnderwriterDesc')}</p>
      {isTablet ? (
        <ProposalTable
          lead={vm.state.currentLead}
          isBroker={vm.state.isBroker}
          header={
            !isPersonalMotorFinance
              ? _t('loans.summaryOfLoanApplication')
              : _t('loans.summaryOfApplication')
          }
          isBorrower={vm.state.isBorrower}
        />
      ) : (
        <ProposalTableMobile
          lead={vm.state.currentLead}
          isBroker={vm.state.isBroker}
          header={
            !isPersonalMotorFinance
              ? _t('loans.summaryOfLoanApplication')
              : _t('loans.summaryOfApplication')
          }
          isBorrower={vm.state.isBorrower}
        />
      )}

      {isPersonalMotorFinance &&
          <div className="warning-wrapper">
            <div className="warning-message"><Warning className="svg"/>{_t('loans.pmfWarningMessage1')}</div>
            <div className="warning-message"><Warning className="svg"/>{_t('loans.pmfWarningMessage2')}</div>
            <div className="warning-message"><Warning className="svg"/>{_t('loans.pmfWarningMessage3')}</div>
          </div>
      }

      <FooterButtons goPrev={vm.goPrev} goNext={vm.nextStep} />
    </div>
  );
};

export const LeadOverviewStep = styled(LeadOverviewStepComponent)`
  .step-name {
    margin: 0 0 -0.5rem;
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
  }

  .processing-message {
    font-size: 1rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-500);
  }

  .warning-message {
    background-color: #003f87;
    border-radius: 10px;
    padding: 14px;
    color: white; 
    font-weight: bold;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    .svg {
      min-height: 20px;
      min-width: 20px;
      flex-shrink: 0;
    }
  }
  
  .warning-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 2rem ;
    gap: 1.5rem;
  }
  
  .proposal-table {
    background-color: var(--primary-dark-000);

    &__header {
      padding: 0.5rem;
      text-align: center;
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-000);
      background-color: var(--primary-blue-500);
      border: solid 1px var(--primary-dark-blue-200);
      border-radius: 4px 4px 0 0;
      border-bottom: none;
    }

    &__body {
      padding: 1rem;
      border: solid 1px var(--primary-dark-blue-200);
      border-top: none;
      border-radius: 0 0 4px 4px;
    }

    .title {
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-300);
    }

    .section {
      .title {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.71;
        color: var(--primary-dark-blue-900);
      }
      .subtitle {
        font-size: 0.75rem;
        line-height: 1.67;
        color: var(--primary-dark-300);
      }

      margin-bottom: 1.5rem;
    }

    .summary {
      text-align: center;
      .value {
        font-family: var(--font-family-secondary);
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.4px;
        color: var(--primary-dark-blue-500);
      }

      .duration {
        font-size: 0.75rem;
        line-height: 1.67;
        color: var(--primary-dark-blue-900);
      }
    }

    .line {
      padding: 0.75rem 0 0.5rem;
      &:not(:last-child) {
        border-bottom: var(--border-light);
      }

      .value {
        font-size: 0.875rem;
        line-height: 1.71;
        color: var(--primary-dark-blue-900);
      }

      .double-value {
        padding-top: 28px;
      }
    }

    .asset {
      padding-top: 2rem;
      .title {
        font-size: 0.875rem;
        font-weight: 600;
        color: var(--primary-dark-blue-900);
      }

      .value {
        min-height: 23px;
        font-size: 0.875rem;
        color: var(--primary-dark-blue-900);
      }
    }

    @media only screen and (min-width: ${Breakpoints.tablet}px) {
      display: grid;
      grid-template:
        '. header'
        'names body';

      &__header {
        grid-area: header;
      }

      &__names {
        grid-area: names;
        padding: 1rem;
        background-color: var(--primary-dark-blue-25);
        border-radius: 4px;

        .section.summary {
          text-align: left;
        }
      }

      &__body {
        border-radius: 0;
        grid-area: body;
        text-align: center;
      }

      .section.summary {
        height: 50px;
        margin: 0;
      }

      .section.asset {
        border-bottom: var(--border-light);
        padding-bottom: 0.5rem;
        line-height: 1.71;
      }

      .line {
        min-height: 45px;
      }
    }
  }

  .application__form_buttons .button-panel ${Button} {
    max-width: unset;
    margin: 0;
  }

  .help {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-900);

    button {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
    }
  }
`;

import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { auth0Actions, auth0Selector, isVerifiedSelector } from 'modules/auth/authStore';
import { uiActions, uiSelector } from 'modules/ui-components/store';
import { hasPermission, Permission } from 'modules/auth/services/PermissionsService';
import { DEFAULT_CONTACT_EMAIL, DEFAULT_CONTACT_NUMBER } from 'utils/constants';
import { leadSelector } from 'modules/leads/store';
import { Steps } from 'models/Application';

@singleton()
@autobind
export class LayoutViewModel extends ViewModel {
  state!: {
    drawerOpen: boolean;
    contactInfo: string[];
    leadStep: Steps;
    isVerified: boolean;
    isLogoutDisabled: boolean;
    canSeeProfilePage: boolean;
    canSeeMyDocuments: boolean;
    canSeePayments: boolean;
  };

  toggleDrawer() {
    this.store.dispatch(uiActions.ToggleDrawer());
  }

  resendVerificationEmail() {
    this.store.dispatch(auth0Actions.ResendVerificationEmail());
  }

  logout() {
    this.store.dispatch(auth0Actions.Logout());
  }

  protected stateMapper = createSelector(
    auth0Selector,
    uiSelector,
    isVerifiedSelector,
    leadSelector,
    (auth, ui, isVerified, leads) => ({
      drawerOpen: ui.drawer.open,
      contactInfo: [DEFAULT_CONTACT_NUMBER, DEFAULT_CONTACT_EMAIL],
      leadStep: leads.step,
      isVerified,
      isLogoutDisabled: auth.isLoggingOut,
      canSeeProfilePage: hasPermission(Permission.CanSeeProfilePage, auth.user?.permissions),
      canSeeMyDocuments: hasPermission(Permission.CanSeeMyDocuments, auth.user?.permissions),
      canSeePayments: hasPermission(Permission.CanSeePayments, auth.user?.permissions),
    })
  );
}

import { BaseOption } from '../modules/ui-components/Select';

export const ALLOWED_FILE_MIME_TYPES: string[] = [
  'text/plain',
  'application/pdf',
  'image/jpeg',
  'image/png',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ALLOWED_LEAD_STAGES = ['Draft', 'Submitted', 'In Progress'];

export const DOCUMENTS_LIMIT = 10;
export const PAYMENTS_LIMIT = 10;

const ONE_MB_IN_BYTE = 1048576;
const MAXIMUM_FILE_SIZE_IN_MB = 25;
export const MAXIMUM_FILE_SIZE = ONE_MB_IN_BYTE * MAXIMUM_FILE_SIZE_IN_MB;

export enum MinimumNetCostWhenFileIsRequired {
  BANK_STATEMENTS = 50000,
}

export const IRELAND_CONF: string = 'Ireland';
export const UK_CONF: string = 'United Kingdom';

export const COUNTRY_OF_MAIN_OPERATIONS: { value: string; label: string }[] = [
  { value: IRELAND_CONF, label: 'Ireland' },
  { value: UK_CONF, label: 'UK' },
];

export const NEW_LEAD = 'new';

export const COUNTIES_IN_IRELAND = [
  { value: 'Carlow', label: 'Carlow' },
  { value: 'Cavan', label: 'Cavan' },
  { value: 'Clare', label: 'Clare' },
  { value: 'Cork', label: 'Cork' },
  { value: 'Donegal', label: 'Donegal' },
  { value: 'Dublin', label: 'Dublin' },
  { value: 'Galway', label: 'Galway' },
  { value: 'Kerry', label: 'Kerry' },
  { value: 'Kildare', label: 'Kildare' },
  { value: 'Kilkenny', label: 'Kilkenny' },
  { value: 'Laois', label: 'Laois' },
  { value: 'Leitrim', label: 'Leitrim' },
  { value: 'Limerick', label: 'Limerick' },
  { value: 'Longford', label: 'Longford' },
  { value: 'Louth', label: 'Louth' },
  { value: 'Mayo', label: 'Mayo' },
  { value: 'Meath', label: 'Meath' },
  { value: 'Monaghan', label: 'Monaghan' },
  { value: 'Offaly', label: 'Offaly' },
  { value: 'Roscommon', label: 'Roscommon' },
  { value: 'Sligo', label: 'Sligo' },
  { value: 'Tipperary', label: 'Tipperary' },
  { value: 'Waterford', label: 'Waterford' },
  { value: 'Westmeath', label: 'Westmeath' },
  { value: 'Wexford', label: 'Wexford' },
  { value: 'Wicklow', label: 'Wicklow' },
];

export const COUNTIES_IN_UK = [
  {
    label: 'Aberdeenshire',
    value: 'Aberdeenshire',
  },
  {
    label: 'Angus',
    value: 'Angus',
  },
  {
    label: 'Antrim',
    value: 'Antrim',
  },
  {
    label: 'Argyll & Bute',
    value: 'Argyll & Bute',
  },
  {
    label: 'Armagh',
    value: 'Armagh',
  },
  {
    label: 'Ayrshire',
    value: 'Ayrshire',
  },
  {
    label: 'Banffshire',
    value: 'Banffshire',
  },
  {
    label: 'Bath and North East Somerset',
    value: 'Bath and North East Somerset',
  },
  {
    label: 'Bedfordshire',
    value: 'Bedfordshire',
  },
  {
    label: 'Berkshire',
    value: 'Berkshire',
  },
  {
    label: 'Berwickshire',
    value: 'Berwickshire',
  },
  {
    label: 'Blaenau Gwent',
    value: 'Blaenau Gwent',
  },
  {
    label: 'Borders',
    value: 'Borders',
  },
  {
    label: 'Bridgend',
    value: 'Bridgend',
  },
  {
    label: 'Bristol',
    value: 'Bristol',
  },
  {
    label: 'Buckinghamshire',
    value: 'Buckinghamshire',
  },
  {
    label: 'Caerphilly',
    value: 'Caerphilly',
  },
  {
    label: 'Caithness',
    value: 'Caithness',
  },
  {
    label: 'Cambridgeshire',
    value: 'Cambridgeshire',
  },
  {
    label: 'Cardiff',
    value: 'Cardiff',
  },
  {
    label: 'Carmarthenshire',
    value: 'Carmarthenshire',
  },
  {
    label: 'Ceredigion',
    value: 'Ceredigion',
  },
  {
    label: 'Cheshire',
    value: 'Cheshire',
  },
  {
    label: 'Clackmannanshire',
    value: 'Clackmannanshire',
  },
  {
    label: 'Conwy',
    value: 'Conwy',
  },
  {
    label: 'Cornwall',
    value: 'Cornwall',
  },
  {
    label: 'County Durham',
    value: 'County Durham',
  },
  {
    label: 'Cumbria',
    value: 'Cumbria',
  },
  {
    label: 'Denbighshire',
    value: 'Denbighshire',
  },
  {
    label: 'Derbyshire',
    value: 'Derbyshire',
  },
  {
    label: 'Devon',
    value: 'Devon',
  },
  {
    label: 'Dorset',
    value: 'Dorset',
  },
  {
    label: 'Down',
    value: 'Down',
  },
  {
    label: 'Dumfries & Galloway',
    value: 'Dumfries & Galloway',
  },
  {
    label: 'Dunbartonshire',
    value: 'Dunbartonshire',
  },
  {
    label: 'East Ayrshire',
    value: 'East Ayrshire',
  },
  {
    label: 'East Dunbartonshire',
    value: 'East Dunbartonshire',
  },
  {
    label: 'East Lothian',
    value: 'East Lothian',
  },
  {
    label: 'East Renfrewshire',
    value: 'East Renfrewshire',
  },
  {
    label: 'East Riding of Yorkshire',
    value: 'East Riding of Yorkshire',
  },
  {
    label: 'East Sussex',
    value: 'East Sussex',
  },
  {
    label: 'Essex',
    value: 'Essex',
  },
  {
    label: 'Fermanagh',
    value: 'Fermanagh',
  },
  {
    label: 'Fife',
    value: 'Fife',
  },
  {
    label: 'Flintshire',
    value: 'Flintshire',
  },
  {
    label: 'Gloucestershire',
    value: 'Gloucestershire',
  },
  {
    label: 'Greater London',
    value: 'Greater London',
  },
  {
    label: 'Greater Manchester',
    value: 'Greater Manchester',
  },
  {
    label: 'Gwynedd',
    value: 'Gwynedd',
  },
  {
    label: 'Hampshire',
    value: 'Hampshire',
  },
  {
    label: 'Herefordshire',
    value: 'Herefordshire',
  },
  {
    label: 'Hertfordshire',
    value: 'Hertfordshire',
  },
  {
    label: 'Highland',
    value: 'Highland',
  },
  {
    label: 'Inverclyde',
    value: 'Inverclyde',
  },
  {
    label: 'Isle of Anglesey',
    value: 'Isle of Anglesey',
  },
  {
    label: 'Isle of Wight',
    value: 'Isle of Wight',
  },
  {
    label: 'Isles of Scilly',
    value: 'Isles of Scilly',
  },
  {
    label: 'Kent',
    value: 'Kent',
  },
  {
    label: 'Kincardineshire',
    value: 'Kincardineshire',
  },
  {
    label: 'Lanarkshire',
    value: 'Lanarkshire',
  },
  {
    label: 'Lancashire',
    value: 'Lancashire',
  },
  {
    label: 'Leicestershire',
    value: 'Leicestershire',
  },
  {
    label: 'Lincolnshire',
    value: 'Lincolnshire',
  },
  {
    label: 'Londonderry',
    value: 'Londonderry',
  },
  {
    label: 'Merseyside',
    value: 'Merseyside',
  },
  {
    label: 'Merthyr Tydfil',
    value: 'Merthyr Tydfil',
  },
  {
    label: 'Midlothian',
    value: 'Midlothian',
  },
  {
    label: 'Monmouthshire',
    value: 'Monmouthshire',
  },
  {
    label: 'Moray',
    value: 'Moray',
  },
  {
    label: 'Neath Port Talbot',
    value: 'Neath Port Talbot',
  },
  {
    label: 'Newport',
    value: 'Newport',
  },
  {
    label: 'Norfolk',
    value: 'Norfolk',
  },
  {
    label: 'North Ayrshire',
    value: 'North Ayrshire',
  },
  {
    label: 'North Lanarkshire',
    value: 'North Lanarkshire',
  },
  {
    label: 'North Somerset',
    value: 'North Somerset',
  },
  {
    label: 'North Yorkshire',
    value: 'North Yorkshire',
  },
  {
    label: 'Northamptonshire',
    value: 'Northamptonshire',
  },
  {
    label: 'Northumberland',
    value: 'Northumberland',
  },
  {
    label: 'Nottinghamshire',
    value: 'Nottinghamshire',
  },
  {
    label: 'Orkney',
    value: 'Orkney',
  },
  {
    label: 'Oxfordshire',
    value: 'Oxfordshire',
  },
  {
    label: 'Pembrokeshire',
    value: 'Pembrokeshire',
  },
  {
    label: 'Perth & Kinross',
    value: 'Perth & Kinross',
  },
  {
    label: 'Powys',
    value: 'Powys',
  },
  {
    label: 'Renfrewshire',
    value: 'Renfrewshire',
  },
  {
    label: 'Rhondda Cynon Taff',
    value: 'Rhondda Cynon Taff',
  },
  {
    label: 'Rutland',
    value: 'Rutland',
  },
  {
    label: 'Shetland',
    value: 'Shetland',
  },
  {
    label: 'Shropshire',
    value: 'Shropshire',
  },
  {
    label: 'Somerset',
    value: 'Somerset',
  },
  {
    label: 'South Ayrshire',
    value: 'South Ayrshire',
  },
  {
    label: 'South Gloucestershire',
    value: 'South Gloucestershire',
  },
  {
    label: 'South Lanarkshire',
    value: 'South Lanarkshire',
  },
  {
    label: 'South Yorkshire',
    value: 'South Yorkshire',
  },
  {
    label: 'Staffordshire',
    value: 'Staffordshire',
  },
  {
    label: 'Stirlingshire',
    value: 'Stirlingshire',
  },
  {
    label: 'Suffolk',
    value: 'Suffolk',
  },
  {
    label: 'Surrey',
    value: 'Surrey',
  },
  {
    label: 'Swansea',
    value: 'Swansea',
  },
  {
    label: 'Torfaen',
    value: 'Torfaen',
  },
  {
    label: 'Tyne & Wear',
    value: 'Tyne & Wear',
  },
  {
    label: 'Tyrone',
    value: 'Tyrone',
  },
  {
    label: 'Vale of Glamorgan',
    value: 'Vale of Glamorgan',
  },
  {
    label: 'Warwickshire',
    value: 'Warwickshire',
  },
  {
    label: 'West Dunbartonshire',
    value: 'West Dunbartonshire',
  },
  {
    label: 'West Lothian',
    value: 'West Lothian',
  },
  {
    label: 'West Midlands',
    value: 'West Midlands',
  },
  {
    label: 'West Sussex',
    value: 'West Sussex',
  },
  {
    label: 'West Yorkshire',
    value: 'West Yorkshire',
  },
  {
    label: 'Western Isles',
    value: 'Western Isles',
  },
  {
    label: 'Wiltshire',
    value: 'Wiltshire',
  },
  {
    label: 'Worcestershire',
    value: 'Worcestershire',
  },
  {
    label: 'Wrexham',
    value: 'Wrexham',
  },
];

export const COUNTRY_COUNTY_MAP = {
  [IRELAND_CONF]: COUNTIES_IN_IRELAND,
  [UK_CONF]: COUNTIES_IN_UK,
};

export const EXCLUDED_APPLICATION_STATES = ['Not Taken Up', 'Closed', 'Activated', 'Cancelled'];

export const OPEN_BANKING_REDIRECT_DELAY = 3000;

export const OPEN_BANKING_WINDOW_CHECKING_IN_SECONDS = 600;
export const OPEN_BANKING_REQUESTS_TIMEBOX_IN_SECONDS = 120;

export const DEFAULT_CONTACT_EMAIL = 'support@capitalflow.ie';
export const CRE_CONTACT_EMAIL = 'propertybusiness@capitalflow.ie';
export const DEFAULT_CONTACT_NUMBER = '+353 (1) 563 2400';

export const KEY_ENTER = 13;

export enum AssetType {
  car = 'Car',
  lightCommercial = 'Light Commercial',
  cateringEquipment = 'Catering Equipment',
  coach = 'Coach',
  computerEquipment = 'Computer Equipment',
  crane = 'Crane',
  digitalPrintingEquipment = 'Digital Printing Equipment',
  engineeringEquipment = 'Engineering Equipment',
  farmMachinery = 'Farm Machinery',
  fixturesAndFittings = 'Fixtures and Fittings',
  forestryEquipment = 'Forestry Equipment',
  forklifts = 'Forklifts',
  leisureEquipment = 'Leisure Equipment',
  medicalEquipment = 'Medical Equipment',
  minibus = 'Minibus',
  miscEquipment = 'Misc Equipment',
  motorBike = 'Motor Bike',
  officeEquipment = 'Office Equipment',
  plantMachineryYellow = 'Plant Machinery (Yellow)',
  printFishingEquipment = 'Print Fishing Equipment',
  printingEquipment = 'Printing Equipment',
  quarryEquipment = 'Quarry Equipment',
  refrigerationEquipment = 'Refrigeration Equipment',
  tractor = 'Tractor',
  trailer = 'Trailer',
  truck = 'Truck',
  wasteAndRecyclingEquipment = 'Waste & Recycling Equipment',
  woodworkingEquipment = 'Woodworking Equipment',
}

export const VEHICLE_ASSETS: AssetType[] = [
  AssetType.car,
  AssetType.lightCommercial,
  AssetType.coach,
  AssetType.forklifts,
  AssetType.minibus,
  AssetType.motorBike,
  AssetType.plantMachineryYellow,
  AssetType.tractor,
  AssetType.trailer,
  AssetType.truck,
];

export const CONDITIONS: BaseOption[] = [
  { label: 'New', value: 'New' },
  { label: 'Used', value: 'Used' },
];

export const SPECIAL_USE_EMAIL_DOMAIN_NAMES = [
  'arpa',
  'invalid',
  'local',
  'localhost',
  'onion',
  'test',
];

export const COUNTRIES = [
  {
    value: 'Ireland',
    label: 'Ireland',
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
  },
  {
    value: 'Albania',
    label: 'Albania',
  },
  {
    value: 'Andorra',
    label: 'Andorra',
  },
  {
    value: 'Argentina',
    label: 'Argentina',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
  {
    value: 'Austria',
    label: 'Austria',
  },
  {
    value: 'Azerbaijan',
    label: 'Azerbaijan',
  },
  {
    value: 'Bahrain',
    label: 'Bahrain',
  },
  {
    value: 'Belarus',
    label: 'Belarus',
  },
  {
    value: 'Belgium',
    label: 'Belgium',
  },
  {
    value: 'Bolivia',
    label: 'Bolivia',
  },
  {
    value: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
  },
  {
    value: 'Brazil',
    label: 'Brazil',
  },
  {
    value: 'Bulgaria',
    label: 'Bulgaria',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'China',
    label: 'China',
  },
  {
    value: 'Croatia',
    label: 'Croatia',
  },
  {
    value: 'Cyprus',
    label: 'Cyprus',
  },
  {
    value: 'Czech Republic',
    label: 'Czech Republic',
  },
  {
    value: 'Denmark',
    label: 'Denmark',
  },
  {
    value: 'Estonia',
    label: 'Estonia',
  },
  {
    value: 'Faroe Islands',
    label: 'Faroe Islands',
  },
  {
    value: 'Finland',
    label: 'Finland',
  },
  {
    value: 'France',
    label: 'France',
  },
  {
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Germany',
    label: 'Germany',
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
  },
  {
    value: 'Greece',
    label: 'Greece',
  },
  {
    value: 'Guernsey',
    label: 'Guernsey',
  },
  {
    value: 'Hong Kong',
    label: 'Hong Kong',
  },
  {
    value: 'Hungary',
    label: 'Hungary',
  },
  {
    value: 'Iceland',
    label: 'Iceland',
  },
  {
    value: 'India',
    label: 'India',
  },
  {
    value: 'Israel',
    label: 'Israel',
  },
  {
    value: 'Italy',
    label: 'Italy',
  },
  {
    value: 'Japan',
    label: 'Japan',
  },
  {
    value: 'Jersey',
    label: 'Jersey',
  },
  {
    value: 'Latvia',
    label: 'Latvia',
  },
  {
    value: 'Liechtenstein',
    label: 'Liechtenstein',
  },
  {
    value: 'Lithuania',
    label: 'Lithuania',
  },
  {
    value: 'Luxembourg',
    label: 'Luxembourg',
  },
  {
    value: 'Macedonia',
    label: 'Macedonia',
  },
  {
    value: 'Malta',
    label: 'Malta',
  },
  {
    value: 'Mexico',
    label: 'Mexico',
  },
  {
    value: 'Moldova',
    label: 'Moldova',
  },
  {
    value: 'Monaco',
    label: 'Monaco',
  },
  {
    value: 'Montenegro',
    label: 'Montenegro',
  },
  {
    value: 'Netherlands',
    label: 'Netherlands',
  },
  {
    value: 'New Zealand',
    label: 'New Zealand',
  },
  {
    value: 'Northern Ireland',
    label: 'Northern Ireland',
  },
  {
    value: 'Norway',
    label: 'Norway',
  },
  {
    value: 'Pakistan',
    label: 'Pakistan',
  },
  {
    value: 'Poland',
    label: 'Poland',
  },
  {
    value: 'Portugal',
    label: 'Portugal',
  },
  {
    value: 'Qatar',
    label: 'Qatar',
  },
  {
    value: 'Romania',
    label: 'Romania',
  },
  {
    value: 'Russia',
    label: 'Russia',
  },
  {
    value: 'San Marino',
    label: 'San Marino',
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
  },
  {
    value: 'Serbia',
    label: 'Serbia',
  },
  {
    value: 'Serbia and Montenegro',
    label: 'Serbia and Montenegro',
  },
  {
    value: 'Singapore',
    label: 'Singapore',
  },
  {
    value: 'Slovakia',
    label: 'Slovakia',
  },
  {
    value: 'Slovenia',
    label: 'Slovenia',
  },
  {
    value: 'South Africa',
    label: 'South Africa',
  },
  {
    value: 'South Korea',
    label: 'South Korea',
  },
  {
    value: 'Spain',
    label: 'Spain',
  },
  {
    value: 'Sweden',
    label: 'Sweden',
  },
  {
    value: 'Switzerland',
    label: 'Switzerland',
  },
  {
    value: 'Thailand',
    label: 'Thailand',
  },
  {
    value: 'Turkey',
    label: 'Turkey',
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
  },
  {
    value: 'United States',
    label: 'United States',
  },
];

import { createBrowserHistory, Listener } from 'history';
import { compile, match, parse } from 'path-to-regexp';
import { injectable, singleton } from 'tsyringe';

interface Route {
  path: string;
  matcher: any;
  compile: any;
  tokens: any[];
}

@singleton()
@injectable()
export class RoutingService {
  routes: { [key: string]: Route } = {};
  private history = createBrowserHistory();

  get location() {
    return this.history.location;
  }

  addRoutes(routes: { [name: string]: string }) {
    this.routes = {
      ...this.routes,
      ...parseRoutes(routes),
    };
  }

  listen(cb: Listener) {
    this.history.listen(cb);
  }

  getCurrentRoute() {
    return this.location.pathname + this.location.search;
  }

  push(url: string) {
    this.history.push(url);
  }

  replace(url: string) {
    this.history.replace(url);
  }

  compile(to: string, params: any) {
    const url = this.routes[to].compile(params);
    const searchParams = new URLSearchParams();
    Object.entries(params || {}).forEach(([key, value]) => {
      if (!this.routes[to].tokens.includes(key)) {
        searchParams.set(key, value as string);
      }
    });

    const search = searchParams.toString();
    return url + (search ? '?' + search : '');
  }

  match(pathname: string) {
    const [hit] = Object.entries(this.routes)
      .map(([route, path]) => [route, match(path.path)(pathname)])
      .filter(([route, path]) => !!path);

    if (!hit) {
      return null;
    }

    const [route, { params }] = hit as [string, any];

    const searchEntries = new URLSearchParams(this.location.search.substr(1)).entries();
    Array.from(searchEntries).forEach(([key, value]) => {
      if (!this.routes[route].tokens.includes(key)) {
        params[key] = value;
      }
    });

    return [route, params];
  }
}

const parseRoutes = (routes: { [name: string]: string }) => {
  return Object.entries(routes)
    .map(([key, value]): [string, Route] => [
      key,
      {
        path: value,
        matcher: match(value),
        compile: compile(value),
        tokens: parse(value)
          .filter((t: any) => t.name)
          .map((t: any) => t.name),
      },
    ])
    .reduce((acc: { [key: string]: Route }, [k, v]) => {
      acc[k] = v;
      return acc;
    }, {});
};

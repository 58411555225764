import autobind from 'autobind-decorator';
import { DocumentsResponse, UploadedFile } from 'models/File';
import { singleton } from 'tsyringe';
import { DOCUMENTS_LIMIT } from 'utils/constants';
import { cameliseKeys, camelKeysToSnakeCase } from '../utils/string';
import { Http } from './Http';

interface GetUserDocumentsParams {
  applications?: string[];
  leads?: string[];
  types?: string[];
  offset?: number;
  limit?: number;
}

interface UploadFileBody {
  uploadFile: Blob;
  type: string;
  customerId?: string;
  applicationId?: string;
  leadId?: string;
}

@singleton()
@autobind
export class DocumentsService {
  protected readonly http: Http;

  constructor(apiUrl: string = '/documents') {
    this.http = new Http(apiUrl);
  }

  async getUserDocuments({
    applications,
    leads,
    types,
    offset = 0,
    limit = DOCUMENTS_LIMIT,
  }: GetUserDocumentsParams): Promise<DocumentsResponse> {
    const formattedTypes = (types || []).join(',');
    const formattedApplications = (applications || []).join(',');
    const formattedLeads = (leads || []).join(',');
    const { data } = await this.http.request('/v1/documents', {
      params: {
        offset: offset,
        limit: limit,
        types: formattedTypes,
        applications: formattedApplications,
        leads: formattedLeads,
      },
    });
    return cameliseKeys(data);
  }

  async sendFile(body: FormData): Promise<UploadedFile> {
    const { data } = await this.http.request(`/v1/documents`, {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: body,
    });
    return cameliseKeys(data);
  }

  async uploadFile(body: UploadFileBody): Promise<UploadedFile> {
    const { data } = await this.http.request(`/v1/documents`, {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: camelKeysToSnakeCase(body),
    });
    return cameliseKeys(data);
  }

  async getFileUrl(fileId: string): Promise<UploadedFile> {
    const { data } = await this.http.request(`/v1/documents/${fileId}`);
    return cameliseKeys(data);
  }
}

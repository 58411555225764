import { createSelector } from '@reduxjs/toolkit';
import autobind from 'autobind-decorator';
import { Steps } from 'models/Application';
import { Lead } from 'models/Lead';
import { isBorrowerSelector, isBrokerSelector } from 'modules/auth/authStore';
import { Module, uploadDocumentsActions } from 'modules/upload-documents/store';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { leadActions, leadSelector } from '../../store';

@singleton()
@autobind
export class LeadOverviewViewModel extends ViewModel {
  state!: {
    currentLead: Lead;
    isBroker: boolean;
    isBorrower: boolean;
    step: Steps;
  };

  protected connected() {
    this.store.dispatch(leadActions.LoadLead({ id: this.state.currentLead.id }));
  }

  goPrev() {
    this.store.dispatch(leadActions.SetStep({ step: Steps.FINANCE }));
  }

  nextStep() {
    this.store.dispatch(
      uploadDocumentsActions.RedirectToUploadDocuments({
        id: this.state.currentLead.id,
        module: Module.LEAD,
      })
    );
  }

  protected stateMapper = createSelector(
    leadSelector,
    isBrokerSelector,
    isBorrowerSelector,
    (leads, isBroker, isBorrower) => ({
      currentLead: leads.lead,
      isBroker,
      isBorrower,
      step: leads.step,
    })
  );
}

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { container } from 'tsyringe';
import { getT } from '../../utils/framework/intl';
import { State } from 'utils/store';
import { Auth0Client, GenericError } from '@auth0/auth0-spa-js';
import { Permission, PermissionsService } from './services/PermissionsService';
import { CrmService } from '../../services/CrmService';
import { User, UserType } from 'models/User';
import { notifyError, notifySuccess } from '../notification/store';
import { captureException, captureMessage, setUserContext } from 'utils/reporting';
import { errorPageActions } from 'modules/error-page/store';
import { RedirectRoute, routingActions, routingSelector } from 'modules/routing/store';
import { Route } from 'modules/routing/routes';

export interface Auth0State {
  user?: User;
  isAuthenticationPending: boolean;
  isLoggingOut: boolean;
}

enum CacheLocation {
  localStorage = 'localstorage',
  memory = 'memory',
}

const initialState = { isAuthenticationPending: true, isLoggingOut: false } as Auth0State;
export const auth0 = createSlice({
  name: 'auth0',
  initialState,
  reducers: {
    Authenticate: (state, action: PayloadAction<{ user: User | undefined }>) => {
      state.user = action.payload.user;
      state.isAuthenticationPending = false;
    },
    StartAuthenticating: state => {
      state.isAuthenticationPending = true;
    },
    Logout: state => {
      state.isLoggingOut = true;
    },
    EnableLogout: state => {
      state.isLoggingOut = false;
    },
    ResendVerificationEmail() {},
  },
});

export const auth0Actions = {
  ...auth0.actions,
};

export const auth0Selector = (state: State) => state[auth0.name] as Auth0State;
export const userTypeSelector = createSelector(auth0Selector, auth => auth.user?.userType);

export const isVerifiedSelector = createSelector(auth0Selector, auth => auth.user?.isVerified);
export const isBrokerSelector = createSelector(
  auth0Selector,
  auth => auth.user?.userType === UserType.Broker
);
export const isBorrowerSelector = createSelector(
  auth0Selector,
  auth => auth.user?.userType === UserType.Borrower
);

export const isDealerSelector = createSelector(
  auth0Selector,
  auth => auth.user?.userType === UserType.Dealer
);

function* handleAuthRequest() {
  const auth0 = container.resolve(Auth0);
  const user: User = yield select(state => state.auth0.user);
  if (!user) {
    yield auth0.showLoginForm();
  }
}

function* triggerAuthError(error: Error) {
  const _t = getT();

  yield put(
    errorPageActions.ShowError({
      error: error,
      details: {
        title: _t('auth.authorizationFailedTitle'),
        message: _t('auth.authorizationFailedMessage'),
        description: _t('notFoundPage.errorInfo'),
      },
    })
  );
}

function* emailVerification() {
  const crmService = container.resolve(CrmService);
  const _t = getT();

  yield takeEvery(auth0Actions.ResendVerificationEmail, function* () {
    try {
      const authState: Auth0State = yield select(auth0Selector);
      yield crmService.resendVerificationEmail(authState.user?.sub || '');
      yield put(notifySuccess(_t('notification.resendEmailSuccess')));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.resendEmailFailure')));
    }
  });
}

function* requestAuth0User() {
  const auth0 = container.resolve(Auth0);
  const permissionsService = container.resolve(PermissionsService);
  const currentRoute: RedirectRoute = yield select(state => routingSelector(state));

  if (currentRoute.route === Route.LoginRedirect) {
    if (currentRoute.params.code) {
      yield call(handleRedirectCallback);
    }

    yield put(routingActions.Navigate({ route: Route.Dashboard, params: {}, isBack: false }));
  }

  let user: User = yield auth0.getUser();

  if (user) {
    setUserContext(user);
    const permissions: Permission[] = yield permissionsService.addPermissions(user);
    user = { ...user, permissions };
  }

  yield put(auth0Actions.Authenticate({ user: user }));
}

function* handleRedirectCallback() {
  const auth0 = container.resolve(Auth0);

  try {
    yield auth0.redirectCallback();
  } catch (error) {
    captureException(error);
    yield triggerAuthError(error as Error);
    return;
  }
}

export function* auth0Saga() {
  const _t = getT();

  yield fork(emailVerification);
  yield fork(requestAuth0User); // Runs on Page Load (auth0 handles login through redirects)
  yield takeEvery(auth0Actions.StartAuthenticating, handleAuthRequest);
  yield takeEvery(auth0Actions.Logout, function* () {
    const auth0 = container.resolve(Auth0);
    try {
      yield auth0.logout();
    } catch (err) {
      captureException(err);
      yield put(auth0Actions.EnableLogout());
      yield put(notifyError(_t('notification.logoutFailed')));
    }
  });
}

export class Auth0 {
  private client: Auth0Client;

  constructor() {
    const [domain, client_id, redirect_uri, cacheLocation, audience] = [
      process.env.REACT_APP_AUTH0_DOMAIN || '',
      process.env.REACT_APP_AUTH0_CLIENT_ID || '',
      process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
      process.env.REACT_APP_AUTH0_USE_LOCAL_STORAGE === 'true'
        ? CacheLocation.localStorage
        : CacheLocation.memory,
      process.env.REACT_APP_AUTH0_AUDIENCE,
    ];

    this.client = new Auth0Client({
      domain,
      client_id,
      redirect_uri,
      audience: audience,
      useRefreshTokens: true,
      cacheLocation: cacheLocation,
    });
  }

  async getAccessToken() {
    try {
      return await this.client.getTokenSilently();
    } catch (err) {
      captureMessage('Could not generate access token.', 'info');
      if ((err as GenericError).error === 'login_required') {
        await this.showLoginForm();
      } else {
        throw err;
      }
    }
  }

  async showLoginForm() {
    await this.client.loginWithRedirect();
  }

  async showSignupForm({ email } = { email: '' }) {
    await this.client.loginWithRedirect({ screen_hint: 'signup', login_hint: email });
  }

  async redirectCallback() {
    await this.client.handleRedirectCallback();
  }

  async getUser(): Promise<User | undefined> {
    await this.client.checkSession();
    const user = await this.client.getUser();

    if (!user) {
      return undefined;
    }

    return {
      sub: user.sub,
      userType: user[`${process.env.REACT_APP_AUTH0_RULE_NAMESPACE}user_type`],
      zohoId: user[`${process.env.REACT_APP_AUTH0_RULE_NAMESPACE}zoho_id`],
      nickname: user.nickname,
      name: user.name,
      email: user.email,
      isVerified: user.email_verified,
    };
  }

  async logout() {
    return await this.client.logout({ returnTo: window.location.origin });
  }
}

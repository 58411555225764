import { container } from 'tsyringe';
import { Store } from 'redux';
import { State, STORE_DI_TOKEN } from 'utils/store';

interface Subscription {
  active: boolean;
  callback: () => void;
}

export class ViewModel {
  state?: any;
  private _state: any;
  protected store = container.resolve<Store<State>>(STORE_DI_TOKEN);
  private subscriptions: Subscription[] = [];
  private storeUnsubscribe?: () => void;

  constructor() {
    Object.defineProperty(this, 'state', {
      set(value) {
        if (value !== this._state) {
          this._state = value;
          this.triggerUpdate();
        }
      },
      get() {
        return this._state;
      },
    });
  }

  /** @internal */
  _onStateChange = () => {
    const state = this.store.getState();
    const mapped = this.stateMapper(state);
    if (mapped !== undefined) {
      this.state = mapped;
    }
  };

  /** @internal */
  _connect(callback: () => void) {
    const subscription = { callback, active: true };
    this.subscriptions.push(subscription);
    const unsubscribe = () => {
      subscription.active = false; // Allow unsubscribing during update
      this.subscriptions = this.subscriptions.filter(s => s !== subscription);
      if (this.subscriptions.length === 0) {
        this.storeUnsubscribe!();
        this.storeUnsubscribe = undefined;
        this.disconnected();
      }
    };

    if (!this.storeUnsubscribe) {
      this.storeUnsubscribe = this.store.subscribe(this._onStateChange);
      this.connected();
    }
    this._onStateChange();

    return unsubscribe;
  }

  triggerUpdate() {
    setTimeout(() => {
      this.subscriptions.forEach(s => s.active && s.callback());
    }, 0);
  }

  /**
   * Update VM's state based on Redux State.
   * Should EITHER return the new state (i.e. using Selectors) or assign the new state object.
   *
   * Can skip triggering updates on components by returning undefined and not assigning changes
   * to `this.state`.
   * Use of `createSelector` is recommended to memoize values if you transform the redux state.
   */
  protected stateMapper(state: State) {}

  /**
   * Triggered when VM when **any** component starts using it.
   * Multiple components using same instance (i.e. singletons) will not trigger connect again.
   * Basically a constructor / ngOnInit / componentDidMount
   */
  protected connected() {}

  /**
   * Triggered when VM is no longer used by any of the components.
   * Basically a destructor / ngOnDestroy / componentDidUnmount
   */
  protected disconnected() {}
}

import { FormattedMessage } from 'react-intl';
import { Button } from './Button';
import React from 'react';
import styled from '@emotion/styled';

interface Props {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  isLoading?: boolean;
  className?: string;
  previous: () => void;
  next: () => void;
}

const RequestPaginationComponent = ({
  hasPreviousPage,
  hasNextPage,
  isLoading,
  className,
  previous,
  next,
}: Props) => {
  return (
    <div className={className}>
      <Button className="cta" onClick={next} disabled={!hasNextPage || isLoading}>
        <FormattedMessage id={'loans.next'} />
      </Button>
      <Button className="cta" onClick={previous} disabled={!hasPreviousPage || isLoading}>
        <FormattedMessage id={'loans.prev'} />
      </Button>
    </div>
  );
};

export const RequestPagination = styled(RequestPaginationComponent)`
  display: flex;
  flex-direction: row-reverse;

  button:first-of-type {
    margin-left: 1rem;
  }

  button {
    height: 2rem;
    width: 6rem;

    &:disabled {
      opacity: 0.5;
    }
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { Breakpoints, useBreakpoint } from 'utils/styling';
import { ReactComponent as MenuClose } from 'assets/icons/menu-close.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as MyDocuments } from 'assets/icons/my-documents.svg';
import { ReactComponent as Euro } from 'assets/icons/file-euro.svg';
import { Drawer } from 'modules/ui-components/Drawer';
import { Link } from 'modules/ui-components/Link';
import { Route } from 'modules/routing';
import { LogoutButton } from './LogoutButton';
import { LayoutViewModel } from '../LayoutViewModel';
import { useConnect } from 'utils/framework';
import { MenuButton } from './MenuButton';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  open: boolean;
  toggleOpen: () => void;
  logout: () => void;
  className?: string;
}

const UserBarComponent = ({ open, toggleOpen, logout, className }: Props) => {
  const vm = useConnect(LayoutViewModel);
  const [isLaptop] = useBreakpoint(Breakpoints.laptop, { useBody: true });
  const Container = isLaptop ? React.Fragment : Drawer;
  const props = isLaptop ? {} : ({ open, onClose: toggleOpen } as any);
  const onLogout = () => {
    toggleOpen();
    logout();
  };
  const { cardPayments } = useFlags();

  return (
    <Container {...props}>
      <div className={classNames(className, { 'in-drawer': !isLaptop })}>
        {!isLaptop && (
          <div className="navigator">
            <button onClick={toggleOpen}>
              <MenuClose />
            </button>
            <Link to={Route.Dashboard} className="logo">
              <Logo />
            </Link>
          </div>
        )}

        <div className="container">
          <div className="links" onClick={toggleOpen}>
            <MenuButton route={Route.Dashboard} icon={<Dashboard />} message={'layout.dashboard'} />
            {vm.state.canSeeProfilePage && (
              <MenuButton route={Route.Profile} icon={<UserIcon />} message={'layout.profile'} />
            )}
            {vm.state.canSeeMyDocuments && (
              <MenuButton
                route={Route.MyDocuments}
                icon={<MyDocuments />}
                message={'layout.myDocuments'}
              />
            )}
            {cardPayments && vm.state.canSeePayments && (
              <MenuButton route={Route.Payments} icon={<Euro />} message={'layout.payments'} />
            )}
          </div>

          {!isLaptop && (
            <div className="logout">
              <LogoutButton onClick={onLogout} disabled={vm.state.isLogoutDisabled} />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export const UserBar = styled(UserBarComponent)`
  background-color: var(--primary-dark-blue-25);
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.in-drawer {
    padding: 0;
    width: 18rem;
    height: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;

    flex: 1;

    @media only screen and (min-width: ${Breakpoints.laptop}px) {
      flex: 0;
      top: calc(var(--header-height) * 2 + 1.25rem);
      position: sticky;
      width: 16.25rem;
    }
  }

  .navigator {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1.75rem 1fr;
    align-items: center;
    border-bottom: var(--border-light);

    .logo {
      justify-self: center;
      display: flex;
      margin-right: 1.75rem;
    }
  }

  .links {
    flex: 1;
    margin: 2rem 1rem;

    a {
      height: 2.5rem;
      margin-right: -1rem;

      display: flex;
      font-weight: 600;
      line-height: 1.75;
      color: var(--primary-dark-blue-900);
      align-items: center;

      &.active {
        border-right: 3px solid var(--primary-blue-500);
      }

      .icon {
        display: grid;
        place-items: center;
        width: 1.5rem;
        margin-right: 1rem;
      }
    }

    @media only screen and (min-width: ${Breakpoints.laptop}px) {
      flex: 0;
      margin: 1rem 1.5rem 0.5rem;

      a {
        height: auto;
        margin-right: -1.5rem;
        margin-bottom: 0.5rem;

        svg {
          margin-right: 1rem;
        }
      }
    }
  }

  .user {
    padding-top: 1.5rem;
    margin: 1rem;
    border-top: var(--border-light);

    .info {
      font-size: 0.625rem;
      font-weight: 600;
      color: var(--primary-dark-300);
      text-transform: uppercase;
    }

    .panel {
      display: flex;
      margin-top: 1rem;

      .name {
        flex: 1;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.71;
        color: var(--primary-dark-blue-900);

        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @media only screen and (min-width: ${Breakpoints.laptop}px) {
      margin-left: 1.5rem;
    }
  }

  .logout {
    width: 13.25rem;
    margin: 1.5rem 0.5rem 1rem 2rem;
  }

  .navigator {
    button {
      background: none;
      border: none;
      line-height: 0.75;
      padding: 0.25rem;
      cursor: pointer;
    }
  }
`;

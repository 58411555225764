import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { PAYMENTS_LIMIT } from 'utils/constants';
import { cameliseKeys } from '../utils/string';
import { Http } from './Http';
import { PaymentIntent, PaymentIntentType, PaymentIntentsResponse } from 'models/Payment';

interface GetUserPaymentsParams {
  status?: string[];
  paymentType?: PaymentIntentType;
  offset?: number;
  limit?: number;
}

@singleton()
@autobind
export class PaymentsService {
  protected readonly http: Http;

  constructor(apiUrl: string = '/payments') {
    this.http = new Http(apiUrl);
  }

  async getUserPaymentIntents({
    status,
    paymentType = PaymentIntentType.INBOUND,
    offset = 0,
    limit = PAYMENTS_LIMIT,
  }: GetUserPaymentsParams): Promise<PaymentIntentsResponse> {
    const formattedStatuses = (status || []).join(',');
    const { data } = await this.http.request('/intents', {
      params: {
        offset: offset,
        limit: limit,
        payment_type: paymentType,
        status: formattedStatuses,
      },
    });
    return cameliseKeys(data);
  }

  async getPaymentIntent(intentId: string): Promise<PaymentIntent> {
    const { data } = await this.http.request(`/intents/${intentId}`);
    return cameliseKeys(data);
  }
}

import React, { useEffect, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { Steps } from 'models/Application';
import { Lead, LeadDetailsFormValues } from 'models/Lead';
import { FooterButtons } from 'modules/ui-components/FooterButtons';
import { Input } from 'modules/ui-components/Input';
import { PhoneNumber } from 'modules/ui-components/PhoneNumber';
import { Select } from 'modules/ui-components/Select';
import {
  COUNTRY_COUNTY_MAP,
  COUNTRY_OF_MAIN_OPERATIONS,
  IRELAND_CONF,
  UK_CONF,
} from 'utils/constants';
import { useConnect } from 'utils/framework';
import { _t } from 'utils/string';
import { ClientDetailsViewModel } from '../ClientDetailsViewModel';
import { Title } from '../../utils';
import { getT } from 'utils/framework/intl';
import * as Yup from 'yup';
import { isValidPhoneFormat } from 'utils/validators';
import { Button } from 'modules/ui-components/Button';

const LeadDetailsFormSchema = () => {
  const _t = getT();
  const streetMaxLength = 250;
  const addressMaxLength = 255;

  return Yup.object({
    countryOfIncorporation: Yup.string(),
    street: Yup.string()
      .required(_t('form.required'))
      .max(streetMaxLength, _t('form.max', { max: streetMaxLength })),
    address_2: Yup.string()
      .required(_t('form.required'))
      .max(addressMaxLength, _t('form.max', { max: addressMaxLength })),
    address_3: Yup.string().max(addressMaxLength, _t('form.max', { max: addressMaxLength })),
    country: Yup.string().required(_t('form.required')),
    county: Yup.string().required(_t('form.required')),
    phone: Yup.string()
      .required(_t('form.required'))
      .test({
        name: 'phoneFormat',
        test: value => isValidPhoneFormat(value),
        message: _t('auth.phoneMatchFormat'),
      }),
  });
};

const formInitialValues = (lead?: Partial<Lead>): LeadDetailsFormValues => {
  return {
    countryOfIncorporation: lead?.countryOfIncorporation || '',
    street: lead?.street || '',
    address_2: lead?.address_2 || '',
    address_3: lead?.address_3 || '',
    country: lead?.country || '',
    county: lead?.county || '',
    phone: lead?.phone || '',
    ucgsProduct: lead?.ucgsProduct || false,
    zipCode: lead?.zipCode || '',
  };
};

export const LeadDetails = () => {
  const vm = useConnect(ClientDetailsViewModel);

  if (!vm.state.canSeeLeadDetails) {
    return <FooterButtons />;
  }

  return (
    <Formik
      initialValues={formInitialValues(vm.state.lead)}
      validationSchema={LeadDetailsFormSchema}
      onSubmit={async values => vm.submitLeadDetails({ values, step: Steps.ASSETS })}
      enableReinitialize={true}
    >
      {({ handleSubmit, values, errors }) => (
        <Form>
          {vm.state.isUcgsAvailable && (
            <Input
              className="UCGS-checkbox"
              type="checkbox"
              name="ucgsProduct"
              label={_t('loans.UCGS')}
            />
          )}
          <Title>{_t('loans.trading')}</Title>
          <Select
            placeholder={_t('loans.countryBusiness')}
            label={_t('loans.countryBusiness')}
            name="countryOfIncorporation"
            options={COUNTRY_OF_MAIN_OPERATIONS}
          />
          <Title>{_t('loans.contactDetails')}</Title>
          <PhoneNumber label={_t('layout.phone')} name="phone" type="text" />
          <LocationInputs vm={vm} />
          {vm.state.canSeeFooterButtons ? (
            <FooterButtons
              goNext={handleSubmit}
              saveForLater={() =>
                vm.submitLeadDetails({
                  values,
                  saveForLater: true,
                  errorKeys: Object.keys(errors) as Array<keyof LeadDetailsFormValues>,
                })
              }
              loading={vm.state.isLeadUpdating}
            />
          ) : (
            <FooterButtons />
          )}
        </Form>
      )}
    </Formik>
  );
};

const LocationInputs = ({ vm }: { vm: ClientDetailsViewModel }) => {
  const { values, validateField, errors, setFieldValue } =
    useFormikContext<LeadDetailsFormValues>();
  const [countyOptions, setCounty] = useState([{ value: '', label: 'Select Country' }]);

  useEffect(() => {
    if (values.country === UK_CONF) {
      setFieldValue('zipCode', '');
    }
    if (values.country) {
      setCounty(COUNTRY_COUNTY_MAP[values.country]);
      return;
    }
    setCounty([{ value: '', label: 'Select Country' }]);
  }, [values.country]);

  return (
    <div>
      <Title>{_t('loans.companyAddress')}</Title>
      <div className="form-section">
        {(!values.country || values.country === IRELAND_CONF) && (
          <div className="row">
            <div style={{ flex: 2 }}>
              <Input type="text" name="zipCode" label={_t('loans.eircode')} />
            </div>

            <Button
              className="eircode-button button-primary"
              type="button"
              onClick={() => {
                values.zipCode && vm.searchEircode(values, values.zipCode);
              }}
            >
              {_t('loans.searchEircode')}
            </Button>
          </div>
        )}

        <Input name="street" type="text" label={_t('loans.address1')} />
        <Input name="address_2" type="text" label={_t('loans.address2')} />
        <Input name="address_3" type="text" label={_t('loans.address3')} />
        <Select
          placeholder={_t('loans.country')}
          label={_t('loans.country')}
          name="country"
          options={COUNTRY_OF_MAIN_OPERATIONS}
        />
        <Select
          label={_t('loans.county')}
          name="county"
          menuPlacement="top"
          options={countyOptions}
          isDisabled={values.country === ''}
        />
      </div>
    </div>
  );
};

import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { paymentsActions, paymentsSelector, Filter } from './store';
import { formatDate } from 'utils/date';
import { TableBodyType } from 'modules/ui-components/table/Table';
import { formatCurrency, translateLabels } from 'utils/string';
import { capitalCase, constantCase } from 'change-case';
import { PAYMENTS_LIMIT } from 'utils/constants';
import { PaymentIntent, PaymentIntentStatus } from 'models/Payment';
import { paymentCheckoutActions } from 'modules/payment-checkout/store';
import { Module, uploadDocumentsActions } from 'modules/upload-documents/store';
import { routingActions } from 'modules/routing/store';
import { Route } from 'modules/routing';

export interface BaseOption {
  label: string;
  value: string;
}

@singleton()
@autobind
export class PaymentsViewModel extends ViewModel {
  state!: {
    isLoading: boolean;
    paymentIntentListOffset: number;
    paymentIntentListHasNextPage: boolean;
    areFiltersDisabled: boolean;
    tableHead: string[];
    tableBody: TableBodyType;
    options: {
      status: BaseOption[];
    };
  };

  protected tableHead = [
    'payments.applicationNumber',
    'payments.amount',
    'payments.status',
    'payments.createdAt',
    'payments.actions',
  ];

  get initialFormValues() {
    return {
      status: '',
    };
  }

  protected connected() {
    this.store.dispatch(paymentsActions.FetchPaymentIntents({ offset: 0 }));
  }

  setFilterValues(filter: Filter) {
    this.store.dispatch(paymentsActions.SetFilter(filter));
    this.store.dispatch(paymentsActions.FetchPaymentIntents({ offset: 0 }));
  }

  checkoutPaymentIntent(intentId: string) {
    this.store.dispatch(paymentCheckoutActions.RedirectToPaymentCheckout({ id: intentId }));
  }

  loadNextPage() {
    const { paymentIntentListOffset } = this.state;
    this.store.dispatch(
      paymentsActions.FetchPaymentIntents({ offset: paymentIntentListOffset + PAYMENTS_LIMIT })
    );
  }

  loadPreviousPage() {
    const { paymentIntentListOffset } = this.state;
    this.store.dispatch(
      paymentsActions.FetchPaymentIntents({ offset: paymentIntentListOffset - PAYMENTS_LIMIT })
    );
  }

  redirectToDashboard() {
    this.store.dispatch(routingActions.Navigate({ route: Route.Dashboard, params: {} }));
  }

  protected stateMapper = createSelector(paymentsSelector, paymentsState => {
    const tableBody = formatPaymentIntentsToTableBody(paymentsState.intents.results);
    return {
      isLoading: paymentsState.isLoading,
      tableHead: translateLabels(this.tableHead),
      tableBody: tableBody,
      paymentIntentListOffset: paymentsState.intents.offset,
      paymentIntentListHasNextPage: paymentsState.intents.hasMore,
      areFiltersDisabled: !tableBody.length && !paymentsState.filter.status,
      options: { status: getPaymentIntentStatusOptions() },
    };
  });
}

const formatPaymentIntentsToTableBody = (intents: PaymentIntent[]) => {
  return intents.map(intent => {
    const { applicationNumber, amount, status, createdAt, paymentId } = intent;
    return [
      applicationNumber,
      formatCurrency(amount),
      capitalCase(status),
      formatDate(createdAt || ''),
      { paymentId, status },
    ];
  });
};

const getPaymentIntentStatusOptions = () => {
  return Object.values(PaymentIntentStatus).map(type => {
    return { label: capitalCase(type), value: constantCase(type) };
  });
};

export const leads = {
  leads: 'Leads',
  noLeads: 'No leads',
  companyName: 'Company name',
  name: 'Name',
  phone: 'Phone number',
  email: 'Email address',
  term: 'Term',
  loanPurpose: 'Loan purpose',
  fundingAmount: 'Funding amount',
  continue: 'Continue',
  uploadDocuments: 'Upload documents',
  cancel: 'Cancel',
  noCancel: 'I want to keep it',
  yesCancel: 'Yes, cancel it',
  cancelLead: 'Cancel Enquiry',
  cancelLeadConfirmation:
    'Are you sure you want to cancel this enquiry? Once it is cancelled it will no longer show in the Portal.',
  address: 'Address',
  firstName: 'First name',
  showAddress: 'Show address',
  hideAddress: 'Hide address',
  enquiryInfo: 'Looks like you just made this enquiry, would you like to apply for it?',
  noLeadDetails: 'No enquiry details yet.',
  personalMotorFinance: 'Personal Motor Finance',
};

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routing, routingSaga, RoutingState } from 'modules/routing/store';
import { auth0, auth0Saga, Auth0State } from 'modules/auth/authStore';
import { ui, UIState } from 'modules/ui-components/store';
import { notification, NotificationState } from 'modules/notification/store';
import { profile, profileSaga, ProfileState } from 'modules/profile/store';
import { dashboard, dashboardSaga, DashboardState } from 'modules/dashboard/store';
import { documents, documentsSaga, DocumentsState } from 'modules/documents/store';
import { lead, leadSaga, LeadState } from 'modules/leads/store';
import {
  uploadDocuments,
  uploadDocumentsSaga,
  UploadDocumentsState,
} from 'modules/upload-documents/store';
import { errorPageSaga, ErrorPageState, errors } from 'modules/error-page/store';
import { openBanking, openBankingSaga, OpenBankingState } from 'modules/open-banking/store';
import { calculator, CalculatorState } from 'modules/leads/loan-calculator/store';
import { payments, paymentsSaga, PaymentsState } from 'modules/payments/store';
import {
  paymentCheckout,
  paymentCheckoutSaga,
  PaymentCheckoutState,
} from 'modules/payment-checkout/store';

export const STORE_DI_TOKEN = 'store';

export interface State {
  auth0: Auth0State;
  lead: LeadState;
  routing: RoutingState;
  ui: UIState;
  notification: NotificationState;
  profile: ProfileState;
  dashboard: DashboardState;
  documents: DocumentsState;
  uploadDocuments: UploadDocumentsState;
  errors: ErrorPageState;
  openBanking: OpenBankingState;
  calculator: CalculatorState;
  payments: PaymentsState;
  paymentCheckout: PaymentCheckoutState;
}

export function initStore() {
  const appReducer = combineReducers<State>({
    auth0: auth0.reducer,
    lead: lead.reducer,
    routing: routing.reducer,
    ui: ui.reducer,
    notification: notification.reducer,
    profile: profile.reducer,
    dashboard: dashboard.reducer,
    documents: documents.reducer,
    uploadDocuments: uploadDocuments.reducer,
    errors: errors.reducer,
    openBanking: openBanking.reducer,
    calculator: calculator.reducer,
    payments: payments.reducer,
    paymentCheckout: paymentCheckout.reducer,
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: appReducer,
    middleware: [sagaMiddleware],
    devTools: true,
  });

  sagaMiddleware.run(errorPageSaga);
  sagaMiddleware.run(routingSaga);
  sagaMiddleware.run(auth0Saga);
  sagaMiddleware.run(leadSaga);
  sagaMiddleware.run(profileSaga);
  sagaMiddleware.run(dashboardSaga);
  sagaMiddleware.run(documentsSaga);
  sagaMiddleware.run(uploadDocumentsSaga);
  sagaMiddleware.run(openBankingSaga);
  sagaMiddleware.run(paymentsSaga);
  sagaMiddleware.run(paymentCheckoutSaga);

  return store;
}

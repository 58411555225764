import styled from '@emotion/styled';

export const Spinner = styled('div')`
  width: 14px;
  height: 14px;
  border: 2px solid var(--primary-dark-000);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

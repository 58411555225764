import React from 'react';
import styled from '@emotion/styled';
import { getT } from 'utils/framework/intl';
import { Input } from 'modules/ui-components/Input';
import { ClientDetails } from './normalFlow/ClientDetails';
import { useConnect } from 'utils/framework';
import { ClientDetailsViewModel } from './ClientDetailsViewModel';
import { MotorFinanceClientDetails } from './personalMotorFinanceFlow/MotorFinanceClientDetails';
import { MotorFinanceLeadDetails } from './personalMotorFinanceFlow/MotorFinanceLeadDetails';
import { Breakpoints } from 'utils/styling';
import { Select } from 'modules/ui-components/Select';
import { LeadDetails } from './normalFlow/LeadDetails';

interface Props {
  className?: string;
}

const ClientDetailsStepComponent = ({ className }: Props) => {
  const _t = getT();
  const vm = useConnect(ClientDetailsViewModel);

  if (vm.state.isPersonalMotorFinanceFlow) {
    return (
      <div className={className}>
        <h1 className="page__header">{_t('loans.yourDetails')}</h1>
        <MotorFinanceClientDetails />
        <MotorFinanceLeadDetails />
      </div>
    );
  }

  return (
    <div className={className}>
      <h1 className="page__header">{_t('loans.yourDetails')}</h1>
      <ClientDetails />
      <LeadDetails />
    </div>
  );
};

export const ClientDetailsStep = styled(ClientDetailsStepComponent)`
  .title-small {
    margin: 0 0 0.5rem 0;
  }

  ${Input}, ${Select}, .phoneNumber {
    width: 100%;
    margin-bottom: 1rem;
  }

  .action-btn {
    width: 9.375rem;
    height: 2.5rem;
    font-weight: bold;
  }

  .form-section {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.25rem 0.25rem 1.25rem;
    background-color: var(--primary-dark-blue-25);
  }

  .UCGS-checkbox {
    margin: 1.5rem 0 0;
    padding: 0.625rem 0 0;

    .label {
      margin: 0;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .row {
    display: flex;
    align-items: flex-start;
    gap: 0.625rem;
    width: 100%;

    @media only screen and (max-width: ${Breakpoints.tablet}px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .eircode-button {
    flex: 1;
    margin-top: 1.7rem;
  }
`;

import React from 'react';
import styled from '@emotion/styled';

interface Props {
  title: string;
  className?: string;
  subtitle?: string;
}

const FileHeaderComponent = ({ title, subtitle, className }: Props) => {
  return (
    <div className={className}>
      <p className="title">{title}</p>
      {subtitle && <p className="subtitle">{subtitle}</p>}
    </div>
  );
};

export const FileHeader = styled(FileHeaderComponent)`
  p {
    margin: 0;
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
  }

  .subtitle {
    padding-top: 1rem;
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
  }
`;

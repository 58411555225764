import React from 'react';
import styled from '@emotion/styled';
import { useConnect } from 'utils/framework';
import { CheckoutPageViewModel } from './CheckoutPageViewModel';
import { CheckoutForm } from './components/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { container } from 'tsyringe';
import { STRIPE_DI_TOKEN } from 'utils/stripe';
import { Stripe } from '@stripe/stripe-js';
import { CheckoutBox } from './components/CheckoutBox';
import { getT } from 'utils/framework/intl';

interface Props {
  className?: string;
}

const CheckoutPageComponent = ({ className }: Props) => {
  const vm = useConnect(CheckoutPageViewModel);
  const stripe: Stripe = container.resolve(STRIPE_DI_TOKEN);
  const clientSecret = vm.state.intent?.clientSecret;
  const _t = getT();

  if (vm.state.isIntentLoading || clientSecret === null) {
    return (
      <CheckoutBox className={className}>
        <div>{_t('uiComponents.loading')}</div>
      </CheckoutBox>
    );
  }

  return (
    <CheckoutBox
      className={className}
      amount={vm.state.intent.amount}
      paymentRef={vm.state.intent.paymentId}
    >
      <Elements stripe={stripe} options={{ clientSecret: clientSecret }}>
        <CheckoutForm intent={vm.state.intent} />
      </Elements>
    </CheckoutBox>
  );
};

export const CheckoutPage = styled(CheckoutPageComponent)``;

import { fork, put, select } from '@redux-saga/core/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notifyError } from 'modules/notification/store';
import { takeLeading } from 'redux-saga/effects';
import { container } from 'tsyringe';
import { getT } from 'utils/framework/intl';
import { captureException } from 'utils/reporting';
import { State } from 'utils/store';
import { PaymentIntentsResponse } from 'models/Payment';
import { PaymentsService } from 'services/PaymentsService';

export interface Filter {
  status: string;
}

export interface PaymentsState {
  intents: PaymentIntentsResponse;
  filter: Filter;
  isLoading: boolean;
}

const initialState: PaymentsState = {
  intents: {
    results: [],
    total: 0,
    hasMore: false,
    offset: 0,
    limit: 0,
  },
  filter: {
    status: '',
  },
  isLoading: false,
};

export const payments = createSlice({
  name: 'payments',
  initialState: initialState,
  reducers: {
    FetchPaymentIntents: (state: PaymentsState, action: PayloadAction<{ offset?: number }>) => {
      state.isLoading = true;
    },
    FetchPaymentIntentsComplete: (
      state: PaymentsState,
      action: PayloadAction<{ intents: PaymentIntentsResponse }>
    ) => {
      state.intents = action.payload.intents;
      state.isLoading = false;
    },
    SetFilter: (state: PaymentsState, action: PayloadAction<Filter>) => {
      state.filter = action.payload;
    },
  },
});

export const paymentsActions = {
  ...payments.actions,
};

function* handleFetchPaymentIntentData() {
  const paymentsService = container.resolve(PaymentsService);
  const _t = getT();

  yield takeLeading(paymentsActions.FetchPaymentIntents, function* (action) {
    const { filter }: PaymentsState = yield select(paymentsSelector);
    const currentOffset = action.payload.offset ? action.payload.offset : 0;

    const requestedStatus = !!filter?.status ? [filter.status] : undefined;

    try {
      const paymentIntents: PaymentIntentsResponse = yield paymentsService.getUserPaymentIntents({
        offset: currentOffset,
        status: requestedStatus,
      });

      yield put(paymentsActions.FetchPaymentIntentsComplete({ intents: paymentIntents }));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'payments' })));
    }
  });
}

export function* paymentsSaga() {
  yield fork(handleFetchPaymentIntentData);
}

export const paymentsSelector = (state: State) => state[payments.name] as PaymentsState;

import autobind from 'autobind-decorator';
import { Auth0 } from 'modules/auth/authStore';
import { Route } from 'modules/routing';
import { RouteParams, routingActions, routingSelector } from 'modules/routing/store';
import { container, singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { cameliseKeys } from 'utils/string';

@singleton()
@autobind
export class SignupRedirectViewModel extends ViewModel {
  protected async connected() {
    const auth0 = container.resolve(Auth0);
    const user = await auth0.getUser();

    if (user) {
      this.store.dispatch(routingActions.Navigate({ route: Route.Dashboard, params: {} }));
    } else {
      const { email }: RouteParams = await cameliseKeys(
        routingSelector(this.store.getState()).params
      );
      auth0.showSignupForm({ email });
    }
  }
}

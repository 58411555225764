import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';
import { Header } from './components/Header';
import { useConnect } from 'utils/framework';
import Notification from 'modules/notification/Notification';
import { Help } from './components/Help';
import { LayoutViewModel } from './LayoutViewModel';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const LeadLayoutComponent = ({ children, className }: Props) => {
  const vm = useConnect(LayoutViewModel);

  return (
    <div className={className}>
      <Header step={vm.state.leadStep} />
      <div className="sidebar" />
      <main className="layout__content">
        <Notification />
        {children}
      </main>
      <Help />
    </div>
  );
};

export const LeadLayout = styled(LeadLayoutComponent)`
  min-height: 100vh;
  display: grid;
  grid-template:
    'header' 3.875rem
    'main' 1fr
    'help' 15.5rem
    / 1fr;
  padding-bottom: 4.25rem; /* Space for form buttons */

  ${Header} {
    grid-area: header;
    z-index: 1100; /* Place above drawer */
  }

  ${Help} {
    grid-area: help;
  }

  .sidebar {
    display: none;
    grid-area: sidebar;
    position: sticky;
    width: 100%;
    top: 3.875rem;
    background-color: var(--primary-dark-blue-25);
  }

  .layout__content {
    grid-area: main;
    background-color: var(--primary-dark-000);
    padding: 1rem;
    max-width: 40rem;
    width: 100%;
    justify-self: center;
  }

  @media only screen and (min-width: ${Breakpoints.desktopS}px) {
    grid-template:
      'header header header' 3.875rem
      'sidebar main help' 1fr
      / 16.25rem 1fr 16.25rem;

    .sidebar {
      display: block;
    }
  }
`;

import autobind from 'autobind-decorator';
import {
  Loan,
  LoansResponse,
  LoanSchedule,
  LoanScheduleResponse,
  CamelisedLoansResponse,
  PaginatedResponse,
  CamelisedLoanScheduleResponse,
} from 'models/Loan';
import { singleton } from 'tsyringe';
import { cameliseKeys } from '../utils/string';
import { Http } from './Http';

@singleton()
@autobind
export class AccountsService {
  protected readonly http: Http;

  constructor(apiUrl: string = '/accounts') {
    this.http = new Http(apiUrl);
  }

  fetchLoanSchedule = async (id: string): Promise<LoanSchedule> => {
    const { data }: LoanScheduleResponse = await this.http.request(`/v1/loans/${id}/schedule`);
    const { results }: CamelisedLoanScheduleResponse = cameliseKeys(data);
    return { installments: results };
  };

  fetchLoansList = async (page: number): Promise<PaginatedResponse<Loan>> => {
    const pageLimit = 10;
    const offset = pageLimit * page;
    const { data }: LoansResponse = await this.http.request(
      `/v1/loans?offset=${offset}&limit=${pageLimit}`
    );

    const camelisedData: CamelisedLoansResponse = cameliseKeys(data);
    return {
      results: camelisedData.results,
      page: page,
      moreRecords: camelisedData.hasMore,
    };
  };
}

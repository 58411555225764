import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { dashboardActions, dashboardSelector } from 'modules/dashboard/store';
import { Loan, PaginatedResponse } from 'models/Loan';
import { auth0Selector } from 'modules/auth/authStore';
import { formatCurrency } from 'utils/string';
import { _t } from 'utils/string';
import { toNumber } from 'utils/function';

@singleton()
@autobind
export class LoansViewModel extends ViewModel {
  unsubscribe!: () => void;
  state!: {
    zohoId: string;
    loanList: PaginatedResponse<Loan>;
    loanListPage: number;
    loanListHasNextPage: boolean;
    loanListIsLoading: boolean;
  };

  previousLoansPage = () => {
    const { loanListPage } = this.state;
    this.store.dispatch(dashboardActions.FetchLoans({ page: loanListPage - 1 }));
  };

  nextLoansPage = () => {
    const { loanListPage } = this.state;
    this.store.dispatch(dashboardActions.FetchLoans({ page: loanListPage + 1 }));
  };

  protected connected() {
    if (this.state.zohoId) {
      this.store.dispatch(dashboardActions.FetchLoans({ page: 0 }));
    }
  }

  protected stateMapper = createSelector(dashboardSelector, auth0Selector, (dashboard, auth) => ({
    zohoId: auth.user?.zohoId,
    loanList: {
      ...dashboard.loanList,
      results: dashboard.loanList.results.map(loan =>
        formatLoan(loan, dashboard.loadingNextPayment)
      ),
    },
    loanListPage: dashboard.loanList.page,
    loanListHasNextPage: dashboard.loanList.moreRecords,
    loanListIsLoading: dashboard.loanListIsLoading,
  }));
}

const formatLoan = (loan: Loan, loadingNextPayment: boolean) => {
  const getTotalBalance = (loan: Loan): number | null => {
    if (!loan.principleBalance && !loan.interestBalance && !loan.feesBalance && !loan.penaltyDue) {
      return null;
    }
    const totalBalance =
      toNumber(loan.principleBalance) +
      toNumber(loan.interestBalance) +
      toNumber(loan.feesBalance) +
      toNumber(loan.penaltyDue);
    return totalBalance;
  };

  const currentBalance = getTotalBalance(loan);
  const nextPaymentAmount = loan.nextPaymentAmount
    ? formatCurrency(loan.nextPaymentAmount)
    : _t('loans.notAvailable');

  return {
    ...loan,
    loanAmount: loan.loanAmount ? formatCurrency(loan.loanAmount) : loan.loanAmount,
    currentBalance: currentBalance ? formatCurrency(currentBalance) : currentBalance,
    nextPaymentAmount: loadingNextPayment ? 'Loading...' : nextPaymentAmount,
    type: loan.loanName || _t('loans.notAvailable'),
    loanId: loan.loanId || _t('loans.notAvailable'),
  };
};

import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import {
  CartellAssetResponse,
  EircodeAddressResponse,
  VisionNetCompany,
  VisionNetInputTypes,
} from '../models/Application';
import { cameliseKeys } from '../utils/string';
import { Http } from './Http';

@singleton()
@autobind
export class ThirdPartyService {
  protected readonly http: Http;

  constructor(apiUrl: string = '/third-party') {
    this.http = new Http(apiUrl);
  }

  async searchCartell(registrationNumber: string): Promise<CartellAssetResponse> {
    const response = await this.http.request(`/cartell/${registrationNumber}`, {
      method: 'GET',
    });
    return cameliseKeys(response.data);
  }

  async searchEircode(eircode: string): Promise<EircodeAddressResponse> {
    const response = await this.http.request(`/vision-net/address/${eircode}`, {
      method: 'GET',
    });
    return cameliseKeys(response.data);
  }

  async searchVisionNet(
    companyValue: string,
    type: VisionNetInputTypes
  ): Promise<VisionNetCompany[]> {
    const response = await this.http.request(
      `/vision-net/company?company_type=COMPANY&${type}=${companyValue}`,
      {
        method: 'GET',
      }
    );
    return cameliseKeys(response.data.companies);
  }
}

import React, { useEffect } from 'react';
import { useConnect } from 'utils/framework';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import { getT } from 'utils/framework/intl';
import { Select } from 'modules/ui-components/Select';
import { Input } from 'modules/ui-components/Input';
import { Toggle } from 'modules/ui-components/Toggle';
import { CurrencyInput } from 'modules/ui-components/CurrencyInput';
import { RegistrationNumberInput } from '../../../../ui-components/RegistrationNumberInput';
import {
  CartellAssetResponse,
  Condition,
  AssetDetails as AssetDetailsType,
  Steps,
} from '../../../../../models/Application';
import { assetTypes } from '../../../../../utils/function';
import { AssetType, CONDITIONS } from '../../../../../utils/constants';
import { FooterButtons } from 'modules/ui-components/FooterButtons';
import { AssetDetailsViewModel } from '../AssetDetailsViewModel';
import * as Yup from 'yup';
import { isValidYearOfManufacture } from 'utils/validators';
import { Lead } from 'models/Lead';

const initialValues = (
  lead?: Partial<Lead>,
  cartell?: CartellAssetResponse
): AssetDetailsType => {
  return {
    assetType: lead?.assetType || '',
    make: cartell?.make || lead?.make || '',
    model: cartell?.model || lead?.model || '',
    chassisNumber: cartell?.chassisNumber || lead?.chassisNumber || '',
    yearOfManufacture: cartell?.manufactureDate || lead?.yearOfManufacture || '',
    registrationNumber: cartell?.registrationNumber || lead?.registrationNumber || '',
    assetCost: lead?.assetCost || 0,
    totalCost: lead?.totalCost || 0,
    condition: lead?.condition || Condition.new,
    dealer: lead?.dealer || '',
  };
};

const validationSchema = () => {
  const _t = getT();
  return Yup.object({
    assetType: Yup.string().required(_t('form.required')),
    make: Yup.string()
      .max(256, _t('form.lengthMax', { len: 256 }))
      .required(_t('form.required')),
    model: Yup.string()
      .max(256, _t('form.lengthMax', { len: 256 }))
      .required(_t('form.required')),
    assetCost: Yup.number()
      .min(5000, _t('form.min', { min: 5000 }))
      .required(_t('form.required')),
    chassisNumber: Yup.string().max(20, _t('form.lengthMax', { len: 20 })),
    registrationNumber: Yup.string().max(11, _t('form.lengthMax', { len: 11 })),
    yearOfManufacture: Yup.string()
      .matches(/^[0-9]+$/, _t('form.digits'))
      .test('len', _t('form.yearOfManufacture'), val => isValidYearOfManufacture(val))
      .required(_t('form.required')),
    dealer: Yup.string().nullable(),
  });
};

export const AssetDetails = () => {
  const vm = useConnect(AssetDetailsViewModel);
  const _t = getT();

  if (vm.state.searchInCartell) {
    return (
      <div>
        <h1 className="page__header">{_t('loans.assetDetails')}</h1>
        <SearchInCartellForm vm={vm} />
        <FooterButtons />
      </div>
    );
  }

  return (
    <div>
      <h1 className="page__header">{_t('loans.assetDetails')}</h1>
      <Formik
        initialValues={initialValues(vm.state.lead, vm.state.cartell)}
        onSubmit={async values => vm.submit({ values, step: Steps.FINANCE })}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, handleSubmit, errors }) => (
          <Form>
            <AssetDetailsForm vm={vm} values={values} setFieldValue={setFieldValue} />
            <FooterButtons
              saveForLater={() =>
                vm.submit({
                  values,
                  saveForLater: true,
                  errors: Object.keys(errors) as Array<keyof Lead>,
                })
              }
              goPrev={() =>
                vm.submit({
                  values,
                  prev: true,
                  step: Steps.CUSTOMER,
                  errors: Object.keys(errors) as Array<keyof Lead>,
                })
              }
              goNext={handleSubmit}
              loading={vm.state.isLeadUpdating}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const SearchInCartellForm = ({ vm }: { vm: AssetDetailsViewModel }) => {
  return (
    <div>
      <RegistrationNumberInput
        search={vm.searchAssetDetails}
        isSearchingForAsset={vm.state.isSearchingForAsset}
        setIsSearchingForAsset={(isSearchingForAsset: boolean) =>
          vm.setIsSearchingForAsset(isSearchingForAsset)
        }
      />
      <div
        className="cartell-link enter-manually"
        onClick={() => {
          vm.resetCartell();
          vm.setSearchInCartell(false);
        }}
      >
        <FormattedMessage id="form.enterTheAssetManually" />
      </div>
    </div>
  );
};

interface FormProps {
  vm: AssetDetailsViewModel;
  values: AssetDetailsType;
  setFieldValue: (field: string, value: any) => void;
}

const AssetDetailsForm = ({ vm, values, setFieldValue }: FormProps) => {
  const _t = getT();
  useEffect(() => {
    setFieldValue('totalCost', Number(values.assetCost));
  }, [values.assetCost]);

  return (
    <div className="asset">
      <div className="asset-header">
        <div>{_t('loans.asset')} </div>
        {values.assetType && (
          <>
            {vm.assetIsVehicle(values.assetType) && (
              <div className="cartell-link" onClick={() => vm.setSearchInCartell(true)}>
                <FormattedMessage id="form.enterRegistration" />
              </div>
            )}
          </>
        )}
      </div>
      <Select
        placeholder={_t('form.select')}
        label={_t('loans.assetType')}
        name="assetType"
        options={assetTypes}
        onChange={e => {
          const newValue = e as { value?: AssetType };
          vm.changeAssetType(newValue);
        }}
      />
      <div className="row">
        <Input type="text" name="make" label={_t('loans.make')} />
        <Input type="text" name="model" label={_t('loans.model')} />
        <Input type="text" name="yearOfManufacture" label={_t('loans.yearOfManufacture')} />
      </div>
      {values.assetType && (
        <>
          {vm.assetIsVehicle(values.assetType) && (
            <>
              <div className="row">
                <Input
                  type="text"
                  name="registrationNumber"
                  label={_t('loans.registrationNumber')}
                />
                <Input type="text" name="chassisNumber" label={_t('loans.chassisNumber')} />
              </div>
            </>
          )}
        </>
      )}
      <div className="row">
        <CurrencyInput name="assetCost" label={_t('loans.totalCost')} />
      </div>
      <div className="row">
        <Toggle name="condition" asRadio label={_t('loans.condition')} options={CONDITIONS} />
      </div>
      <Input type="text" name="dealer" label={_t('loans.dealer')} />
    </div>
  );
};

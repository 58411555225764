import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { formatCurrency } from 'utils/string';
import { Formik, Form } from 'formik';
import { Toggle } from 'modules/ui-components/Toggle';
import { CurrencyInput } from 'modules/ui-components/CurrencyInput';
import { Select } from 'modules/ui-components/Select';
import { useConnect } from 'utils/framework';
import { Drawer } from 'modules/ui-components/Drawer';
import { Input } from 'modules/ui-components/Input';
import { CalculatorSummary } from 'modules/leads/loan-calculator/LoanCalculatorSummarySection';
import { getT } from 'utils/framework/intl';
import { CalculatorValues, ZohoLoanProduct } from 'models/Application';
import { CalculatorViewModel } from 'modules/leads/loan-calculator/CalculatorViewModel';
import { Breakpoints } from 'utils/styling';
import { MAX_TERM, MAX_TERM_PERSONAL_MOTOR_FINANCE, MIN_TERM } from './CalculatorService';
import { getFiltredAssetTypes } from '../../../utils/function';
import { CONDITIONS } from '../../../utils/constants';

interface Props {
  className?: string;
  open: boolean;
  toggleOpen: () => void;
}
interface FormProps {
  vm: CalculatorViewModel;
  className?: string;
  values: CalculatorValues;
  setFieldValue: (field: string, value: any) => void;
}

export const LoanCalculatorComponent = ({ className, open, toggleOpen }: Props) => {
  const vm = useConnect(CalculatorViewModel);

  return (
    <Drawer open={open} onClose={toggleOpen} className={className}>
      <div className="calculator">
        <Formik
          initialValues={vm.state.formInitialValues}
          validationSchema={vm.validationSchema}
          onSubmit={async values => vm.submitCalculator(values)}
        >
          {({ values, setFieldValue }) => (
            <ApplicationForm vm={vm} values={values} setFieldValue={setFieldValue} />
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

const ApplicationForm = ({ vm, values, setFieldValue }: FormProps) => {
  const _t = getT();
  const { vatExempt, loanProduct, netCost } = values;
  const hideVat = loanProduct === ZohoLoanProduct.Leasing && !vatExempt;
  const maxTerm =
    values.loanProduct === ZohoLoanProduct.PersonalMotorFinance
      ? MAX_TERM_PERSONAL_MOTOR_FINANCE
      : MAX_TERM;
  const oldMonthlyPayment = useRef(values.monthlyPayment);

  useEffect(() => {
    if (vatExempt && loanProduct === ZohoLoanProduct.HirePurchase) {
      setFieldValue('vatExempt', false);
    }
  }, [setFieldValue, vatExempt, loanProduct]);

  useEffect(() => {
    setFieldValue('financeAmount', netCost);
  }, [setFieldValue, netCost]);

  useEffect(() => {
    if (values.term > maxTerm) setFieldValue('term', maxTerm);
  }, [setFieldValue, values.term, values.loanProduct]);

  useEffect(() => {
    if (oldMonthlyPayment.current === values.monthlyPayment)
      setFieldValue(
        'monthlyPayment',
        vm.calculatorService.calculateMonthlyRepayment(
          values.financeAmount,
          Math.min(Math.max(values.term, MIN_TERM), maxTerm)
        )
      );
    oldMonthlyPayment.current = values.monthlyPayment;
  }, [
    setFieldValue,
    vm.calculatorService,
    values.term,
    values.financeAmount,
    values.monthlyPayment,
  ]);

  return (
    <Form>
      <div className="calculator-main-sections">
        <div className="loan-type">
          <Toggle
            name={'loanProduct'}
            label={_t('loans.loanType')}
            options={vm.state.loanProducts}
            onChange={(e: ZohoLoanProduct) => {
              const correctAssetType = getFiltredAssetTypes(e).find(
                assetType => assetType.value === values.assetDetails.assetType
              );

              if (!correctAssetType && values.assetDetails.assetType !== '') {
                setFieldValue('assetDetails.assetType', '');
              }
            }}
          />
        </div>
        <div className="asset-value">
          <div className="asset-value-section">
            <CurrencyInput
              className="cost-input"
              name="netCost"
              label={_t('loans.financeAmount')}
            />
          </div>
        </div>

        {values.loanProduct === ZohoLoanProduct.Leasing && (
          <div className="vat-container">
            <Input type="checkbox" name="vatExempt" label={_t('loans.vatExempt')} />
          </div>
        )}

        <div className="terms">
          <div className="help-wrapper slider-container">
            <Input
              type="range"
              name="term"
              min={MIN_TERM}
              max={maxTerm}
              step={1}
              label={_t('loans.terms', {
                help: (...chunks: any) => (
                  <span className="terms-help" key="none">
                    {chunks}
                  </span>
                ),
              })}
            />
            <div className="help">
              <div>{MIN_TERM}</div>
              <div>{maxTerm}</div>
            </div>
          </div>
          <div className="slider-input-container">
            <Input
              className="currency-input"
              name="term"
              type="number"
              min={MIN_TERM}
              max={maxTerm}
            />
          </div>
        </div>

        <div className="monthly-payments">
          <div className="help-wrapper slider-container">
            <Input
              label={_t('loans.monthlyPayments')}
              type="range"
              name="term"
              rtl
              min={MIN_TERM}
              max={maxTerm}
              step={1}
            />
            <div className="help">
              <div>
                {formatCurrency(
                  vm.calculatorService.calculateMonthlyRepayment(values.financeAmount, maxTerm)
                )}
              </div>
              <div>
                {formatCurrency(
                  vm.calculatorService.calculateMonthlyRepayment(values.financeAmount, MIN_TERM)
                )}
              </div>
            </div>
          </div>
          <div className="slider-input-container">
            <div className="help-wrapper">
              <CurrencyInput
                className="currency-input"
                name="monthlyPayment"
                step={0.01}
                onBlur={() => {
                  const term = vm.calculatorService.calculateTerm(
                    values.financeAmount,
                    values.monthlyPayment
                  );
                  values.term = Math.min(Math.max(MIN_TERM, term), maxTerm);
                  values.monthlyPayment = vm.calculatorService.calculateMonthlyRepayment(
                    values.financeAmount,
                    values.term
                  );
                }}
              />
              {hideVat && <div className="help help--right">{_t('loans.additionalVatAmount')}</div>}
            </div>
          </div>
        </div>

        <div className="asset-details">
          <Select
            placeholder={_t('form.select')}
            label={_t('loans.assetType')}
            name="assetDetails.assetType"
            options={getFiltredAssetTypes(values.loanProduct)}
          />
          <Toggle
            name="assetDetails.condition"
            asRadio
            label={_t('loans.condition')}
            options={CONDITIONS}
          />
        </div>
      </div>

      <CalculatorSummary
        totalCost={netCost}
        label={_t(hideVat ? 'loans.totalNetCost' : 'loans.totalCost')}
        hideVat={hideVat}
      />
    </Form>
  );
};

export const LoanCalculator = styled(LoanCalculatorComponent)`
  margin-top: 7.75rem;
  height: calc(100% - 7.75rem);
  overflow-y: auto;

  .drawer {
    width: 100%;
    position: absolute;
    top: 0;
    pointer-events: none;
  }

  .backdrop {
    overflow: hidden;
  }

  .calculator {
    background: white;
    display: flex;
    justify-content: center;
    pointer-events: auto;

    form {
      flex: 0 1 var(--content-max-width);
    }

    .calculator-main-sections {
      padding: 1rem;
      display: grid;
      gap: 1rem 3rem;
      grid-template:
        'loan-type'
        'asset-details'
        'asset-value'
        'vat-container'
        'terms'
        'monthly-payments';
    }

    ${Input}, ${Toggle}, ${Select} {
      margin-bottom: 1.5rem;
    }

    .help-wrapper {
      .help {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        line-height: 1.67;
        color: var(--primary-dark-300);
        margin-top: -1.25rem;

        &--right {
          justify-content: flex-end;
        }
      }
    }

    .loan-type {
      grid-area: loan-type;
    }

    .asset-value {
      grid-area: asset-value;
    }

    .vat-container {
      grid-area: vat-container;
    }

    .terms {
      grid-area: terms;
    }

    .monthly-payments {
      grid-area: monthly-payments;

      ${Input} .error {
        display: none;
      }
    }

    .asset-details {
      grid-area: asset-details;
      ${Toggle} .values {
        flex-direction: row !important;
        align-items: center;
        label:not(:first-of-type) {
          margin-left: 1rem;
        }
      }
    }

    @media only screen and (min-width: ${Breakpoints.desktopS}px) {
      .calculator-main-sections {
        padding: 1rem 1.5rem;
        grid-template:
          'loan-type .'
          'asset-details terms'
          'asset-value monthly-payments'
          'vat-container .'
          / 1fr 1fr;
      }

      .monthly-payments,
      .terms,
      .asset-value,
      .asset-details {
        display: flex;
        gap: 1.5rem;

        > * {
          flex: 1;
        }

        > *:first-of-type {
          flex: 2;
        }
      }

      .asset-value {
        > * {
          flex: 2;
        }

        > *:first-of-type {
          flex: 1;
        }
      }

      .slider-input-container {
        margin-top: 1.125rem;
      }
    }
  }
`;

import { RoutingService } from 'utils/framework';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { channel } from 'redux-saga';
import { container } from 'tsyringe';
import { fork, put, select, take } from 'redux-saga/effects';
import { Route, routes } from './routes';
import { auth0Actions, auth0Selector } from 'modules/auth/authStore';
import { User } from 'models/User';

export type RouteParams = { [key: string]: string };

const authenticatedRoutes = [
  Route.Profile,
  Route.LoanApplication,
  Route.UploadDocuments,
  Route.Dashboard,
];

export interface RedirectRoute {
  route: Route;
  params: RouteParams;
}

export interface RoutingState {
  route?: Route;
  lastRedirectFrom?: RedirectRoute | null;
  params: RouteParams;
}

const initialState: RoutingState = { route: Route.Dashboard, params: {} };

export const routing = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    Navigate: (
      state: RoutingState,
      action: PayloadAction<{
        route: Route;
        params: RouteParams;
        isBack?: boolean;
        replace?: boolean;
      }>
    ) => {
      state.route = action.payload.route;
      state.params = action.payload.params || {};
    },
    UnauthorizedAccess: () => undefined,
    RedirectAfterAuthorized: () => undefined,
    RedirectAfterAuthorizedSuccess: (state: RoutingState) => {
      state.lastRedirectFrom = null;
    },
  },
});

export const routingActions = {
  ...routing.actions,
};

export const routingSelector = (state: any) => state[routing.name] as RoutingState;

const locationChanges = channel<{ pathname: string; isBack?: boolean }>();

function* listenToLocationChanges() {
  const router = container.resolve(RoutingService);
  router.listen(({ location, action }) => {
    if (action === 'POP') {
      locationChanges.put({ pathname: location.pathname, isBack: true });
    }
  });

  while (true) {
    const change: {
      pathname: string;
      isBack?: boolean | undefined;
    } = yield take(locationChanges);
    const [route, params] = router.match(change.pathname) || [];
    yield put(routingActions.Navigate({ route, params, isBack: change.isBack }));
  }
}

function* listenToNavigation() {
  const router = container.resolve(RoutingService);

  while (true) {
    const action: {
      payload: {
        route: Route;
        params: RouteParams;
        isBack?: boolean | undefined;
        replace?: boolean | undefined;
      };
    } = yield take(routing.actions.Navigate);
    const { route, params } = action.payload;
    if (route) {
      const url = router.compile(route, params);
      if (!action.payload.isBack) {
        action.payload.replace ? router.replace(url) : router.push(url);
      }
    }
  }
}

function* listenToUnauthorized() {
  while (true) {
    yield take(routing.actions.UnauthorizedAccess);

    const user: User = yield select(state => auth0Selector(state).user);
    if (user) {
      yield put(routingActions.Navigate({ route: Route.Dashboard, params: {}, replace: true }));
    } else {
      yield put(auth0Actions.StartAuthenticating());
    }
  }
}

function* listenRedirectAfterAuthorized() {
  while (true) {
    yield take(routing.actions.RedirectAfterAuthorized);

    const currentRoute: RedirectRoute = yield select(state => routingSelector(state));
    const lastRedirectFrom: RedirectRoute = yield select(state => state.routing.lastRedirectFrom);

    if (lastRedirectFrom) {
      yield put(routingActions.Navigate(lastRedirectFrom));
    } else if (authenticatedRoutes.includes(currentRoute.route)) {
      yield put(routingActions.Navigate(currentRoute));
    } else {
      yield put(routingActions.Navigate({ route: Route.Dashboard, params: {} }));
    }

    yield put(routingActions.RedirectAfterAuthorizedSuccess());
  }
}

function setupRouter() {
  const router = container.resolve(RoutingService);
  router.addRoutes(routes);
  locationChanges.put({ pathname: router.location.pathname });
}

export function* routingSaga() {
  setupRouter();
  yield fork(listenToLocationChanges);
  yield fork(listenToNavigation);
  yield fork(listenToUnauthorized);
  yield fork(listenRedirectAfterAuthorized);
}

import { Form, Formik } from 'formik';
import { FooterButtons } from 'modules/ui-components/FooterButtons';
import { Input } from 'modules/ui-components/Input';
import { Toggle } from 'modules/ui-components/Toggle';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AssetType, CONDITIONS } from 'utils/constants';
import { useConnect } from 'utils/framework';
import { AssetDetailsViewModel } from '../AssetDetailsViewModel';
import { Lead, MotorFinanceAssetDetailsValues } from 'models/Lead';
import { getT } from 'utils/framework/intl';
import * as Yup from 'yup';
import { _t } from 'utils/string';
import { CartellAssetResponse, Condition, Steps, ZohoLoanProduct } from 'models/Application';
import { RegistrationNumberInput } from 'modules/ui-components/RegistrationNumberInput';
import { Select } from 'modules/ui-components/Select';
import { getFiltredAssetTypes } from 'utils/function';
import { TooltipInput } from "modules/ui-components/TooltipInput";

const initialValues = (
  lead?: Partial<Lead>,
  cartell?: CartellAssetResponse
): MotorFinanceAssetDetailsValues => {
  return {
    assetType: lead?.assetType || '',
    registrationNumber: cartell?.registrationNumber || lead?.registrationNumber || '',
    make: cartell?.make || lead?.make || '',
    model: cartell?.model || lead?.model || '',
    yearOfRegistration:
      cartell?.firstRegisteredIreland.split('-')[0] || lead?.yearOfRegistration || '',
    condition: lead?.condition || Condition.new,
    dealer: lead?.dealer || '',
    mileage: lead?.mileage || 0,
  };
};

const validationSchema = (isBroker: boolean) => {
  const _t = getT();

  return Yup.object({
    assetType: isBroker ? Yup.string().required(_t('form.required')) : Yup.string(),
    registrationNumber: Yup.string().max(11, _t('form.lengthMax', { len: 11 })),
    make: Yup.string()
      .max(256, _t('form.lengthMax', { len: 256 }))
      .required(_t('form.required')),
    model: Yup.string()
      .max(256, _t('form.lengthMax', { len: 256 }))
      .required(_t('form.required')),
    yearOfRegistration: Yup.string()
      .matches(/^[0-9]+$/, _t('form.digits'))
      .length(4, 'Year of Registration must have exactly 4 characters.')
      .test('len', _t('form.yearOfRegistration'), val => !val || parseInt(val) >= 1995),
    condition: Yup.string().required(_t('form.required')),
    dealer: Yup.string().nullable(),
    mileage: Yup.number()
      .min(0, _t('form.min', { min: 0 }))
      .max(999999999, _t('form.max', { max: 999999999 }))
      .required(_t('form.required')),
  });
};

export const MotorFinanceAssetDetails = () => {
  const vm = useConnect(AssetDetailsViewModel);

  if (vm.state.searchInCartell) {
    return (
      <div>
        <h1 className="page__header">{_t('loans.vehicleDetails')}</h1>
        <RegistrationNumberInput
          search={vm.searchAssetDetails}
          isSearchingForAsset={vm.state.isSearchingForAsset}
          setIsSearchingForAsset={(isSearchingForAsset: boolean) =>
            vm.setIsSearchingForAsset(isSearchingForAsset)
          }
        />
        <div
          className="cartell-link enter-manually"
          onClick={() => {
            vm.resetCartell();
            vm.setSearchInCartell(false);
          }}
        >
          <FormattedMessage id="form.enterTheAssetManually" />
        </div>
        <FooterButtons />
      </div>
    );
  }

  return (
    <div>
      <h1 className="page__header">{_t('loans.vehicleDetails')}</h1>
      <Formik
        initialValues={initialValues(vm.state.lead, vm.state.cartell)}
        validationSchema={() => validationSchema(vm.state.isBroker)}
        onSubmit={async values =>
          vm.submitMotorFinance({
            values,
            step: Steps.FINANCE,
          })
        }
        enableReinitialize={true}
      >
        {({ handleSubmit, values, errors }) => (
          <Form>
            <div className="asset">
              <div className="asset-header">
                <div>{_t('loans.asset')}</div>
                <div className="cartell-link" onClick={() => vm.setSearchInCartell(true)}>
                  <FormattedMessage id="form.enterRegistration" />
                </div>
              </div>
              {vm.state.isBroker && (
                <Select
                  placeholder={_t('form.select')}
                  label={_t('loans.assetType')}
                  name="assetType"
                  options={getFiltredAssetTypes(ZohoLoanProduct.PersonalMotorFinance)}
                  onChange={e => {
                    const newValue = e as { value?: AssetType };
                    vm.changeAssetType(newValue);
                  }}
                />
              )}
              <Input
                type="text"
                name="registrationNumber"
                label={_t('loans.registrationNumberOptional')}
              />
              <div className="row">
                <Input type="text" name="make" label={_t('loans.make')} />
                <Input type="text" name="model" label={_t('loans.model')} />
              </div>

              <TooltipInput label={_t('loans.yearOfRegistration')} type="text" name="yearOfRegistration" help={_t('loans.yearOfRegistrationTooltip')}></TooltipInput>

              <div className="row">
                {!vm.state.isBorrower && (
                  <Input type="number" name="mileage" label={_t('loans.mileage')} />
                )}
                <Toggle
                  name="condition"
                  asRadio
                  label={_t('loans.condition')}
                  options={CONDITIONS}
                />
              </div>

              <Input type="text" name="dealer" label={_t('loans.dealerOrSupplier')} />
            </div>
            <FooterButtons
              saveForLater={() =>
                vm.submitMotorFinance({
                  values,
                  saveForLater: true,
                  errors: Object.keys(errors) as Array<keyof MotorFinanceAssetDetailsValues>,
                })
              }
              goPrev={() =>
                vm.submitMotorFinance({
                  values,
                  prev: true,
                  step: Steps.CUSTOMER,
                  errors: Object.keys(errors) as Array<keyof MotorFinanceAssetDetailsValues>,
                })
              }
              goNext={handleSubmit}
              loading={vm.state.isLeadUpdating}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';
import { getT } from 'utils/framework/intl';
import { formatCurrency } from 'utils/string';

interface Props {
  className?: string;
  children: React.ReactNode;
  amount?: number;
  paymentRef?: string;
}

const CheckoutBoxComponent = ({ className, children, amount, paymentRef }: Props) => {
  const _t = getT();
  return (
    <div className={className}>
      <div className="checkout-box__box">
        <h2 className="checkout-box__sub-title">{_t('payments.makePayment')}</h2>
        <p className="checkout-box__payment_ref">
          {_t('payments.paymentRef')}
          {paymentRef}
        </p>
        <h1 className="checkout-box__amount">{formatCurrency(String(amount || 0))}</h1>
        {children}
      </div>
    </div>
  );
};

export const CheckoutBox = styled(CheckoutBoxComponent)`
  .checkout-box__amount {
    margin: 1rem 0 0;
    font-family: var(--font-family-secondary);
    line-height: 1.5;
    color: var(--primary-dark-blue-500);
  }

  .checkout-box__payment_ref {
    font-size: 0.875rem;
    color: var(--primary-dark-300);
    margin-bottom: 0;
  }

  .checkout-box__sub-title {
    font-family: var(--font-family-secondary);
    line-height: 1.5;
    color: var(--primary-dark-blue-500);
    margin: 1.5rem 0 1rem;

    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      width: 2.5rem;
      height: 3px;
      background: var(--bg-image);
      left: 0;
      bottom: -0.25rem;
    }
  }

  .checkout-box__box {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-height: 30rem;
    margin: 2rem 0 2.5rem;
    border-radius: var(--border-radius);

    @media only screen and (min-width: ${Breakpoints.tablet}px) {
      padding: 1rem 7.5rem;
      margin: 5rem auto;
    }

    position: relative;
    background: var(--primary-dark-000);
    box-shadow: 0 32px 50px -20px rgba(0, 38, 73, 0.06);
  }
`;

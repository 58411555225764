import React, { useEffect, useRef } from 'react';
import { useConnect } from 'utils/framework';
import { Route, RoutingViewModel } from './routing';
import { AnonymousLayout } from 'modules/ui-components/layouts/AnonymousLayout';
import { PrimaryLayout } from './ui-components/layouts/PrimaryLayout';
import { useIntl } from 'react-intl';
import { DashboardPage } from 'modules/dashboard/DashboardPage';
import { LeadLayout } from 'modules/ui-components/layouts/LeadLayout';
import { LeadPage } from 'modules/leads/LeadPage';
import { NotFoundPage } from 'modules/ui-components/layouts/errorPages/NotFoundPage';
import { ProfilePage } from './profile/ProfilePage';
import { MyDocumentsPage } from './documents/MyDocumentsPage';
import { UploadDocuments } from './upload-documents/UploadDocumentsPage';
import { UploadDocumentsLayout } from './ui-components/layouts/UploadDocumentsLayout';
import { ErrorPage } from './error-page/ErrorPage';
import { OpenBankingRedirect } from './open-banking-redirect/OpenBankingRedirect';
import { SignupRedirect } from './sign-up-redirect/SignupRedirect';
import LoginRedirect from './login-redirect/LoginRedirect';
import { PaymentsPage } from './payments/PaymentsPage';
import { CheckoutPage } from './payment-checkout/CheckoutPage';
import { CheckoutCompletePage } from './payment-checkout/CheckoutCompletePage';

interface Page {
  title?: string;
  requiresAuth?: boolean;
  layout?: React.ComponentType<{ children: React.ReactNode }>;
  content: React.ComponentType;
  redirectHome?: boolean;
}

const defaults: Page = {
  title: window.document.title,
  requiresAuth: true,
  layout: AnonymousLayout,
  content: NotFoundPage,
};

const redirectRoutes = {
  [Route.Login]: {
    content: () => null,
    requiresAuth: false,
    layout: AnonymousLayout,
    redirectHome: true,
  },
  [Route.ResetPassword]: {
    content: () => null,
    requiresAuth: false,
    layout: AnonymousLayout,
    redirectHome: true,
  },
  [Route.ForgotPassword]: {
    content: () => null,
    requiresAuth: false,
    layout: AnonymousLayout,
    redirectHome: true,
  },
  [Route.ForgotPasswordSuccess]: {
    content: () => null,
    requiresAuth: false,
    layout: AnonymousLayout,
    redirectHome: true,
  },
  [Route.AccountSetup]: {
    content: () => null,
    requiresAuth: false,
    layout: AnonymousLayout,
    redirectHome: true,
  },
  [Route.EmailVerification]: {
    content: () => null,
    requiresAuth: false,
    layout: AnonymousLayout,
    redirectHome: true,
  },
};

const useRoutes = (): { [key in Route]: Page } => {
  const { formatMessage: _t } = useIntl();

  return {
    ...redirectRoutes,
    [Route.Dashboard]: {
      content: DashboardPage,
      layout: PrimaryLayout,
    },
    [Route.LoanApplication]: {
      content: LeadPage,
      layout: LeadLayout,
    },
    [Route.UploadDocuments]: {
      content: UploadDocuments,
      layout: UploadDocumentsLayout,
    },
    [Route.Profile]: {
      content: ProfilePage,
      layout: PrimaryLayout,
    },
    [Route.MyDocuments]: {
      content: MyDocumentsPage,
      layout: PrimaryLayout,
    },
    [Route.Payments]: {
      content: PaymentsPage,
      layout: PrimaryLayout,
    },
    [Route.PaymentCheckout]: {
      content: CheckoutPage,
      layout: AnonymousLayout,
    },
    [Route.PaymentCheckoutComplete]: {
      content: CheckoutCompletePage,
      layout: AnonymousLayout,
    },
    [Route.OpenBankingRedirect]: {
      content: OpenBankingRedirect,
      layout: AnonymousLayout,
    },
    [Route.LoginRedirect]: {
      content: LoginRedirect,
      layout: AnonymousLayout,
    },
    [Route.Signup]: {
      requiresAuth: false,
      layout: AnonymousLayout,
      content: SignupRedirect,
    },
    [Route.ErrorPage]: {
      requiresAuth: false,
      layout: AnonymousLayout,
      content: ErrorPage,
    },
  };
};

export const Router: React.FC = () => {
  const prevPage = useRef(defaults);
  const vm = useConnect(RoutingViewModel);
  const routes = useRoutes();
  const { isAuthenticating, isAuthenticated } = vm.state;
  const page = vm.state.route && routes[vm.state.route];

  if (page?.redirectHome) {
    vm.navigate(Route.Dashboard, {});
  }

  let authenticatedPage = useAuthenticationRoutes(vm);
  if (authenticatedPage && (isAuthenticating || !isAuthenticated)) {
    authenticatedPage = {
      ...authenticatedPage,
      layout: prevPage.current.layout /* Prevent layout blink */,
      content: () => <></>,
    };
  }
  prevPage.current = { ...defaults, ...(authenticatedPage || page) };
  return <RenderRoute page={prevPage.current} />;
};

const useAuthenticationRoutes = (vm: RoutingViewModel) => {
  const routes = useRoutes();
  const page = vm.state.route && routes[vm.state.route];
  const { isAuthenticated, isAuthenticating } = vm.state;
  const requiresAuth = page && (page.requiresAuth ?? defaults.requiresAuth);

  useEffect(() => {
    if (requiresAuth && !isAuthenticated && !isAuthenticating) {
      vm.trigger403();
    }
  }, [vm, requiresAuth, isAuthenticated, isAuthenticating]);

  if (requiresAuth) {
    return page;
  }
};

const RenderRoute = ({ page }: { page: Page }) => {
  const { title } = page;
  useEffect(() => {
    window.document.title = title!;
  }, [title]);

  return React.createElement(page.layout!, { children: React.createElement(page.content) });
};

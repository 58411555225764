export const form = {
  required: 'Required',
  minLength: 'Must be at least {len} characters long',
  minOneDigit: 'Must have at least one digit',
  minOneLetter: 'Must have at least one letter',
  contact: 'Contact {index}',
  integer: 'Must be an integer',
  lengthMax: 'Must be at most {len} characters long',
  lengthExact: 'Must be exactly {len} characters long',
  min: 'Must be greater than or equal to {min}',
  minTotal: 'Must be greater than or equal to {min} inclusive of deposit',
  max: 'Must be less than or equal to {max}',
  lengthHelp: 'min. {len} characters',
  email: 'Must be a valid email',
  samePassword: 'Both passwords need to be the same',
  select: 'Select',
  submit: 'Submit',
  addClient: 'You need to add a client',
  ppsn: 'PPSN should be 7 numbers followed by 1 or 2 capital letters',
  phone: 'Must be in format +353 or +44',
  passwordConfirm: 'Passwords must match',
  ppsnUnique: 'Each contact must have a unique PPSN',
  dobMin: 'An applicant needs to be 18 years or over to apply',
  dobMax: 'An applicant needs to be less than 100 years to apply',
  yearOfManufacture: 'Should be between year 2000 and current year',
  yearOfRegistration: 'Should be after year 1994',
  digits: 'Input must be numeric',
  needRole: 'You must choose a role for user',
  addNewClient: 'Add new client',
  fileNotAllowed: 'File type not allowed.',
  fileWrongPattern:
    'The file {fileName} you are uploading contains forbidden characters. Please rename the file and try again.',
  fileAlreadyImported: 'File {fileName} has already been imported.',
  fileAlreadyUploaded: 'File {fileName} has already been uploaded.',
  registrationNumber: 'Registration Number',
  search: 'Search',
  searchAssetByRegistrationNumber: 'Search Asset by Registration Number',
  enterTheAssetManually: 'Not a vehicle, or want to enter the Asset Details Manually?',
  enterRegistration: 'Want us to find your vehicle automatically?',
  searching: 'Searching',
  ireland: 'IRL',
  uk: 'UK',
  privateCompanyLimited: 'Private Company Limited by Shares (LTD company)',
  soleTrader: 'Sole Trader',
  applicantNameMaxLength: 'First name and Last name must be less than {max}',
};

import { singleton } from 'tsyringe';
import autobind from 'autobind-decorator';
import { getT } from 'utils/framework/intl';
import { CalculatorValues, ZohoLoanProduct } from 'models/Application';

export const DEFAULT_INTEREST_RATE = 9;
export const MIN_TERM = 12;
export const MAX_TERM = 72;
export const MAX_TERM_PERSONAL_MOTOR_FINANCE = 63;
export const DEFAULT_TERM = 36;

@singleton()
@autobind
export class CalculatorService {
  get calculatorFormOptions() {
    const _t = getT();
    return {
      loanProducts: [
        {
          value: ZohoLoanProduct.Leasing,
          label: _t(`loans.leasing`),
        },
        {
          value: ZohoLoanProduct.HirePurchase,
          label: _t(`loans.hirePurchase`),
        },
        {
          value: ZohoLoanProduct.PersonalMotorFinance,
          label: _t(`loans.personalMotorFinance`),
        },
      ],
      settlement: [
        { value: false, label: _t('loans.no') },
        { value: true, label: _t('loans.yes') },
      ],
    };
  }

  get formInitialValues(): CalculatorValues {
    return {
      loanProduct: ZohoLoanProduct.Leasing,
      netCost: 0,
      vatExempt: false,
      financeAmount: 0,
      monthlyPayment: 0,
      term: DEFAULT_TERM,
      assetDetails: { condition: '', assetType: '' },
    };
  }

  calculateMonthlyRepayment(
    financeAmount: number,
    term: number,
    interestRate = DEFAULT_INTEREST_RATE
  ) {
    const r = interestRate / 100 / 12;
    const P = financeAmount;
    const n = term;
    return this.round((P * (r * (1 + r) ** n)) / ((1 + r) ** n - 1), 2);
  }

  calculateTerm(
    financeAmount: number,
    monthlyRepayment: number,
    interestRate = DEFAULT_INTEREST_RATE
  ) {
    const r = interestRate / 100 / 12;
    const P = financeAmount;
    const A = monthlyRepayment;
    const base = 1 + r;
    const x = A / (A - P * r);

    if (x <= 0) return Infinity;

    const term = Math.log(x) / Math.log(base);
    return this.round(term, 0);
  }

  private round(value: number, decimalPlaces: number) {
    return +value.toFixed(decimalPlaces);
  }
}

import React from 'react';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';
import { Header } from './components/Header';
import Notification from 'modules/notification/Notification';
import { _t } from 'utils/string';
import { Help } from './components/Help';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const UploadDocumentsLayoutComponent = ({ children, className }: Props) => {
  return (
    <div className={className}>
      <Header step={_t('loans.uploadDocuments')} />
      <div className="left-sidebar"></div>
      <main className="layout__content">
        <Notification />
        {children}
      </main>
      <Help />
    </div>
  );
};

export const UploadDocumentsLayout = styled(UploadDocumentsLayoutComponent)`
  min-height: 100%;
  display: grid;
  grid-template:
    'header' 3.875rem
    'main' 1fr
    'help' 15.5rem
    / 1fr;
  padding-bottom: 4.25rem; /* Space for form buttons */

  ${Header} {
    grid-area: header;
    z-index: 1100; /* Place above drawer */
  }

  .left-sidebar {
    grid-area: sidebar;
    position: relative;
    background-color: var(--primary-dark-blue-25);
  }

  ${Help} {
    grid-area: help;
  }

  .layout__content {
    grid-area: main;
    background-color: var(--primary-dark-000);
    padding: 1rem;
    max-width: 40rem;
    width: 100%;
    justify-self: center;
  }

  @media only screen and (min-width: ${Breakpoints.desktopS}px) {
    grid-template:
      'header header header' 3.875rem
      'sidebar main help' 1fr
      / 16.25rem 1fr 16.25rem;
  }
`;

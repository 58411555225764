import { container } from 'tsyringe';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { State } from 'utils/store';
import { Route } from 'modules/routing/routes';
import { routingActions } from 'modules/routing/store';
import { ThirdPartyService } from '../../services/ThirdPartyService';
import {
  Steps,
  VisionNetCompany,
  CartellAssetResponse,
  VisionNetInputTypes,
  EircodeAddressResponse,
} from 'models/Application';
import { CreateLeadResponse, Lead } from 'models/Lead';
import { CrmService } from 'services/CrmService';
import { AssetType, IRELAND_CONF } from '../../utils/constants';
import { notifyError } from 'modules/notification/store';
import { captureException } from 'utils/reporting';
import { getT } from 'utils/framework/intl';
import { _t } from 'utils/string';
import { capitalCase } from 'change-case';

export type Eircodes = Record<string, EircodeAddressResponse>;

export interface LeadState {
  lead?: Partial<Lead>;
  isUpdating: boolean;
  step: Steps;
  companies: VisionNetCompany[];
  companiesLoading: boolean;
  canEditClient: boolean;
  cartell?: CartellAssetResponse;
  isSearchingForAsset: boolean;
  searchInCartell: boolean;
  eircodes: Eircodes;
  isTimeWithEmployerLessThanOneYear: boolean;
}

const initialState: LeadState = {
  isUpdating: false,
  step: Steps.CUSTOMER,
  companies: [],
  companiesLoading: false,
  canEditClient: true,
  isSearchingForAsset: false,
  searchInCartell: false,
  eircodes: {},
  isTimeWithEmployerLessThanOneYear: false,
};

export const lead = createSlice({
  name: 'lead',
  initialState: initialState,
  reducers: {
    LoadLead(state: LeadState, action: PayloadAction<{ id: string }>) {},
    CreateLead(state: LeadState, action: PayloadAction<Partial<Lead>>) {},
    UpdateLead(
      state: LeadState,
      action: PayloadAction<{
        values: Partial<Lead>;
        saveForLater?: boolean;
        step?: Steps;
      }>
    ) {
      state.isUpdating = true;
    },
    SetCanEditClient(state: LeadState, action: PayloadAction<boolean>) {
      state.canEditClient = action.payload;
    },
    SetStep(state: LeadState, action: PayloadAction<{ step: Steps }>) {
      state.step = action.payload.step;
    },
    StopUpdatingLead(state: LeadState) {
      state.isUpdating = false;
    },
    UpdateCurrentLead(state: LeadState, action: PayloadAction<Partial<Lead>>) {
      state.lead = { ...state.lead, ...action.payload };
    },
    RedirectToLeadSteps: (state: LeadState, action: PayloadAction<string>) => {},
    FindCompany: (
      state: LeadState,
      action: PayloadAction<{ value: string; type: VisionNetInputTypes }>
    ) => {
      state.companiesLoading = true;
    },
    FindCompanyComplete: (state: LeadState, action: PayloadAction<VisionNetCompany[]>) => {
      state.companies = action.payload;
      state.companiesLoading = false;
    },
    ResetLead(state: LeadState) {
      return initialState;
    },
    ResetCartell: (state: LeadState) => {
      state.cartell = undefined;
    },
    SetAssetType: (state: LeadState, action: PayloadAction<{ assetType: AssetType }>) => {
      if (state.lead) state.lead.assetType = action.payload.assetType;
    },
    SetIsSearchingForAsset: (
      state: LeadState,
      action: PayloadAction<{ isSearchingForAsset: boolean }>
    ) => {
      state.isSearchingForAsset = action.payload.isSearchingForAsset;
    },
    SetCartell: (state: LeadState, action: PayloadAction<{ cartell: CartellAssetResponse }>) => {
      state.cartell = action.payload.cartell;
    },
    SearchCartell: (state: LeadState, action: PayloadAction<{ registrationNumber: string }>) => {},
    SetSearchAssetMode: (state: LeadState, action: PayloadAction<{ searchInCartell: boolean }>) => {
      state.searchInCartell = action.payload.searchInCartell;
    },
    SetIsTimeWithEmployerLessThanOneYear(state: LeadState, action: PayloadAction<boolean>) {
      state.isTimeWithEmployerLessThanOneYear = action.payload;
    },
    SearchEircode: (
      state: LeadState,
      action: PayloadAction<{ eircode: string; country: string; isPrevious: boolean }>
    ) => {},
    SetEircode: (
      state: LeadState,
      action: PayloadAction<{
        eircodeResponse: EircodeAddressResponse;
        isPrevious: boolean;
        country: string;
      }>
    ) => {
      const response = action.payload.eircodeResponse;
      state.eircodes[response.eircode] = response;

      if (!action.payload.isPrevious && state.lead) {
        state.lead.country = action.payload.country;
        state.lead.address_2 = response.address_2;
        state.lead.address_3 = response.address_3;
        state.lead.street = response.address_1;
        state.lead.county = response.county;
        state.lead.zipCode = response.eircode;
      } else if (action.payload.isPrevious && state.lead) {
        state.lead.previousCountry = action.payload.country;
        state.lead.previousAddress_2 = response.address_2;
        state.lead.previousAddress_3 = response.address_3;
        state.lead.previousAddress_1 = response.address_1;
        state.lead.previousCounty = response.county;
        state.lead.previousEircode = response.eircode;
      }
    },
  },
});

export const leadActions = {
  ...lead.actions,
};

export const leadSelector = (state: State) => state[lead.name] as LeadState;

function* handleLead() {
  const crmService = container.resolve(CrmService);
  const _t = getT();

  yield takeEvery(leadActions.LoadLead, function* (action) {
    try {
      const response: Lead = yield crmService.fetchLead(action.payload.id);
      yield put(leadActions.UpdateCurrentLead(response));
      yield put(leadActions.SetCanEditClient(false));

      const isLessThanOneYear =
        response.timeWithEmployerYears != undefined &&
        response.timeWithEmployerMonths != undefined &&
        Number(response.timeWithEmployerYears || 0) +
          Math.floor(Number(response.timeWithEmployerMonths || 0) / 12) <
          1;

      yield put(leadActions.SetIsTimeWithEmployerLessThanOneYear(isLessThanOneYear));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'application' })));
    }
  });

  yield takeEvery(leadActions.UpdateLead, function* (action) {
    try {
      const { lead }: LeadState = yield select(leadSelector);
      if (lead && lead.id) {
        const { values, saveForLater, step } = action.payload;
        yield crmService.updateLead(lead.id, values);
        yield put(leadActions.SetCanEditClient(false));
        yield put(leadActions.StopUpdatingLead());

        if (saveForLater) {
          yield put(routingActions.Navigate({ route: Route.Dashboard, params: {} }));
          return;
        }

        yield put(leadActions.UpdateCurrentLead(values));
        yield put(leadActions.SetStep({ step: step || Steps.CUSTOMER }));
      }
    } catch (err) {
      captureException(err);
      yield put(leadActions.StopUpdatingLead());
      yield put(notifyError(_t('notification.submitLeadFailed')));
    }
  });

  yield takeEvery(leadActions.CreateLead, function* (action) {
    try {
      yield put(leadActions.SetCanEditClient(false));
      const response: CreateLeadResponse = yield crmService.createLead(action.payload);
      yield put(leadActions.RedirectToLeadSteps(response.details.id));
      yield put(leadActions.UpdateCurrentLead({ ...action.payload, id: response.details.id }));
    } catch (err) {
      captureException(err);
      yield put(leadActions.SetCanEditClient(true));
      yield put(notifyError(_t('notification.createApplicationFailed')));
    }
  });
}

function* handleRedirect() {
  yield takeEvery(leadActions.RedirectToLeadSteps, function* (action) {
    try {
      yield put(
        routingActions.Navigate({ route: Route.LoanApplication, params: { id: action.payload } })
      );
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.redirectToLeadStepsFailed')));
    }
  });
}

function* handleThirdParty() {
  const thirdPartyService = container.resolve(ThirdPartyService);
  const _t = getT();

  yield takeLatest(leadActions.FindCompany, function* (action) {
    try {
      const response: VisionNetCompany[] = yield thirdPartyService.searchVisionNet(
        action.payload.value,
        action.payload.type
      );
      yield put(leadActions.FindCompanyComplete(response));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.visionNetSearchError')));
    }
  });

  yield takeEvery(leadActions.SearchCartell, function* (action) {
    try {
      const response: CartellAssetResponse = yield thirdPartyService.searchCartell(
        action.payload.registrationNumber
      );

      yield put(
        leadActions.SetCartell({
          cartell: { ...response, registrationNumber: action.payload.registrationNumber },
        })
      );

      yield put(leadActions.SetSearchAssetMode({ searchInCartell: false }));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.assetDetailsSearch')));
    }
    yield put(leadActions.SetIsSearchingForAsset({ isSearchingForAsset: false }));
  });

  yield takeEvery(leadActions.SearchEircode, function* (action) {
    const leadState: LeadState = yield select(leadSelector);
    try {
      if (!leadState.eircodes[action.payload.eircode]) {
        var response: EircodeAddressResponse = yield thirdPartyService.searchEircode(
          action.payload.eircode
        );

        // If address_4 is not the eircode itself we want to append it to address_3
        response.address_3 =
          response.address_4 &&
          response.address_4.replaceAll(' ', '').toLowerCase() !== response.eircode.toLowerCase()
            ? `${response.address_3}, ${response.address_4}`
            : response.address_3;
        response.county = capitalCase(response.county);
      } else {
        var response = leadState.eircodes[action.payload.eircode];
      }

      yield put(
        leadActions.SetEircode({
          eircodeResponse: response,
          isPrevious: action.payload.isPrevious,
          country: IRELAND_CONF,
        })
      );
    } catch (err) {
      captureException(err);
      const response = (err as any)?.response;
      if (response && response.status === 422) {
        yield put(notifyError(_t('notification.eircodeSearchInvalid')));
      } else if (response && response.status === 404) {
        yield put(notifyError(_t('notification.eircodeSearchNotFound')));
      } else {
        yield put(notifyError(_t('notification.eircodeSearchFailed')));
      }
    }
  });
}

export function* leadSaga() {
  yield fork(handleLead);
  yield fork(handleRedirect);
  yield fork(handleThirdParty);
}

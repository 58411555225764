import { fork, put, select, takeEvery } from '@redux-saga/core/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { constantCase } from 'change-case';
import { DocumentsResponse, FileCategories, UploadedFile } from 'models/File';
import { notifyError } from 'modules/notification/store';
import { takeLeading } from 'redux-saga/effects';
import { DocumentsService } from 'services/DocumentsService';
import { container } from 'tsyringe';
import { getT } from 'utils/framework/intl';
import { captureException } from 'utils/reporting';
import { State } from 'utils/store';
import { userTypeSelector } from '../auth/authStore';
import { UserType } from '../../models/User';

export interface Filter {
  fileType: string;
}

export interface DocumentsState {
  documents: DocumentsResponse;
  filter: Filter;
  isLoading: boolean;
}

export const availableToUserDocumentTypes = [
  FileCategories.BANK_STATEMENTS,
  FileCategories.TAX_CLEARANCE_CERTIFICATE,
  FileCategories.STATEMENT_OF_AFFAIRS,
  FileCategories.ACCOUNTS,
  FileCategories.PROPOSAL,
  FileCategories.INVOICE,
  FileCategories.WELCOME_PACK,
  FileCategories.CREDIT_REPORT,
  FileCategories.UCGS_ELIGIBILITY_DOCUMENT,
  FileCategories.PAYSLIPS,
  FileCategories.VAT_SCHEDULE,
  FileCategories.ANNUAL_CF_STATEMENTS,
  FileCategories.BALANCE_CERT,
  FileCategories.INTEREST_CERT,
  FileCategories.LAD_LETTERS,
  FileCategories.STATEMENTS,
  FileCategories.CLEARANCE_LETTER,
  FileCategories.VARIATION_LETTER,
];

export const availableToBorrowerDocumentTypes = [
  FileCategories.BANK_STATEMENTS,
  FileCategories.TAX_CLEARANCE_CERTIFICATE,
  FileCategories.STATEMENT_OF_AFFAIRS,
  FileCategories.ACCOUNTS,
  FileCategories.PROPOSAL,
  FileCategories.WELCOME_PACK,
  FileCategories.UCGS_ELIGIBILITY_DOCUMENT,
  FileCategories.PAYSLIPS,
  FileCategories.VAT_SCHEDULE,
  FileCategories.ANNUAL_CF_STATEMENTS,
  FileCategories.BALANCE_CERT,
  FileCategories.INTEREST_CERT,
  FileCategories.LAD_LETTERS,
  FileCategories.STATEMENTS,
  FileCategories.CLEARANCE_LETTER,
  FileCategories.VARIATION_LETTER,
];

const initialState: DocumentsState = {
  documents: {
    results: [],
    total: 0,
    hasMore: false,
    offset: 0,
    limit: 0,
  },
  filter: {
    fileType: '',
  },
  isLoading: false,
};

export const documents = createSlice({
  name: 'documents',
  initialState: initialState,
  reducers: {
    FetchDocuments: (state: DocumentsState, action: PayloadAction<{ offset?: number }>) => {
      state.isLoading = true;
    },
    FetchDocumentsComplete: (
      state: DocumentsState,
      action: PayloadAction<{ documents: DocumentsResponse }>
    ) => {
      state.documents = action.payload.documents;
      state.isLoading = false;
    },
    SetFilter: (state: DocumentsState, action: PayloadAction<Filter>) => {
      state.filter = action.payload;
    },
    DownloadDocument: (state: DocumentsState, action: PayloadAction<string>) => {},
  },
});

export const documentsActions = {
  ...documents.actions,
};

function* handleMyDocumentsData() {
  const documentService = container.resolve(DocumentsService);
  const _t = getT();

  yield takeLeading(documentsActions.FetchDocuments, function* (action) {
    const { filter }: DocumentsState = yield select(documentsSelector);
    const userType: UserType = yield select(userTypeSelector);
    const currentOffset = action.payload.offset ? action.payload.offset : 0;

    const availableDocumentTypes = getDocumentTypesForUser(userType);

    const requestedTypes = !!filter?.fileType
      ? [filter.fileType]
      : availableDocumentTypes.map(type => constantCase(type));

    try {
      const documents: DocumentsResponse = yield documentService.getUserDocuments({
        offset: currentOffset,
        types: requestedTypes,
      });

      yield put(documentsActions.FetchDocumentsComplete({ documents: documents }));
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.fetchFailed', { data: 'documents' })));
    }
  });
}

function* handleDownloadDocument() {
  const documentService = container.resolve(DocumentsService);
  const _t = getT();

  yield takeEvery(documentsActions.DownloadDocument, function* ({ payload }) {
    try {
      const response: UploadedFile = yield documentService.getFileUrl(payload);
      const urlElement = document.createElement('a');
      urlElement.target = '_blank';
      urlElement.href = response.url || '';

      urlElement.click();
      urlElement.remove();
    } catch (err) {
      captureException(err);
      yield put(notifyError(_t('notification.downloadFailed', { data: 'document' })));
    }
  });
}

export function* documentsSaga() {
  yield fork(handleMyDocumentsData);
  yield fork(handleDownloadDocument);
}

export const documentsSelector = (state: State) => state[documents.name] as DocumentsState;

export const getDocumentTypesForUser = (userType?: UserType) => {
  return userType === UserType.Borrower
    ? availableToBorrowerDocumentTypes
    : availableToUserDocumentTypes;
};

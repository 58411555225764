import React from 'react';
import styled from '@emotion/styled';
import { useConnect } from 'utils/framework';
import { CheckoutPageViewModel } from './CheckoutPageViewModel';
import { CheckoutBox } from './components/CheckoutBox';
import { CheckoutCompleteBox } from './components/CheckoutCompleteBox';
import { routingSelector } from 'modules/routing/store';
import { CheckoutCompleteViewModel } from './CheckoutCompleteViewModel';

interface Props {
  className?: string;
}

const CheckoutCompletePageComponent = ({ className }: Props) => {
  const vm = useConnect(CheckoutCompleteViewModel);

  return (
    <CheckoutCompleteBox
      className={className}
      amountPaid={vm.state.amountPaid}
    ></CheckoutCompleteBox>
  );
};

export const CheckoutCompletePage = styled(CheckoutCompletePageComponent)``;

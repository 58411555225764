import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Breakpoints, useBreakpoint } from 'utils/styling';
import { Button } from 'modules/ui-components/Button';
import { getT } from 'utils/framework/intl';
import { formatCurrency, zohoLoanProductLabel } from 'utils/string';
import { ApplicationAssets } from './ApplicationAssets';
import { format } from 'date-fns/fp';
import { Arrow } from 'modules/ui-components/Arrow';
import {
  Application as ApplicationType,
  ApplicationStatus,
  FinishedApplicationStatuses,
} from 'models/Application';
import { ApplicationsViewModel } from './ApplicationsViewModel';

interface Props {
  className?: string;
  application: ApplicationType;
  vm: ApplicationsViewModel;
}

const ApplicationComponent = ({ className, application, vm }: Props) => {
  const _t = getT();
  const [isAssetsOpen, setIsAssetsOpen] = useState(false);
  const [isLaptop] = useBreakpoint(Breakpoints.laptop, { useBody: true });
  const isOnFinishedStage = FinishedApplicationStatuses.includes(application.stage);

  const sections = [
    [
      { label: _t('loans.loanType'), value: zohoLoanProductLabel(application.loanProduct) },
      { label: _t('loans.applicationNumber'), value: application.applicationNumber },
    ],
    [
      {
        label: _t('loans.financeAmount'),
        value: application.financeAmount && formatCurrency(application.financeAmount),
      },
      { label: _t('loans.term'), value: application.term },
    ],
    [
      {
        label: _t('loans.createdDate'),
        value: application.createdTime && format('dd MMM yyyy', new Date(application.createdTime)),
      },
    ],
  ];

  useEffect(() => {
    if (!application.assets && isAssetsOpen) {
      vm.getApplicationAssets(application.id);
    }
  }, [isAssetsOpen, application, vm]);

  return (
    <div className={className}>
      <div className="top">
        {isLaptop && (
          <div className="openDetails">
            <div className="openDetails--arrow" onClick={() => setIsAssetsOpen(!isAssetsOpen)}>
              <Arrow open={isAssetsOpen} isLaptop={isLaptop} />
            </div>
          </div>
        )}

        <div className="informations">
          <div className="header">
            <p className="header-title tooltip value">{application.contactName.name}</p>
            <div className="header-status">
              <p className="value">{application.portalStage || _t('loans.notAvailable')}</p>
              <p className="label">{_t('loans.status')}</p>
            </div>
          </div>
          {sections.map((section, i) => (
            <div key={i} className="section">
              {section.map(({ label, value }) => (
                <div className="labeled-value" key={label}>
                  <div className="value value--small">{value || _t('loans.notAvailable')}</div>
                  <p className="label">{label}</p>
                </div>
              ))}
            </div>
          ))}
        </div>

        {!isOnFinishedStage && (
          <div className="actions">
            <Button
              onClick={() => vm.uploadDocuments(application.id)}
              className="broker-primary broker-primary--simple"
            >
              {_t('loans.uploadDocuments')}
            </Button>
          </div>
        )}
      </div>
      {isAssetsOpen && <ApplicationAssets assets={application.assets} />}

      {!isLaptop && (
        <div className="openDetails--mobile" onClick={() => setIsAssetsOpen(!isAssetsOpen)}>
          <Arrow open={isAssetsOpen} isLaptop={isLaptop} />
        </div>
      )}
    </div>
  );
};

export const Application = styled(ApplicationComponent)`
  margin-bottom: 1rem;
  border: var(--border-default);
  border-radius: var(--border-radius);

  .top {
    display: flex;
    gap: 1rem;
    padding: 1rem;

    @media (max-width: ${Breakpoints.laptop}px) {
      flex-direction: column;
    }

    .openDetails {
      min-width: 2rem;
      padding: 0.5rem;
      &--arrow {
        margin-top: 2.375rem;
        cursor: pointer;
      }
    }

    .informations {
      flex: 1;
      gap: 1rem;
      display: grid;
      grid-template-areas: 'header header header';
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

      @media (max-width: ${Breakpoints.laptop}px) {
        grid-template-areas: 'header';
        grid-template-columns: 1fr;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        grid-area: header;
        padding-bottom: 0.375rem;
        border-bottom: 1px solid var(--primary-dark-blue-50);

        &-title {
          color: var(--primary-dark-blue-500);
          flex: 2;
        }

        &-status {
          flex: 1;

          .label {
            margin: 0;
          }

          @media (max-width: ${Breakpoints.laptop}px) {
            flex: 2;
            text-align: right;
          }
        }
      }

      .section {
        border-right: 1px solid var(--primary-dark-blue-50);
        padding-right: 1rem;

        @media (max-width: ${Breakpoints.laptop}px) {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          padding-right: 0;
          border-right: none;

          .labeled-value:nth-of-type(2n) {
            text-align: right;
          }
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding-left: 1rem;
      border-left: 1px solid var(--primary-dark-blue-50);

      @media (max-width: ${Breakpoints.laptop}px) {
        padding: 0;
        margin: 0;
        border-left: none;
      }

      button {
        height: 2rem;
        @media (max-width: ${Breakpoints.laptop}px) {
          height: auto;
        }
      }
    }
  }

  .openDetails--mobile {
    display: grid;
    place-items: center;
    padding: 1rem;
    border-top: 1px solid var(--primary-dark-blue-50);
    cursor: pointer;
  }

  .tooltip {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
  }

  .label {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-300);
    margin: 0 0 0.5rem 0;
  }

  .value {
    font-family: var(--font-family-secondary);
    color: var(--primary-blue-500);
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin: 0;

    &--small {
      margin-top: 0.375rem;
      color: var(--primary-blue-500);
      font-size: 0.75rem;
      font-weight: 600;
      font-family: var(--font-family-primary);
      line-height: 1.67;
      letter-spacing: normal;
    }
  }
`;

import { PhoneNumberPrefixes } from 'models/Loan';

// Irish Phone number must start with +353 followed by 83, 85, 86, 87 or 89 followed by 6 numbers
export const IrishSMSFormat = /^\+353(83|85|86|87|89).(\d){6}$/;

// UK Phone number must start with +44 followed by a 7, followed by 9 numbers
export const UKSMSFormat = /^\+44(7).(\d){8}$/;

export const CRIFPhoneFormat = /\+?^(353)(\d){8,}$|\+?^(08)(\d){8}$/;

export const isValidYearOfManufacture = (value?: string | null): boolean => {
  if (value) {
    return parseInt(value) >= 2000 && parseInt(value) <= new Date().getFullYear() + 1;
  }
  return true;
};

export const haveOneDigitRegex = /\d/;
export const haveOneLetterRegex = /[a-zA-Z]/;

export const minPasswordLength = 8;
export const maxPasswordLength = 26;

export const isValidPhoneFormat = (value?: string): boolean => {
  if (!value) return false;

  if (value.startsWith(PhoneNumberPrefixes.Ireland)) {
    return IrishSMSFormat.test(value);
  } else if (value.startsWith(PhoneNumberPrefixes.England)) {
    return UKSMSFormat.test(value);
  }

  return false;
};

export const isLessThanNumberOfYears = (years: string | null | undefined, months: string | null | undefined, numberOfYears: number = 1) : boolean => {
  if (years == undefined && months == undefined) return false;
  if (years?.trim() === "" && months?.trim() === "") return false;
  return Number(years || 0) + Math.floor(Number(months || 0) / 12) < numberOfYears;
};

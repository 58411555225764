import { ComponentClass, createElement, FC, useEffect, useState } from 'react';
import { container } from 'tsyringe';
import { ViewModel } from './ViewModel';

export function connect(token: typeof ViewModel) {
  return (target: FC<{ vm: ViewModel }> | ComponentClass<{ vm: ViewModel }>) => {
    const vm = container.resolve(token as any);
    if (!(vm instanceof ViewModel)) {
      throw new Error('VM must be an instance of ViewModel');
    }

    return (props: any) => {
      const vm = useConnect(token as any);
      return createElement(target, { ...props, vm, __connected: vm.state });
    };
  };
}

export function useConnect<T extends typeof ViewModel>(token: T): InstanceType<T> {
  const [, triggerUpdate] = useState({});
  const [vm] = useState(() => {
    const vm = container.resolve(token as any);
    if (!(vm instanceof ViewModel)) {
      throw new Error('VM must be an instance of ViewModel');
    }
    vm._onStateChange();

    return vm;
  });

  useEffect(() => {
    const disconnect = vm._connect(() => {
      triggerUpdate({}); // Create a new reference here to trigger the rerender
    });
    triggerUpdate({}); // Refresh the component as the state could have changed on connect

    return disconnect;
  }, [vm]);

  return vm as any;
}

import { container, instanceCachingFactory } from 'tsyringe';
import { initStore, STORE_DI_TOKEN } from 'utils/store';
import { createIntl, createIntlCache } from 'react-intl';
import { messages } from 'translations';
import { PSInit } from './function';
import { Auth0 } from 'modules/auth/authStore';
import { CrmService } from 'services/CrmService';
import { AccountsService } from 'services/AccountsService';
import { DocumentsService } from 'services/DocumentsService';
import { ThirdPartyService } from 'services/ThirdPartyService';
import { PaymentsService } from 'services/PaymentsService';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_DI_TOKEN } from './stripe';
import { useStripe } from '@stripe/react-stripe-js';

declare global {
  interface Window {
    $zoho: { salesiq?: { widgetcode?: string; ready: () => void; values: {} } };
  }
}

export const INTL_DI_TOKEN = 'intl';
export const PAGESENSE_TOKEN = 'pagesense';
export const CHATBOT_TOKEN = 'chatbot';

export function registerServices() {
  const CrmServiceUrl = process.env.REACT_APP_CRM_SERVICE;
  const AccountsServiceUrl = process.env.REACT_APP_ACCOUNTS_SERVICE;
  const DocsServiceUrl = process.env.REACT_APP_DOCUMENTS_API_URL;
  const ThirdPartyServiceUrl = process.env.REACT_APP_THIRD_PARTY_API_URL;
  const PaymentsServiceUrl = process.env.REACT_APP_PAYMENTS_API_URL;
  const StripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  container.register(Auth0, { useFactory: instanceCachingFactory(() => new Auth0()) });
  container.register(CrmService, {
    useFactory: instanceCachingFactory(() => new CrmService(CrmServiceUrl)),
  });
  container.register(AccountsService, {
    useFactory: instanceCachingFactory(() => new AccountsService(AccountsServiceUrl)),
  });
  container.register(DocumentsService, {
    useFactory: instanceCachingFactory(() => new DocumentsService(DocsServiceUrl)),
  });
  container.register(ThirdPartyService, {
    useFactory: instanceCachingFactory(() => new ThirdPartyService(ThirdPartyServiceUrl)),
  });
  container.register(PaymentsService, {
    useFactory: instanceCachingFactory(() => new PaymentsService(PaymentsServiceUrl)),
  });
  container.register(STRIPE_DI_TOKEN, {
    useFactory: instanceCachingFactory(() => loadStripe(StripePublishableKey || '')),
  });
  container.register(STORE_DI_TOKEN, { useFactory: instanceCachingFactory(initStore) });
  container.register(INTL_DI_TOKEN, { useFactory: instanceCachingFactory(initIntl) });
  container.register(PAGESENSE_TOKEN, { useFactory: instanceCachingFactory(pagesenseInit) });
  container.register(CHATBOT_TOKEN, { useFactory: instanceCachingFactory(chatbotInit) });
}

const initIntl = () => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale: 'en-GB',
      messages,
    },
    cache
  );
};

export const pagesenseInit = () => {
  return PSInit(window, 'script');
};

const chatbotInit = () => {
  const appWindow = window as Window;

  appWindow.$zoho = {};
  appWindow.$zoho.salesiq = appWindow.$zoho.salesiq || {
    widgetcode: process.env.REACT_APP_CHATBOT_TOKEN,
    values: {},
    ready: function () {},
  };

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'zsiqscript';
  script.defer = true;
  script.src = 'https://salesiq.zoho.eu/widget';
  const t = document.getElementsByTagName('script')[0];
  if (t.parentNode) {
    t.parentNode.insertBefore(script, t);
  }
};

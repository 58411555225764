import styled from '@emotion/styled';
import { Lead } from 'models/Lead';
import React from 'react';
import { _t } from 'utils/string';
import { Breakpoints } from 'utils/styling';

interface Props {
  lead: Lead;
  className?: string;
}

export const LeadDetailsContainer = ({ lead, className }: Props) => {
  const detailsAvailable = lead.street && lead.address_2 && lead.county && lead.country;

  if (!detailsAvailable) {
    return (
      <div className={className}>
        <p className="noData">{_t('leads.noLeadDetails')}</p>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="desc">
        <p className="label">{_t('loans.address1')}</p>
        <p className="value">{lead.street || _t('loans.notAvailable')}</p>
      </div>
      <div className="desc">
        <p className="label">{_t('loans.address2')}</p>
        <p className="value">{lead.address_2 || _t('loans.notAvailable')}</p>
      </div>
      <div className="desc">
        <p className="label">{_t('loans.address3')}</p>
        <p className="value">{lead.address_3 || _t('loans.notAvailable')}</p>
      </div>
      <div className="desc">
        <p className="label">{_t('loans.county')}</p>
        <p className="value">{lead.county || _t('loans.notAvailable')}</p>
      </div>
      <div className="desc">
        <p className="label">{_t('loans.country')}</p>
        <p className="value">{lead.country || _t('loans.notAvailable')}</p>
      </div>
    </div>
  );
};

export const LeadDetails = styled(LeadDetailsContainer)`
  display: grid;
  padding: 1rem;
  background-color: var(--primary-dark-blue-25);
  border-top: var(--border-default);
  gap: 1rem;

  .desc {
    .label {
      margin-bottom: 0.25rem;
      font-size: 0.75rem;
      line-height: 1.67;
      color: var(--primary-dark-300);
    }

    .value {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.71;
      color: var(--primary-dark-blue-900);
      word-break: break-all;
    }
  }

  .noData {
    margin: 0;
    color: var(--primary-dark-300);
    font-size: 0.875rem;
    text-align: center;

    @media only screen and (min-width: ${Breakpoints.laptop}px) {
      grid-area: noData;
      text-align: left;
    }
  }

  @media only screen and (min-width: ${Breakpoints.laptop}px) {
    grid-template-areas: 'noData noData noData noData';
    grid-template-columns: repeat(4, 1fr);
    padding: 1.5rem 1.5rem 1.5rem 4rem;
  }
`;

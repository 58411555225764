import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { NotificationState, notificationSelector, notificationActions } from './store';
import { Notification } from 'models/Notification';

interface State {
  notifications: Notification[];
}

export class NotificationViewModel extends ViewModel {
  state!: NotificationState;

  protected stateMapper = createSelector(
    notificationSelector,
    (notification: NotificationState): State => {
      return {
        notifications: notification.notifications,
      };
    }
  );

  clearNotifications = () => {
    this.store.dispatch(notificationActions.ClearNotifications());
  };
}

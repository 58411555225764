import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { auth0Selector } from 'modules/auth/authStore';
import { hasPermission, Permission } from 'modules/auth/services/PermissionsService';

@singleton()
@autobind
export class DashboardViewModel extends ViewModel {
  unsubscribe!: () => void;
  state!: {
    canSeeLoans: boolean;
    canSeeLeads: boolean;
    canSeeApplications: boolean;
  };

  protected stateMapper = createSelector(auth0Selector, auth => ({
    canSeeLoans: hasPermission(Permission.CanSeeLoans, auth.user?.permissions),
    canSeeLeads: hasPermission(Permission.CanSeeLeads, auth.user?.permissions),
    canSeeApplications: hasPermission(Permission.CanSeeApplications, auth.user?.permissions),
  }));
}

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  open?: boolean;
  className?: string;
  slideFrom?: 'left' | 'right';
  onClose: () => void;
}

const DrawerComponent: React.FC<Props> = ({
  children,
  className,
  open,
  slideFrom,
  onClose,
}: Props) => {
  const transitionRef = React.useRef(null);

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    open && document.addEventListener('keydown', keyListener);
    return () => document.removeEventListener('keydown', keyListener);
  }, [open, onClose]);

  return ReactDOM.createPortal(
    <CSSTransition in={open} timeout={300} mountOnEnter unmountOnExit nodeRef={transitionRef}>
      <div
        className={classNames(className, { slideRight: slideFrom === 'right' })}
        ref={transitionRef}
      >
        <div className="drawer">{children}</div>
        <div className="backdrop" onClick={() => onClose()} />
      </div>
    </CSSTransition>,
    document.body
  );
};

export const Drawer = styled(DrawerComponent)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
  transition-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
  }

  .drawer {
    position: absolute;
    overflow: auto;
    z-index: 100;
    height: 100%;
  }

  &.enter {
    .backdrop {
      opacity: 0;
    }

    .drawer {
      transform: translateX(-100%);
    }
  }

  &.enter-active {
    .backdrop {
      opacity: 0.5;
      transition: opacity 0.3s;
    }
    .drawer {
      transform: translateX(0);
      transition: transform 0.3s;
    }
  }

  &.exit {
    .backdrop {
      opacity: 0.5;
    }

    .drawer {
      transform: translateX(0);
    }
  }

  &.exit-active {
    .backdrop {
      opacity: 0;
      transition: opacity 0.3s;
    }

    .drawer {
      transform: translateX(-100%);
      transition: transform 0.3s;
    }
  }

  &.slideRight {
    .drawer {
      right: 0;
    }

    &.enter .drawer {
      transform: translateX(100%);
    }

    &.enter-active .drawer {
      transform: translateX(0);
    }

    &.exit-active .drawer {
      transform: translateX(100%);
    }
  }
`;

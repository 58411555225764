import autobind from 'autobind-decorator';
import { container, singleton } from 'tsyringe';
import { ViewModel } from 'utils/framework';
import { createSelector } from '@reduxjs/toolkit';
import { FinanceDetailsValues, Steps, ZohoLoanProduct } from 'models/Application';
import { getT } from 'utils/framework/intl';
import {
  CalculatorService,
  MAX_TERM,
  MAX_TERM_PERSONAL_MOTOR_FINANCE,
  MIN_TERM,
} from 'modules/leads/loan-calculator/CalculatorService';
import { extendObject } from 'utils/object';
import * as Yup from 'yup';
import { isBorrowerSelector, isBrokerSelector, isDealerSelector } from 'modules/auth/authStore';
import { Lead, LeadStage } from 'models/Lead';
import { leadActions, leadSelector } from '../../store';

@singleton()
@autobind
export class FinanceDetailsViewModel extends ViewModel {
  state!: {
    lead: Lead;
    isBroker: boolean;
    isDealer: boolean;
    isBorrower: boolean;
    currentLead: Partial<Lead>;
    isLeadUpdating: boolean;
    step: Steps;
  };
  calculatorService = container.resolve(CalculatorService);

  get validationSchema() {
    const _t = getT();

    return Yup.object({
      term: Yup.number()
        .min(MIN_TERM, _t('form.min', { min: MIN_TERM }))
        .when('loanProduct', {
          is: ZohoLoanProduct.PersonalMotorFinance,
          then: Yup.number().max(
            MAX_TERM_PERSONAL_MOTOR_FINANCE,
            _t('form.max', { max: MAX_TERM_PERSONAL_MOTOR_FINANCE })
          ),
          otherwise: Yup.number().max(MAX_TERM, _t('form.max', { max: MAX_TERM })),
        })
        .required(_t('form.required')),
      reasonForPurchase: Yup.string()
        .max(2000, _t('form.lengthMax', { len: 2000 }))
        .required(_t('form.required')),
      consentConfirmed: Yup.boolean().test(
        'required',
        _t('form.required'),
        value => value === true
      ),
      timeline: Yup.string().when('loanProduct', {
        is: (loanProduct: string) => loanProduct === ZohoLoanProduct.PersonalMotorFinance,
        then: Yup.string().required(_t('form.required')),
        otherwise: Yup.string(),
      }),
      financeAmount: Yup.number().min(5000, _t('form.minTotal', { min: 5000 })),
      netCost: Yup.number().min(5000, _t('form.min', { min: 5000 })),
      ucgsEligibilityCode: Yup.string().max(30, _t('form.max', { max: 30 })),
    });
  }

  get formInitialValues(): FinanceDetailsValues {
    const calculatorDefaults = this.calculatorService.formInitialValues;
    const financeDefaults = {
      deposit: 0,
      tradeIn: 0,
      settlement: this.formOptions.settlement[0].value,
      settlementAmount: 0,
      commission: 0,
      consentConfirmed: false,
      reasonForPurchase: '',
      ucgsEligibilityCode: '',
      ucgsProduct: false,
      timeline: '',
    };
    return extendObject(
      { ...calculatorDefaults, ...financeDefaults },
      this.leadToForm(this.state.currentLead) as FinanceDetailsValues
    );
  }

  get formOptions() {
    return this.calculatorService.calculatorFormOptions;
  }

  leadToForm(lead: Partial<Lead>): Partial<FinanceDetailsValues> {
    return {
      loanProduct: lead.product,
      monthlyPayment: lead.monthlyRepayments,
      term: lead.term,
      netCost: lead.assetCost,
      deposit: lead.deposit,
      tradeIn: lead.tradeIn,
      commission: lead.commission,
      settlement: !!lead.settlement,
      settlementAmount: lead.settlement,
      reasonForPurchase: lead.reasonForPurchase,
      vatExempt: lead.vatExempt,
      ucgsEligibilityCode: lead.ucgsEligibilityCode,
      ucgsProduct: lead.ucgsProduct,
      timeline: lead.timeline,
    };
  }

  formToLead(values: FinanceDetailsValues, saveForLater: boolean, prev: boolean): Partial<Lead> {
    const isPersonalMotorFinance = values.loanProduct === ZohoLoanProduct.PersonalMotorFinance;

    let leadData: Partial<Lead> = {
      product: values.loanProduct,
      monthlyRepayments: values.monthlyPayment,
      term: values.term,
      assetCost: values.netCost,
      deposit: values.deposit,
      tradeIn: values.tradeIn,
      reasonForPurchase: values.reasonForPurchase,
      settlement: values.settlement ? values.settlementAmount : undefined,
    };

    if (!isPersonalMotorFinance) {
      leadData = {
        ...leadData,
        commission: values.commission,
        vatExempt: values.vatExempt,
        ucgsProduct: values.ucgsProduct,
        ucgsEligibilityCode: values.ucgsEligibilityCode,
      };
    } else {
      leadData = {
        ...leadData,
        ucgsProduct: false,
        ucgsEligibilityCode: '',
        timeline: values.timeline !== '' ? values.timeline : undefined,
      };
    }

    if (
      (this.state.currentLead.leadStage === LeadStage.Draft || !this.state.currentLead.leadStage) &&
      !saveForLater &&
      !prev
    ) {
      leadData = { ...leadData, leadStage: LeadStage.Submitted };
    }

    return leadData;
  }

  submit(
    values: FinanceDetailsValues,
    { saveForLater, step, prev }: { saveForLater?: boolean; prev?: boolean; step?: Steps }
  ) {
    this.store.dispatch(
      leadActions.UpdateLead({
        values: this.formToLead(values, !!saveForLater, !!prev),
        step,
        saveForLater,
      })
    );
  }

  protected stateMapper = createSelector(
    leadSelector,
    isBrokerSelector,
    isDealerSelector,
    isBorrowerSelector,
    (leads, isBroker, isDealer, isBorrower) => ({
      lead: leads.lead,
      isBroker,
      isDealer,
      isBorrower,
      currentLead: leads.lead,
      isLeadUpdating: leads.isUpdating,
      step: leads.step,
    })
  );
}

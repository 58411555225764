import { ReactComponent as SaveForLater } from 'assets/icons/save-for-later.svg';
import { Button } from 'modules/ui-components/Button';
import React from 'react';
import { getT } from 'utils/framework/intl';
import { Spinner } from 'modules/ui-components/Spinner';
import { Link } from 'modules/ui-components/Link';
import { Route } from 'modules/routing';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';

interface Props {
  submitElement?: React.ReactElement;
  saveForLater?: () => void;
  goPrev?: () => void;
  goNext?: () => void;
  backToDashboard?: boolean;
  className?: string;
  loading?: boolean;
}

const FooterButtonsComponent = ({
  saveForLater,
  goPrev,
  goNext,
  backToDashboard,
  className,
  loading,
  submitElement,
}: Props) => {
  const _t = getT();

  const submitButton = submitElement || (
    <Button
      onClick={goNext}
      className="broker-primary broker-primary--simple"
      type="submit"
      disabled={loading}
    >
      {_t('loans.next')}
    </Button>
  );

  return (
    <div className={className}>
      {saveForLater && (
        <button
          type="button"
          className="save-for-later-icon"
          onClick={saveForLater}
          disabled={loading}
        >
          <SaveForLater />
        </button>
      )}

      <div className="button-panel">
        {loading && <Spinner />}
        {saveForLater && (
          <Button
            type="button"
            className="save-for-later"
            onClick={saveForLater}
            disabled={loading}
          >
            {_t('loans.saveForLater')}
          </Button>
        )}
        {backToDashboard && (
          <Link to={Route.Dashboard}>
            <Button>{_t('loans.backToDashboard')}</Button>
          </Link>
        )}
        {goPrev && (
          <Button type="button" onClick={goPrev} disabled={loading}>
            {_t('loans.prev')}
          </Button>
        )}
        {(goNext || submitElement) && submitButton}
      </div>
    </div>
  );
};

export const FooterButtons = styled(FooterButtonsComponent)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  border-top: var(--border-light);
  background: var(--primary-dark-000);
  display: flex;
  justify-content: space-between;
  min-height: 4.25rem;

  ${Button} {
    min-width: 6.25rem;
    width: auto;
    margin-left: 1rem;
  }

  .button-panel {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .save-for-later-icon {
    background: none;
    align-self: center;
    border: none;
    cursor: pointer;
    padding: 0.25rem;
  }

  .save-for-later {
    display: none;
  }

  .loading {
    background: none;
    border: none;
    pointer-events: none;
    width: 24px;
  }

  @media only screen and (min-width: ${Breakpoints.desktopS}px) {
    .save-for-later-icon {
      display: none;
    }

    .save-for-later {
      display: block;
    }

    ${Button} {
      padding: 0.25rem;
      width: 8.5rem;
    }
  }
`;

import { Form, Formik } from 'formik';
import React, { ChangeEvent, useEffect } from 'react';
import { _t } from 'utils/string';
import { FooterButtons } from 'modules/ui-components/FooterButtons';
import { PhoneNumber } from 'modules/ui-components/PhoneNumber';
import { Input } from 'modules/ui-components/Input';
import { Select } from 'modules/ui-components/Select';
import { COUNTRY_COUNTY_MAP, IRELAND_CONF } from 'utils/constants';
import { CurrencyInput } from 'modules/ui-components/CurrencyInput';
import { Title } from '../../utils';
import { EMPLOYMENT_STATUS, HOME_STATUSES, MONTHS_NUMBERS } from '../../leadConstants';
import { useConnect } from 'utils/framework';
import { ClientDetailsViewModel, previousAddressFields, previousEmployerFields } from '../ClientDetailsViewModel';
import { Lead, MotorFinanceLeadDetailsValues } from 'models/Lead';
import { getT } from 'utils/framework/intl';
import * as Yup from 'yup';
import { isLessThanNumberOfYears, isValidPhoneFormat } from 'utils/validators';
import { Steps } from 'models/Application';
import { Button } from 'modules/ui-components/Button';

const formInitialValues = (lead?: Partial<Lead>): MotorFinanceLeadDetailsValues => {
  return {
    phone: lead?.phone || '',
    street: lead?.street || '',
    address_2: lead?.address_2 || '',
    address_3: lead?.address_3 || '',
    country: IRELAND_CONF,
    county: lead?.county || '',
    zipCode: lead?.zipCode || '',
    timeAtAddressYears: lead?.timeAtAddressYears?.toString() || '',
    timeAtAddressMonths: lead?.timeAtAddressMonths?.toString() || '',
    homeStatus: lead?.homeStatus || '',
    occupation: lead?.occupation || '',
    employmentStatus: lead?.employmentStatus || '',
    employer: lead?.employer || '',
    timeWithEmployerYears: lead?.timeWithEmployerYears?.toString() || '',
    timeWithEmployerMonths: lead?.timeWithEmployerMonths?.toString() || '',
    previousEmployer: lead?.previousEmployer || '',
    timeWithPreviousEmployerYears: lead?.timeWithPreviousEmployerYears?.toString() || '',
    timeWithPreviousEmployerMonths: lead?.timeWithPreviousEmployerMonths?.toString() || '',
    incomeNetMonthly: lead?.incomeNetMonthly || 0,
    otherIncomeNetMonthly: lead?.otherIncomeNetMonthly || 0,
    mortgageRentPerMonth: lead?.mortgageRentPerMonth || 0,
    otherBorrowingsPerMonth: lead?.otherBorrowingsPerMonth || 0,
    previousAddress_1: lead?.previousAddress_1 || '',
    previousAddress_2: lead?.previousAddress_2 || '',
    previousAddress_3: lead?.previousAddress_3 || '',
    previousCounty: lead?.previousCounty || '',
    previousEircode: lead?.previousEircode || '',
    livedAtThisAddressForLessThan_3Years: lead?.livedAtThisAddressForLessThan_3Years || false,
  };
};


const validationSchema = (isBorrower: boolean) => {
  const _t = getT();
  const streetMaxLength = 250;
  const addressMaxLength = 255;

  return Yup.object({
    phone: Yup.string()
      .required(_t('form.required'))
      .test({
        name: 'phoneFormat',
        test: value => isValidPhoneFormat(value),
        message: _t('auth.phoneMatchFormat'),
      }),

    street: Yup.string()
      .required(_t('form.required'))
      .max(streetMaxLength, _t('form.max', { max: streetMaxLength })),
    address_2: Yup.string()
      .required(_t('form.required'))
      .max(addressMaxLength, _t('form.max', { max: addressMaxLength })),
    address_3: Yup.string().max(addressMaxLength, _t('form.max', { max: addressMaxLength })),
    county: Yup.string().required(_t('form.required')),
    zipCode: Yup.string()
      .max(16, _t('form.max', { max: 16 }))
      .required(_t('form.required')),
    timeAtAddressYears: Yup.number()
      .integer(_t('form.integer'))
      .min(0, _t('form.min', { min: 0 }))
      .max(99, _t('form.max', { max: 99 }))
      .required(_t('form.required')),
    timeAtAddressMonths: Yup.number().required(_t('form.required')),
    homeStatus: Yup.string().required(_t('form.required')),

    occupation: Yup.string()
      .max(255, _t('form.max', { max: 255 }))
      .required(_t('form.required')),
    employmentStatus: Yup.string().required(_t('form.required')),
    employer: Yup.string().required(_t('form.required')),
    timeWithEmployerYears: Yup.number()
      .integer(_t('form.integer'))
      .min(0, _t('form.min', { min: 0 }))
      .max(99, _t('form.max', { max: 99 }))
      .required(_t('form.required')),
    timeWithEmployerMonths: Yup.number().required(_t('form.required')),
    previousEmployer: Yup.string().max(255, _t('form.max', { max: 255 })).when('timeWithEmployerYears', {
      is: (val: number) => val < 1,
      then: Yup.string().required(_t('form.required')),
    }),
    timeWithPreviousEmployerYears: Yup.number()
      .integer(_t('form.integer'))
      .min(0, _t('form.min', { min: 0 }))
      .max(99, _t('form.max', { max: 99 }))
      .when('timeWithEmployerYears', {
        is: (val: number) => val < 1,
        then: Yup.number().required(_t('form.required')),
      }),
    timeWithPreviousEmployerMonths: Yup.number().when('timeWithEmployerYears', {
      is: (val: number) => val < 1,
      then: Yup.number().required(_t('form.required')),
    }),
    incomeNetMonthly: Yup.number().required(_t('form.required')),
    otherIncomeNetMonthly: Yup.number().required(_t('form.required')),
    mortgageRentPerMonth: Yup.number().required(_t('form.required')),
    otherBorrowingsPerMonth: Yup.number().required(_t('form.required')),
    previousAddress_1: Yup.string().when('livedAtThisAddressForLessThan_3Years', {
      is: (val: boolean) => !!val && !isBorrower,
      then: Yup.string()
        .required(_t('form.required'))
        .max(streetMaxLength, _t('form.max', { max: streetMaxLength })),
      otherwise: Yup.string(),
    }),
    previousAddress_2: Yup.string().when('livedAtThisAddressForLessThan_3Years', {
      is: (val: boolean) => !!val && !isBorrower,
      then: Yup.string()
        .required(_t('form.required'))
        .max(addressMaxLength, _t('form.max', { max: addressMaxLength })),
      otherwise: Yup.string(),
    }),
    previousAddress_3: Yup.string().when('livedAtThisAddressForLessThan_3Years', {
      is: (val: boolean) => !!val && !isBorrower,
      then: Yup.string().max(addressMaxLength, _t('form.max', { max: addressMaxLength })),
      otherwise: Yup.string(),
    }),
    previousCounty: Yup.string().when('livedAtThisAddressForLessThan_3Years', {
      is: (val: boolean) => !!val && !isBorrower,
      then: Yup.string().required(_t('form.required')),
      otherwise: Yup.string(),
    }),
    previousEircode: Yup.string().when('livedAtThisAddressForLessThan_3Years', {
      is: (val: boolean) => !!val && !isBorrower,
      then: Yup.string()
        .max(16, _t('form.max', { max: 16 }))
        .required(_t('form.required')),
      otherwise: Yup.string(),
    }),
  });
};

export const MotorFinanceLeadDetails = () => {
  const vm = useConnect(ClientDetailsViewModel);

  if (!vm.state.canSeeLeadDetails) {
    return <FooterButtons />;
  }

  const resetFields = (fields: Array<string>, setFieldValue: (field: any, value: any) => void) => {
    fields.forEach(field => setFieldValue(field, ''));
  };

  return (
    <Formik
      initialValues={formInitialValues(vm.state.lead)}
      validationSchema={() => validationSchema(vm.state.isBorrower)}
      onSubmit={async values =>
        vm.submitMotorFinanceLeadDetails({
          values,
          step: Steps.ASSETS,
        })
      }
      enableReinitialize={true}
    >
      {({ handleSubmit, values, errors, setFieldValue, validateField }) => (
        <Form className="form">
          <Title>{_t('loans.contactDetails')}</Title>
          <div className="phoneNumber">
            <PhoneNumber label={_t('layout.phone')} name="phone" type="text" />
          </div>
          <Title>{_t('loans.motorFinanceAddress')}</Title>
          <div className="form-section">
            <div className="row">
              <div style={{ flex: 2 }}>
                <Input type="text" name="zipCode" label={_t('loans.eircode')} />
              </div>
              <Button
                className="eircode-button button-primary"
                type="button"
                onClick={() => {
                  validateField('zipCode');
                  !errors.zipCode && vm.searchEircode(values, values.zipCode);
                }}
              >
                {_t('loans.searchEircode')}
              </Button>
            </div>
            <Input name="street" type="text" label={_t('loans.address1')} />
            <Input name="address_2" type="text" label={_t('loans.address2')} />
            <Input name="address_3" type="text" label={_t('loans.address3')} />
            <Select
              label={_t('loans.county')}
              name="county"
              options={COUNTRY_COUNTY_MAP[values.country]}
            />

            <div className="row">
              <Input
                name="timeAtAddressYears"
                type="number"
                label={_t('loans.timeAtAddressYears')}
                onBlur={e => {
                  const newValue = (e.target as HTMLInputElement).value;
                  setFieldValue(
                    'livedAtThisAddressForLessThan_3Years',
                    isLessThanNumberOfYears(newValue, values.timeWithEmployerMonths, 3)
                  );
                  if (!isLessThanNumberOfYears(newValue, values.timeWithEmployerMonths, 3))
                    resetFields(previousAddressFields, setFieldValue);
                }}
              />
              <Select
                name="timeAtAddressMonths"
                label={_t('loans.timeAtAddressMonths')}
                options={MONTHS_NUMBERS}
                onChange={e => {
                  const newValue = e as { value: string };
                  setFieldValue(
                    'livedAtThisAddressForLessThan_3Years',
                    isLessThanNumberOfYears(values.timeAtAddressYears, newValue.value, 3)
                  );
                  if (!isLessThanNumberOfYears(values.timeAtAddressYears, newValue.value, 3))
                    resetFields(previousAddressFields, setFieldValue);
                }}
              />
            </div>
            <Select
              name="homeStatus"
              label={_t('loans.residentialStatus')}
              options={HOME_STATUSES}
            />
            {!vm.state.isBorrower && values.livedAtThisAddressForLessThan_3Years && (
              <>
                <div className="row">
                  <div style={{ flex: 2 }}>
                    <Input type="text" name="previousEircode" label={_t('loans.previousEircode')} />
                  </div>

                  <Button
                    className="eircode-button button-primary"
                    type="button"
                    onClick={() => {
                      validateField('previousEircode');
                      !errors.previousEircode &&
                        values.previousEircode &&
                        vm.searchEircode(values, values.previousEircode, true);
                    }}
                  >
                    {_t('loans.searchEircode')}
                  </Button>
                </div>
                <Input name="previousAddress_1" type="text" label={_t('loans.previousAddress1')} />
                <Input name="previousAddress_2" type="text" label={_t('loans.previousAddress2')} />
                <Input name="previousAddress_3" type="text" label={_t('loans.previousAddress3')} />
                <div className="row">
                  <Select
                    label={_t('loans.previousCounty')}
                    name="previousCounty"
                    options={COUNTRY_COUNTY_MAP[values.country]}
                  />
                </div>
              </>
            )}
          </div>
          <Title>Employment History</Title>
          <div className="form-section">
            <div className="row">
              <Input name="occupation" label={_t('loans.occupation')} type="text" />
              <Select
                label={_t('loans.employmentStatus')}
                name="employmentStatus"
                options={EMPLOYMENT_STATUS}
              />
            </div>
            <Input label={_t('loans.employerName')} name="employer" type="text" />
            <div className="row">
              <Input
                label={_t('loans.timeWithEmployerYears')}
                name="timeWithEmployerYears"
                type="number"
                onBlur={e => {
                  const newValue = (e.target as HTMLInputElement).value;
                  vm.setIsTimeWithEmployerLessThanOneYear(isLessThanNumberOfYears(newValue, values.timeWithEmployerMonths));
                  if (!vm.state.isTimeWithEmployerLessThanOneYear)
                    resetFields(previousEmployerFields, setFieldValue);
                }}
              />
              <Select
                label={_t('loans.timeWithEmployerMonths')}
                name="timeWithEmployerMonths"
                options={MONTHS_NUMBERS}
                onChange={e => {
                  const newValue = e as { value: string };
                  vm.setIsTimeWithEmployerLessThanOneYear(isLessThanNumberOfYears(values.timeWithEmployerYears, newValue.value));
                  if (!vm.state.isTimeWithEmployerLessThanOneYear)
                    resetFields(previousEmployerFields, setFieldValue);
                }}
              />
            </div>
            {vm.state.isTimeWithEmployerLessThanOneYear &&
              (<>
                <Input
                  label={_t('loans.previousEmployer')}
                  name="previousEmployer"
                  type="text"
                />
                <div className="row">
                  <Input
                    label={_t('loans.timeWithPreviousEmployerYears')}
                    name="timeWithPreviousEmployerYears"
                    type="number"
                  />
                  <Select
                    label={_t('loans.timeWithPreviousEmployerMonths')}
                    name="timeWithPreviousEmployerMonths"
                    options={MONTHS_NUMBERS}
                  />
                </div>
              </>)}
            <div className="row">
              <CurrencyInput name="incomeNetMonthly" label={_t('loans.netSalaryPerMonth')} />
              <CurrencyInput name="otherIncomeNetMonthly" label={_t('loans.otherIncomePerMonth')} />
            </div>
            <div className="row">
              <CurrencyInput name="mortgageRentPerMonth" label={_t('loans.MortgageRentPerMonth')} />
              <CurrencyInput
                name="otherBorrowingsPerMonth"
                label={_t('loans.otherBorrowingsPerMonth')}
              />
            </div>
          </div>
          {vm.state.canSeeFooterButtons ? (
            <FooterButtons
              goNext={handleSubmit}
              saveForLater={() =>
                vm.submitMotorFinanceLeadDetails({
                  values,
                  saveForLater: true,
                  errorKeys: Object.keys(errors) as Array<keyof MotorFinanceLeadDetailsValues>,
                })
              }
              loading={vm.state.isLeadUpdating}
            />
          ) : (
            <FooterButtons />
          )}
        </Form>
      )}
    </Formik>
  );
};

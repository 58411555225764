export const auth = {
  wrongPassword: 'Wrong username or password',
  logIn: 'Log in',
  logOut: 'Log out',
  signUp: 'Sign up',
  continue: 'Continue',
  createYourAccount: 'Create your account',
  greeting: 'Welcome!',
  creatingAccountFor: 'You are creating an account for',
  createPassword: 'Create your password',
  enterPassword: 'Enter password',
  agreeTos:
    'I agree with <tosLink>Terms and Conditions</tosLink> and <policyLink>Privacy Policy</policyLink>',
  tosRequired: 'You must agree to Terms and Conditions and Privacy Policy',
  signInForNews: 'I wish to receive by email news and offers from Capitalflow',
  welcomeBack: 'Welcome back',
  createAnAccount: 'Create an Account',
  dontHaveAnAccount: "Don't have an account?",
  alreadyHaveAnAccount: 'Already have an account?',
  yourEmail: 'Your email',
  enterYourEmail: 'Enter your email',
  password: 'Password',
  enterYourPassword: 'Enter your password',
  confirmPassword: 'Confirm password',
  enterYourPasswordConfirm: 'Confirm your password',
  forgotPassword: 'Forgot password?',
  phoneNumber: 'Phone number',
  phoneMatchFormat: 'Needs to be a valid mobile number',
  enterYourPhoneNumber: 'Enter your phone number eg. 353XXXXXXX',
  smsAuth: 'SMS Authentication',
  googleAuth: 'Authentication App',
  invalidSecurityCode: 'Invalid code. Try again',
  linkNoLongerValid: 'This link is no longer valid',
  resetLinkNoLongerValid: 'This link is no longer valid. Please reset your password again.',
  pleaseContactSupport: `Need any help?? \nPlease contact our support team.`,
  contactSupportCta: 'Contact Support Team',
  goToLogin: 'Go to Log in',
  accountSuccessfullyCreatedOrVerified:
    'Your account has been successfully {action}.\n' +
    'You can now log in to the platform using the button below.',
  congratulations: 'Congratulations!',
  securityCodeSent: 'Your security code has been sent to <highlight>{phoneNumber}</highlight>.',
  enterSmsCode: 'Enter SMS code',
  didntReceiveSms: "Didn't receive an SMS? <link>Send again</link>",
  finish: 'Finish',
  useAuthApp: 'Use the verification code generated by the Authenticator app on your smartphone.',
  enterAuthCode: 'Enter 6-digit security code',
  goBack: 'Back',
  resetPasswordHelp:
    "To reset your password, please enter your email address below. We'll send you an email with instructions to follow.",
  resetPassword: 'Reset password',
  weSentYouAnEmail: "We've sent you an email",
  checkYourEmailToReset:
    'Check your email inbox and follow the instruction to reset your password.',
  resettingPassword: 'Resetting password',
  createNewPassword: 'Create new password',
  newPassword: 'New password',
  setPassword: 'Set password',
  passwordChanged: 'Password has been changed',
  passwordChangedSuccess:
    'Your password has been changed successfully.\n' +
    'You can now use it to log in to Capitalflow.',
  resetYourPassword: 'Reset your password',
  setNewPassword: 'Set new password',
  errorSigningUp: 'There was an error signing up.',
  newToCapitalflow: 'New to Capitalflow?',
  needAFewDetails:
    'We just need a few details to get you started! Simply click on the link below to proceed.',
  getAQuote: 'Get a Quote',
  agreeCookies: 'By using this service you are agreeing to our',
  termsAndConditions: 'Terms & Conditions',
  and: 'and',
  privacyPolicy: 'Privacy Policy',
  changeCompany: 'Change company',
  noCompanies: 'No companies',
  changeCompanySubtitle: 'Choose your company',
  changeCompanyDescription: 'Choose which company you would like to login as.',
  emailAddress: 'Email Address',
  enterFirstName: 'Enter first name',
  enterLastName: 'Enter last name',
  enterPhoneNumber: 'Enter phone number',
  inviteUser: 'Invite user',
  addingNewUser: 'Invite new user',
  cancel: 'Cancel',
  invite: 'Invite',
  enterEmail: 'Enter email address',
  role: 'Role',
  status: 'Status',
  noUsers: 'No users',
  companyName: 'Company name',
  actions: 'Actions',
  noInvitations: "You don't have any invitations.",
  accept: 'Accept',
  delete: 'Delete',
  deleteUser: 'Delete user from organisation',
  deleteUserConfirmation:
    'Are you sure you want to delete this user from this organisation? Once removed, they will no longer have access to the Portal.',
  confirm: 'Confirm',
  email: 'Email',
  emailVerification: 'Email verification',
  processing: 'Processing...',
  verificationEmailInfo: 'We are verifying your email, please wait.',
  verificationMessageSuccess: 'Your account has been successfully verified.',
  authorizationFailedTitle: 'Authorization Failed',
  authorizationFailedMessage: 'Sorry, something went wrong during authorization.',
};

import { createSelector } from '@reduxjs/toolkit';
import { UserType } from 'models/User';
import { auth0Selector, Auth0State, auth0Actions } from 'modules/auth/authStore';
import { ViewModel } from 'utils/framework';

interface State {
  user_type?: UserType;
  active: boolean;
  sessionDuration: number;
}

enum SessionDurationInMinutes {
  Customer = Number(process.env.REACT_APP_CUSTOMER_TIMEOUT_MINUTES),
  Partner = Number(process.env.REACT_APP_PARTNER_TIMEOUT_MINUTES),
}

const minuteAsMs = 60 * 1000;

const getSessionDuration = (userType?: UserType) => {
  if (userType === UserType.Borrower) return SessionDurationInMinutes.Customer * minuteAsMs;

  return SessionDurationInMinutes.Partner * minuteAsMs;
};

export class UserActivityViewModel extends ViewModel {
  state!: State;

  protected stateMapper = createSelector(auth0Selector, (auth0: Auth0State): State => {
    const userType = auth0.user?.userType;
    return {
      user_type: userType,
      active: !!userType,
      sessionDuration: getSessionDuration(userType),
    };
  });

  logout = () => {
    this.store.dispatch(auth0Actions.Logout());
  };
}

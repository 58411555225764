import React from 'react';
import { useConnect } from 'utils/framework';
import { LeadsSection } from './leads/LeadsSection';
import { ApplicationsSection } from './applications/ApplicationsSection';
import { LoansSection } from './loans/LoansSection';
import { DashboardViewModel } from './DashboardViewModel';

export const DashboardPage = () => {
  const vm = useConnect(DashboardViewModel);

  return (
    <div>
      {vm.state.canSeeLeads && <LeadsSection />}
      {vm.state.canSeeApplications && <ApplicationsSection />}
      {vm.state.canSeeLoans && <LoansSection />}
    </div>
  );
};

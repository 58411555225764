export const openBanking = {
  btnConnectCustomer: 'Connect',
  btnSentCustomer: 'Connecting',
  btnCompleteCustomer: 'Connected',
  btnExpiredCustomer: 'Re-Connect',

  btnConnectPartner: 'Request Open Banking',
  btnSentPartner: 'Open Banking Requested',
  btnCompletePartner: 'Open Banking Complete',
  btnExpiredPartner: 'Open Banking Expired',

  openBanking: 'Open Banking',
  openBankingHelp:
    'To save your time, if your bank account is with AIB, BOI, PTSB, you can simply click below to connect to open banking, which will allow us to view your statements digitally.',
  openBankingHelpPartner:
    "To save your time, if your customers' bank account is with AIB, BOI, PTSB, you can simply click below to request your customer to connect to open banking, which will allow Capitalflow to view their statements digitally.",
  openBankingStatusError: 'Updating open banking status failed',
  redirectStatusFailed: 'Your Open Banking connection has failed',
};

import React from 'react';
import styled from '@emotion/styled';
import { getT } from 'utils/framework/intl';
import { MyDocumentsViewModel } from './MyDocumentsViewModel';
import { useConnect } from 'utils/framework';
import { Button } from 'modules/ui-components/Button';
import { Filters } from './components/Filters';
import { Table } from 'modules/ui-components/table/Table';
import { RequestPagination } from 'modules/ui-components/RequestPagination';
import { DOCUMENTS_LIMIT } from 'utils/constants';

interface Props {
  className?: string;
}

const MyDocumentsPageComponent = ({ className }: Props) => {
  const vm = useConnect(MyDocumentsViewModel);
  const _t = getT();

  return (
    <div className={className}>
      <div className="header">
        <h1 className="page__header">{_t('myDocuments.title')}</h1>
        <Filters isDisabled={vm.state.areFiltersDisabled} />
      </div>
      <Table
        tableHead={vm.state.tableHead}
        tableBody={vm.state.tableBody}
        isLoading={vm.state.isLoading}
        noContentNode={_t('myDocuments.noDocuments')}
        renderColumns={{
          3: row => (
            <Button
              onClick={() => vm.downloadDocument(row.documentId)}
              className="primary download-btn"
            >
              {_t('myDocuments.download')}
            </Button>
          ),
        }}
      />
      <RequestPagination
        className="pagination"
        isLoading={vm.state.isLoading}
        hasNextPage={vm.state.documentListHasNextPage}
        hasPreviousPage={vm.state.documentListOffset >= DOCUMENTS_LIMIT}
        previous={vm.loadPreviousPage}
        next={vm.loadNextPage}
      />
    </div>
  );
};

export const MyDocumentsPage = styled(MyDocumentsPageComponent)`
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .download-btn {
    width: 5rem;
  }

  .pagination {
    margin-top: 1.25rem;
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import { useField } from 'formik';
import ReactSelect, { ActionMeta, Options, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Props as SelectProps } from 'react-select';
import classNames from 'classnames';

export interface BaseOption {
  label: string;
  value: string;
}

interface Props extends Partial<SelectProps> {
  options: Options<BaseOption>;
  className?: string;
  name: string;
  label?: React.ReactNode;
  onCreateOption?: (option: string) => void;
  menuPlacement?: 'auto' | 'bottom' | 'top' | undefined;
}

const SelectComponent = ({ className, label, name, onCreateOption, ...rest }: Props) => {
  const [field, meta, { setValue, setTouched }] = useField(name);

  const invalid = meta.touched && meta.error;
  const SelectComponent = (onCreateOption ? CreatableSelect : ReactSelect) as any;

  const selectedValue: SingleValue<BaseOption> = rest.options.find(
    o => o.value === field.value
  ) || {
    value: field.value,
    label: '',
  };

  return (
    <label className={classNames(className, { invalid })}>
      <div className="label">{label}</div>
      <SelectComponent
        classNamePrefix={'select'}
        {...rest}
        {...field}
        isOptionSelected={(option: SingleValue<BaseOption>) => option?.value === field.value}
        value={selectedValue}
        onChange={(
          option: { label: string; value: string; __isNew__: string },
          action: ActionMeta<{
            label: string;
            value: string;
          }>
        ) => {
          rest.onChange && rest.onChange(option, action);

          if (option?.__isNew__ && onCreateOption) {
            onCreateOption(option.value);
          }
          setValue(option?.value);
        }}
        onBlue={setTouched}
      />
      {invalid ? <div className="error">{meta.error}</div> : null}
    </label>
  );
};

export const Select = styled(SelectComponent)`
  display: flex;
  flex-direction: column;

  .select__indicator-separator {
    display: none;
  }

  .select__control {
    border-radius: 2px;
    box-shadow: none;
    border: var(--border-default);
  }

  .select__menu {
    margin: 0.125rem 0;
    border-radius: 2px;
    border: var(--border-default);
    box-shadow: 0 2px 6px -2px rgba(0, 38, 73, 0.16);
  }

  .select__menu-list {
    padding: 0.125rem;
  }

  .select__value-container {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.71;
  }

  .select__input-container {
    margin: 0;
    padding: 0;
  }

  .select__option {
    border-radius: 1px;
    font-size: 0.875rem;
    line-height: 1.71;
    color: var(--primary-dark-blue-900);

    &--is-focused {
      background-color: var(--primary-dark-blue-25);
    }

    &--is-selected {
      background-color: var(--primary-dark-blue-50);
    }

    &--is-disabled {
      background-color: var(--primary-dark-100);
    }
  }

  .select__placeholder {
    color: var(--primary-dark-300);
  }

  .label {
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--primary-dark-blue-900);
    margin-bottom: 0.5rem;
  }

  .error {
    position: relative;
    font-size: 0.75rem;
    line-height: 1.67;
    color: var(--additional-red-500);
    margin-top: 0.125rem;
  }

  &.invalid {
    .select__control {
      border-color: var(--additional-red-500);
    }
  }
`;

import React from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';
import { Breakpoints } from 'utils/styling';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
}

const ModalComponent = ({ isOpen, className, children }: Props) => {
  return (
    <div>
      <ReactModal
        className={className}
        overlayClassName="overlay"
        ariaHideApp={false}
        isOpen={isOpen}
      >
        {children}
      </ReactModal>
    </div>
  );
};

export const Modal = styled(ModalComponent)`
  position: absolute;
  z-index: 10000;
  top: 50%;
  left: 50%;
  max-width: 37.5rem;
  width: 90%;
  padding: 0.4rem 2rem 1.5rem 2rem;

  background-color: var(--primary-dark-000);
  border-radius: var(--border-radius);

  transform: translateY(-50%) translateX(-50%);
  transition: all 500ms ease-in-out;

  .modal-body {
    text-align: center;
  }

  .modal-icon {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
  }

  .modal-heading {
    display: flex;
    justify-content: center;
    font-family: var(--font-family-secondary);
    font-weight: bold;
    letter-spacing: 0.4px;
    color: var(--primary-dark-blue-500);
    font-size: 1.4rem;
  }

  .modal-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .modal-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .modal-footer {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .modal-buttons {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    @media only screen and (max-width: ${Breakpoints.tablet}px) {
      flex-direction: column;
    }

    & > button {
      cursor: pointer;
    }
  }
`;

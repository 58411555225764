import React from 'react';
import styled from '@emotion/styled';
import { Confirmation } from 'modules/ui-components/Confirmation';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { AuthBox } from 'modules/auth/components/AuthBox';
import { AnonymousLayout } from '../AnonymousLayout';

interface Props {
  className?: string;
}

const ErrorPageComponent = ({ className }: Props) => {
  return (
    <AnonymousLayout>
      <AuthBox className={className} title={<FormattedMessage id="notFoundPage.errorNumber500" />}>
        <Confirmation
          message={<FormattedMessage id="notFoundPage.somethingWentWrong" />}
          description={<FormattedMessage id="notFoundPage.errorInfo" />}
          icon={<Error />}
        />
      </AuthBox>
    </AnonymousLayout>
  );
};

export const ErrorPage = styled(ErrorPageComponent)`
  .auth-box__box {
    min-height: 25rem;
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import { Confirmation } from 'modules/ui-components/Confirmation';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { AuthBox } from 'modules/auth/components/AuthBox';

interface Props {
  className?: string;
}

const NotFoundPageComponent = ({ className }: Props) => {
  return (
    <AuthBox className={className} title={<FormattedMessage id="notFoundPage.errorNumber404" />}>
      <Confirmation
        message={<FormattedMessage id="notFoundPage.pageNotFound" />}
        description={<FormattedMessage id="notFoundPage.thisIsNotThePageYouAreLookingFor" />}
        icon={<Error />}
      />
    </AuthBox>
  );
};

export const NotFoundPage = styled(NotFoundPageComponent)`
  .auth-box__box {
    min-height: 25rem;
  }
`;

export const myDocuments = {
  title: 'My Documents',
  name: 'Name',
  type: 'Type',
  uploadDate: 'Upload date',
  fileSize: 'File size',
  applicationNumber: 'Application number',
  actions: 'Actions',
  noDocuments: 'No documents',
  download: 'Download',
  documentType: 'Document type',
  all: 'All documents',
  clearFilters: 'Clear',
  filterBy: 'Filter by',
  filters: 'Filters',
  cancel: 'Cancel',
  done: 'Done',
};

export enum Route {
  Dashboard = 'DASHBOARD',
  LoanApplication = 'LOAN_APPLICATION',
  UploadDocuments = 'UPLOAD_DOCUMENTS',
  Profile = 'PROFILE',
  MyDocuments = 'MY_DOCUMENTS',
  Payments = 'PAYMENTS',
  PaymentCheckout = 'PAYMENT_CHECKOUT',
  PaymentCheckoutComplete = 'PAYMENT_CHECKOUT_COMPLETE',
  OpenBankingRedirect = 'OPEN_BANKING_REDIRECT',
  LoginRedirect = 'LOGIN_REDIRECT',
  Login = 'LOGIN',
  Signup = 'SIGNUP',
  AccountSetup = 'ACCOUNT_SETUP',
  ForgotPassword = 'FORGOT_PASSWORD',
  ForgotPasswordSuccess = 'FORGOT_PASSWORD_SUCCESS',
  ResetPassword = 'RESET_PASSWORD',
  EmailVerification = 'EMAIL_VERIFICATION',
  ErrorPage = 'ERROR_PAGE',
}

export const routes = {
  [Route.Dashboard]: '/',
  [Route.Profile]: '/profile',
  [Route.LoanApplication]: '/application/:id',
  [Route.UploadDocuments]: '/upload-documents/:module/:id',
  [Route.MyDocuments]: '/documents',
  [Route.Payments]: '/payments',
  [Route.PaymentCheckoutComplete]: '/payments/complete',
  [Route.PaymentCheckout]: '/payments/:id',
  [Route.OpenBankingRedirect]: '/open-banking-redirect',
  [Route.LoginRedirect]: '/login-redirect',
  [Route.ErrorPage]: '/error',

  [Route.Login]: '/login',
  [Route.Signup]: '/signup',
  [Route.AccountSetup]: '/set-password/:token',
  [Route.ForgotPassword]: '/forgot-password',
  [Route.ForgotPasswordSuccess]: '/forgot-password/success',
  [Route.ResetPassword]: '/reset-password/:token',
  [Route.EmailVerification]: '/verification/:token',
};

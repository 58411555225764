import styled from '@emotion/styled';
import React from 'react';
import classNames from 'classnames';
import { Breakpoints } from 'utils/styling';
import { Tooltip } from '../Tooltip';
import { maxTextLength, RenderColumnsType, RowDataType } from './Table';

interface TableCellProps {
  renderColumns: RenderColumnsType;
  cellIndex: number;
  item: RowDataType;
  tableHeaders: string[];
  className?: string;
}

const checkTooltip = (itemContent: RowDataType) => {
  if (typeof itemContent !== 'string') return false;
  return itemContent.length > maxTextLength;
};

const TableCellComponent = ({
  renderColumns,
  cellIndex,
  item,
  tableHeaders,
  className,
}: TableCellProps) => {
  const itemContent =
    renderColumns && cellIndex in renderColumns ? renderColumns[cellIndex](item) : item;

  const isTooltip = checkTooltip(itemContent);

  if (isTooltip) {
    return (
      <td className={classNames(className, 'tooltip')} key={cellIndex}>
        <Tooltip trigger="click" tooltipContent={() => itemContent}>
          <div className="cell-content tooltip tooltip__child">{itemContent}</div>
        </Tooltip>
        <div className="cell-content cell-mobileHeader">{tableHeaders[cellIndex]}</div>
      </td>
    );
  }

  return (
    <td className={className} key={cellIndex}>
      <div className="cell-content">{itemContent}</div>
      <div className="cell-content cell-mobileHeader">{tableHeaders[cellIndex]}</div>
    </td>
  );
};

export const TableCell = styled(TableCellComponent)`
  display: flex;
  align-items: center;

  padding: 0.313rem 0.625rem;

  .cell-mobileHeader {
    display: none;
  }

  @media (max-width: ${Breakpoints.tablet}px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: unset;

    padding: 0;

    .cell-content {
      padding: 0.625rem;
    }

    .cell-mobileHeader {
      min-width: 35%;
      width: 35%;
      display: block;

      background: var(--primary-dark-blue-50);
      color: var(--primary-dark-300);
    }
  }
`;
